<template>
    <div class="export">
        <a-dropdown :trigger="['click']">
            <a-menu slot="overlay" @click="downloadOperate">
                <a-menu-item key="1" class="more-operate-item more-operate-item1">
                    <div>导出选项</div>
                    <div>(选中{{selectedRowKeys.length}}条)</div>
                </a-menu-item>
                <a-menu-item key="2" class="more-operate-item more-operate-item1">
                    <div>导出所有</div>
                    <div>(共{{total}}条)</div>
                </a-menu-item>
                <a-menu-item key="3" class="more-operate-item">
                    <div>导出记录</div>
                </a-menu-item>
            </a-menu>
            <a-button> 导出 </a-button>
        </a-dropdown>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="outputWordModal" title="选择需要导出的字段" footer='' width='720px'>
            <div class="output-word-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <div class="select-all-word">
                    <a-checkbox @change="selectAllWord2" :checked="checkAll2">
                        全选
                    </a-checkbox>
                </div>
                <div class="word-list">
                    <a-checkbox-group @change="selectItemWord2" style="width:100%" v-model="checkedWordList2">
                        <a-row style="margin-bottom:0px">
                            <a-col :span="6" v-for="(item, index) in fieldList" :key="index">
                                <a-checkbox :value="item.value"  >
                                    {{item.label}}
                                </a-checkbox>
                            </a-col>
                        </a-row>
                    </a-checkbox-group>
                </div>
                <a-divider class="divider3" type="horizontal" />
                <div class="btn-group">
                    <a-button type="primary" ghost @click="cancelOutput">取消</a-button>
                    <a-button type="primary" @click="sureOutput">确认</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="overDownloadModal" footer='' width='480px'>
            <div class="over-download-modal modal-public">
                <div class="sure-off-bind">
                    <a-icon type="check-circle" />
                    <span>导出数据任务已完成</span>
                </div>
                <div class="jump-tip">
                    您可前往
                    <span @click="showDownloadList">导出-导出记录</span>
                    查看进度详情
                </div>
                <div class="tip-static">并在任务完成后进行下载</div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group2">
                    <a-button ghost @click="cancelOverDownload">取消</a-button>
                    <a-button type="primary" @click="sureOverDownload">知道了</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="outputRecordModal" title="导出记录" footer='' width='1120px'>
            <div class="output-record-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <a-row class="condition">
                    <a-col :span="8">
                        <div class="title title1">选择模块：</div>
                        <div class="content1">
                            <a-select class="select-module" placeholder='请选择模块' @change="changeModule">
                                <a-select-option :value="item.value" v-for="(item,index) in moduleList" :key='index'>
                                    {{item.name}}
                                </a-select-option>
                            </a-select>
                        </div>
                    </a-col>
                    <a-col :span="8">
                        <div class="title title2">选择时间：</div>
                        <div class="content2">
                            <a-range-picker format="YYYY-MM-DD HH:mm:ss" @change="dateChange"/>
                        </div>
                    </a-col>
                    <a-col :span="8">
                        <div class="title title3">选择状态：</div>
                        <div class="content3">
                            <a-select class="select-module" placeholder='请选择状态' @change="changeState">
                                <a-select-option value="1">
                                    待执行
                                </a-select-option>
                                <a-select-option value="2">
                                    执行中
                                </a-select-option>
                                <a-select-option value="3">
                                    已下载
                                </a-select-option>
                                <a-select-option value="4">
                                    执行失败
                                </a-select-option>
                            </a-select>
                        </div>
                    </a-col>
                </a-row>
                <a-table rowKey="downloadUrl" :data-source="outputData" :pagination='false' :loading="ifShowLoading" :scroll="{ y: 340 }">
                    <a-table-column key="createTime" title="创建时间" data-index="createTime" />
                    <a-table-column key="moduleName" title="模块" data-index="moduleName" />
                    <a-table-column key="dataCount" title="导出数据量" data-index="dataCount" />
                    <a-table-column key="status" title="进度" data-index="status">
                        <template slot-scope="text, record">
                            <div v-if="record.status==1">
                                <span style="width:8px;height:8px;border-radius:4px;background:rgba(255, 195, 0, 1);display:inline-block;margin-right:8px;"></span>
                                <span>待执行</span>
                            </div>
                            <div v-if="record.status==2">
                                <span style="width:8px;height:8px;border-radius:4px;background:rgba(24, 144, 255, 1);display:inline-block;margin-right:8px;"></span>
                                <span>执行中</span>
                            </div>
                            <div v-if="record.status==3">
                                <span style="width:8px;height:8px;border-radius:4px;background:rgba(67, 207, 124, 1);display:inline-block;margin-right:8px;"></span>
                                <span>已完成</span>
                            </div>
                            <div v-if="record.status==4">
                                <span style="width:8px;height:8px;border-radius:4px;background:#F12121;display:inline-block;margin-right:8px;"></span>
                                <span>执行失败</span>
                            </div>
                        </template>
                    </a-table-column>
                    <a-table-column key="downloadUrl" title="附件" data-index="downloadUrl">
                        <template slot-scope="text, record">
                            <div v-if="record.downloadUrl!=''">
                                <a :href="record.downloadUrl">
                                    <a-icon style="color:#1890FF;font-size:20px;cursor:pointer;" type="download" />
                                </a>
                            </div>
                        </template>
                    </a-table-column>
                </a-table>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group">
                    <a-button type="primary" ghost @click="cancelOutputRecord">取消</a-button>
                    <a-button type="primary" @click="sureOutputRecord">确认</a-button>
                </div>
            </div>
        </a-modal>
    </div>
</template>

<script>
export default {
    props:[],
    data(){
        return{
            outputWordModal: false,
            outputRecordModal: false,
            checkAll2: false,
            checkedWordList2: [],
            overDownloadModal: false,
            moduleId: '',
            selectedRowKeys: [],
            dataObj: {},
            total: 0,
            fieldList: [],
            currentOutSelect: '',
            selectModuleId: '',
            downloadState: '',
            moduleList: [],
            startTime: '',
            endTime: '',
            ifShowLoading: false,
            outputData: [],
            targetPage: ''
        }
    },
    methods: {
        downloadOperate(e) {
            if(e.key == '1'){
                if(this.selectedRowKeys.length==0){
                    this.$message.error('请先选择')
                }else{
                    this.outputWordModal = true
                    this.currentOutSelect = ''
                    this.getFieldList()
                }
            }else if(e.key == '2'){
                if(this.total == 0){
                    this.$message.error('暂无可导出内容')
                }else{
                    this.outputWordModal = true
                    this.currentOutSelect = 'all'
                    this.$parent.passDataToChild()
                    this.getFieldList()
                }
            }else if(e.key == '3'){
                this.outputRecordModal = true
                this.getOutputList()
            }
        },
        cancelOutput() {
            this.outputWordModal = false
        },
        sureOutput() {
            this.outputWordModal = false
            this.axios.post('/api/export', {
                moduleId: this.moduleId,
                count: this.currentOutSelect==''?this.selectedRowKeys.length:this.total,
                idList: this.currentOutSelect==''?this.selectedRowKeys:undefined,
                deptId: this.currentOutSelect==''?undefined:this.dataObj.deptId,
                digitalEmployeeId: this.targetPage==''?undefined:this.dataObj.digitalEmployeeId,
                dataViewId: this.currentOutSelect==''?undefined:this.dataObj.dataViewId,
                dataRange: this.currentOutSelect==''?undefined:this.dataObj.dataRange,
                conditionList: this.currentOutSelect==''?undefined:this.dataObj.conditionList,
                fieldIdList: this.checkedWordList2,
            })
            .then(response=> {
                if(response.data.code == 0){
                    this.overDownloadModal = true
                }else{
                    this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                }
            })
            .catch(error=> {
                console.log(error);
            });
        },
        showDownloadList() {
            this.overDownloadModal = false
            this.outputRecordModal = true
        },
        cancelOutputRecord() {
            this.outputRecordModal = false
        },
        sureOutputRecord() {
            this.outputRecordModal = false
        },
        selectAllWord2(e) {
            this.checkedWordList2 = []
            if(e.target.checked) {
                this.checkAll2 = true
                for(let i = 0; i < this.fieldList.length; i++){
                    this.checkedWordList2.push(this.fieldList[i]['value'])
                }
            }else{
                this.checkAll2 = false
            }
        },
        selectItemWord2(e) {
            this.checkedWordList2 = e
            if(this.checkedWordList2.length == this.fieldList.length) {
                this.checkAll2 = true
            }else{
                this.checkAll2 = false
            } 
        },
        cancelOverDownload() {
            this.overDownloadModal = false
        },
        sureOverDownload() {
            this.overDownloadModal = false
        },
        getData(moduleId, selectedRowKeys, obj, flag) {
            this.selectedRowKeys = selectedRowKeys
            this.dataObj = obj
            if(flag!=undefined){
                this.targetPage = flag
            }
        },
        getTotalNum(num, moduleId) {
            this.total = num
            this.moduleId = moduleId
        },
        getFieldList() {
            this.axios(
                {
                    method:'get',
                    url:'/api/export/fieldList', 
                    params: {
                        moduleId: this.moduleId,
                    }
                }
            )
            .then(response=> {
                if(response.data.code == 0){
                    this.fieldList = response.data.data
                }else{
                    this.$message.error(response.data.userTip);
                    if(response.data.code == 'A0200'){
                        this.$router.push("/login")
                    }
                }
            })
            .catch(error=> {
                console.log(error)
            })
        },
        getModuleList() {
            this.axios(
                {
                    method:'get',
                    url:'/api/module/list/export', 
                }
            )
            .then(response=> {
                if(response.data.code == 0){
                    this.moduleList = response.data.data
                }else{
                    this.$message.error(response.data.userTip);
                    if(response.data.code == 'A0200'){
                        this.$router.push("/login")
                    }
                }
            })
            .catch(error=> {
                console.log(error)
            })
        },
        dateChange(date, dateString) {
            this.startTime = dateString[0]
            this.endTime = dateString[1]
            this.getOutputList()
        },
        getOutputList() {
            this.ifShowLoading = true
            this.axios(
                {
                    method:'get',
                    url:'/api/export/list', 
                    params: {
                        moduleId: this.selectModuleId,
                        startTime: this.startTime,
                        endTime: this.endTime,
                        status: this.downloadState
                    }
                }
            )
            .then(response=> {
                if(response.data.code == 0){
                    this.ifShowLoading = false
                    this.outputData = response.data.data
                }else{
                    this.$message.error(response.data.userTip);
                    if(response.data.code == 'A0200'){
                        this.$router.push("/login")
                    }
                }
            })
            .catch(error=> {
                console.log(error)
            })
        },
        changeModule(val) {
            this.selectModuleId = val
            this.getOutputList()
        },
        changeState(val) {
            this.downloadState = val
            this.getOutputList()
        }
    },
    mounted: function() {
        this.getModuleList()
    }
}
</script>
<style scoped lang="less">
    .export{
        display: inline-block;
    }
    .modal-public{
        .divider1{
            margin: 0px 0 24px 0;
        }
        .divider2{
            margin: 24px 0 12px;
        }
        .divider3{
            margin: 0px 0 12px;
        }
        .btn-group{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                margin-right: 16px;
            }
        }
        .btn-group2{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                background: #fff;
                color: rgba(121, 136, 155, 0.7);
                border: 1px solid rgba(121, 136, 155, 0.5);
                margin-right: 16px;
            }
            button:nth-child(1):hover{
                background: rgba(121, 136, 155, 0.08);
            }
        }
    }
    .output-word-modal{
        padding-bottom: 48px;
        .select-all-word{
            margin-bottom: 12px;
        }
        .word-list{
            div{
                margin-bottom: 13px;
            }
        }
    }
    .over-download-modal{
        padding-top: 24px;
        padding-bottom: 48px;
        .sure-off-bind{
            i{
                color: rgba(67, 207, 124, 1);
                font-size: 24px;
            }
            span{
                color: rgba(18, 18, 18, 1);
                font-size: 20px;
                margin-left: 24px;
            }
        }
        .jump-tip{
            color: rgba(80, 80, 80, 1);
            font-size: 14px;
            padding-left: 50px;
            margin: 24px 0 8px 0;
            span{
                color: rgba(24, 144, 255, 1);
                margin: 0 4px;
                cursor: pointer;
            }
        }
        .tip-static{
            color: rgba(80, 80, 80, 1);
            font-size: 14px;
            padding-left: 50px;
        }
    }
    .output-record-modal{
        padding-bottom: 48px;
        .condition{
            margin-bottom: 24px;
            .title{
                color: rgba(153, 153, 153, 1);
                font-size: 14px;
                margin: 0 0 8px;
            }
            .title1{
                padding-right: 32px;
            }
            .title2{
                padding: 0 16px;
            }
            .title3{
                padding-left: 32px;
            }
            .content1{
                padding-right: 32px;
                color: rgba(121, 136, 155, 1);
                .select-module{
                    width: 100%;
                }
            }
            .content2{
                padding: 0 16px;
                color: rgba(153, 153, 153, 1);
            }
            .content3{
                padding-left: 32px;
                color: rgba(153, 153, 153, 1);
                .select-module{
                    width: 100%;
                }
            }
        }
    }
</style>