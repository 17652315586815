<template>
    <div class="call-detail">
        <div v-if="!ifPhone">
            <div class="head">
                <span class="title">{{digitalDetail.moduleName+"-"+digitalDetail.dataTitle}}</span>
                <OutLink :moduleId='moduleId' :dataId='currentId' :original='""'></OutLink>
            </div>
            <div class="content">
                <div class="content-in">
                    <div class="modular-content">
                        <div class="left-content">
                            
                        </div>
                        <div class="center-content">
                            <div class="basic-info">
                                <div class="title">基本信息</div>
                                <a-row class="row-list">
                                    <a-col :span="(item.type=='LONG_TEXT'||item.type=='CUSTOMER_LABEL')?24:12" class="col-list" :class="{'col-left':index%2==0,'col-right':index%2!=0, 'col-time':item.type=='TIME'}" v-for="(item,index) in digitalDetail.detailList" :key='index'>

                                        <div class="first-content">{{item.name}}:</div>

                                        <div v-if="item.type!='CUSTOMER_LABEL'" class="second-content">{{item.value}}</div>

                                        <div v-if="item.type=='CUSTOMER_LABEL'" class="label-content">
                                            <span v-for="(item1,index1) in item.value" :style="{'background':item1.color}" :key='index1'>
                                                {{item1.name}}
                                            </span>
                                        </div>
                                    </a-col>
                                </a-row>
                                <div style="color:#bcbcbc;font-size:12px;">附件:</div>
                                <a-row style="margin-top:9px;">
                                    <a-col :class="{'pd-8':index%2!=0,}" :span="12" v-for="(item,index) in attachmentList" :key='index'>
                                        <div class="attachment-item">
                                            <img :src="filterImg(item.suffix)" alt="">
                                            <div class="attachment-content">
                                                <div class="name-operation">
                                                    <span :title='item.fileName'>{{item.fileName.length>16?item.fileName.substring(0,16)+'...':item.fileName}}</span>
                                                    <a-icon @click="downloadFire(item.previewUrl)" type="download" />
                                                </div>
                                                <div style="clear:both"></div>
                                                <div class="file-size">{{item.size}}</div>
                                            </div>
                                        </div>
                                    </a-col>
                                </a-row>
                            </div>
                            <div class="follow-time">
                                <div class="title">跟踪时间</div>
                                <a-row class="row-time">
                                    <a-col :span="12">
                                        <div class="row-time-title">最后动态时间：</div>
                                        <div class="row-time-value">{{timeObj.last_dynamic_time}}</div>
                                    </a-col>
                                    <a-col :span="12">
                                        <div class="row-time-title">下次联系时间：</div>
                                        <div class="row-time-value">{{timeObj.next_contact_time}}</div>
                                    </a-col>
                                </a-row>
                            </div>
                            <div class="dynamic-info">
                                <div class="title">
                                    <span>动态信息</span>
                                </div>
                                <div class="filter-condition">
                                    <span @click="selectDynamic('')" :class="{'active':currentActive==''}">全部{{" ("+dynamicNumData.total+")"}}</span>
                                    <span @click="selectDynamic(2)" :class="{'active':currentActive==2}">跟踪轨迹{{" ("+dynamicNumData.follow+")"}}</span>
                                    <span @click="selectDynamic(3)" :class="{'active':currentActive==3}">通话轨迹{{" ("+dynamicNumData.chat+")"}}</span>
                                    <span @click="selectDynamic(1)" :class="{'active':currentActive==1}">数据轨迹{{" ("+dynamicNumData.data+")"}}</span>
                                </div>
                                <div class="dynamic-list" v-for="(item,index) in dynamicData" :key='index'>
                                    <div class="date">
                                        <span>{{item.createTime.split(" ")[0]}}</span>
                                        <span :style="{'background':randomColor()}"></span>
                                    </div>
                                    <div class="active-track">
                                        <div class="time">
                                            <span>{{item.operator+''+item.createTime.split(" ")[1]}}</span>
                                        </div>
                                        <div class="active-event">{{item.action}}</div>
                                        <div class="dynamic-content">{{item.content}}</div>
                                        <div v-if="item.type == 2">
                                            <div class="label-content">
                                                <span v-for="(item1,index1) in item.labelList" :style="{'border-color':item1.color,'color':item1.color}" :key='index1'>
                                                    {{item1.name}}
                                                </span>
                                            </div>
                                            <div class="next-time">
                                                <span>下次联系时间：</span>
                                                <span>{{item.nextContactTime}}</span>
                                            </div>
                                        </div>
                                        <div v-if="item.type == 3" class="chat-detail">
                                            <div class="play-voice" @click="viewChatDetail(item.viewUrl)">
                                                <a-icon type="play-circle" />
                                                <span>对话详情</span>
                                            </div>
                                            <span class="voice-content">通话时长{{item.duration}}s</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="comments">
                                <Comments ref="commentChild"></Comments>
                            </div>
                        </div>
                        <div class="right-content">
                            <div class="data-info">
                                <div class="related-info">
                                    <span></span>
                                    数据信息
                                </div>
                                <ul>
                                    <li>
                                        <div>创建时间：</div>
                                        <div>{{digitalDetail.createTime}}</div>
                                    </li>
                                    <li>
                                        <div>修改时间：</div>
                                        <div>{{digitalDetail.updateTime}}</div>
                                    </li>
                                    <li>
                                        <div>创建人：</div>
                                        <div>{{digitalDetail.createBy}}</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="ifPhone" style="background:#F0F2F3">
            <div style="background:#fff;height:144px;padding:16px;">
                <div>
                    <span style="font-weight:bold;font-size:18px;">{{digitalDetail.moduleName+"-"+digitalDetail.dataTitle.length>12?digitalDetail.dataTitle.substring(0,12)+'...':digitalDetail.dataTitle}}</span>
                    <OutLinkPhone :moduleId='moduleId' :dataId='currentId' :original='""'></OutLinkPhone>
                </div>
                <ul style="padding-top:16px;font-size:13px;">
                    <li>
                        <div>
                            <span style="display:inline-block;width:70px;color:rgba(188, 188, 188, 1);">创建人：</span>
                            <span style="color:rgba(80, 80, 80, 1);">{{digitalDetail.createBy}}</span>
                        </div>
                    </li>
                    <li style="padding:8px 0">
                        <div>
                            <span style="display:inline-block;width:70px;color:rgba(188, 188, 188, 1);">创建时间：</span>
                            <span style="color:rgba(80, 80, 80, 1);">{{digitalDetail.createTime}}</span>
                        </div>
                    </li>
                    <li>
                        <div>
                            <span style="display:inline-block;width:70px;color:rgba(188, 188, 188, 1);">修改时间：</span>
                            <span style="color:rgba(80, 80, 80, 1);">{{digitalDetail.updateTime}}</span>
                        </div>
                    </li>
                   
                </ul>
            </div>
            <div style="margin-top:7px;padding:16px;background:#fff">
                <div v-for="(item,index) in digitalDetail.detailList" :key='index' style="margin-bottom:20px;">
                    <div style="color:rgba(188, 188, 188, 1);font-size:13px;">{{item.name}}:</div>
                    <div v-if="item.code!='workTime'&&item.type!='CUSTOMER_LABEL'" style="color:rgba(51, 51, 51, 1);font-size:16px;">{{item.value}}</div>
                    <div v-if="item.code=='workTime'" style="color:rgba(51, 51, 51, 1);font-size:16px;">{{item.value.timeList.length>0?'已设定':'未设定'}}</div>

                    <div v-if="item.type=='CUSTOMER_LABEL'">
                        <span v-for="(item1,index1) in item.value" :style="{'background':item1.color,'margin':'4px 4px 0 0','float':'left','font-size':'12px','color':'#fff','padding':'3px 12px'}" :key='index1'>
                            {{item1.name}}
                        </span>
                    </div>
                    <div style="clear:both"></div>
                </div>
            </div>
            <div style="clean:both"></div>
            <div style="margin-top:7px;padding:16px;background:#fff">
                <div class="comments">
                    <Comments ref="commentChild"></Comments>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Comments from "../../components/Comments";
    import OutLink from "../../components/OutLink";
    import OutLinkPhone from "../../components/OutLinkPhone";
    export default {
        name: 'OutCallShare',
        components: {Comments, OutLink, OutLinkPhone},
        data() {
            return {
                currentId: '',
                moduleId: '',
                digitalDetail: {
                    dataTitle: ''
                },
                ifPhone: false,
                currentCondition: 1,
                timeObj: {
                    last_dynamic_time: '',
                    next_contact_time: ''
                },
                currentActive: 2,
                dynamicList: [],
                colorList: ['#256fe7', '#e54444', '#5dc7ff', '#ffaf00', '#23da77', '#a680ff'],
                dynamicData: [],
                filterContent: '',
                newFormData1: '',
                attachmentList: [],
                currentFireId: '',
                dynamicNumData: {
                    tatal: 0,
                    follow: 0,
                    data: 0,
                    chat: 0
                },
            }
        },
        methods: {
            downloadFire(url) {
                window.open(url, '_blank');
            },
            filterImg(type) {
                let url = "http://eocc.oss-cn-beijing.aliyuncs.com/icon/"
                let suffix = type.toLowerCase();
                if (suffix == "xls" || suffix == "xlsx") {
                    url+="excel.png";
                } else if (suffix == "doc" || suffix == "docx") {
                    url+="word.png";
                } else if (suffix == "zip" || suffix == "rar") {
                    url+="zip.png";
                } else if (suffix == "ppt") {
                    url+="ppt.png";
                } else if (suffix == "pptx") {
                    url+="ppt.png";
                } else if (suffix == "pdf") {
                    url+="pdf.png";
                } else if (suffix == "txt") {
                    url+="txt.png";
                } else {
                    url+="unknown.png";
                }
                return url
            },
            querySelectData() {
                this.axios(
                   {
                        method:'get',
                        url:'api/externalLinks/'+this.currentId, 
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.digitalDetail = response.data.data
                        this.dynamicList = response.data.data.dynamicList
                        this.attachmentList = response.data.data.attachmentList
                        this.$refs.commentChild.getParentCommentTree(this.moduleId, response.data.data.id, false)
                        this.selectDynamic('')
                        let arr = response.data.data.dynamicList
                        this.dynamicNumData.total = arr.length
                        for(let i = 0; i < arr.length; i++){
                            if(arr[i]['type'] == 1){
                               this.dynamicNumData.data++ 
                            }else if(arr[i]['type'] == 2){
                                this.dynamicNumData.follow++ 
                            }else if(arr[i]['type'] == 3){
                                this.dynamicNumData.chat++ 
                            }
                        }
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            chooseCondition(type) {
                this.currentCondition = type
            },
            selectDynamic(type) {
                this.currentActive = type
                let arr = []
                for(let i = 0; i < this.dynamicList.length; i++){
                    if(type == ''){
                        arr = this.dynamicList
                    }else if(type == 1){
                        if(this.dynamicList[i]['type'] == 1){
                            arr.push(this.dynamicList[i])
                        }
                    }else if(type == 2){
                        if(this.dynamicList[i]['type'] == 2){
                            arr.push(this.dynamicList[i])
                        }
                    }else if(type == 3){
                        if(this.dynamicList[i]['type'] == 3){
                            arr.push(this.dynamicList[i])
                        }
                    }
                }
                this.dynamicData = arr
            },
            randomColor() {
                let num = Math.floor(Math.random()*6)
                let color = this.colorList[num]
                return color
            },
            viewChatDetail(url) {
                if(url == ''){
                    this.$message.warning('该对话未接通')
                }else{
                    window.open(url, '_blank');
                }
            },
        },
        mounted: function() {
            this.currentId = this.$route.query.id
            this.moduleId = this.$route.query.moduleId
            this.querySelectData()
            let screenWidth = document.body.clientWidth
            if(screenWidth < 900){
                this.ifPhone = true
            }
            window.onresize = () => {
                screenWidth = document.body.clientWidth
                if(screenWidth < 900){
                    this.ifPhone = true
                }else{
                    this.ifPhone = false
                }
            }
        }
    }
</script>

<style scoped lang="less">
    .call-detail{
        height: 100%;
        position: relative;
        background: rgba(240, 242, 243, 1);
        .head{
            height: 48px;
            line-height: 48px;
            padding: 0 24px;
            background: #fff;
            z-index: 100;
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            button{
                float: right;
                width: 64px;
                height: 32px;
                margin: 8px 0 0 8px;
            }
            .title{
                color: rgba(18, 18, 18, 1);
                font-size: 16px;
            }
            i{
                float: right;
                margin-top: 16px;
                font-size: 16px;
                color: rgba(204, 204, 204, 1);
                cursor: pointer;
            }
        }
        .content{
            position: absolute;
            padding: 64px 16px 16px 16px;
            left: 0px;
            top: 0px;
            height: 100%;
            padding-top: 64px;
            width: 100%;
            .content-in{
                width: 100%;
                height: 100%;
                position: relative;
                .modular-content{
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    height: 100%;
                    width: 100%;
                    overflow-y: auto;
                    display: flex;
                    .left-content{
                        width: 288px;
                        height: 100%;
                        border-radius: 4px;
                        padding: 21px 24px;
                    }
                    .center-content{
                        flex: 1;
                        height: 100%;
                        margin: 0 24px;
                        overflow-y: auto;
                        overflow-x: hidden;
                        .basic-info{
                            width: 100%;
                            // height: 704px;
                            background: #fff;
                            border-radius: 4px;
                            padding: 24px;
                            .title{
                                color: rgba(18, 18, 18, 1);
                                font-size: 14px;
                            }
                            .col-left{
                                padding-right: 24px;
                            }
                            .col-right{
                                // padding-left: 24px;
                            }
                            .row-list{
                                .col-list{
                                    margin-top: 19px;
                                    margin-bottom: 5px;
                                    min-height: 69px;
                                    .first-content{
                                        color: rgba(188, 188, 188, 1);
                                        font-size: 12px;
                                    }
                                    .second-content{
                                        color: rgba(51, 51, 51, 1);
                                        font-size: 14px;
                                        margin-top: 9px;
                                        img{
                                            width: 21px;
                                        }
                                        .change-head{
                                            color: rgba(24, 144, 255, 1);
                                            font-size: 12px;
                                            cursor: pointer;
                                            margin-left: 24px;
                                        }
                                    }
                                    .selection-range{
                                        margin-top: 9px;
                                        width: 100%;
                                    }
                                    .third-content{
                                        color: rgba(51, 51, 51, 1);
                                        font-size: 14px;
                                        margin-top: 4px;
                                        display: none;
                                    }
                                    .user-content{
                                        color: rgba(51, 51, 51, 1);
                                        font-size: 14px;
                                        margin-top: 4px;
                                    }
                                    .label-content{
                                        margin-top: 9px;
                                        button{
                                            border: none;
                                            background: rgba(158, 172, 196, 0.12);
                                            color: rgba(158, 172, 196, 1);
                                            font-size: 13px;
                                            height: 28px;
                                        }
                                        span{
                                            display: inline-block;
                                            height: 28px;
                                            line-height: 28px;
                                            color: #fff;
                                            font-size: 13px;
                                            padding: 0 16px;
                                            margin: 8px 8px 8px 0;
                                            background: rgba(158, 172, 196, 0.12);
                                            i{
                                                cursor: pointer;
                                            }
                                        }
                                    }
                                }
                                .col-time{
                                    display: none !important;
                                }
                            }
                            .add-attachment{
                                margin-left: 24px;
                                border: none;
                                background: rgba(158, 172, 196, 0.12);
                                color: rgba(158, 172, 196, 1);
                                font-size: 13px;
                            }
                            .pd-8{
                                padding-left: 8px;
                            }
                            .attachment-item{
                                padding: 9px 12px 0 17px;
                                height: 52px;
                                border: 1px solid rgba(158, 172, 196, 0.3);
                                margin-top: 8px;
                                display: flex;
                                img{
                                    width: 36px;
                                    height: 36px;
                                }
                                .attachment-content{
                                    flex: 1;
                                    padding-left: 8px;
                                    .name-operation{
                                        span{
                                            float: left;
                                            color: rgba(18, 18, 18, 1);
                                            font-size: 13px;
                                        }
                                        i{
                                            float: right;
                                            color: rgba(158, 172, 196, 1);
                                            font-size: 13px;
                                            cursor: pointer;
                                            margin-left: 12px;
                                        }
                                        i:hover{
                                            color: #1890ff;
                                        }
                                    }
                                    .file-size{
                                        color: rgba(158, 172, 196, 1);
                                        font-size: 12px;
                                    }
                                }
                            }
                        }  
                        .follow-time{
                            height: 136px;
                            padding: 18px 0 0 24px;
                            margin-top: 16px;
                            border-radius: 4px;
                            background: #fff;
                            .title{
                                color: rgba(18, 18, 18, 1);
                                font-size: 14px;
                            }
                            .row-time{
                                margin-top: 24px;
                                .row-time-title{
                                    color: rgba(188, 188, 188, 1);
                                    font-size: 12px;
                                }
                                .row-time-value{
                                    color: rgba(51, 51, 51, 1);
                                    font-size: 14px;
                                    margin-top: 9px;
                                }
                            }
                        }
                        .dynamic-info{
                            padding: 18px 0 36px 24px;
                            margin-top: 16px;
                            border-radius: 4px;
                            background: #fff;
                            .title{
                                span{
                                    color: rgba(18, 18, 18, 1);
                                    font-size: 14px;
                                }
                                button{
                                    float: right;
                                    color: rgba(24, 144, 255, 1);
                                    font-size: 14px;
                                    border: none;
                                }
                            }
                            .filter-condition{
                                margin-top: 23px;
                                margin-bottom: 16px;
                                span{
                                    display: inline-block;
                                    height: 28px;
                                    line-height: 28px;
                                    color: rgba(121, 136, 155, 1);
                                    font-size: 13px;
                                    margin-right: 8px;
                                    padding: 0 12px;
                                    cursor: pointer;
                                    background: rgba(255, 255, 255, 0.12);
                                }
                                span.active{
                                    color: rgba(24, 144, 255, 1);
                                }
                            }
                            .dynamic-list{
                                display: flex;
                                margin-bottom: 24px;
                                .date{
                                    width: 90px;
                                    padding-right: 10px;
                                    color: rgba(153, 153, 153, 1);
                                    font-size: 12px;
                                    span:nth-child(2){
                                        display: inline-block;
                                        width: 8px;
                                        height: 8px;
                                        border-radius: 4px;
                                        float: right;
                                        margin-top: 8px;
                                    }
                                }
                                .active-track{
                                    flex: 1;
                                    .time{
                                        color: rgba(153, 153, 153, 1);
                                        font-size: 12px;
                                    }
                                    .active-event{
                                        color: rgba(153, 153, 153, 1);
                                        font-size: 12px;
                                    }
                                    .dynamic-content{
                                        color: rgba(80, 80, 80, 1);
                                        font-size: 14px;
                                    }
                                    .chat-detail{
                                        .play-voice{
                                            color: #1890ff;
                                            font-size: 12px;
                                            text-decoration: underline;
                                            display: inline-block;
                                            margin-right: 16px;
                                            cursor: pointer;
                                            i{
                                                margin-right: 2px;
                                            }
                                        }
                                        .voice-content{
                                            color: #505050;
                                            font-size: 12px;
                                        }
                                    }
                                    .label-content{
                                        // margin-top: 9px;
                                        button{
                                            border: none;
                                            background: rgba(158, 172, 196, 0.12);
                                            color: rgba(158, 172, 196, 1);
                                            font-size: 13px;
                                            height: 28px;
                                            border: 1px solid rgba(158, 172, 196, 1);
                                            margin: 8px 8px 8px 0;
                                        }
                                        span{
                                            display: inline-block;
                                            height: 28px;
                                            line-height: 28px;
                                            border: 1px solid #fff;
                                            font-size: 13px;
                                            padding: 0 16px;
                                            margin: 8px 8px 8px 0;
                                            background: #fff;
                                            border-radius: 4px;
                                            i{
                                                cursor: pointer;
                                            }
                                        }
                                    }
                                    .next-time{
                                        color: rgba(80, 80, 80, 1);
                                        font-size: 14px;
                                    }
                                }
                            }
                        }
                        .comments{
                            padding: 18px 24px;
                            background: #fff;
                            margin-top: 16px;
                            border-radius: 4px;
                            .title{
                                color: rgba(18, 18, 18, 1);;
                                font-size: 14px;
                            }
                            .textarea-comment{
                                margin-top: 20px;
                                background: rgba(247, 247, 247, 1);
                                border: none;
                                resize: none;
                            }
                            .release{
                                text-align: right;
                                color: rgba(24, 144, 255, 1);
                                font-size: 14px;
                                margin-top: 4px;
                                span{
                                    cursor: pointer;
                                }
                            }
                        }  
                    }
                    .right-content{
                        width: 288px;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        .data-info{
                            width: 100%;
                            height: 280px;
                            background: #fff;
                            border-radius: 4px;
                            margin-bottom: 16px;
                            padding: 18px 24px;
                            .related-info{
                                color: rgba(18, 18, 18, 1);
                                font-size: 14px;
                                span{
                                    width: 3px;
                                    height: 14px;
                                    float: left;
                                    background: rgba(24, 144, 255, 1);
                                    margin: 4px 7px 0 0;
                                }
                            }
                            ul{
                                li{
                                    padding-top: 24px;
                                    div:nth-child(1){
                                        color: rgba(188, 188, 188, 1);
                                        font-size: 12px;
                                        margin-bottom: 4px;
                                    }
                                    div:nth-child(2){
                                        color: rgba(80, 80, 80, 1);
                                        font-size: 14px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>