<template>
     <div class="user-manage">
        <div class="head">
            <span class="title">{{moduleName}}</span>
            <a-icon type="exclamation-circle" />
        </div>
        <div class="content">
            <div class="basic-info">
                <div class="operate-group">
                    <ScreenColumn ref="child3"></ScreenColumn>
                    <a-divider class="vertical" type="vertical" />
                    <a-button class="add-data" type="primary" @click="addData">
                        <a-icon type="plus" />
                        新增
                    </a-button>
                    <Export ref="child4"></Export>
                    <MoreOperation ref="child5"></MoreOperation>
                </div>
                <div class="tree-table">
                    <div class="tree">
                        <div style="color:rgba(18, 18, 18, 1);font-size:16px;padding-left:24px;margin-bottom:0px;">
                            部门树
                            <span style="display:inline-block;width:326px;height:1px"></span>
                        </div>
                        <TreeData ref="child1"></TreeData>
                    </div>
                    <div class="table-data">
                        <a-table
                            :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
                            :columns="columns"
                            :data-source="tableData"
                            :pagination="false"
                            :scroll="{ x: 'max-content' }"
                        >
                            <span slot="dataTitle" slot-scope="text, record">
                                <a @click="viewRecords(record)">{{ record.dataTitle }}</a>
                            </span>
                            <div slot="phone" slot-scope="text, record">
                                <span v-html="record.phone"></span>
                            </div>
                        </a-table>
                        <div class="pagination-table">
                            <a-pagination
                                show-size-changer
                                :total="total"
                                :show-total="total => `共 ${total} 条     `"
                                v-model="currentPage"
                                @change="pageChange"
                                @showSizeChange="onShowSizeChange"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <a-drawer
            title='模块名称-新增数据'
            placement="right"
            :closable="true"
            :visible="visible"
            :destroyOnClose='true'
            :maskClosable='true'
            @close="onClose"
            :width='800'
            :mask='true'
            :headerStyle="{ border: 'none', height: '56px', position: 'absolute', width: '100%', left: '0', top: '0', zIndex: '100'}"
            :bodyStyle="{ background: 'rgba(240, 242, 243, 1)', height: '100%', position: 'absolute', width: '100%', left: '0', top: '0', zIndex: '10', padding: '72px 0 0' }"
        >
            <div class="drawer-set drawer-public">
                <div class="drawer-content">
                    <div class="drawer-body-content">
                        <a-row class="row-list">
                            <a-col :span="12" class="col-left">
                                <div class="title">
                                    <span>账号：</span>
                                    <span class="icon">*</span>
                                </div>
                                <a-input class="content" v-model="dataObj.account" placeholder="请输入账号" />
                            </a-col>
                            <a-col :span="12" class="col-right">
                                <div class="title">
                                    <span>部门：</span>
                                    <span class="icon">*</span>
                                </div>
                                <div class="clean"></div>
                                <a-tree-select
                                    v-model="dataObj.deptId"
                                    style="width: 265px;display:inline-block;"
                                    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                                    :tree-data="treeData"
                                    placeholder="请选择"
                                    tree-default-expand-all
                                >
                                </a-tree-select>
                            </a-col>
                        </a-row>
                        <a-row class="row-list">
                            <a-col :span="12" class="col-left">
                                <div class="title">
                                    <span>是否为管理员：</span>
                                </div>
                                <div class="clean"></div>
                                <a-select class="select-item content" v-model="dataObj.isAdmin">
                                    <a-select-option value="0">
                                        否
                                    </a-select-option>
                                    <a-select-option value="1">
                                        是
                                    </a-select-option>
                                </a-select>
                            </a-col>
                            <a-col :span="12" class="col-right">
                                <div class="title">
                                    <span>状态：</span>
                                </div>
                                <div class="clean"></div>
                                <a-select class="select-item content" v-model="dataObj.status">
                                    <a-select-option value="1">
                                        启用
                                    </a-select-option>
                                    <a-select-option value="2">
                                        停用
                                    </a-select-option>
                                </a-select>
                            </a-col>
                        </a-row>
                        <a-row class="row-list">
                            <a-col :span="12" class="col-left">
                                <div class="title">
                                    <span>姓名：</span>
                                    <span class="icon">*</span>
                                </div>
                                <a-input class="content" v-model="dataObj.name" placeholder="请输入姓名" />
                            </a-col>
                            <a-col :span="12" class="col-right">
                                <div class="title">
                                    <span>密码：</span>
                                </div>
                                <div class="clean"></div>
                                <div class="original-password content">初始密码为123456</div>
                            </a-col>
                        </a-row>
                    </div>
                </div>
                <div class="drawer-foot">
                    <div class="btn-group">
                        <a-button type="primary" ghost @click="cancelDrawer">取消</a-button>
                        <a-button type="primary" @click="sureDrawer(true)">提交并新增下一条</a-button>
                        <a-button type="primary" @click="sureDrawer(false)">提交</a-button>
                    </div>
                </div>
            </div>
        </a-drawer>
        <a-drawer
            title='用户管理-用户名'
            placement="right"
            :closable="true"
            :visible="visible2"
            :destroyOnClose='true'
            :maskClosable='true'
            @close="onClose2"
            :width='800'
            :mask='true'
            :headerStyle="{ border: 'none', height: '56px', position: 'absolute', width: '100%', left: '0', top: '0', zIndex: '100'}"
            :bodyStyle="{ background: 'rgba(240, 242, 243, 1)', height: '100%', position: 'absolute', width: '100%', left: '0', top: '0', zIndex: '10', padding: '56px 0 0' }"
        >
            <div class="drawer-view drawer-public">
                <div style="height:100%;overflow-y:auto">
                    <div class="basic-info">
                        <a-row class="basic-info-row">
                            <a-col :span="8">
                                <div class="title">创建人：</div>
                                <div class="content">{{currentPersonalInfo.createBy}}</div>
                            </a-col>
                            <a-col :span="8">
                                <div class="title">创建时间：</div>
                                <div class="content">{{currentPersonalInfo.createTime}}</div>
                            </a-col>
                            <a-col :span="8">
                                <div class="title">修改时间：</div>
                                <div class="content">{{currentPersonalInfo.updateTime}}</div>
                            </a-col>
                        </a-row>
                    </div>
                    <div class="self-info">
                        <div class="self-content">
                            <div class="head-title">个人信息</div>
                            <a-row class="self-info-row">
                                <a-col class="col-left" :span="12">
                                    <div class="title">账号：</div>
                                    <div class="content">{{currentPersonalInfo.account}}</div>
                                </a-col>
                                <a-col class="col-right" :span="12">
                                    <div class="title">部门：</div>
                                    <div v-if="!ifEditUserDetail" class="content">{{currentPersonalInfo.deptName}}</div>
                                    <a-tree-select
                                        style="width:100%"
                                        v-if="ifEditUserDetail"
                                        class="selection-range"
                                        @change="changeDepartment"
                                        v-model="currentPersonalInfo.deptId"
                                        :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                                        :tree-data="currentPersonalInfo.deptTree"
                                        :placeholder="currentPersonalInfo.deptName"
                                        tree-default-expand-all
                                    >
                                    </a-tree-select>
                                </a-col>
                            </a-row>
                            <a-row class="self-info-row">
                                <a-col class="col-left" :span="12">
                                    <div class="title">企业名称：</div>
                                    <div class="content">{{currentPersonalInfo.orgName}}</div>
                                </a-col>
                                <a-col class="col-right" :span="12">
                                    <div class="title">是否为管理员：</div>
                                    <div v-if="!ifEditUserDetail" class="content">{{currentPersonalInfo.isAdmin=='0'?'否':'是'}}</div>
                                    <a-select v-if="ifEditUserDetail" class="select-item content" v-model="currentPersonalInfo.isAdmin">
                                        <a-select-option :value="0">
                                            否
                                        </a-select-option>
                                        <a-select-option :value="1">
                                            是
                                        </a-select-option>
                                    </a-select>
                                </a-col>
                            </a-row>
                            <a-row class="self-info-row">
                                <a-col class="col-left" :span="12">
                                    <div class="title">状态：</div>
                                    <div v-if="!ifEditUserDetail" class="content">{{currentPersonalInfo.status==2?'停用':'启用'}}</div>
                                    <a-select v-if="ifEditUserDetail" class="select-item content" v-model="currentPersonalInfo.status">
                                        <a-select-option :value="2">
                                            停用
                                        </a-select-option>
                                        <a-select-option :value="1">
                                            启用
                                        </a-select-option>
                                    </a-select>
                                </a-col>
                                <a-col class="col-right" :span="12">
                                    <div class="title">头像：</div>
                                    <div>
                                        <img :src="currentPersonalInfo.avatar" alt="">
                                    </div>
                                </a-col>
                            </a-row>
                            <a-row class="self-info-row">
                                <a-col class="col-left" :span="12">
                                    <div class="title">姓名：</div>
                                    <div v-if="!ifEditUserDetail" class="content">{{currentPersonalInfo.name}}</div>
                                    <div v-if="ifEditUserDetail" class="content">
                                        <a-input v-model="currentPersonalInfo.name" class="content" placeholder="请输入姓名" />
                                    </div>
                                </a-col>
                                <a-col class="col-right" :span="12">
                                    <div class="title">微信号：</div>
                                    <div class="content">{{currentPersonalInfo.wechatName}}</div>
                                </a-col>
                            </a-row>
                            <a-row class="self-info-row">
                                <a-col class="col-left" :span="12">
                                    <div class="title">密码：</div>
                                    <div class="content">
                                        <span>******</span>
                                        <span v-if="ifEditUserDetail" @click="resetPassword" class="reset-password">重置密码</span>
                                    </div>
                                    
                                </a-col>
                                <a-col class="col-right" :span="12">
                                    <div class="title">
                                        手机号：
                                        <!-- <a-icon @click="playPhone(currentPersonalInfo.phone)" type="phone" style="cursor:pointer"/> -->
                                    </div>
                                    <div class="content">
                                        <span class="take-phone" @click="playPhone(currentPersonalInfo.phone)">
                                            {{currentPersonalInfo.phone}}
                                            <img title='呼叫' class="take-phone1"  src='http://eocc.oss-cn-beijing.aliyuncs.com/icon/phone.svg'>
                                            <img title='呼叫' class="take-phone2"  src='http://eocc.oss-cn-beijing.aliyuncs.com/icon/phone1.svg'>
                                        </span>
                                    </div>
                                </a-col>
                            </a-row>
                            <a-row class="self-info-row">
                                <a-col class="col-left" :span="12">
                                    <div class="title">号码数量：</div>
                                    <div class="content" v-if="!ifEditUserDetail">
                                        <span>{{ currentPersonalInfo.callingNumbers }}</span>
                                    </div>
                                    <div class="content" v-if="ifEditUserDetail">
                                        <span style="color:rgba(80, 80, 80, 1);">已选择</span>
                                        <span style="color:rgba(24, 144, 255, 1)"> {{selectedRowKeys1.length}} </span>
                                        <span style="color:rgba(80, 80, 80, 1)">个</span>
                                        <a-icon @click="selectNumberFun" style="margin-left:36px;color:rgba(121, 136, 155, 0.5);cursor:pointer" type="zoom-in" />
                                    </div>
                                </a-col>
                            </a-row>
                        </div>
                        <div class="extension-info">
                            <div class="head-title">分机信息</div>
                            <a-row class="self-info-row">
                                <a-col class="col-left" :span="12">
                                    <div class="title">是否已激活：</div>
                                    <div class="content">
                                        <span>{{currentPersonalInfo.extension==null?'未激活':'已激活'}}</span>
                                        <span v-if="ifEditUserDetail&&currentPersonalInfo.extension==null" @click="activationNum" style="color:rgba(24, 144, 255, 1);font-size: 12px;margin-left: 24px;cursor: pointer;">激活</span>
                                    </div>
                            
                                </a-col>
                                <a-col class="col-right" :span="12">
                                    <div class="title">分机号：</div>
                                    <div class="content">{{currentPersonalInfo.extension==null?'-':currentPersonalInfo.extension.extensionCode}}</div>
                                </a-col>
                            </a-row>
                            <a-row class="self-info-row">
                                <a-col class="col-left" :span="12">
                                    <div class="title">注册地址：</div>
                                    <div class="content">
                                        <span>{{currentPersonalInfo.extension==null?'-':currentPersonalInfo.extension.sipServer}}</span>
                                    </div>
                                </a-col>
                                <a-col class="col-right" :span="12">
                                    <div class="title">密码：</div>
                                    <div class="content extention-password">
                                        <div v-if="currentPersonalInfo.extension!=null">
                                            <span v-if="!ifShowPwd">******</span>
                                            <span v-if="ifShowPwd">{{currentPersonalInfo.extension.password}}</span>
                                            <a-icon v-if="!ifShowPwd" @click="showPassword" type="eye" />
                                            <a-icon v-if="ifShowPwd" @click="hidePassword" type="eye-invisible" />
                                        </div>
                                        <span v-if="currentPersonalInfo.extension==null">-</span>
                                        <span v-if="ifEditUserDetail" @click="editPwd" class="reset-password">修改</span>
                                    </div>
                                </a-col>
                            </a-row>
                             <!-- <a-row class="self-info-row">
                                <a-col class="col-left" :span="12">
                                    <div class="title">分机状态：</div>
                                    <div class="content">
                                        <span>{{currentPersonalInfo.extension==null?'-':currentPersonalInfo.extension.status}}</span>
                                    </div>
                                </a-col>
                            </a-row> -->
                        </div>
                    </div>
                </div>
                <div class="drawer-foot">
                    <div v-if="!ifEditUserDetail" class="btn-group">
                        <a-button type="primary" ghost @click="deleteInfoDrawer">删除</a-button>
                        <a-button type="primary" @click="editDrawer">编辑</a-button>
                    </div>
                    <div v-if="ifEditUserDetail" class="btn-group">
                        <a-button type="primary" ghost @click="cancelInfoDrawer">取消</a-button>
                        <a-button type="primary" @click="saveDrawer">保存</a-button>
                    </div>
                </div>
            </div>
        </a-drawer>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="distributionModal" title="删除用户" footer='' width='600px'>
            <div class="distribution-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <div>
                    <div style="color:rgba(153, 153, 153, 1);font-size:14px;margin-bottom:12px;">请选择删除后将该用户的数据分配给谁（单选）：</div>
                    <DistributionUser ref="child2"></DistributionUser>
                </div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group3">
                    <a-button ghost @click="cancelDelete1">取消</a-button>
                    <a-button type="primary" @click="sureDelete1">确认</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="deleteSelfModal" footer='' width='480px'>
            <div class="delete-modal modal-public">
                <div class="sure-off-bind">
                    <a-icon type="warning" />
                    <span>您您确定要删除用户吗？</span>
                </div>
                <div class="tip">数据删除后，将无法恢复</div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group2">
                    <a-button ghost @click="cancelDelete2">取消</a-button>
                    <a-button type="primary" @click="sureDelete2">确认</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="activationModal" footer='' width='480px'>
            <div class="delete-modal modal-public">
                <div class="sure-off-bind">
                    <a-icon type="warning" />
                    <span>您确定要激活吗？</span>
                </div>
                <div class="tip">一旦激活，无法取消</div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group2">
                    <a-button ghost @click="cancelActivation">取消</a-button>
                    <a-button type="primary" @click="sureActivation">确认</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="extensionTipModal" footer='' width='480px'>
            <div class="delete-modal modal-public">
                <div class="sure-off-bind">
                    <a-icon type="warning" />
                    <span>分机用户人数已达上限</span>
                </div>
                <div class="tip">当前版本只支持10个分机用户，请开通收费版</div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group2">
                    <a-button ghost @click="cancelExtension">取消</a-button>
                    <a-button type="primary" @click="sureExtension">确认</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="resetModal" footer='' width='480px'>
            <div class="reset-modal modal-public">
                <div class="sure-off-bind">
                    <a-icon type="warning" />
                    <span>您确定要重置该用户的密码吗？</span>
                </div>
                <div class="tip">密码重置为初始密码123456</div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group2">
                    <a-button ghost @click="cancelReset">取消</a-button>
                    <a-button type="primary" @click="sureReset">确认</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="changePwdModal" title="修改密码" footer='' width='480px'>
            <div class="edit-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <div class="changed-name">输入新的密码：</div>
                <a-input v-model.trim="newpwd" type='password' autocomplete="off" placeholder="请输入密码" />
                <div class="changed-name">再次输入新的密码：</div>
                <a-input v-model.trim="againPwd" type='password' autocomplete="off" placeholder="请输入密码" />
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group">
                    <a-button type="primary" ghost @click="cancelChangePwd">取消</a-button>
                    <a-button type="primary" @click="sureChangePwd">确认</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="chooseAccountModal" :closable='false' footer='' :zIndex='10000000' width='1020px'>
            <div class="choose-account-modal">
                <div class="add-title">
                    <span>选外显号码</span>
                    <a-icon @click="cancelChooseAccount" type="close" />
                </div>
                <div class="choose-account-data">
                    <a-row>
                        <a-col :span="13">
                            <div class="account-left-content">
                                <a-input v-model="searchNum" @input='inputChange()' placeholder="搜索号码" />
                                <div class="account-table">
                                    <a-table
                                        :row-selection="{ selectedRowKeys: selectedRowKeys1, onChange: onSelectChange1 }"
                                        :columns="columns1"
                                        :data-source="numberData1"
                                        :pagination='false'
                                        scroll="{ x: '100%', y: '240'}"
                                        :rowKey="(record,index)=>{return record.id}"
                                    >
                                    </a-table>
                                </div>
                            </div>
                        </a-col>
                        <a-col :span="11">
                            <div class="account-right-content">
                                <div class="choosed-title">
                                    <span>已选择：{{ selectedRowKeys1.length }}条</span>
                                    <span @click="cleanSelected">清空</span>
                                </div>
                                <div class="choosed-content">
                                    <div class="choosed-item" v-for="(item,index) in selectedRowKeys1" :key='index'>
                                        <a-icon class="choosed-phone" type="phone" />
                                        <span class="choosed-number">{{dataObj2[item]}}</span>
                                        <a-icon class="choosed-close" @click="deleteSelectedNum(index)" type="close" />
                                    </div>
                                </div>
                            </div>
                        </a-col>
                    </a-row>
                </div>
                <div class="btn-group">
                    <div>
                        <a-button type="default" @click="cancelChooseAccount">取消</a-button>
                        <a-button type="primary" @click="sureChooseAccount">确认</a-button>
                    </div>
                </div>
            </div>
        </a-modal>
    </div>
</template>
<script>
    const columns = [];
    const tableData = [];
    import TreeData from '../../components/TreeData'
    import ScreenColumn from '../../components/ScreenColumn'
    import Export from '../../components/Export'
    import MoreOperation from '../../components/MoreOperation'
    import DistributionUser from '../../components/DistributionUser'
    export default {
        name: 'UserManger',
        components: {TreeData, ScreenColumn, Export, MoreOperation, DistributionUser},
        data () {
            return {
                selectedRowKeys: [],
                visible: false,
                visible2: false,
                ifEditUserDetail: false,
                resetModal: false,
                deleteSelfModal: false,
                checkedAllWord: false,
                selectedNum: 0,
                totalNum: 0,
                // deleteSelectedModal: false,
                checkedAllWord2: false,
                // setConditionModal: false,
                // batchDeleteModal: false,
                treeData: [],
                currentPage: 1,
                pageSize: 10,
                moduleId: '',
                tableData,
                columns,
                total: 0,
                showField: {
                    fieldList: [],
                    valueList: []
                },
                conditionList: [],
                deptId: '',
                selectedDepartment: '',
                ifAdmin: 0,
                dataObj: {
                    id: undefined,
                    type: '',
                    account: '',
                    deptId: '',
                    isAdmin: '',
                    status: '',
                    name: '',
                    isResetPwd: undefined
                },
                currentId: '',
                currentPersonalInfo: '',
                isResetPwd: undefined,
                distributionModal: false,
                activationModal: false,
                changePwdModal: false,
                newpwd: '',
                againPwd: '',
                extensionTipModal: false,
                extensionData: '',
                ifShowPwd: false,
                chooseAccountModal: false,
                searchNum: '',
                selectedRowKeys1: [],
                numberData1: [],
                dataObj2: {},
                timer4: null,
                columns1: [
                     {
                        dataIndex: 'id',
                        key: 'id',
                        title: 'id',
                        colSpan: 0,
                        customRender: () => {
                            return {
                                attrs: {
                                    colSpan: 0,
                                },
                            };
                        },
                    },
                    {
                        dataIndex: 'number',
                        scopedSlots: {
                            customRender: 'number'
                        },
                        key: 'number',
                        title: '号码'
                    },
                    {
                        dataIndex: 'userCount',
                        scopedSlots: {
                            customRender: 'userCount'
                        },
                        key: 'userCount',
                        title: '用户数'
                    },
                    {
                        dataIndex: 'remark',
                        scopedSlots: {
                            customRender: 'remark'
                        },
                        key: 'remark',
                        title: '备注'
                    },
                ],
                moduleName: ''
            }
        },
        methods: {
            querySelectData() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/user/'+this.currentId, 
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.currentPersonalInfo = response.data.data
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            rowclick(record) {
                return {
                    on: {
                        click: () => {
                            this.visible2 = true;
                            this.currentId = record.id
                            this.querySelectData()
                        },
                    }
                };
            },
            queryBasicData(type) {
                if(type == 'init'){
                    this.currentPage = 1
                }
                this.axios.post('/api/module/data/list', {
                    current: this.currentPage,
                    size: this.pageSize,
                    moduleId: this.moduleId,
                    conditionList: this.$refs.child3.conditionList,
                    deptId: this.deptId
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.tableData = response.data.data.tableData.records
                        this.columns = response.data.data.tableHeader
                        for(let i = 0; i < this.columns.length; i++){
                            if(this.columns[i]['title'] == 'id'){
                                this.columns[i] = {
                                    title: this.columns[i]['title'],
                                    dataIndex: this.columns[i]['dataIndex'],
                                    colSpan:0,  
                                    customRender: () => {
                                        return {
                                            attrs: {
                                                colSpan: 0,
                                            },
                                        };
                                    },
                                }
                            }
                            if(this.columns[i]['dataIndex'] == 'dataTitle'){
                                this.columns[i] = {
                                    title: this.columns[i]['title'],
                                    dataIndex: this.columns[i]['dataIndex'],
                                    scopedSlots: { customRender: 'dataTitle' },
                                }
                            }
                        }
                        this.total = response.data.data.tableData.total
                        this.$refs.child4.getTotalNum(response.data.data.tableData.total, this.moduleId)
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            viewRecords(record) {
                this.visible2 = true;
                this.currentId = record.id
                this.querySelectData()
            },
            pageChange(current, pageSize) {
                this.currentPage = current
                this.pageSize = pageSize
                this.queryBasicData()
            },
            onShowSizeChange(current, pageSize) {
                this.currentPage = current
                this.pageSize = pageSize
                this.queryBasicData()
            },
            queryColumnList() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/module/'+this.moduleId+'/listPage/init', 
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.showField.fieldList = response.data.data.showField.fieldList
                        this.showField.valueList = response.data.data.showField.valueList
                        this.conditionList = response.data.data.conditionList
                        this.$refs.child3.getData(this.showField,this.moduleId,this.conditionList)
                        let obj = {
                            DELETE: false,
                            EDIT: true,
                        }
                        this.$refs.child5.getScreenData(this.moduleId,this.conditionList,obj)
                        this.treeData = response.data.data.deptTree
                        this.$refs.child1.childMethod(this.treeData, response.data.data.allDept)
                        // console.log(response.data.data.allDept)
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            queryUserTree() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/user/tree', 
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        let obj = {}
                        obj.peopleTree = response.data.data
                        obj.personSelected = []
                        this.$refs.child2.getParentData(obj)
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            queryTreeData() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/department/tree', 
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        // console.log(response.data.data)
                        this.treeData = response.data.data.deptTree
                        this.$refs.child1.childMethod(this.treeData, response.data.data.allDept)
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            onClose() {
                this.visible = false;
                this.dataObj = {
                    id: undefined,
                    type: '',
                    account: '',
                    deptId: '',
                    isAdmin: '',
                    status: '',
                    name: '',
                    isResetPwd: undefined
                }
            },
            addData() {
                this.visible = true
                this.dataObj.type = 'new'
            },
            cancelDrawer() {
                this.visible = false
                this.dataObj = {
                    id: undefined,
                    type: '',
                    account: '',
                    deptId: '',
                    isAdmin: '',
                    status: '',
                    name: '',
                    isResetPwd: undefined,
                }
            },
            sureDrawer(type) {
                this.visible = type
                this.axios.post('/api/user', {
                    id: this.dataObj.type=='new'?undefined:this.dataObj.id,
                    account: this.dataObj.account,
                    deptId: this.dataObj.deptId,
                    isAdmin: this.dataObj.isAdmin,
                    name: this.dataObj.name,
                    status: this.dataObj.status,
                    isResetPwd: this.dataObj.isResetPwd
                })
                .then(response=> {
                    if(response.data.code == 0){
                        let tip = ''
                        if(this.dataObj.type=='new'){
                            tip = '新增成功'
                        }else{
                            tip = '修改成功'
                        }
                        this.dataObj = {
                            id: undefined,
                            type: '',
                            account: '',
                            deptId: '',
                            isAdmin: '',
                            status: '',
                            name: '',
                            isResetPwd: undefined
                        }
                        this.queryBasicData()
                        this.$message.success(tip)
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            editUser() {
                this.visible2 = true
            },
            onClose2() {
                this.visible2 = false;
            },
            selectState(value) {
                console.log(`selected ${value}`);
            },
            changeDepartment(value) {
                console.log(`selected ${value}`);
            },
            deleteInfoDrawer() {
                this.distributionModal = true
                this.queryUserTree()
                setTimeout(()=>{
                    this.$refs.child2.personSelected = ''
                })
            },
            editDrawer() {
                this.queryAccountData()
                this.ifEditUserDetail = true
            },
            cancelInfoDrawer() {
                this.ifEditUserDetail = false
                this.visible2 = false
            },
            saveDrawer() {
                this.ifEditUserDetail = false
                this.visible2 = false
                this.axios.post('/api/user', {
                    id: this.currentId,
                    deptId: this.currentPersonalInfo.deptId,
                    isAdmin: this.currentPersonalInfo.isAdmin,
                    name: this.currentPersonalInfo.name,
                    status: this.currentPersonalInfo.status,
                    isResetPwd: this.isResetPwd,
                    numberIdList: this.selectedRowKeys1
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.$message.success('修改成功')
                        this.isResetPwd = undefined
                        this.queryBasicData()
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            resetPassword() {
                this.resetModal = true
            },
            cancelReset() {
                this.resetModal = false
            },
            sureReset() {
                this.resetModal = false
                this.isResetPwd = 1
            },
            cancelDelete1() {
                this.distributionModal = false
            },
            cancelDelete2() {
                this.deleteSelfModal = false
            },
            sureDelete1() {
                if(this.$refs.child2.personSelected==''){
                    this.$message.error('请先选择用户')
                }else{
                    this.visible2 = false
                    this.distributionModal = false
                    this.deleteSelfModal = true
                }
            },
            sureDelete2() {
                this.deleteSelfModal = false
                this.axios.post('/api/user/delete', {
                    id: this.currentId,
                    assignTo: this.$refs.child2.personSelected,
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.$message.success('删除成功')
                        this.queryBasicData()
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            onSelectChange(selectedRowKeys) {
                let obj = {}
                obj.deptId = ''
                obj.digitalEmployeeId = ''
                obj.dataViewId = ''
                obj.dataRange = ''
                obj.conditionList = this.$refs.child3.conditionList
                this.selectedRowKeys = selectedRowKeys;
                this.$refs.child4.getData(this.moduleId, this.selectedRowKeys, obj)
                this.$refs.child5.getData(this.moduleId, this.selectedRowKeys)
            },
            passDataToChild() {
                let obj = {}
                obj.deptId = ''
                obj.digitalEmployeeId = ''
                obj.dataViewId = ''
                obj.dataRange = ''
                obj.conditionList = this.$refs.child3.conditionList
                this.$refs.child4.getData(this.moduleId, this.selectedRowKeys, obj)
            },
            getDeptId(id) {
                this.deptId = id
                this.queryBasicData()
            },
            activationNum() {
                this.activationModal = true
            },
            cancelActivation() {
                this.activationModal = false
            },
            sureActivation() {
                this.activationModal = false
                this.axios.post('/api/extension/active', {
                    userId: this.currentId,
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.$message.success('激活成功')
                        this.querySelectData()
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            editPwd() {
                this.changePwdModal = true
            },
            cancelChangePwd() {
                this.changePwdModal = false
                this.newpwd = ''
                this.againPwd = ''
            },
            sureChangePwd() {
                if(this.newpwd == this.againPwd){
                    this.changePwdModal = false
                    this.axios.post('/api/extension/updatePassword', {
                        userId: this.currentId,
                        password: this.newpwd
                    })
                    .then(response=> {
                        if(response.data.code == 0){
                            
                            this.querySelectData()
                            this.$message.success('修改成功')
                        }else{
                            this.$message.error(response.data.userTip);
                        }
                    })
                    .catch(error=> {
                        console.log(error);
                    });
                }else{
                    this.$message.error('两次密码不一致') 
                }
            },
            cancelExtension() {
                this.extensionTipModal = false
            },
            sureExtension() {
                this.extensionTipModal = false
            },
            showPassword() {
                this.ifShowPwd = true
            },
            hidePassword() {
                this.ifShowPwd = false
            },
            playPhone(value) {
                this.$emit('fatherMethod', value);
            },
            onSelectChange1(selectedRowKeys) {
                this.selectedRowKeys1 = selectedRowKeys;
            },
            selectNumberFun() {
                this.chooseAccountModal = true
                this.queryAccountData()
            },
            cancelChooseAccount() {
                this.selectedRowKeys1 = []
                this.chooseAccountModal = false
            },
            sureChooseAccount() {
                this.chooseAccountModal = false
            },
            queryAccountData() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/user/callingNumberList', 
                        params: {
                            number: this.searchNum,
                            userId: this.currentId
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.selectedRowKeys1 = []
                        this.numberData1 = response.data.data.allNumberList
                        let userNumberList = response.data.data.userNumberList
                        for(let i = 0; i < this.numberData1.length; i++){
                            this.dataObj2[this.numberData1[i]['id']] = this.numberData1[i]['number']
                        }
                        for(let i = 0; i < userNumberList.length; i++){
                            this.dataObj2[userNumberList[i]['id']] = userNumberList[i]['number']
                        }
                        let selectedData = response.data.data.userNumberList
                        for(let i = 0; i < selectedData.length; i++){
                            this.selectedRowKeys1.push(selectedData[i]['id'])
                        }
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            inputChange() {
                clearTimeout(this.timer4)
                this.timer4 = setTimeout(()=>{
                    this.queryAccountData()
                },1500)
            },
            deleteSelectedNum(index) {
                this.selectedRowKeys1.splice(index, 1);
            },
            cleanSelected() {
                this.selectedRowKeys1 = []
            }
        },
        mounted: function() {
            this.sessionData('set','personalCenter','userManage');
            this.moduleId = this.$route.query.moduleId
            this.moduleName = this.$route.query.name
            this.queryBasicData()
            this.queryColumnList()
            this.queryTreeData()
        }
    }
</script>
<style lang="less" scoped>
    .user-manage{
        width: 100%;
        height: 100%;
        position: relative;
        .head{
            height: 48px;
            line-height: 48px;
            padding: 0 24px;
            background: #fff;
            border-left: 1px solid #e8ebee;
            position: absolute;
            left: 0px;
            top: 0px;
            z-index: 100;
            width: 100%;
            .title{
                color: rgba(18, 18, 18, 1);
                font-size: 16px;
            }
            i{
                float: right;
                margin-top: 16px;
                font-size: 16px;
                color: rgba(204, 204, 204, 1);
                cursor: pointer;
            }
        }
        .content{
            height: 100%;
            width: 100%;
            padding: 16px;
            position: absolute;
            left: 0px;
            top: 0px;
            z-index: 10;
            padding-top: 64px;
            .basic-info{
                height: 100%;
                background: #fff;
                width: 100%;
                padding: 0 24px;
                border-radius: 4px;
                position: relative;
                .operate-group{
                    height: 80px;
                    padding: 24px 24px 0 24px;
                    text-align: right;
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    width: 100%;
                    z-index: 100;
                    .vertical{
                        height: 32px;
                        margin: 0 23px 0 0;
                    }
                    .add-data{
                        margin-right: 8px;
                    }
                }
                .download-list{
                    display: inline-block;
                    width: 160px;
                    height: 168px;
                    padding: 0;
                    background: #fff;
                    border-radius: 4px;
                    position: absolute;
                    right: 127px;
                    top: 64px;
                    box-shadow: 0px 2px 32px 0px rgba(0, 0, 0, 0.16);
                    z-index: 100;
                    ul{
                        li{
                            cursor: pointer;
                            height: 56px;
                            text-align: center;
                            div.first{
                                color: rgba(80, 80, 80, 1);
                                font-size: 14px;
                                padding-top: 10px;
                            }
                            div.second{
                                color: rgba(188, 188, 188, 1);
                                font-size: 12px;
                            }
                        }
                        li:nth-child(3){
                            line-height: 36px;
                        }
                        li.checked{
                            background: rgba(242, 243, 245, 0.6);
                        }
                        .down-all{
                            padding: 0 24px;
                            .down-list-in{
                                border-top: 1px solid rgba(229, 229, 229, 1);
                                border-bottom: 1px solid rgba(229, 229, 229, 1);
                                height: 100%;
                            }
                        }
                    }
                }
                .tree-table{
                    display: flex;
                    height: 100%;
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    width: 100%;
                    padding: 80px 24px 0 24px;
                    z-index: 10;
                    .tree{
                        width: 350px;
                        padding-right: 24px;
                        height: 100%;
                        float: left;
                        // overflow-y: auto;
                    }
                    .table-data{
                        flex: 1;
                        // float: left;
                        padding-left: 24px;
                        overflow-x: auto;
                    }
                    .pagination-table{
                        float: right;
                        padding-top: 16px;
                    }
                }
            }
        }
    }
    .drawer-public{
        height: 100%;
        // display: flex;
        position: relative;
        .drawer-content{
            padding: 0 16px 68px;
            width: 100%;
            .drawer-body-content{
                width: 100%;
                height: 500px;
                padding: 24px;
                background: #fff;
                border-radius: 4px;
            }
        }
        .drawer-foot{
            position: absolute;
            left: 0px;
            bottom: 0px;
            width: 100%;
            height: 52px;
            background: #fff;
            box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
            .btn-group{
                text-align: center;
                padding-top: 10px;
                width: 100%;
                button{
                    height: 32px;
                    font-size: 14px;
                }
                button:nth-child(2){
                    margin: 0 16px;
                }
            }
        }
    }
    .drawer-set{
        .row-list{
            margin-bottom: 13px;
            .col-left{
                padding-right: 24px;
            }
            .col-right{
                padding-left: 24px;
            }
            .title{
                color: rgba(188, 188, 188, 1);
                font-size: 12px;
                margin-bottom: 9px;
                float: left;
                span{
                    float: left;
                }
                .icon{
                    color: rgba(227, 60, 100, 1);
                    margin-left: 4px;
                }
            }
            .original-password{
                padding-top: 4px;
            }
            .select-item{
                width: 100%;
            }
            .content{
                color: rgba(51, 51, 51, 1);
            }
        }
    }
    .drawer-view{
        height: 100%;
        // overflow-y: auto;
        padding-bottom: 52px;
        position: relative;
        .basic-info{
            width: 100%;
            height: 88px;
            background: #fff;
            padding: 0 24px 0;
            .basic-info-row{
                border-top: 1px solid rgba(229, 229, 229, 1);
                padding-top: 24px;
                .title{
                    color: rgba(188, 188, 188, 1);
                    font-size: 12px;
                    margin-bottom: 6px;
                }
                .content{
                    color: rgba(80, 80, 80, 1);
                    font-size: 14px;
                }
            }
        }
        .self-info{
            margin-top: 16px;
            width: 100%;
            padding: 0 16px;
            .self-content{
                padding: 18px 24px 1px;
                background: #fff;
                width: 100%;
                height: 100%;
                border-radius: 4px;
                .col-left{
                    padding-right: 24px;
                }
                .col-right{
                    padding-left: 24px;
                }
                .head-title{
                    color: rgba(18, 18, 18, 1);
                    font-size: 14px;
                    margin-bottom: 24px;
                }
                .self-info-row{
                    margin-bottom: 24px;
                    .title{
                        color: rgba(188, 188, 188, 1);
                        font-size: 12px;
                        margin-bottom: 9px;
                    }
                    .content{
                        color: rgba(80, 80, 80, 1);
                        font-size: 14px;
                        width: 100%;
                        .reset-password{
                            color: rgba(24, 144, 255, 1);
                            font-size: 12px;
                            margin-left: 24px;
                            cursor: pointer;
                            display: none;
                        }
                    }
                    .content:hover{
                        .reset-password{
                            display: inline-block;
                        }
                    }
                    img{
                        width: 21px;
                    }
                }
            }
            .extension-info{
                background: #fff;
                margin-top: 16px;
                margin-bottom: 16px;
                border-radius: 4px;
                padding: 18px 24px;
                .head-title{
                    color: rgba(18, 18, 18, 1);
                    font-size: 14px;
                    margin-bottom: 24px;
                }
                .col-left{
                    padding-right: 24px;
                }
                .col-right{
                    padding-left: 24px;
                }
                .head-title{
                    color: rgba(18, 18, 18, 1);
                    font-size: 14px;
                    margin-bottom: 24px;
                }
                .self-info-row{
                    margin-bottom: 24px;
                    .title{
                        color: rgba(188, 188, 188, 1);
                        font-size: 12px;
                        margin-bottom: 9px;
                    }
                    .content{
                        color: rgba(80, 80, 80, 1);
                        font-size: 14px;
                        width: 100%;
                        .reset-password{
                            color: rgba(24, 144, 255, 1);
                            font-size: 12px;
                            margin-left: 24px;
                            cursor: pointer;
                            display: none;
                        }
                    }
                    .content:hover{
                        .reset-password{
                            display: inline-block;
                        }
                    }
                    .extention-password{
                        div{
                            display: inline-block;
                        }
                        i{
                            display: none;
                            color: rgba(24, 144, 255, 1);
                            font-size: 16px;
                            margin-left: 24px;
                            cursor: pointer;
                        }
                    }
                    .extention-password:hover{
                        i{
                            display: inline-block;
                        }
                    }
                }
            }
        }
        .take-phone{
            cursor: pointer;
            .take-phone1{
                width: 14px;
                height: 14px;
                margin-left: 3px;
            }
            .take-phone2{
                display: none;
                width: 14px;
                height: 14px;
                margin-left: 3px;
            }
        }
        .take-phone:hover{
            .take-phone1{
                display: none;
            }
            .take-phone2{
                display: inline-block;
            }
        }
    }
    .modal-public{
        .divider1{
            margin: 0px 0 24px 0;
        }
        .divider2{
            margin: 24px 0 12px;
        }
        .divider3{
            margin: 0px 0 12px;
        }
        .btn-group{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                margin-right: 16px;
            }
        }
        .btn-group2{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                background: #fff;
                color: rgba(121, 136, 155, 0.7);
                border: 1px solid rgba(121, 136, 155, 0.5);
                margin-right: 16px;
            }
            button:nth-child(1):hover{
                background: rgba(121, 136, 155, 0.08);
            }
        }
        .btn-group3{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                background: #fff;
                color: rgba(24, 144, 255, 1);
                border: 1px solid rgba(24, 144, 255, 1);
                margin-right: 16px;
            }
            button:nth-child(1):hover{
                background: rgba(121, 136, 155, 0.08);
            }
        }
    }
    .reset-modal{
        padding-top: 24px;
        padding-bottom: 48px;
        .sure-off-bind{
            i{
                color: rgba(255, 170, 0, 1);
                font-size: 24px;
            }
            span{
                color: rgba(18, 18, 18, 1);
                font-size: 20px;
                margin-left: 24px;
            }
        }
        .tip{
            color: rgba(80, 80, 80, 1);
            padding-left: 50px;
            margin: 24px 0;
        }
    }
    .delete-modal{
        padding-top: 24px;
        padding-bottom: 48px;
        .sure-off-bind{
            i{
                color: rgba(255, 170, 0, 1);
                font-size: 24px;
            }
            span{
                color: rgba(18, 18, 18, 1);
                font-size: 20px;
                margin-left: 24px;
            }
        }
        .tip{
            color: rgba(80, 80, 80, 1);
            padding-left: 50px;
            margin: 24px 0;
        }
    }
    .distribution-modal{
        // padding-top: 24px;
        padding-bottom: 48px;
        .sure-off-bind{
            i{
                color: rgba(255, 170, 0, 1);
                font-size: 24px;
            }
            span{
                color: rgba(18, 18, 18, 1);
                font-size: 20px;
                margin-left: 24px;
            }
        }
        .tip{
            color: rgba(80, 80, 80, 1);
            padding-left: 50px;
            margin: 24px 0;
        }
    }
    .delete-selected-modal{
        padding-bottom: 46px;
        .des{
            color: rgba(153, 153, 153, 1);
            font-size: 14px;
        }
        .data-num{
            color: rgba(24, 144, 255, 1);
            font-size: 14px;
            margin-top: 8px;
        }
        .reset-changed{
            text-align: right;
            color: rgba(24, 144, 255, 1);
            font-size: 14px;
            span{
                cursor: pointer;
            }
        }
    }
    .set-condition-modal{
        padding-bottom: 46px;
        .reset-changed{
            text-align: right;
            color: rgba(24, 144, 255, 1);
            font-size: 14px;
            span{
                cursor: pointer;
            }
        }
    }
    .batch-delete-modal{
        padding-bottom: 46px;
        .des{
            color: rgba(153, 153, 153, 1);
            font-size: 14px;
        }
        .data-num{
            color: rgba(24, 144, 255, 1);
            font-size: 14px;
            margin-top: 8px;
        }
        .reset-changed{
            text-align: right;
            color: rgba(24, 144, 255, 1);
            font-size: 14px;
            span{
                cursor: pointer;
            }
        }
    }
    .edit-modal{
        padding-bottom: 48px;
        .changed-name{
            color: rgba(153, 153, 153, 1);
            font-size: 14px;
            margin-top: 24px;
        }
        input{
            margin-top: 8px;
        }
    }
    .choose-account-modal{
        .add-title{
            padding: 20px 20px 14px 0px;
            border-bottom: 1px solid rgba(229, 229, 229, 1);
            span{
                color: rgba(18, 18, 18, 1);
                font-size: 14px;
                font-weight: bold;
            }
            i{
                float: right;
                color: rgba(158, 172, 196, 1);
                font-size: 16px;
                cursor: pointer;
            }
        }
        .choose-account-data{
            padding: 24px 0;
            .account-left-content{
                padding-right: 24px;
                border-right: 1px solid rgba(229, 229, 229, 1);
                .account-table{
                    margin-top: 24px;
                }
            }
            .account-right-content{
                padding-left: 24px;
                .choosed-title{
                    span:nth-child(1){
                        color: rgba(153, 153, 153, 1);
                        font-size: 14px;
                    }
                    span:nth-child(2){
                        color: rgba(24, 144, 255, 1);
                        font-size: 14px;
                        float: right;
                        cursor: pointer;
                    }
                }
                .choosed-content{
                    margin-top: 24px;
                    max-height: 305px;
                    overflow-y: auto;
                    .choosed-item{
                        display: inline-block;
                        min-width: 132px;
                        height: 32px;
                        margin: 0 8px 8px 0;
                        background: rgba(121, 136, 155, 0.1);
                        text-align: center;
                        line-height: 32px;
                        padding: 0 8px;
                        .choosed-phone{
                            color: rgba(121, 136, 155, 1);
                            font-size: 16px;
                            float: left;
                            margin-top: 7px;
                        }
                        .choosed-number{
                            color: rgba(80, 80, 80, 1);
                            font-size: 14px;
                            display: inline-block;
                            min-width: 84px;
                            overflow: hidden;
                        }
                        .choosed-close{
                            color: rgba(153, 153, 153, 1);
                            font-size: 14px;
                            float: right;
                            margin-top: 8px;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
        .btn-group{
            border-top: 1px solid rgba(229, 229, 229, 1);
            padding: 16px 20px 20px 0;
            height: 58px;
            div{
                float: right;
            }
            button{
                display: inline-block;
                width: 64px;
                height: 28px;
                margin-left: 8px;
                font-size: 13px;
            }
            button:nth-child(1){
                color: rgba(158, 172, 196, 1);
            }
        }
    }
</style>