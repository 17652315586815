<template>
    <div class="screen-condition">
        <a-row v-for="(item,index) in data" :key='index' style="margin-bottom:12px">
            <a-col :span="4">
                {{item.fieldName}}
            </a-col>
            <a-col :span="6">
                <a-select v-model="item.selectedCondition" style="width:100%;height:32px" @change="selectConChange(item,index)">
                    <a-select-option :value="item1.value" v-for="(item1,index1) in item.methodList" :key='index1'>
                        {{item1.name}}
                    </a-select-option>
                </a-select>
            </a-col>
            <a-col :span="14" style="padding-left:12px;">
                <Linkage ref="child4"></Linkage>
            </a-col>
        </a-row>
    </div>
</template>
<script>

import Linkage from './Linkage'
export default {
    components: {Linkage},
    data(){
        return{
            data: [],
        }
    },
    methods: {
        selectConChange(item, index) {
            this.$refs.child4[index].getPassData(item,index)
        },
        getData(data) {
            this.data = data
        }
    },
}
</script>
<style scoped lang="less">
    .screen-condition{
        .row-list{
            margin-bottom: 12px;
            .screen-name{
                color: rgba(80, 80, 80, 1);
                font-size: 14px;
                line-height: 30px;
            }
            .screen-selection{
                padding: 0 12px;
                .select-condition{
                    width: 100%;
                    color: rgba(80, 80, 80, 1);
                }
            }
            .screen-value{
                color: rgba(80, 80, 80, 1);
            }
        }
        .reset-content{
            color: rgba(24, 144, 255, 1);
            font-size: 14px;
            text-align: right;
            margin-top: 12px;
        }
    }
</style>