<template>
    <div>
        <a-row>
            <a-col :span="12">
                <div class="affect-modal-left">
                    <a-tabs default-active-key="1">
                        <a-tab-pane key="1" tab="部门">
                            <a-input placeholder="搜索部门" @change="onChange1" />
                            <div class="department-tree">
                                <a-tree
                                    v-model="checkedKeys"
                                    checkable
                                    :expanded-keys="expandedKeys"
                                    :auto-expand-parent="autoExpandParent1"
                                    :tree-data="departmentTree"
                                    :checkStrictly="true"
                                    :defaultExpandAll="true"
                                    @expand="onExpand"
                                />
                            </div>
                        </a-tab-pane>
                        <a-tab-pane key="2" tab="人" force-render>
                            <a-input placeholder="搜索人" @change="onChange2" />
                            <div class="person-tree">
                                <a-tree
                                    v-model="checkedKeys2"
                                    checkable
                                    :expanded-keys="expandedKeys2"
                                    :auto-expand-parent="autoExpandParent2"
                                    :tree-data="peopleTree"
                                    :defaultExpandAll="true"
                                    @expand="onExpand2"
                                />
                            </div>
                        </a-tab-pane>
                    </a-tabs>
                </div>
            </a-col>
            <a-col :span="12">
                <div  class="affect-modal-right">
                    <div class="title">
                        <span>已选择：</span>
                        <span @click="clearOut">清空</span>
                    </div>
                    <div class="selected-list">
                        <div>
                            <div class="selected-item" v-for="(item,index) in departmentSelected" :key='index'>
                                <img src="./../assets/img/department.svg" alt="">
                                <span>{{deptAndUserMap[item+'']}}</span>
                                <a-icon @click="deleteDepart(item)" type="close" />
                            </div>
                        </div>
                        <div>
                            <div class="selected-item" v-for="(item,index) in personSelected" :key='index'>
                                <img src="./../assets/img/person.png" alt="">
                                <span>{{deptAndUserMap[item+'']}}</span>
                                <a-icon @click="deletePerson(item)" type="close" />
                            </div>
                        </div>
                    </div>
                </div>
            </a-col>
        </a-row>
    </div>
</template>

<script>
export default {
    props:[],
    data(){
        return{
            expandedKeys2: [],
            checkedKeys2: [],
            departmentSelected: [],
            personSelected: [],
            departmentTree: [],
            peopleTree: [],
            expandedKeys: [],
            checkedKeys: [],
            autoExpandParent1: true,
            autoExpandParent2: true,
            deptAndUserMap: '',
            dataList1: [],
            searchValue1: '',
            dataList2: [],
            searchValue2: ''
        }
    },
    methods: {
        onChange1(e) {
            const value = e.target.value;
            const expandedKeys = this.dataList1
                .map(item => {
                    if (item.title.indexOf(value) > -1) {
                        return this.getParentKey1(item.key, this.departmentTree);
                    }
                    return null;
                })
                .filter((item, i, self) => item && self.indexOf(item) === i);
            Object.assign(this, {
                expandedKeys,
                searchValue1: value,
                autoExpandParent1: true,
            });
        },
        onChange2(e) {
            const value = e.target.value;
            const expandedKeys2 = this.dataList2
                .map(item => {
                    if (item.title.indexOf(value) > -1) {
                        return this.getParentKey2(item.key, this.peopleTree);
                    }
                    return null;
                })
                .filter((item, i, self) => item && self.indexOf(item) === i);
            Object.assign(this, {
                expandedKeys2,
                searchValue2: value,
                autoExpandParent2: true,
            });
        },
        onExpand(expandedKeys) {
            this.expandedKeys = expandedKeys;
            this.autoExpandParent = false;
        },
        onExpand2(expandedKeys) {
            this.expandedKeys2 = expandedKeys;
            this.autoExpandParent2 = false;
        },
        deleteDepart(id) {
            this.departmentSelected.some((item, i)=>{
                if(item == id){
                    this.departmentSelected.splice(i, 1)
                    return true
                }
            })
        },
        deletePerson(id) {
            this.personSelected.some((item, i)=>{
                if(item == id){
                    this.personSelected.splice(i, 1)
                    return true
                }
            })
        },
        clearOut() {
            this.departmentSelected = []
            this.personSelected = []
            this.checkedKeys = []
            this.checkedKeys2 = []
        },
        getParentData(passDataObj){
            setTimeout(()=>{
                this.departmentTree = passDataObj.departmentTree
                this.generateList1(this.departmentTree)
                this.peopleTree = passDataObj.peopleTree
                this.generateList2(this.peopleTree)
                this.checkedKeys = passDataObj.departmentSelected
                this.checkedKeys2 = passDataObj.personSelected
                this.departmentSelected = passDataObj.departmentSelected
                this.personSelected = passDataObj.personSelected
            })
        },
        generateList1(data) {
            for (let i = 0; i < data.length; i++) {
                const node = data[i];
                const key = node.key;
                const title = node.title
                this.dataList1.push({ key, title: title });
                if (node.children) {
                    this.generateList1(node.children);
                }
            }
        },
        getParentKey1(key, tree) {
            let parentKey;
            for (let i = 0; i < tree.length; i++) {
                const node = tree[i];
                if (node.children) {
                    if (node.children.some(item => item.key === key)) {
                        parentKey = node.key;
                    } else if (this.getParentKey1(key, node.children)) {
                        parentKey = this.getParentKey1(key, node.children);
                    }
                }
            }
            return parentKey;
        },
        generateList2(data) {
            for (let i = 0; i < data.length; i++) {
                const node = data[i];
                const key = node.key;
                const title = node.title
                this.dataList2.push({ key, title: title });
                if (node.children) {
                    this.generateList2(node.children);
                }
            }
        },
        getParentKey2(key, tree) {
            let parentKey;
            for (let i = 0; i < tree.length; i++) {
                const node = tree[i];
                if (node.children) {
                    if (node.children.some(item => item.key === key)) {
                        parentKey = node.key;
                    } else if (this.getParentKey2(key, node.children)) {
                        parentKey = this.getParentKey2(key, node.children);
                    }
                }
            }
            return parentKey;
        },
        queryDeptAndUserMap() {
            this.axios(
                {
                    method:'get',
                    url:'/api/user/deptAndUserMap', 
                }
            )
            .then(response=> {
                if(response.data.code == 0){
                    this.deptAndUserMap = response.data.data
                }else{
                    this.$message.error(response.data.userTip);
                    if(response.data.code == 'A0200'){
                        this.$router.push("/login")
                    }
                }
            })
            .catch(error=> {
                console.log(error)
            })
        }
    },
    mounted: function() {
        this.queryDeptAndUserMap()
    },
    watch: {
        checkedKeys(val) {
            if(val.checked){
                this.departmentSelected = val.checked
            }else{
                this.departmentSelected = val
            }
        },
        checkedKeys2(val) {
            this.personSelected = val
        },
    },
}
</script>
<style scoped lang="less"> 
    .modal-public{
        .divider1{
            margin: 0px 0 24px 0;
        }
        .divider2{
            margin: 24px 0 12px;
        }
        .btn-group{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                margin-right: 16px;
            }
        }
        .btn-group2{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                background: #fff;
                color: rgba(121, 136, 155, 0.7);
                border: 1px solid rgba(121, 136, 155, 0.5);
                margin-right: 16px;
            }
            button:nth-child(1):hover{
                background: rgba(121, 136, 155, 0.08);
            }
        }
    }
    .offbindaffect-modal{
        border-top: 1px solid #e8e8e8;
        padding-top: 24px;
        padding-bottom: 46px;
        .affect-modal-left{
            width: 100%;
            height: 100%;
            border-right: 1px solid #e8e8e8;
            padding-right: 24px;
            .department-tree,.person-tree{
                height: 300px;
                overflow-x: hidden;
                overflow-y: auto;
                padding-top: 16px;
            }
        }
        .affect-modal-right{
            width: 100%;
            height: 100%;
            padding-left: 24px;
            .title{
                margin-bottom: 24px;
                span:nth-child(1){
                    color: rgba(153, 153, 153, 1);
                    font-size: 14px;
                }
                span:nth-child(2){
                    color: rgba(24, 144, 255, 1);
                    font-size: 14px;
                    cursor: pointer;
                    float: right;
                }
            }
            .selected-list{
                height: 346px;
                overflow-y: auto;
                .selected-item{
                    display: inline-block;
                    height: 32px;
                    padding: 0 6px;
                    background: rgba(121, 136, 155, 0.1);
                    border-radius: 4px;
                    margin-right: 8px;
                    margin-bottom: 8px;
                    line-height: 32px;
                    img:nth-child(1){
                        color: rgba(121, 136, 155, 1);
                        width: 18px;
                        float: left;
                        margin-top: 7px;
                    }
                    span:nth-child(2){
                        color: rgba(80, 80, 80, 1);
                        font-size: 14px;
                        margin-left: 4px;
                    }
                    i:nth-child(3){
                        color: rgba(153, 153, 153, 1);
                        font-size: 14px;
                        cursor: pointer;
                        margin-left: 8px;
                    }
                }
            }
        }
    }
</style>