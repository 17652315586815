<template>
    <div class="system-set">
        <div class="head">
            <span class="title">{{moduleName}}</span>
            <a-button v-if="!ifEdit" type="primary" @click="editInfo">
                编辑
            </a-button>
            <a-button v-if="ifEdit" type="primary" @click="saveInfo">
                保存
            </a-button>
            <a-button v-if="ifEdit" ghost type="primary" @click="calcelInfo">
                取消
            </a-button>
            <a-icon type="exclamation-circle" />
        </div>
        <div class="content">
            <div class="basic-info">
                <div class="title">基本信息</div>
                <div class="application">
                    <a-row>
                        <a-col class="col-left" :span="12">
                            <div class="item-title">应用名称：</div>
                            <div v-if="!ifEdit">{{basicData.appName}}</div>
                            <a-input v-if="ifEdit" v-model="appName" :placeholder="basicData.appName" />
                        </a-col>
                        <a-col class="col-right" :span="12">
                            <div class="item-title">应用主题色：</div>
                            <div>
                                <a-icon type="bg-colors" />
                                <span>{{basicData.appTheme}}</span>
                            </div>
                        </a-col>
                    </a-row>
                </div>
                <div class="logo">
                    <a-row>
                        <a-col class="col-left" :span="12">
                            <div class="item-title">logo图片：</div>
                            <div>
                                <img :src="basicData.logo" alt="">
                                <a-icon @click="showBigImg" v-if="!ifEdit" type="eye" />
                                <span v-if="ifEdit" @click="changeLogo">替换</span>
                            </div>
                        </a-col>
                        <a-col class="col-right" :span="12">
                            <div class="item-title">logo跳转url链接：</div>
                            <div v-if="!ifEdit">{{basicData.website}}</div>
                            <a-input v-model="website" v-if="ifEdit" :placeholder="basicData.website" />
                        </a-col>
                    </a-row>
                </div>
                 <div class="company">
                    <a-row>
                        <a-col class="col-left" :span="12">
                            <div class="item-title">企业名称：</div>
                            <div v-if="!ifEdit">{{basicData.name}}</div>
                            <a-input v-model="companyName" v-if="ifEdit" :placeholder="basicData.name" />
                        </a-col>
                    </a-row>
                </div>
            </div>
            <div class="account-info">
                <div class="title">账户信息</div>
                <div class="application">
                    <a-row v-if="basicData.isOpenExtension==1">
                        <a-col class="col-left" :span="12">
                            <div class="item-title">账户有效期：</div>
                            <div class="item-content">
                                <span>{{basicData.extensionExpirationDate}}</span>
                                <a-button @click="goToMiddleCenter">续费</a-button>
                            </div>
                            
                        </a-col>
                        <a-col class="col-right" :span="12">
                            <div class="item-title">分机数量：</div>
                            <div class="item-content">
                                <span class="extention-number">{{basicData.activeCount}}个</span>
                                <span>/{{basicData.allCount}}个</span>
                                <a-button @click="goToMiddleCenter">添加人数</a-button>
                            </div>
                        </a-col>
                    </a-row>
                    <div class="current-edition" v-if="basicData.isOpenExtension!=1">
                        <div class="edition-title">基础版</div>
                        <div class="edition-content">
                            <a-button @click="goToMiddleCenter" type="primary">
                            开通
                            </a-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <a-modal :centered='true' :visible="previewVisible" :footer="null" @cancel="handleCancel">
            <img alt="example" style="width: 100%" :src="basicData.logo" />
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="uoloadModal" title="logo图片" footer='' width='600px'>
            <div class="upload-modal">
                <a-divider class="divider1" type="horizontal" />
                <CropperImage ref="child"></CropperImage>
                <!-- <a-divider class="divider2" type="horizontal" />
                <div class="btn-group">
                    <a-button type="primary" ghost @click="cancelUpload">取消</a-button>
                    <a-button type="primary" @click="sureUpload">确认</a-button>
                </div> -->
                <a-divider class="divider2" type="horizontal" />
                <div class="upload-btn">
                    <a-button type="primary" style="float:right" @click="uploadImg()">确定 </a-button>
                </div>
            </div>
        </a-modal>
    </div>
</template>

<script>
    import CropperImage from "../../components/CropperImage";
    export default {
        name: 'SystemSet',
        components: {CropperImage},
        data() {
            return {
                ifEdit: false,
                previewVisible: false,
                uoloadModal: false,
                formValidate: {
                    mainImage: '',
                },
                cropperModel:false,
                cropperName:'',
                basicData: null,
                appName: '',
                website: '',
                companyName: '',
                imgId: '',
                moduleId: '',
                moduleName: ''
            }
        },
        methods: {
            querySystemSetData() {
                this.axios(
                    {
                        method:'get',
                        url:'api/organization', 
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.basicData = response.data.data
                        this.appName = response.data.data.appName
                        this.website = response.data.data.website
                        this.companyName = response.data.data.name
                        // if(response.data.data.isOpenExtension!=1){
                        //     this.$message.warning('该账号还未激活')
                        // }
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            editInfo() {
                this.ifEdit = true
            },
            saveInfo() {
                this.ifEdit = false
                this.axios.post('/api/organization', {
                    name: this.companyName,
                    logo: this.imgId,
                    website: this.website,
                    appName: this.appName,
                    appTheme: this.basicData.appTheme
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.$message.success('修改成功')
                        this.querySystemSetData()
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            calcelInfo() {
                this.ifEdit = false
            },
            showBigImg() {
                this.previewVisible = true
            },
            handleCancel() {
                this.previewVisible = false
            },
            changeLogo() {
                this.uoloadModal = true
            },
            cancelUpload() {
                this.uoloadModal = false
            },
            sureUpload() {
                this.uoloadModal = false
            },
            handleUploadSuccess (data){
                switch(data.name){
                    case 'flagImg':
                    this.formValidate.mainImage = 'http://ydfblog.cn/dfs/'+data.url;
                    break;
                }
                this.cropperModel = false;
            },
            uploadImg() {
                if(this.$refs.child.imgBase64==''){
                    this.$message.error('请先上传图片')
                }else{
                    this.uoloadModal = false
                    this.axios.post('/api/file/uploadImg', {
                        base64Img: this.$refs.child.imgBase64,
                    })
                    .then(response=> {
                        if(response.data.code == 0){
                            this.$message.success('上传成功')
                            this.imgId = response.data.data.fileId
                        }else{
                            this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                        }
                    })
                    .catch(error=> {
                        console.log(error);
                    });
                }
            },
            goToMiddleCenter() {
                let host = window.location.host
                this.axios(
                    {
                        method:'get',
                        url:'/api/intelligence/superLoginUrl', 
                        params: {
                            hostName: host
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        let url = response.data.data.superLoginUrl
                        window.location.href = url 
                    }else{
                        this.$message.error(response.data.message);
                    }
                })
                .catch(error=> {
                    console.log(error);
                })
            }
        },
        mounted: function() {
            this.sessionData('set','personalCenter','systemSet');
            this.moduleId = this.$route.query.moduleId
            this.moduleName = this.$route.query.name
            this.querySystemSetData()
        }
    }
</script>

<style scoped lang="less">
    .system-set{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        .head{
            height: 48px;
            line-height: 48px;
            padding: 0 24px;
            background: #fff;
            border-left: 1px solid #e8ebee;
            button{
                float: right;
                width: 64px;
                height: 32px;
                margin: 8px 0 0 8px;
            }
            .title{
                color: rgba(18, 18, 18, 1);
                font-size: 16px;
            }
            i{
                float: right;
                margin-top: 16px;
                font-size: 16px;
                cursor: pointer;
                color: rgba(204, 204, 204, 1);
            }
        }
        .content{
            padding: 16px;
            flex: 1;
            .basic-info{
                background: #fff;
                width: 100%;
                padding: 18px 24px;
                height: 400px;
                border-radius: 4px;
                .title{
                    color: rgba(18, 18, 18, 1);
                    font-size: 14px;
                }
                .item-title{
                    color: rgba(188, 188, 188, 1);
                    font-size: 12px;
                    margin-bottom: 9px;
                }
                .col-left{
                    padding-right: 24px;
                }
                .col-right{
                    padding-left: 24px;
                }
                .application{
                    margin-top: 25px;
                    color: rgba(51, 51, 51, 1);
                    font-size: 14px;
                    i{
                        color: rgba(24, 144, 255, 1);
                        font-size: 18px;
                        margin-right: 4px;
                        cursor: pointer;
                    }
                }
                .logo{
                    margin-top: 24px;
                    color: rgba(51, 51, 51, 1);
                    font-size: 14px;
                    i{
                        color: rgba(121, 136, 155, 1);
                        font-size: 18px;
                        margin-left: 24px;
                        cursor: pointer;
                        float: left;
                        margin-top: 2px;
                    }
                    img{
                        width: 21px;
                        height: 21px;
                        float: left;
                    }
                    span{
                        color: rgba(24, 144, 255, 1);
                        font-size: 12px;
                        cursor: pointer;
                        margin-left: 24px;
                    }
                }  
                .company{
                    margin-top: 24px;
                    color: rgba(51, 51, 51, 1);
                    font-size: 14px;
                }
            }
            .account-info{
                height: 260px;
                background: #fff;
                border-radius: 4px;
                margin-top: 16px;
                padding: 18px 24px;
                .title{
                    color: rgba(18, 18, 18, 1);
                    font-size: 14px;
                }
                .item-title{
                    color: rgba(188, 188, 188, 1);
                    font-size: 12px;
                    margin-bottom: 9px;
                }
                .col-left{
                    padding-right: 24px;
                }
                .col-right{
                    padding-left: 24px;
                }
                .application{
                    margin-top: 25px;
                    color: rgba(51, 51, 51, 1);
                    font-size: 14px;
                    i{
                        color: rgba(24, 144, 255, 1);
                        font-size: 18px;
                        margin-right: 4px;
                        cursor: pointer;
                    }
                    .item-content{
                        button{
                            margin-left: 30px;
                            height: 28px;
                            border-color: rgba(121, 136, 155, 1);
                            color: rgba(121, 136, 155, 1);
                        }
                    }
                    .extention-number{
                        color: rgba(67, 207, 124, 1);
                    }
                    .current-edition{
                        .edition-title{
                            color: #bcbcbc;
                            font-size: 12px;
                            margin-bottom: 9px;
                        }
                    }
                }
            }                
        }
    }
    .upload-modal{
        .divider1{
            margin: 0px 0 24px 0;
        }
        .divider2{
            margin: 24px 0 12px;
        }
        padding-bottom: 50px;
        .btn-group{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                margin-right: 16px;
            }
        }
    }
</style>