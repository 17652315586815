<template>
    <div style="width:100%;height:100%">
        <router-view></router-view>
    </div>
</template>

<script>
    export default {
        name: 'PublicMenu',
        data() {
            return {
                
            }
        },
        methods: {
            queryBasicInfo() {
                let host = window.location.host
                this.axios(
                    {
                    method:'get',
                    url:'/api/login/loginInfo', 
                    params: {
                        hostName: host,
                    }
                })
                .then(response=> {
                    if(response.data.code == 0){
                        document.title = response.data.data.sysName
                        const favicon = document.getElementById('dynamic-favicon');
                        favicon.href = response.data.data.titleLogo
                        this.$forceUpdate()
                    }else{
                        this.$message.error(response.data.message);
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            }
        },
        mounted: function() {
            this.queryBasicInfo()
        },
    }
</script>

<style scoped lang="less">

</style>