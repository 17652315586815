<template>
    <div style="padding-top:200px;padding-left:200px">
        <a-button @click="registerSip" type="primary" style="margin-right:100px">
        注册
        </a-button>
        <a-button type="primary" @click="doCall">
        呼叫
        </a-button>
        <audio class="hide" id="peervideom0" autoplay playsinline/>
    </div>
</template>

<script>
    export default {
        name: 'test',
        
        data() {
            return {
                janus: null,
                sipcall: null,
                opaqueId: "siptest-"+window.Janus.randomString(12),
                localTracks: {}, 
                localVideos: 0,
                remoteTracks: {},
                remoteVideos: 0,
                spinner: null,
                selectedApproach: 'secret',
                registered: false,
                masterId: null, 
                helpers: {}, 
                helpersCount: 0,
                incoming: null,
            }
        },
        methods: {
            registerSip() {
                this.janus = new window.Janus(
				{
					server: window.server,
					iceServers: window.iceServers,
					success: ()=> {
						this.janus.attach(
							{
								plugin: "janus.plugin.sip",
								opaqueId: this.opaqueId,
								success: (pluginHandle)=> {
									this.sipcall = pluginHandle;
									window.Janus.log("Plugin attached! (" + this.sipcall.getPlugin() + ", id=" + this.sipcall.getId() + ")");
									this.registerUsername()
								},
								error: (error)=> {
									console.log("  -- Error attaching plugin...", error);
								},
								consentDialog: (on)=> {
									if(on) {
										console.log("同意对话框")
									} else {
										// Restore screen
										console.log("关闭对话框")
									}
								},
								iceState: (state)=> {
									console.log("ICE state changed to " + state);
								},
								mediaState: (medium, on, mid)=> {
									console.log("Janus " + (on ? "started" : "stopped") + " receiving our " + medium + " (mid=" + mid + ")");
								},
								webrtcState: (on)=> {
									console.log("Janus says our WebRTC PeerConnection is " + (on ? "up" : "down") + " now");
								},
								slowLink: (uplink, lost, mid)=> {
									console.log("Janus reports problems " + (uplink ? "sending" : "receiving") +
										" packets on mid " + mid + " (" + lost + " lost packets)");
								},
								onmessage: (msg, jsep)=> {
									var error = msg["error"];
									if(error) {
										if(this.registered) {
											this.sipcall.hangup();
										}
										return;
									}
									var callId = msg["call_id"];
									var result = msg["result"];
									if(result && result["event"]) {
										var event = result["event"];
										if(event === 'registration_failed') {
											console.log("Registration failed: " + result["code"] + " " + result["reason"]);
											return;
										}
										if(event === 'registered') {
											console.log("Successfully registered as " + result["username"] + "!");
											if(!this.registered) {
												this.registered = true;
												this.masterId = result["master_id"];
											}
										} else if(event === 'calling') {
											console.log("Waiting for the peer to answer...");
										}  else if(event === 'progress') {
											console.log("There's early media from " + result["username"] + ", wairing for the call!", jsep);
										} else if(event === 'accepted') {
											console.log(result["username"] + " accepted the call!", jsep);
											if(jsep) {
												console.log(jsep)
												console.log(this.sipcall)
												this.sipcall.handleRemoteJsep({ jsep: jsep, error: this.doHangup });
											}
											this.sipcall.callId = callId;
										}else if(event === 'hangup') {
											console.log("Call hung up (" + result["code"] + " " + result["reason"] + ")!");
											this.sipcall.hangup();
										}
									}
								},
								onlocaltrack: (track, on)=> {
									var trackId = track.id.replace(/[{}]/g, "");
                                    var stream 
									if(!on) {
										stream = this.localTracks[trackId];
										if(stream) {
											try {
												var tracks = stream.getTracks();
												for(var i in tracks) {
													var mst = tracks[i];
													if(mst)
														mst.stop();
												}
											} catch(e) {
                                                console.log(e)
                                            }
										}
										delete this.localTracks[trackId];
										return;
									}
									stream = this.localTracks[trackId];
									if(stream) {
										return;
									}
									if(this.sipcall.webrtcStuff.pc.iceConnectionState !== "completed" &&
											this.sipcall.webrtcStuff.pc.iceConnectionState !== "connected") {
												console.log("正在呼叫")
									}
								},
								onremotetrack: (track, mid, on)=> {
									if(!on) {
										var stream = this.remoteTracks[mid];
										if(stream) {
											try {
												var tracks = stream.getTracks();
												for(var i in tracks) {
													var mst = tracks[i];
													if(mst)
														mst.stop();
												}
											} catch(e) {
                                                console.log(e)
                                            }
										}
										delete this.remoteTracks[mid];
										return;
									}
									if(track.kind === "audio") {
										stream = new window.MediaStream([track]);
										this.remoteTracks[mid] = stream;
										console.log("Created remote audio stream:", stream);
										window.Janus.attachMediaStream(document.getElementById("peervideom0"), stream);
									} 
								},
								oncleanup: ()=> {
									console.log(" ::: Got a cleanup notification :::");
									if(this.sipcall) {
										delete this.sipcall.callId;
										delete this.sipcall.doAudio;
										delete this.sipcall.doVideo;
									}
									this.localTracks = {};
									this.localVideos = 0;
									this.remoteTracks = {};
									this.remoteVideos = 0;
								}
							});
					},
					error: (error)=> {
						console.log(error)
					},
					destroyed: ()=> {
						window.location.reload();
					}
				});
            },
            registerUsername() {
                let register = {
                    request: "register",
                    username: 'sip:8001231016@223.112.32.61:5070',
                    authuser: '8001231016',
                    display_name: '8001231016',
                    secret: 'admin123',
                    proxy: 'sip:223.112.32.61:5070'
                }
                this.sipcall.send({ message: register });
                return;
            },
            doHangup() {
                console.log(9)
            },
            doCall() {
                this.actuallyDoCall(this.sipcall,'sip:8001231014@223.112.32.61:5070', false);
            },
            actuallyDoCall(handle, uri, doVideo) {
                handle.doAudio = true;
                handle.doVideo = doVideo;
                let tracks = [{ type: 'audio', capture: true, recv: true }];
                handle.createOffer(
                    {
                        tracks: tracks,
                        success: (jsep)=> {
                            var body = { request: "call", uri: uri };
                            body["autoaccept_reinvites"] = false;
                            handle.send({ message: body, jsep: jsep });
                        },
                        error: (error)=> {
                            console.log(error)
                        }
                    });
            }
        },
        mounted: function() {
            
        }
    }
</script>

<style scoped lang="less">
    
</style>
