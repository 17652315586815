<template>
    <div class="login-page">
        <div class="login-info" :style="{backgroundImage:'url('+basicInfo.backgroundUrl+')'}">
            <div class="pro-title">{{basicInfo.sysName}}</div>
            <div class="pro-des">{{basicInfo.description}}</div>
        </div>
        <div class="login-content">
            <div class="login-logo">
                <img :src="basicInfo.logo" alt="">
                <span>{{basicInfo.logoName}}</span>
            </div>
            <img class="colorful" src="../../assets/img/colorful.png" alt="">
            <!-- <div @click="goRegister" class="no-account">没有账号，去注册</div> -->
            <div class="sys-login">
                <div v-if="step1" class="phone-input" style="position:relative">
                    <div class="login-title">登录</div>
                    <a-input @keyup.enter="nextStep1" v-model.trim="unserNameOrPhone" class="info-input" style="outline:none" placeholder="请输入用户名/手机号" />
                    <a-button @click="nextStep1" class="next-step1" type="primary">下一步</a-button>
                </div>
                <div v-if="step2" class="company">
                    <div class="login-title">选择企业</div>
                    <div class="pro-list">
                        <div v-for="(item, index) in proList" :key="index" :class="{'active':index==currentCompany}" @click="selectPro(index,item.id)">{{item.name}}</div>
                    </div>
                    <div class="btn-group">
                        <div @click="lastStep">上一步</div>
                        <div @click="nextStep2">下一步</div>
                    </div>
                </div>
                <div v-if="step3" class="company">
                    <div class="login-title">
                        <span>{{organizationName}}</span>
                    </div>
                    <div class="login-way">
                        <span>通过你的账号密码登录企业，或</span>
                        <span @click="selectMsgLogin">手机验证码</span>
                    </div>
                    <a-input v-model.trim="unserNameOrPhone" disabled class="username-input" placeholder="请输入用户名" />
                    <a-input @keyup.enter="loginIn1()" v-model.trim="password" autocomplete="off" type='password' class="password-input" placeholder="请输入密码" />
                    <div class="btn-group1">
                        <div @click="loginIn1()">登录</div>
                    </div>
                    <div class="change-company" @click="changeCompany">更改企业</div>
                </div>
                <div v-if="step4" class="company company2">
                    <div class="login-title">
                        <span>{{organizationName}}</span>
                    </div>
                    <div class="login-way">
                        <span>通过你的手机验证码登录企业，或</span>
                        <span @click="selectAccountLogin">账号密码</span>
                    </div>
                    <a-input v-model.trim="telephone" @change="changeTelephone()" class="phonenumber-input" type='text' placeholder="请输入手机号" />
                    <VerificationCode1 v-bind:message="message" ref='code'></VerificationCode1>
                    <div class="btn-group1">
                        <div @click="loginIn2">登录</div>
                    </div>
                    <div class="change-company" @click="changeCompany">更改企业</div>
                </div>
                <div v-if="step5" class="company company2">
                    <div class="login-title">
                        <span>请先绑定手机号</span>
                    </div>
                    <div class="phone">输入手机号：</div>
                    <a-input v-model.trim="bindedPhoneNum" @change="changePhone()" class="phonenumber-input" type='text' placeholder="请输入手机号" />
                    <VerificationCode v-bind:message="message" ref='code'></VerificationCode>
                    <a-button @click="intoSystem" class="login-btn" type="primary">
                    登录
                    </a-button>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
import VerificationCode from '../../components/VerificationCode'
import VerificationCode1 from '../../components/VerificationCode1'
export default {
    name: 'Login',
    props: {
        msg: String
    },
    components: { VerificationCode, VerificationCode1 },
    data() {
        return {
            step1: true,
            step2: false,
            step3: false,
            step4: false,
            step5: false,
            proList: [],
            unserNameOrPhone: '',
            currentCompany: null,
            currentOrganizationId: null,
            organizationName: null,
            username: '',
            password: '',
            bindedPhoneNum: null,
            verifyCode: '',
            message: {
                bindedPhoneNum: null,
                type: ''
            },
            telephone: null,
            basicInfo: '',
            pageTitle: '',
            faviconIcon: ''
        }
    },
    methods: {
        nextStep1() {
            this.axios(
                {
                    method:'get',
                    url:'api/organization/list/'+this.unserNameOrPhone, 
                }
            )
            .then(response=> {
                if(response.data.code == 0){
                    this.step1 = false
                    this.step2 = true
                    this.proList = response.data.data
                }else{
                    this.$message.error(response.data.userTip);
                    if(response.data.code == 'A0200'){
                        this.$router.push("/login")
                    }
                }
            })
            .catch(error=> {
                console.log(error)
            })
        },
        selectPro(index,id) {
            this.currentCompany = index
            this.currentOrganizationId = id
        },
        lastStep() {
            this.step2 = false
            this.step1 = true
        },
        nextStep2() {
            if(this.currentOrganizationId == null){
                this.$message.error('请选择企业')
            }else{
                this.axios(
                    {
                        method:'get',
                        url:'api/organization/'+this.currentOrganizationId, 
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.step2 = false
                        this.step3 = true
                        this.organizationName = response.data.data.name
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            }
        },
        selectMsgLogin() {
            this.step3 = false
            this.step4 = true
        },
        selectAccountLogin() {
            this.step4 = false
            this.step3 = true
        },
        changeCompany() {
            this.step3 = false
            this.step4 = false
            this.step2 = true
        },
        loginIn1(phone, verifyCode) {
            this.axios.post('/api/login/account/password', {
                account: this.unserNameOrPhone,
                password: this.password,
                orgId: this.currentOrganizationId,
                phone: phone,
                verifyCode: verifyCode,
            })
            .then(response=> {
                if(response.data.code == 0){
                    if(response.data.data.isBindPhone == 0){
                        this.step4 = false
                        this.step3 = false
                        this.step5 = true
                        this.$message.error('请先绑定手机号')
                    }else{
                        this.sessionData('set','token',response.data.data.token);
                        this.sessionData('set','name',response.data.data.name);
                        this.sessionData('set','avatar',response.data.data.avatar);
                        // this.$router.push("/PersonalInfo")
                        this.$router.push({
                            path: "/customerLead",
                            query: {
                                moduleId: 8
                            }
                        })
                    }
                }else{
                    this.$message.error(response.data.userTip);
                    if(response.data.code == 'A0200'){
                        this.$router.push("/login")
                    }
                }
            })
            .catch(error=> {
                console.log(error);
            });
        },
        loginIn2() {
            this.axios.post('/api/login/phone/verifyCode', {
                orgId: this.currentOrganizationId,
                phone: this.telephone,
                verifyCode: this.$refs.code.verificationCode,
            })
            .then(response=> {
                if(response.data.code == 0){
                    this.sessionData('set','token',response.data.data.token);
                    // this.$router.push("/PersonalInfo")
                    this.$router.push({
                        path: "/customerLead",
                        query: {
                            moduleId: 8
                        }
                    })
                }else{
                    this.$message.error(response.data.userTip);
                    if(response.data.code == 'A0200'){
                        this.$router.push("/login")
                    }
                }
            })
            .catch(error=> {
                console.log(error);
            });
        },
        intoSystem() {
            this.loginIn1(this.bindedPhoneNum, this.$refs.code.verificationCode)
        },
        changePhone() {
            this.message.bindedPhoneNum = this.bindedPhoneNum
            this.message.type = 2
        },
        changeTelephone() {
            this.message.bindedPhoneNum = this.telephone
            this.message.type = 1
        },
        goRegister() {
            window.open("http://cloud.ixiaowen.net/#/Login?registerValue=true&appId=2", "_self");
        },
        queryBasicInfo() {
            let host = window.location.host
            this.axios(
                {
                method:'get',
                url:'/api/login/loginInfo', 
                params: {
                    hostName: host,
                }
            })
            .then(response=> {
                if(response.data.code == 0){
                    this.basicInfo = response.data.data
                    this.pageTitle = response.data.data.sysName
                    this.faviconIcon = response.data.data.titleLogo
                }else{
                    this.$message.error(response.data.message);
                }
            })
            .catch(error=> {
                console.log(error)
            })
        }
    },
    mounted: function() {
        this.queryBasicInfo()
    },
    updated() {
        document.title = this.pageTitle;
        const favicon = document.getElementById('dynamic-favicon');
        favicon.href = this.faviconIcon
    }
}
</script>

<style scoped lang="less"> 
    .textarea-comment{
        margin-top: 20px;
        background: rgba(247, 247, 247, 1);
        border: none;
        resize: none;
        height: 71px;
    }
    /*chrome滚动条样式*/  
    ::-webkit-scrollbar {/*滚动条整体部分，其中的属性有width,height,background,border（就和一个块级元素一样）等。*/  
        width: 0px !important;  
        height: 5px;  
    }  
    .login-page{
        width: 100%;
        height: 100%;
        display: flex;
        .login-info{
            width: 640px;
            height: 100%;
            // background: url('../../assets/img/login-bg.jpg');
            padding: 196px 0 0 120px;
            .pro-title{
                color: rgba(18, 18, 18, 1);
                font-size: 40px;
                letter-spacing: 1px;
            }
            .pro-des{
                color: rgba(44, 64, 90, 1);
                font-size: 16px;
                margin-top: 32px;
            }
        }
        .login-content{
            flex: 1;
            position: relative;
            background: #fff;
            .colorful{
                position: absolute;
                right: 0;
                top: 0;
                width: 360px;
            }
            .login-logo{
                position: absolute;
                left: 60px;
                top: 20px;
                img{
                    width: 48px;
                    float: left;
                    margin-right: 8px;
                }
                span{
                    color: rgba(0, 0, 0, 1);
                    font-size: 20px;
                    float: left;
                    font-weight: bold;
                    margin-top: 8px;
                }
            }
            .no-account{
                color:rgba(0, 0, 0, 1);
                font-size: 14px;
                cursor: pointer;
                position: absolute;
                right: 70px;
                top: 34px;
                font-weight: bold;
                font-size: 15px;
            }
            .sys-login{
                width: 320px;
                display: inline-block;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -52%);
                margin-top: -80px;
                .phone-input{
                    height: 262px;
                    display: inline-block;
                    background: #fff;
                    border-radius: 4px;
                    overflow: hidden;
                    width: 100%;
                    .login-title{
                        font-size: 26px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 1);
                        text-align: left;
                    }
                    .info-input{
                        height: 52px;
                        margin-top: 47px;
                        border: none;
                        background: rgba(188, 202, 214, 0.2);
                    }
                    .next-step1{
                        margin-top: 20px;
                        width: 100%;
                        height: 52px;
                    }
                }
                .phone-input2{
                    height: 348px
                }
                .company{
                    max-height: 422px;
                    min-height: 300px;
                    display: inline-block;
                    background: #fff;
                    border-radius: 4px;
                    overflow: hidden;
                    margin-top: 47px;
                    width: 100%;
                    .login-title{
                        font-size: 26px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 1);
                        text-align: left;
                        span:nth-child(2){
                            color: rgba(24, 144, 255, 1);
                            margin-left: 8px;
                        }
                    }
                    .pro-list{
                        max-height: 256px;
                        overflow: auto;
                        margin-top: 47px;
                        div{
                            width: 100%;
                            height: 72px;
                            line-height: 72px;
                            border-radius: 4px;
                            background: rgba(188, 202, 214, 0.2);
                            color: rgba(139, 152, 167, 1);
                            padding-left: 10px;
                            cursor: pointer;
                            margin-bottom: 20px;
                        }
                        div:hover{
                            background: rgba(24, 144, 255, 0.2);
                            color: rgba(24, 144, 255, 1);
                        }
                        div.active{
                            background: rgba(24, 144, 255, 0.2);
                            color: rgba(24, 144, 255, 1);
                            border: 1px solid rgba(24, 144, 255, 1);
                        }
                    }
                    .btn-group{
                        margin-top: 20px;
                        div{
                            display: inline-block;
                            width: 154px;
                            height: 52px;
                            text-align: center;
                            line-height: 52px;
                            border-radius: 4px;
                            font-size: 18px;  
                            cursor: pointer;                  
                        }
                        div:nth-child(1){
                            margin-right: 8px;
                            background: rgba(24, 144, 255, 1);
                            color: #fff;
                        }
                        div:nth-child(2){
                            background: rgba(24, 144, 255, 1);
                            color: #fff;
                        }
                    }
                    .btn-group1{
                        margin-top: 20px;
                        div{
                            display: inline-block;
                            width: 100%;
                            height: 52px;
                            text-align: center;
                            line-height: 52px;
                            border-radius: 4px;
                            font-size: 18px;  
                            cursor: pointer;  
                            background: rgba(24, 144, 255, 1);
                            color: #fff;                
                        }
                    }
                    .login-way{
                        font-size: 14px;
                        margin-top: 20px;
                        span:nth-child(1){
                            color: rgba(18, 18, 18, 1);
                        }
                        span:nth-child(2){
                            color: rgba(24, 144, 255, 1);
                            margin-left: 4px;
                            cursor: pointer;
                        }
                    }
                    .username{
                        color: rgba(153, 153, 153, 1);
                        font-size: 14px;
                        margin-top: 23px;
                    }
                    .username-input{
                        width: 100%;
                        height: 52px;
                        margin-top: 45px;
                        border: none;
                        background: rgba(188, 202, 214, 0.4);
                    }
                    .password{
                        color: rgba(153, 153, 153, 1);
                        font-size: 14px;
                        margin-top: 16px;
                    }
                    .password-input{
                        width: 100%;
                        height: 52px;
                        margin: 20px 0 0 0;
                        border: none;
                        background: rgba(188, 202, 214, 0.2);
                    }
                    .password-input:focus{ 
                        outline: none 
                    }
                    .change-company{
                        text-align: right;
                        color:rgba(24, 144, 255, 1);
                        font-size: 13px;
                        margin-top: 16px;
                        cursor: pointer;
                    }
                    .phone{
                        color: rgba(153, 153, 153, 1);
                        font-size: 14px;
                        margin-top: 23px;
                    }
                    .phonenumber-input{
                        width: 100%;
                        height: 52px;
                        margin-top: 45px;
                        background: rgba(188, 202, 214, 0.2);
                        border: none;
                    }
                }
                .company2{
                    max-height: 600px;
                }
                .login-btn{
                    width: 100%;
                    height: 32px;
                }
            }
        }
    }
    .ant-input:focus {
        border: none;
        box-shadow: none;
    }
</style>
