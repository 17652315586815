<template>
    <div class="login">
        <div class="title">EOCC产品登录</div>
        <div class="sys-login">
            <div class="sys-login-title">系统登录</div>
            <div v-if="step0" class="phone-input phone-input2">
                <div class="login-title">完善信息</div>
                <div class="input-info-title">输入企业名称：</div>
                <a-input v-model.trim="proName" class="info-input" placeholder="请输入企业名称" />
                <div class="input-info-title">输入姓名：</div>
                <a-input v-model.trim="username" class="info-input" placeholder="请输入姓名" />
                <a-button @click="nextStep0" class="next-step1" type="primary">下一步</a-button>
            </div>
            <div v-if="step5" class="company company2">
                <div class="login-title">
                    <span>请先绑定手机号</span>
                </div>
                <div class="phone">输入手机号：</div>
                <a-input v-model.trim="bindedPhoneNum" @change="changePhone()" class="phonenumber-input" type='text' placeholder="请输入手机号" />
                <VerificationCode v-bind:message="message" ref='code'></VerificationCode>
                <a-button @click="intoSystem" class="login-btn" type="primary">
                登录
                </a-button>
            </div>
        </div>
    </div>
</template>

<script>
import VerificationCode from '../../components/VerificationCode'
export default {
    name: 'Login',
    props: {
        msg: String
    },
    components: {VerificationCode},
    data() {
        return {
            step0: true,
            step5: false,
            bindedPhoneNum: null,
            message: {
                bindedPhoneNum: null,
                type: ''
            },
            token: '',
            proName: '',
            username: ''
        }
    },
    methods: {
        nextStep0() {
            if(this.username!=''&&this.proName!=''){
                this.axios(
                    {
                        method:'get',
                        url:'/api/organization/isRepeated/'+this.proName, 
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        if(response.data.data.isRepeated == 0){
                            this.step0 = false
                            this.step5 = true
                        }else{
                            this.$message.error('企业名称重复，请重新输入')
                            this.proName = ''
                        }
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            }
        },
        intoSystem() {
            this.axios.post('/api/register',{
                token: this.token,
                orgName: this.proName,
                userName: this.username,
                phone: this.bindedPhoneNum,
                verifyCode: this.$refs.code.verificationCode,
            })
            .then(response=> {
                if(response.data.code == 0){
                    this.sessionData('set','token',response.data.data.token);
                    this.sessionData('set','name',response.data.data.name);
                    this.sessionData('set','avatar',response.data.data.avatar);
                    // this.$router.push("/PersonalInfo")
                    this.$router.push({
                        path: "/customerLead",
                        query: {
                            moduleId: 8
                        }
                    })
                }else{
                    this.$message.error(response.data.userTip);
                }
            })
            .catch(error=> {
                console.log(error);
            });
        },
        changePhone() {
            this.message.bindedPhoneNum = this.bindedPhoneNum
            this.message.type = 2
        },
        verificationToken() {
            this.axios.post('/api/login/oneKey',{
                token: this.token,
            })
            .then(response=> {
                if(response.data.code == 0){
                    if(response.data.data.isRegister==1){
                        this.sessionData('set','token',response.data.data.token);
                        this.sessionData('set','name',response.data.data.name);
                        this.sessionData('set','avatar',response.data.data.avatar);
                        // this.$router.push("/PersonalInfo")
                        this.$router.push({
                            path: "/customerLead",
                            query: {
                                moduleId: 8
                            }
                        })
                    }else{
                        this.$message.error('请先完成注册');
                    }
                }else{
                    this.$message.error(response.data.userTip);
                    if(response.data.code == 'A0200'){
                        this.$router.push("/login")
                    }
                }
            })
            .catch(error=> {
                console.log(error);
            });
        },
    },
    mounted: function() {
        this.token = this.$route.query.token
        this.verificationToken()
    }
}
</script>

<style scoped lang="less"> 
    /*chrome滚动条样式*/  
    ::-webkit-scrollbar {/*滚动条整体部分，其中的属性有width,height,background,border（就和一个块级元素一样）等。*/  
        width: 0px !important;  
        height: 5px;  
    }  
    .login{
        background: rgba(240, 242, 243, 1);
        position: relative;
        margin: 0 auto;
        width: 100%;
        height: 100%;
        .title{
            height: 56px;
            background: rgba(24, 144, 255, 1);
            color: #fff;
            font-size: 16px;
            text-align: center;
            line-height: 56px;
            text-align: center;
        }
        .sys-login{
            width: 480px;
            display: inline-block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -52%);
            .sys-login-title{
                font-size: 30px;
                font-weight: bold;
                color: rgba(51, 51, 51, 1);
                text-align: center;
            }
            .phone-input{
                height: 262px;
                display: inline-block;
                background: #fff;
                border-radius: 4px;
                overflow: hidden;
                margin-top: 41px;
                width: 100%;
                padding: 27px 24px 0;
                .login-title{
                    color: rgba(18, 18, 18, 1);
                    font-size: 18px;
                    border-bottom: 1px solid rgba(229, 229, 229, 1);
                    padding-bottom: 9px;
                }
                .input-info-title{
                    color: rgba(153, 153, 153, 1);
                    font-size: 14px;
                    padding: 17px 0 8px;
                }
                .info-input{
                    height: 32px;
                }
                .next-step1{
                    margin-top: 32px;
                    width: 100%;
                    height: 32px;
                }
            }
            .phone-input2{
                height: 348px
            }
            .company{
                max-height: 422px;
                min-height: 300px;
                display: inline-block;
                background: #fff;
                border-radius: 4px;
                overflow: hidden;
                margin-top: 41px;
                width: 100%;
                padding: 27px 24px 40px; 
                .login-title{
                    color: rgba(18, 18, 18, 1);
                    font-size: 18px;
                    border-bottom: 1px solid rgba(229, 229, 229, 1);
                    padding-bottom: 9px;
                    span:nth-child(2){
                        color: rgba(24, 144, 255, 1);
                        margin-left: 8px;
                    }
                }
                .phone{
                    color: rgba(153, 153, 153, 1);
                    font-size: 14px;
                    margin-top: 23px;
                }
                .phonenumber-input{
                    width: 100%;
                    height: 32px;
                    margin-top: 9px;
                }
            }
            .company2{
                max-height: 555px;
            }
            .login-btn{
                width: 100%;
                height: 32px;
            }
        }
    }
</style>
