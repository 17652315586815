<template>
  <div id="app" style="width:100%;height:100%;">
    <a-config-provider :locale="locale">
      <router-view></router-view>
    </a-config-provider>
  </div>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
export default {
  name: 'App',
  components: {

  },
  data () {
    return {
      locale: zhCN,
    }
  },
  methods: {
    
  },
  mounted: function() {

  },
}
</script>

<style>
  @import "./assets/css/global.scss";
</style>
