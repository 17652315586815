<template>
    <div class="personal-info">
        <div class="head">
            <span class="title">个人信息</span>
            <a-icon type="exclamation-circle" />
        </div>
        <div class="content">
            <div class="basic-info">
                <div>
                    <div class="username" v-if="personalInfo.avatar==''">
                        {{(personalInfo.name).substr(0, 1)}}
                    </div>
                    <img v-if="personalInfo.avatar!=''" style="display:inline-block;width:120px;height:120px;border-radius:60px;float:left" :src="personalInfo.avatar" alt="">
                    <div class="introduce">
                        <div class="name-info">
                            <span>{{personalInfo.account}}</span>
                            <span>|</span>
                            <span>{{personalInfo.deptName}}</span>
                            <span>|</span>
                            <span>{{personalInfo.orgName}}</span>
                            <span class="state">
                                <span v-if="personalInfo.status==1">启用</span>
                                <span v-if="personalInfo.status==2">停用</span>
                            </span>
                        </div>
                        <div class="admin">
                            <span>是否管理员：</span>
                            <span v-if="personalInfo.isAdmin==0">否</span>
                            <span v-if="personalInfo.isAdmin==1">是</span>
                        </div>
                        <div class="createtime">
                            <span>创建时间：</span>
                            <span>{{personalInfo.createTime}}</span>
                        </div>
                    </div>
                </div>
                <div style="clear:both"></div>
                <div v-if="imgBase64==''" class="footer-btn" style="margin-top:16px;">
                    <label class="btn" for="uploads">
                        <a-icon style="margin-right:8px" type="upload" />
                        <span>上传头像</span>
                    </label>
                    <input type="file" id="uploads" style="position:absolute; clip:rect(0 0 0 0);" accept="image/png, image/jpeg, image/gif, image/jpg" @change="selectImg($event)">
                </div>
                <div style="margin-top:16px;" v-if="imgBase64!=''">
                    <a-button @click="sureChangeImg" style="margin-right:16px;" type="primary">确定</a-button>
                    <a-button @click="offChangeImg">取消</a-button>
                </div>
            </div>
            <div class="strategy-info">
                <div class="strategy">
                    <div class="title">我的策略</div>
                    <div class="table-strategy">
                        <a-table
                            :columns="columns"
                            :data-source="data"
                            bordered
                            size="middle"
                            :pagination="false"
                        />
                    </div>
                </div>
                <div class="info-data">
                    <div class="personal-data">
                        <div class="title">信息修改</div>
                        <div class="info-list">
                            <div class="col-title">姓名：</div>
                            <div class="col-content">
                                <span class="text-show">{{personalInfo.name}}</span>
                                <span @click="changeName" class="text-edit">修改</span>
                            </div>
                        </div>
                        <div class="info-list">
                            <div class="col-title">密码：</div>
                            <div class="col-content">
                                <span class="text-show">******</span>
                                <span @click="changePassword" class="text-edit">修改</span>
                            </div>
                        </div>
                        <div class="info-list">
                            <div class="col-title">是否绑定微信：</div>
                            <div class="col-content">
                                <span class="text-show">{{personalInfo.wechatName}}</span>
                                <span v-if="personalInfo.wechatName==''" @click="bindWx" class="text-edit" style="margin-left:0px">绑定</span>
                                <span v-if="personalInfo.wechatName!=''" @click="changeWx" class="text-edit">解除绑定</span>
                            </div>
                        </div>
                        <div class="info-list">
                            <div class="col-title">手机号：</div>
                            <div class="col-content">
                                <span class="text-show">{{personalInfo.phone}}</span>
                                <span @click="changePhone" class="text-edit">修改</span>
                            </div>
                        </div>
                    </div>
                    <div class="extention-info">
                        <div class="title">分机号信息</div>
                        <div class="info-list">
                            <div class="col-title">分机号：</div>
                            <div class="col-content">
                                <span class="text-show">{{extentionData.extensionCode}}</span>
                            </div>
                        </div>
                        <div class="info-list">
                            <div class="col-title">注册地址：</div>
                            <div class="col-content">
                                <span class="text-show">{{extentionData.sipServer}}</span>
                            </div>
                        </div>
                        <div class="info-list">
                            <div class="col-title">密码：</div>
                            <div class="col-content extention-password">
                                <span v-if="!ifShowPwd" class="text-show">******</span>
                                <span v-if="ifShowPwd" class="text-show">{{extentionData.password}}</span>
                                <a-icon v-if="!ifShowPwd" @click="showPassword" type="eye" />
                                <a-icon v-if="ifShowPwd" @click="hidePassword" type="eye-invisible" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="changeNameModal" title="修改姓名" footer='' width='480px'>
            <div class="edit-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <div class="changed-name">输入修改后的姓名：</div>
                <a-input v-model="changedName" :placeholder="personalInfo.name" />
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group">
                    <a-button type="primary" ghost @click="cancelChangeName">取消</a-button>
                    <a-button type="primary" @click="sureChangeName">确认</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="changePasswordModal" title="修改密码" footer='' width='480px'>
            <div class="password-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <div class="changed-password">输入新的密码：</div>
                <a-input v-model.trim='password1' type='password' placeholder="输入新的密码" />
                <div class="twice-changed-password">再次输入新的密码：</div>
                <a-input v-model.trim='password2' type='password' placeholder="再次输入新的密码" />
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group">
                    <a-button type="primary" ghost @click="cancelChangePassword">取消</a-button>
                    <a-button type="primary" @click="sureChangePassword">确认</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="changeWxModal" title="绑定微信号" footer='' width='480px'>
            <div class="erweima-modal">
                <div>扫描图中二维码，关注微信公众号，绑定微信</div>
                <img :src="codeUrl" alt="">
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="offBindWxModal" footer='' width='480px'>
            <div class="offbindwx-modal modal-public">
                <div class="sure-off-bind">
                    <a-icon type="warning" />
                    <span>您确定要解除绑定吗？</span>
                </div>
                <div class="tip">解除绑定后，将无法收到微信消息提醒</div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group2">
                    <a-button ghost @click="cancelBindWx">取消</a-button>
                    <a-button type="primary" @click="sureOffBindWx">确认</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="changePhoneModal" title="替换绑定的手机号" footer='' width='480px'>
            <div class="phone-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <div class="changed-phone">输入新的手机号：</div>
                <a-input v-model.trim="newPhone" @change="changeTelephone()" placeholder="13800000000" />
                <VerificationCode v-bind:message="message" ref='code'></VerificationCode>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group">
                    <a-button type="primary" ghost @click="cancelChangePhone">取消</a-button>
                    <a-button type="primary" @click="sureChangePhone">确认</a-button>
                </div>
            </div>
        </a-modal>
    </div>
</template>

<script>
    import VerificationCode from '../../components/VerificationCode'
    const columns = [
        {
            title: '模块名称',
            dataIndex: 'moduleName',
            key: 'moduleName',
        },
        {
            title: '共享规则',
            children: [
            {
                title: '我创建的',
                dataIndex: 'myCreatedShareStrategy',
                key: 'myCreatedShareStrategy',
            },
           {
                title: '下级创建的',
                dataIndex: 'subordinateCreatedShareStrategy',
                key: 'subordinateCreatedShareStrategy',
            },
            {
                title: '影响到我的',
                dataIndex: 'influenceMeShareStrategy',
                key: 'influenceMeShareStrategy',
            },
            ],
        },
        {
            title: '推送规则',
            children: [
                {
                    title: '我创建的',
                    dataIndex: 'myCreatedNoticeStrategy',
                    key: 'myCreatedNoticeStrategy',
                },
                {
                    title: '下级创建的',
                    dataIndex: 'subordinateCreatedNoticeStrategy',
                    key: 'subordinateCreatedNoticeStrategy',
                },
                {
                    title: '影响到我的',
                    dataIndex: 'influenceMeNoticeStrategy',
                    key: 'influenceMeNoticeStrategy',
                },
            ],
        },
    ];
    export default {
        name: 'PersonalInfo',
        components: {VerificationCode},
        data() {
            return {
                data: [],
                columns,
                changeNameModal: false,
                changePasswordModal: false,
                changeWxModal: false,
                offBindWxModal: false,
                changePhoneModal: false,
                personalInfo: null,
                password1: '',
                password2: '',
                changedName: '',
                newPhone: '',
                message: {
                    bindedPhoneNum: null,
                    type: ''
                },
                codeUrl: '',
                timer: null,
                moduleId: '',
                imgBase64: '',
                imgId: '',
                extentionData: '',
                ifShowPwd: false,
            };
        },
        methods: {
            sureChangeImg() {
                this.axios.post('/api/user/updateAvatar', {
                    avatar: this.imgId,
                })
                .then(response=> {
                    this.imgBase64 = ''
                    this.imgId = ''
                    if(response.data.code == 0){
                        this.$message.success('上传成功！');
                        this.queryPersonalInfo()
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            offChangeImg() {
                this.imgBase64 = ''
            },
            selectImg(e) { // 上传提交
                let file = e.target.files[0]
                if (!/\.(jpg|jpeg|png|JPG|PNG)$/.test(e.target.value)) {
                    this.$message({
                        message: '图片类型要求：jpeg、jpg、png',
                        type: "error"
                    });
                    return false
                }
                //转化为blob
                let reader = new FileReader()
                //转化为base64
                reader.readAsDataURL(file)
                setTimeout(()=>{
                    this.imgBase64 = reader.result
                    this.upLoadImg()
                },500)
                
            },
            upLoadImg() {
                this.axios.post('/api/file/uploadImg', {
                    base64Img: this.imgBase64,
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.imgId = response.data.data.fileId
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            queryPersonalInfo() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/user', 
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.personalInfo = response.data.data
                        this.changedName = response.data.data.name
                        this.newPhone = response.data.data.phone
                        this.data = response.data.data.myStrategyStatisticList
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            changeName() {
                this.changeNameModal = true
            },
            cancelChangeName() {
                this.changeNameModal = false
                this.changedName = ''
            },
            sureChangeName() {
                this.changeNameModal = false
                this.axios.post('/api/user/updateName', {
                    name: this.changedName,
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.$message.success('修改成功')
                        this.queryPersonalInfo()
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
                this.changedName = ''
            },
            changePassword() {
                this.changePasswordModal = true
            },
            cancelChangePassword() {
                this.changePasswordModal = false
                this.password1 = ''
                this.password2 = ''
            },
            sureChangePassword() {
                this.changePasswordModal = false
                if(this.password1 == this.password2){
                    this.axios.post('/api/user/updatePwd', {
                        password: this.password1,
                    })
                    .then(response=> {
                        if(response.data.code == 0){
                            this.$message.success('修改成功')
                            this.queryPersonalInfo()
                        }else{
                            this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                        }
                    })
                    .catch(error=> {
                        console.log(error);
                    });
                }else{
                    this.$message.error('两次密码不一致')
                }
                this.password1 = ''
                this.password2 = ''
            },
            changeWx() {
                this.offBindWxModal = true
            },
            bindWx() {
                this.changeWxModal = true
                this.axios(
                    {
                        method:'get',
                        url:'/api/wechat/qrCode', 
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.codeUrl = response.data.data.qrCodeUrl
                        this.timer = setInterval(()=>{
                            this.queryPersonalInfo()
                            if(this.personalInfo.wechatName!=''){
                                clearInterval(this.timer)
                                this.changeWxModal = false
                                this.$message.success('绑定成功');
                            }
                        },2000)
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
                
            },
            cancelBindWx() {
                this.offBindWxModal = false
            },
            sureOffBindWx() {
                this.offBindWxModal = false
                this.axios.post('/api/user/unBindWechat')
                .then(response=> {
                    if(response.data.code == 0){
                        this.$message.success('解除绑定')
                        this.queryPersonalInfo()
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            changePhone() {
                this.changePhoneModal = true
            },
            cancelChangePhone() {
                this.changePhoneModal = false
            },
            sureChangePhone() {
                this.changePhoneModal = false
                this.axios.post('/api/user/bindPhone', {
                    phone: this.newPhone,
                    verifyCode: this.$refs.code.verificationCode
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.$message.success('修改成功')
                        this.queryPersonalInfo()
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            changeTelephone() {
                this.message.bindedPhoneNum = this.newPhone
                this.message.type = 2
            },
            queryExtentionInfo() {
                this.axios(
                   {
                        method:'get',
                        url:'/api/extension/info', 
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.extentionData = response.data.data
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            showPassword() {
                this.ifShowPwd = true
            },
            hidePassword() {
                this.ifShowPwd = false
            }
        },
        mounted: function() {
            this.sessionData('set','personalCenter','personalInfo');
            this.moduleId = sessionStorage.getItem('moduleId')
            this.queryPersonalInfo()
            this.queryExtentionInfo()
        },
        beforeRouteLeave(to, from, next){
            next();
            clearInterval(this.timer);
        },
    }
</script>

<style scoped lang="less">
    .personal-info{
        width: 100%;
        height: 100%;
        overflow: auto;
        .head{
            height: 48px;
            line-height: 48px;
            padding: 0 24px;
            background: #fff;
            border-left: 1px solid #e8ebee;
            .title{
                color: rgba(18, 18, 18, 1);
                font-size: 16px;
            }
            i{
                float: right;
                margin-top: 16px;
                font-size: 16px;
                color: rgba(204, 204, 204, 1);
                cursor: pointer;
            }
        }
        .content{
            padding: 16px;
            .basic-info{
                height: 210px;
                background: #fff;
                width: 100%;
                padding: 24px;
                border-radius: 4px;
                .username{
                    display: inline-block;
                    width: 120px;
                    height: 120px;
                    border-radius: 60px;
                    color: #fff;
                    text-align: center;
                    line-height: 120px;
                    background: rgba(121, 72, 234, 0.6);
                    font-size: 36px;
                    float: left;
                }
                .introduce{
                    display: inline-block;
                    margin: 15px 0 0 24px;
                    float: left;
                    .name-info{
                        span{
                            display: inline-block;
                            margin-right: 4px;
                            color: rgba(18, 18, 18, 1);
                            font-size: 18px;
                        }
                        span.state span{
                            width: 44px;
                            height: 24px;
                            text-align: center;
                            line-height: 24px;
                            color: rgba(34, 215, 187, 1);
                            border: 1px solid rgba(34, 215, 187, 1);
                            border-radius: 4px;
                            margin-left: 12px;
                            font-size: 14px;
                        }
                    }
                    .admin{
                        margin-top: 24px;
                        color: rgba(153, 153, 153, 1);
                        font-size: 12px;
                    }
                    .createtime{
                        color: rgba(153, 153, 153, 1);
                        font-size: 12px;
                        margin-top: 4px;
                    }
                }
                .footer-btn{
                    margin-top: 24px;
                    .btn {
                        outline: none;
                        display: inline-block;
                        line-height: 30px;
                        white-space: nowrap;
                        cursor: pointer;
                        -webkit-appearance: none;
                        text-align: center;
                        -webkit-box-sizing: border-box;
                        box-sizing: border-box;
                        outline: 0;
                        -webkit-transition: .1s;
                        transition: .1s;
                        font-weight: 500;
                        width: 136px;
                        height: 32px;
                        font-size: 12px;
                        border-radius: 4px;
                        color: rgba(24, 144, 255, 1);
                        background-color: #fff;
                        border: 1px solid rgba(24, 144, 255, 1);
                        margin-right: 10px;
                        span:nth-child(1){
                            margin-right: 4px;
                            font-size: 16px;
                        }
                    }
                    .btn-group{
                        margin-top: 12px;
                        button{
                            margin-right: 8px;
                        }
                    }
                }
            }
            .strategy-info{
                width: 100%;
                height: 648px;
                display: flex;
                margin-top: 16px;
                .strategy{
                    flex: 1;
                    background: #fff;
                    padding: 18px 24px;
                    border-radius: 4px;
                    .title{
                        color: rgba(18, 18, 18, 1);
                        font-size: 14px;
                    }
                    .table-strategy{
                        margin-top: 25px;
                    }
                }
                .info-data{
                    padding: 0 0 0 24px;
                    width: 404px;
                    .personal-data{
                        padding: 18px 24px;
                        background: #fff;
                        border-radius: 4px;
                        height: 352px;
                        .title{
                            color: rgba(18, 18, 18, 1);
                            font-size: 14px;
                        }
                        .info-list{
                            margin-top: 25px;
                            color: rgba(80, 80, 80, 1);
                            font-size: 14px;
                            .col-title{
                                color: rgba(153, 153, 153, 1);
                                margin-bottom: 6px;
                            }
                            div{
                                .text-show{
                                    display: inline-block;
                                    line-height: 21px;
                                }
                                .text-edit{
                                    display: none;
                                    margin-left: 24px;
                                    cursor: pointer;
                                    color: rgba(24, 144, 255, 1);
                                    font-size: 12px;
                                }
                            }
                            div:nth-child(2):hover{
                                .text-edit{
                                    display: inline-block;
                                }
                            }
                        }
                    }
                    .extention-info{
                        height: 280px;
                        margin-top: 16px;
                        background: #fff;
                        border-radius: 4px;
                        padding: 18px 24px;
                        .title{
                            color: rgba(18, 18, 18, 1);
                            font-size: 14px;
                        }
                        .info-list{
                            margin-top: 25px;
                            color: rgba(80, 80, 80, 1);
                            font-size: 14px;
                            .col-title{
                                color: rgba(153, 153, 153, 1);
                                margin-bottom: 6px;
                            }
                            div{
                                .text-show{
                                    display: inline-block;
                                    line-height: 21px;
                                }
                                .text-edit{
                                    display: none;
                                    margin-left: 24px;
                                    cursor: pointer;
                                    color: rgba(24, 144, 255, 1);
                                    font-size: 12px;
                                }
                            }
                            div:nth-child(2):hover{
                                .text-edit{
                                    display: inline-block;
                                }
                            }
                        }
                        .extention-password{
                            i{
                                margin-left: 29px;
                                font-size: 16px;
                                display: none;
                            }
                        }
                        .extention-password:hover{
                            i{
                                display: inline-block;
                            }
                        }
                    }
                }
                
            }
        }
    }
    .modal-public{
        .divider1{
            margin: 0px 0 24px 0;
        }
        .divider2{
            margin: 24px 0 12px;
        }
        .btn-group{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                margin-right: 16px;
            }
        }
        .btn-group2{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                background: #fff;
                color: rgba(121, 136, 155, 0.7);
                border: 1px solid rgba(121, 136, 155, 0.5);
                margin-right: 16px;
            }
            button:nth-child(1):hover{
                background: rgba(121, 136, 155, 0.08);
            }
        }
    }
    .edit-modal{
        padding-bottom: 56px;
        .changed-name{
            color: rgba(153, 153, 153, 1);
            font-size: 14px;
        }
        input{
            margin-top: 8px;
        }
    }
    .password-modal{
        padding-bottom: 56px;
        .changed-password{
            color: rgba(153, 153, 153, 1);
            font-size: 14px;
        }
        .twice-changed-password{
            color: rgba(153, 153, 153, 1);
            font-size: 14px;
            margin-top: 24px;
        }
        input{
            margin-top: 8px;
        }
    }
    .erweima-modal{
        padding-bottom: 24px;
        div{
            color: rgba(80, 80, 80, 1);
            font-size: 14px;
            text-align: center;
        }
        img{
            width: 205px;
            margin: 24px auto 0;
            text-align: center;
            display: block;
        }
    }
    .offbindwx-modal{
        padding-top: 24px;
        padding-bottom: 56px;
        .sure-off-bind{
            i{
                color: rgba(255, 170, 0, 1);
                font-size: 24px;
            }
            span{
                color: rgba(18, 18, 18, 1);
                font-size: 20px;
                margin-left: 24px;
            }
        }
        .tip{
            color: rgba(80, 80, 80, 1);
            padding-left: 50px;
            margin: 24px 0;
        }
    }
    .phone-modal{
        padding-bottom: 56px;
        .changed-phone{
            color: rgba(153, 153, 153, 1);
            font-size: 14px;
        }
        input{
            margin-top: 8px;
        }
    }
    
</style>