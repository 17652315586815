<template>
    <div class="outcall-task">
        <div class="head">
            <span class="title">{{moduleName}}</span>
            <a-icon type="exclamation-circle" />
        </div>
        <div class="content">
            <div class="content-in">
                <div class="modular-content">
                    <div class="modular-in">
                        <div class="modular-operation">
                            <div class="operation-group">
                                <ScreenColumn ref="child3"></ScreenColumn>
                                <a-divider class="divider" type="vertical" />
                                <Export v-if="operationAuthority.EXPORT" ref="child4"></Export>
                                <MoreOperation ref="child5"></MoreOperation>
                            </div>
                        </div>
                        <div class="table-data">
                            <a-table
                                :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
                                :columns="columns"
                                :data-source="tableData"
                                :pagination='false'
                                :scroll="{ x: 'max-content' }"
                                :loading='loading'
                            >
                                <span slot="dataTitle" slot-scope="text, record">
                                    <a @click="viewRecords(record)">{{ record.dataTitle }}</a>
                                </span>
                            </a-table>
                            <div class="pagination-table">
                                <a-pagination
                                    show-size-changer
                                    :total="total"
                                    :show-total="total => `共 ${total} 条     `"
                                    v-model="currentPage"
                                    @change="pageChange"
                                    @showSizeChange="onShowSizeChange"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <a-drawer
            title='待呼号码-手机号码'
            placement="right"
            :closable="true"
            :visible="drawerVisible"
            :destroyOnClose='true'
            :maskClosable='true'
            @close="onClose"
            :width='800'
            :mask='true'
            :headerStyle="{ border: 'none', height: '56px', position: 'absolute', width: '100%', left: '0', top: '0', zIndex: '0'}"
            :bodyStyle="{ background: 'rgba(240, 242, 243, 1)', height: '100%', position: 'absolute', width: '100%', left: '0', top: '0', zIndex: '10', padding: '0 0 0' }"
        >
            <div class="drawer-sence drawer-public">
                <div class="drawer-head">
                    <div class="drawer-title">
                        <span :title="digitalDetail.moduleName+'-'+digitalDetail.dataTitle" class="left">{{digitalDetail.moduleName+"-"+(digitalDetail.dataTitle.length>28?digitalDetail.dataTitle.substring(0,28)+'...':digitalDetail.dataTitle)}}</span>
                        <div class="right">
                            <a-icon @click="onClose" type="close" />
                        </div>
                    </div>
                    <a-row class="row-head">
                        <a-col :span="8" class="col-left">
                            <div>创建人：</div>
                            <div>{{digitalDetail.createBy}}</div>
                        </a-col>
                        <a-col :span="8" class="col-left">
                            <div>创建时间：</div>
                            <div>{{digitalDetail.createTime}}</div>
                        </a-col>
                        <a-col :span="8" class="col-left">
                            <div>修改时间：</div>
                            <div>{{digitalDetail.updateTime}}</div>
                        </a-col>
                    </a-row>
                </div>
                <div class="drawer-content">
                    <div class="drawer-body-content">
                        <div class="basic-info">
                            <div class="title">基本信息</div>
                            <a-row class="row-list">
                                <a-col :span="12" class="col-list" :class="{'col-left':index%2==0,'col-right':index%2!=0}" v-for="(item,index) in digitalDetail.detailList" :key='index'>
                                    <div class="first-content">
                                        {{item.name}}:
                                    </div>
                                    <div v-if="item.type!='CALLING_NUMBER_USER_LIST'&&item.code!='remark'" class="second-content">{{item.value}}</div>
                                    <div v-if="item.code=='remark'&&!ifEditUserDetail" class="second-content">{{item.value}}</div>
                                    <div v-if="ifEditUserDetail&&item.code=='remark'&&item.isEditable==1">
                                        <a-input v-model="item.value" @change="changeTaskName" class="second-content" placeholder="任务名称" />
                                    </div>
                                    <div v-if="!ifEditUserDetail&&item.type=='CALLING_NUMBER_USER_LIST'" class="second-content">{{item.value}}</div>
                                    <div class="affect-list" v-if="item.type=='CALLING_NUMBER_USER_LIST'&&ifEditUserDetail">
                                        <div class="second-content calling-number">
                                            <span v-for="(item1,index1) in child1Data.personSelected" :key="index1" style="margin-right:6px">{{deptAndUserMap[item1+'']}}</span>
                                        </div>
                                        <a-icon @click="chooseAffected" class="affect-icon" type="zoom-in" />
                                    </div>
                                </a-col>
                            </a-row>
                        </div>
                    </div>
                </div>
                <div class="drawer-foot">
                    <div class="btn-group">
                        <a-button type="primary" v-if="operationAuthority.DELETE" ghost @click="deleteDrawer">删除</a-button>
                    </div>
                </div>
                <div class="drawer-foot">
                    <div v-if="!ifEditUserDetail" class="btn-group">
                        <a-button type="primary" @click="editDrawer">编辑</a-button>
                    </div>
                    <div v-if="ifEditUserDetail" class="btn-group">
                        <a-button type="primary" ghost @click="cancelInfoDrawer">取消</a-button>
                        <a-button type="primary" @click="saveDrawer">保存</a-button>
                    </div>
                </div>
            </div>
        </a-drawer>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="offBindAffected" footer='' title="影响到的人" width='720px'>
            <div class="offbindaffect-modal modal-public">
                <BeAffectedPerson ref="child1"></BeAffectedPerson>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group2">
                    <a-button ghost @click="cancelBindAffected">取消</a-button>
                    <a-button type="primary" @click="sureBindAffected">确认</a-button>
                </div>
            </div>
        </a-modal>
    </div>
</template>
<script>
    import ScreenColumn from '../../components/ScreenColumn'
    import Export from '../../components/Export'
    import moment from 'moment'
    import BeAffectedPerson from '../../components/BeAffectedPerson';
    import MoreOperation from '../../components/MoreOperation'
    const columns = []
    const tableData = []
    export default {
        name: 'OutCallTask',
        components: { ScreenColumn, Export, BeAffectedPerson, MoreOperation },
        data () {
            return {
                moduleId: '',
                showField: {
                    fieldList: [],
                    valueList: []
                },
                operationAuthority: '',
                conditionList: [],
                currentPage: 1,
                pageSize: 10,
                loading: false,
                tableData,
                columns,
                total: 0,
                selectedRowKeys: [],
                drawerVisible: false,
                digitalDetail: {
                    dataTitle: ''
                },
                ifEditUserDetail: false,
                offBindAffected: false,
                passDataObjOwner: {
                    peopleTree: null,
                    personSelected: null
                },
                child1Data: {
                    personSelected: [],
                },
                deptAndUserMap: '',
                moduleName: '',
                remark: 'null'
            }
        },
        methods: {
            moment,
            queryColumnList() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/module/'+this.moduleId+'/listPage/init', 
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.operationAuthority = response.data.data.operationAuthority
                        this.showField.fieldList = response.data.data.showField.fieldList
                        this.showField.valueList = response.data.data.showField.valueList
                        this.conditionList = response.data.data.conditionList
                        this.$refs.child3.getData(this.showField,this.moduleId,this.conditionList)
                        this.$refs.child5.getScreenData(this.moduleId,this.conditionList,this.operationAuthority)
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            queryBasicData(type) {
                this.loading = true
                if(type == 'init'){
                    this.currentPage = 1
                }
                this.axios.post('/api/module/data/list', {
                    current: this.currentPage,
                    size: this.pageSize,
                    moduleId: this.moduleId,
                    conditionList: this.$refs.child3.conditionList
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.loading = false
                        this.tableData = response.data.data.tableData.records
                        this.columns = response.data.data.tableHeader
                        for(let i = 0; i < this.columns.length; i++){
                            if(this.columns[i]['title'] == 'id'){
                                this.columns[i] = {
                                    title: this.columns[i]['title'],
                                    dataIndex: this.columns[i]['dataIndex'],
                                    colSpan:0,  
                                    customRender: () => {
                                        return {
                                            attrs: {
                                                colSpan: 0,
                                            },
                                        };
                                    },
                                }
                            }
                            if(this.columns[i]['dataIndex'] == 'dataTitle'){
                                this.columns[i] = {
                                    title: this.columns[i]['title'],
                                    dataIndex: this.columns[i]['dataIndex'],
                                    scopedSlots: { customRender: 'dataTitle' },
                                }
                            }
                        }
                        this.total = response.data.data.tableData.total
                        setTimeout(()=>{
                            this.$refs.child4.getTotalNum(response.data.data.tableData.total, this.moduleId)
                        },2000)
                        
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            onSelectChange(selectedRowKeys) {
                let obj = {}
                obj.deptId = ''
                obj.conditionList = this.$refs.child3.conditionList
                this.selectedRowKeys = selectedRowKeys;
                this.$refs.child4.getData(this.moduleId, this.selectedRowKeys, obj)
                this.$refs.child5.getData(this.moduleId, this.selectedRowKeys)
            },
            passDataToChild() {
                let obj = {}
                obj.deptId = ''
                // obj.dataRange = this.$refs.child2.selectedDataRangeValue
                obj.conditionList = this.$refs.child3.conditionList
                this.$refs.child4.getData(this.moduleId, this.selectedRowKeys, obj)
            },
            pageChange(current, pageSize) {
                this.currentPage = current
                this.pageSize = pageSize
                this.queryBasicData()
            },
            onShowSizeChange(current, pageSize) {
                this.currentPage = current
                this.pageSize = pageSize
                this.queryBasicData()
            },
            viewRecords(record) {
                this.drawerVisible = true;
                this.currentId = record.id
                this.querySelectData()
            },
            querySelectData() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/module/data/detail', 
                        params: {
                            moduleId: this.moduleId,
                            dataId: this.currentId
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.digitalDetail = response.data.data
                        let arr = response.data.data.detailList
                        console.log(this.digitalDetail)
                        for(let i = 0; i < arr.length; i++){
                            if(arr[i]['type'] == 'CALLING_NUMBER_USER_LIST'){
                                this.passDataObjOwner.peopleTree = arr[i]['userTree']
                                this.passDataObjOwner.personSelected = arr[i]['userValueList']
                                this.child1Data.personSelected = arr[i]['userValueList']
                            }
                        }
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            onClose() {
                this.drawerVisible = false
            },
            deleteDrawer() {
                this.drawerVisible = false
                this.axios.post('/api/module/data/delete/batch', {
                    moduleId: this.moduleId,
                    idList: [this.currentId],
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.$message.success('删除成功')
                        this.queryBasicData()
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            editDrawer() {
                this.ifEditUserDetail = true
            },
            cancelInfoDrawer() {
                this.ifEditUserDetail = false
            },
            saveDrawer() {
                this.ifEditUserDetail = false
                this.drawerVisible = false
                this.axios.post('/api/callingNumber/distribute', {
                    callingNumberId: this.currentId,
                    userList: this.child1Data.personSelected,
                    remark: this.remark
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.queryBasicData()
                        this.initDrawer()
                        this.$message.success('修改成功')
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            cancelBindAffected() {
                this.offBindAffected = false
            },
            sureBindAffected() {
                console.log(this.$refs.child1.personSelected)
                this.child1Data.personSelected = this.$refs.child1.personSelected
                this.offBindAffected = false
            },
            chooseAffected() {
                this.offBindAffected = true
                setTimeout(()=>{
                    this.$refs.child1.getParentData(this.passDataObjOwner)
                })
            },
            initDrawer() {
                this.currentId = ''
                this.child1Data.personSelected = []
                this.passDataObjOwner.personSelected = null
            },
            queryDeptAndUserMap() {
                 this.axios(
                    {
                        method:'get',
                        url:'/api/user/deptAndUserMap', 
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.deptAndUserMap = response.data.data
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            changeTaskName(e) {
                console.log(e.target.value)
                this.remark = e.target.value
            },
        },
        mounted: function() {
            this.sessionData('set','personalCenter','numberManage');
            this.moduleId = this.$route.query.moduleId
            this.moduleName = this.$route.query.name
            this.queryColumnList()
            this.queryBasicData('init')
            this.queryDeptAndUserMap()
        }
    }
</script>

<style scoped lang="less">
    .outcall-task{
        height: 100%;
        position: relative;
        .head{
            height: 48px;
            line-height: 48px;
            padding: 0 24px;
            background: #fff;
            border-left: 1px solid #e8ebee;
            z-index: 100;
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            button{
                float: right;
                height: 32px;
                margin: 8px 0 0 8px;
            }
            .title{
                color: rgba(18, 18, 18, 1);
                font-size: 16px;
            }
            i{
                float: right;
                margin-top: 16px;
                font-size: 16px;
                color: rgba(204, 204, 204, 1);
                cursor: pointer;
            }
        }
        .content{
            position: absolute;
            padding: 16px;
            left: 0px;
            top: 0px;
            height: 100%;
            padding-top: 64px;
            width: 100%;
            .content-in{
                width: 100%;
                height: 100%;
                position: relative;
                .modular-content{
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    height: 100%;
                    width: 100%;
                    border-radius: 4px;
                    .modular-in{
                        width: 100%;
                        height: 100%;
                        background: #fff;
                        border-radius: 4px;
                        overflow-y: auto;
                        padding: 24px;
                        .modular-operation{
                            margin-top: 21px;
                            .operation-group{
                                display: inline-block;
                                float: right;
                                .divider{
                                    margin: 0 16px 0 0;
                                    height: 32px;
                                }
                                .download{
                                    margin: 0 8px;
                                }
                            }
                        }
                        .table-data{
                            margin-top: 64px;
                        }
                        .pagination-table{
                            float: right;
                            padding-top: 24px;
                        }
                    }
                }
            }  
        }
    }
    .drawer-public{
        height: 100%;
        position: relative;
        padding-bottom: 60px;
        .drawer-title{
            height: 55px;
            width: 100%;
            span{
                color: rgba(18, 18, 18, 1);
                font-size: 16px;
                line-height: 55px;
            }
            i{
                color: rgba(153, 153, 153, 1);
                font-size: 16px;
                margin-left: 16px;
                cursor: pointer;
                line-height: 50px;
            }
        }
        .drawer-head{
            height: 144px;
            background: #fff;
            padding: 0 24px 0;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            z-index: 100;

        }
        .drawer-content{
            padding: 160px 0 68px 16px;
            width: 100%;
            height: 100%;
            position: absolute;
        }
        .drawer-foot{
            position: absolute;
            left: 0px;
            bottom: 0px;
            width: 100%;
            height: 52px;
            background: #fff;
            box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
            .btn-group{
                text-align: center;
                padding-top: 10px;
                width: 100%;
                button{
                    height: 32px;
                    font-size: 14px;
                }
                button:nth-child(2){
                    margin: 0 16px;
                }
            }
        }
    }
    .drawer-sence{
        .row-head{
            padding-top: 20px;
            border-top: 1px solid rgba(229, 229, 229, 1);
            .col-left{
                div:nth-child(1){
                    color: rgba(188, 188, 188, 1);
                    font-size: 12px;
                }
                div:nth-child(2){
                    color: rgba(80, 80, 80, 1);
                    font-size: 14px;
                    margin-top: 6px;
                }
            }
        }
        .drawer-body-content{
            width: 100%;
            height: 100%;
            border-radius: 4px;
            overflow-y: auto;
            padding-right: 16px;
            .basic-info{
                width: 100%;
                // height: 704px;
                background: #fff;
                border-radius: 4px;
                padding: 24px;
                .title{
                    color: rgba(18, 18, 18, 1);
                    font-size: 14px;
                }
                .col-left{
                    padding-right: 24px;
                }
                .col-right{
                    padding-left: 24px;
                }
                .row-list{
                    .col-list{
                        margin-top: 19px;
                        margin-bottom: 5px;
                        height: 69px;
                        .first-content{
                            color: rgba(188, 188, 188, 1);
                            font-size: 12px;
                        }
                        .second-content{
                            color: rgba(51, 51, 51, 1);
                            font-size: 14px;
                            margin-top: 9px;
                            img{
                                width: 21px;
                            }
                            .change-head{
                                color: rgba(24, 144, 255, 1);
                                font-size: 12px;
                                cursor: pointer;
                                margin-left: 24px;
                            }
                        }
                        .affect-list{
                            display: flex;
                            .calling-number{
                                flex: 1;
                                height: 32px;
                                border: 1px solid #d9d9d9;
                                border-radius: 4px;
                                overflow: hidden;
                                line-height: 32px;
                                padding-left: 12px;
                                margin-top: 4px !important;
                            }
                            .affect-icon{
                                color: rgba(121, 136, 155, 1);
                                font-size: 20px;
                                float: right;
                                margin-top: 10px;
                                cursor: pointer;
                                width: 20px;
                                margin-left: 12px;
                                display: inline-block;
                            }
                        }
                        .selection-range{
                            margin-top: 9px;
                            width: 100%;
                        }
                    }
                }
            }  
        }
    }
    .offbindaffect-modal{
        border-top: 1px solid #e8e8e8;
        padding-top: 24px;
        padding-bottom: 46px;
        .affect-modal-left{
            width: 100%;
            height: 100%;
            border-right: 1px solid #e8e8e8;
            padding-right: 24px;
            .department-tree,.person-tree{
                height: 300px;
                overflow-x: hidden;
                overflow-y: auto;
                padding-top: 16px;
            }
        }
        .affect-modal-right{
            width: 100%;
            height: 100%;
            padding-left: 24px;
            .title{
                margin-bottom: 24px;
                span:nth-child(1){
                    color: rgba(153, 153, 153, 1);
                    font-size: 14px;
                }
                span:nth-child(2){
                    color: rgba(24, 144, 255, 1);
                    font-size: 14px;
                    cursor: pointer;
                    float: right;
                }
            }
            .selected-list{
                height: 346px;
                overflow-y: auto;
                .selected-item{
                    display: inline-block;
                    height: 32px;
                    padding: 0 6px;
                    background: rgba(121, 136, 155, 0.1);
                    border-radius: 4px;
                    margin-right: 8px;
                    margin-bottom: 8px;
                    line-height: 32px;
                    img:nth-child(1){
                        color: rgba(121, 136, 155, 1);
                        width: 18px;
                        float: left;
                        margin-top: 7px;
                    }
                    span:nth-child(2){
                        color: rgba(80, 80, 80, 1);
                        font-size: 14px;
                        margin-left: 4px;
                    }
                    i:nth-child(3){
                        color: rgba(153, 153, 153, 1);
                        font-size: 14px;
                        cursor: pointer;
                        margin-left: 8px;
                    }
                }
            }
        }
    }
    .modal-public{
        .divider1{
            margin: 0px 0 24px 0;
        }
        .divider2{
            margin: 24px 0 12px;
        }
        .btn-group{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                margin-right: 16px;
            }
        }
        .btn-group2{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                background: #fff;
                color: rgba(121, 136, 155, 0.7);
                border: 1px solid rgba(121, 136, 155, 0.5);
                margin-right: 16px;
            }
            button:nth-child(1):hover{
                background: rgba(121, 136, 155, 0.08);
            }
        }
    }
    .offbindwx-modal{
        padding-top: 24px;
        padding-bottom: 46px;
        .sure-off-bind{
            i{
                color: rgba(255, 170, 0, 1);
                font-size: 24px;
            }
            span{
                color: rgba(18, 18, 18, 1);
                font-size: 20px;
                margin-left: 24px;
            }
        }
        .tip{
            color: rgba(80, 80, 80, 1);
            padding-left: 50px;
            margin: 24px 0;
        }
    }
</style>