<template>
    <div class="permission-set">
        <div class="head">
            <span class="title">{{moduleName}}</span>
            <a-button v-if="operationAuthority.SHARE" type="primary" @click="shareSet">
                共享设置
            </a-button>
            <a-icon type="exclamation-circle" />
        </div>
        <div class="content">
            <div class="content-in">
                <a-tabs default-active-key="1" @change="tabSwitch" class="digital-tab">
                    <a-tab-pane key="1" tab="已激活">
                        <div class="tab1 tab-public">
                            <div class="tab-list" :class="{'active':currentList==index}" @click="selectList1(index,item)" v-for="(item,index) in activeData" :key='index'>
                                <div class="tab-head">
                                    <span>{{item.channelCount}}人</span>
                                    <a-icon v-if="operationAuthority.EXTERNAL_LINKS" @click="outLink(item.id)" type="share-alt" />
                                    <a-icon @click="shareLink(item.experienceUrl)" type="paper-clip" />
                                    <img v-if="item.avatar==''" src="../../assets/img/logo.png" alt="">
                                    <img v-if="item.avatar!=''" :src="item.avatar" alt="">
                                </div>
                                <div class="tab-content">
                                    <div class="sence-title">{{item.name}}</div>
                                    <div class="sence-robot">
                                        <div>{{item.digitalEmployeeType}}</div>
                                    </div>
                                    <div class="sence-des">{{item.description==''?'暂无简介':item.description}}</div>
                                    <div class="time-set">
                                        <span class="work-time">工作时间：</span>
                                        <span v-if="item.workTime.weekList.length==0" class="unset">未设定</span>
                                        <span v-if="item.workTime.weekList.length!=0" class="unset">已设定</span>
                                        <div v-if="item.workTime.weekList.length!=0" class="task1">
                                            <div class="task-content">
                                                <div class="content-task"></div>
                                                <div style="position:absolute;padding:12px;width:100%;">
                                                    <div class="date">
                                                        <span v-for="(item1,index1) in item.workTime.weekList" :key='index1' style="display:inline-block;margin-right:5px;">{{judgeDay(item1)}}</span>
                                                    </div>
                                                    <div class="time">
                                                        <!-- <div>[上午] {{item.workTime.morningTime.startTime}}-{{item.workTime.morningTime.endTime}}</div>
                                                        <div class="afternoon">[下午] {{item.workTime.afternoonTime.startTime}}-{{item.workTime.afternoonTime.endTime}}</div> -->
                                                        <div>[时段1] {{item.workTime.timeQuantum1.startTime}}-{{item.workTime.timeQuantum1.endTime}}</div>
                                                        <div class="afternoon">[时段2] {{item.workTime.timeQuantum2.startTime}}-{{item.workTime.timeQuantum2.endTime}}</div>
                                                        <div>[时段3] {{item.workTime.timeQuantum3.startTime}}-{{item.workTime.timeQuantum3.endTime}}</div>
                                                        <div class="afternoon">[时段4] {{item.workTime.timeQuantum4.startTime}}-{{item.workTime.timeQuantum4.endTime}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="sence-code">
                                        <a-icon type="container" />
                                        <span>{{item.appId}}</span>
                                    </div>
                                    <div class="sence-tag">
                                        <div class="sence-icon">
                                            <a-icon type="tags" />
                                        </div>
                                        <div class="sence-tag-list">
                                            <span v-if="item.labels!=''">{{item.labels}}</span>
                                            <span v-if="item.labels==''">暂无标签</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="activeData.length==0" class="no-data">暂无数据</div>
                        </div>
                    </a-tab-pane>
                    <a-tab-pane key="2" tab="未激活" force-render>
                        <div class="tab2 tab-public">
                            <div class="tab-list" :class="{'active':currentUnList==index}" @click="selectList2(index,item)" v-for="(item,index) in inactiveData" :key='index'>
                                <div class="tab-head">
                                    <span>{{item.channelCount}}人</span>
                                    <img v-if="item.avatar==''" src="../../assets/img/logo.png" alt="">
                                    <img v-if="item.avatar!=''" :src="item.avatar" alt="">
                                </div>
                                <div class="tab-content">
                                    <div class="sence-title">{{item.name}}</div>
                                    <div class="sence-robot">
                                        <div>{{item.digitalEmployeeType}}</div>
                                    </div>
                                    <div class="sence-des">{{item.description==''?'暂无简介':item.description}}</div>
                                    <div class="time-set">
                                        <span class="work-time">工作时间：</span>
                                        <span v-if="item.workTime.weekList.length==0" class="unset">未设定</span>
                                        <span v-if="item.workTime.weekList.length!=0" class="unset">已设定</span>
                                        <div v-if="item.workTime.weekList.length!=0" class="task1">
                                            <div class="task-content">
                                                <div class="content-task"></div>
                                                <div style="position:absolute;padding:12px;width:100%;">
                                                    <div class="date">
                                                        <span v-for="(item1,index1) in item.workTime.weekList" :key='index1' style="display:inline-block;margin-right:5px;">{{judgeDay(item1)}}</span>
                                                    </div>
                                                    <div class="time">
                                                        <!-- <div>[上午] {{item.workTime.morningTime.startTime}}-{{item.workTime.morningTime.endTime}}</div>
                                                        <div class="afternoon">[下午] {{item.workTime.afternoonTime.startTime}}-{{item.workTime.afternoonTime.endTime}}</div> -->
                                                        <div>[时段1] {{item.workTime.timeQuantum1.startTime}}-{{item.workTime.timeQuantum1.endTime}}</div>
                                                        <div class="afternoon">[时段2] {{item.workTime.timeQuantum2.startTime}}-{{item.workTime.timeQuantum2.endTime}}</div>
                                                        <div>[时段3] {{item.workTime.timeQuantum3.startTime}}-{{item.workTime.timeQuantum3.endTime}}</div>
                                                        <div class="afternoon">[时段4] {{item.workTime.timeQuantum4.startTime}}-{{item.workTime.timeQuantum4.endTime}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="sence-code">
                                        <a-icon type="container" />
                                        <span>{{item.appId}}</span>
                                    </div>
                                    <div class="sence-tag">
                                        <div class="sence-icon">
                                            <a-icon type="tags" />
                                        </div>
                                        <div class="sence-tag-list">
                                            <span v-if="item.labels!=''">{{item.labels}}</span>
                                            <span v-if="item.labels==''">暂无标签</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="inactiveData.length==0" class="no-data">暂无数据</div>
                        </div>
                    </a-tab-pane>
                </a-tabs>
            </div>
        </div>
        <a-drawer
            :title='digitalDetail.moduleName+"-"+digitalDetail.dataTitle'
            placement="right"
            :closable="true"
            :visible="drawerVisible"
            :destroyOnClose='true'
            :maskClosable='true'
            @close="onClose"
            :width='800'
            :mask='true'
            :headerStyle="{ border: 'none', height: '56px', position: 'absolute', width: '100%', left: '0', top: '0', zIndex: '0'}"
            :bodyStyle="{ background: 'rgba(240, 242, 243, 1)', height: '100%', position: 'absolute', width: '100%', left: '0', top: '0', zIndex: '10', padding: '0 0 0' }"
        >
            <div class="drawer-sence drawer-public">
                <div class="drawer-head">
                    <div class="drawer-title">
                        <span class="left">{{digitalDetail.moduleName+"-"+(digitalDetail.dataTitle.length>28?digitalDetail.dataTitle.substring(0,28)+'...':digitalDetail.dataTitle)}}</span>
                        <div class="right">
                            <a-icon @click="viewDetail" type="select" />
                            <a-icon v-if="operationAuthority.EXTERNAL_LINKS" @click="outLink(digitalDetail.id)" type="share-alt" />
                            <a-icon @click="onClose" type="close" />
                        </div>
                    </div>
                    <a-row class="row-head">
                        <a-col :span="8" class="col-left">
                            <div>创建人：</div>
                            <div>{{digitalDetail.createBy}}</div>
                        </a-col>
                        <a-col :span="8" class="col-left">
                            <div>创建时间：</div>
                            <div>{{digitalDetail.createTime}}</div>
                        </a-col>
                        <a-col :span="8" class="col-left">
                            <div>修改时间：</div>
                            <div>{{digitalDetail.updateTime}}</div>
                        </a-col>
                    </a-row>
                </div>
                <div class="drawer-content">
                    <div class="drawer-body-content">
                        <div class="basic-info">
                            <div class="title">基本信息</div>
                            <a-row class="row-list">
                                <a-col :span="12" class="col-list" :class="{'col-left':index%2==0,'col-right':index%2!=0}" v-for="(item,index) in digitalDetail.detailList" :key='index'>
                                    <div class="first-content">{{item.name}}:</div>
                                    <div v-if="item.type!='IMAGE'&&item.type!='SELECT_SINGLE'&&item.code!='experienceUrl'" class="second-content">{{item.value}}</div>
                                    <div v-if="item.code=='experienceUrl'" class="second-content" style="cursor:pointer;color:#1890FF" :title='item.value'>
                                        <span @click="jumpToUrl(item.value)">{{item.value}}</span>
                                    </div>
                                    <div v-if="item.type=='SELECT_SINGLE'&&ifEditDrawer!=true" class="second-content">{{item.value}}</div>
                                    <div v-if="item.type=='IMAGE'" class="second-content">
                                        <img :src="item.value" alt="">
                                        <span @click="changeHead" class="change-head" v-if="ifEditDrawer">更换</span>
                                    </div>
                                    <div v-if="item.type=='SELECT_SINGLE'&&ifEditDrawer==true&&item.isEditable==1">
                                        <a-select class="selection-range" :defaultValue='item.selectedValue' @change="changeState">
                                            <a-select-option :value="item1.value" v-for="(item1,index1) in item.selectList" :key='index1'>
                                                {{item1.name}}
                                            </a-select-option>
                                        </a-select>
                                    </div>
                                </a-col>
                            </a-row>
                        </div>
                        <div class="comments">
                            <Comments ref="commentChild"></Comments>
                        </div>
                    </div>
                </div>
                <div v-if="operationAuthority.EDIT" class="drawer-foot">
                    <div class="btn-group">
                        <a-button v-if="!ifEditDrawer" type="primary" ghost @click="deleteDrawer">取消</a-button>
                        <a-button v-if="!ifEditDrawer" type="primary" @click="editDrawer">编辑</a-button>
                        <a-button v-if="ifEditDrawer" type="primary" ghost @click="cancelDrawer">取消</a-button>
                        <a-button v-if="ifEditDrawer" type="primary" @click="saveDrawer">保存</a-button>
                    </div>
                </div>
            </div>
        </a-drawer>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="shareModal" title="文字模拟" footer='' width='600px'>
            <div class="share-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <div class="copy-link">
                    <a-input id="copyHtml1"  :placeholder="currentUrl" />
                    <span @click="copyLink(currentUrl)">复制链接</span>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="linkModal" title="外链分享" footer='' width='600px'>
            <div class="share-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <div class="copy-link">
                    <a-input id="copyHtml2"  :placeholder="linkUrl" />
                    <span @click="copyLink2(linkUrl)">复制链接</span>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="uoloadModal" title="logo图片" footer='' width='600px'>
            <div class="upload-modal">
                <a-divider class="divider1" type="horizontal" />
                <CropperImage ref="child"></CropperImage>
                <a-divider class="divider2" type="horizontal" />
                <div class="upload-btn">
                    <a-button type="primary" style="float:right" @click="uploadImg()">确定 </a-button>
                </div>
            </div>
        </a-modal>
    </div>
</template>

<script>
    import CropperImage from "../../components/CropperImage";
    import Comments from "../../components/Comments";
    export default {
        name: 'DigitalEmployee',
        components: {CropperImage, Comments},
        data () {
            return {
                currentList: null,
                drawerVisible: false,
                shareModal: false,
                ifEditDrawer: false,
                uoloadModal: false,
                cropperName:'',
                currentState: 1,
                activeData: [],
                inactiveData: [],
                currentUrl: '',
                moduleId: '',
                digitalDetail: {
                    dataTitle: ''
                },
                selectState: '',
                currentSelect: '',
                imgId: '',
                currentId: '',
                currentUnList: null,
                linkModal: false,
                linkUrl: '',
                operationAuthority: '',
                moduleName: ''
            }
        },
        methods: {
            jumpToUrl(url) {
                window.open(url)
            },
            queryBasicData(status) {
                this.axios.post('/api/employee/list/'+status)
                .then(response=> {
                    if(response.data.code == 0){
                        if(status == 1){
                            this.activeData = response.data.data
                            // console.log(this.activeData)
                        }else{
                            this.inactiveData = response.data.data
                            // console.log(this.inactiveData)
                        }
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            shareSet() {
                this.$router.push({
                    path: "/PublicMenu/share",
                    query: {
                        moduleId: 4
                    }
                })
            },
            tabSwitch(key) {
                this.currentState = key
                this.queryBasicData(key)
            },
            selectList1(index,item) {
                this.currentList = index
                this.drawerVisible = true
                this.currentId = item.id
                this.querySelectData()
            },
            selectList2(index,item) {
                this.currentUnList = index
                this.drawerVisible = true
                this.currentId = item.id
                this.querySelectData()
            },
            querySelectData() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/employee/'+this.currentId, 
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.digitalDetail = response.data.data
                        this.$refs.commentChild.getParentCommentTree(this.moduleId, this.currentId, true)
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            onClose() {
                this.drawerVisible = false
                this.imgId = ''
                this.currentSelect = ''
            },
            deleteDrawer() {
                this.drawerVisible = false
                this.imgId = ''
                this.currentSelect = ''
            },
            editDrawer() {
                this.ifEditDrawer = true
            },
            shareLink(url) {
                event.stopPropagation();
                this.currentUrl = url
                this.shareModal = true
            },
            copyLink(url) {
                this.shareModal = false
                let text = url
                let input = document.getElementById("copyHtml1");
                input.value = text; 
                input.select(); 
                document.execCommand("copy"); 
                this.$message.success('已复制！')
            },
            cancelDrawer() {
                this.ifEditDrawer = false
                this.imgId = ''
                this.currentSelect = ''
            },
            saveDrawer() {
                this.ifEditDrawer = false
                this.axios.post('/api/employee', {
                    id: this.currentId,
                    avatar: this.imgId!=''?this.imgId:undefined,
                    status: this.currentSelect!=''?this.currentSelect:undefined,
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.$message.success('修改成功')
                        this.imgId = ''
                        this.currentSelect = ''
                        this.drawerVisible = false
                        this.querySelectData()
                        this.queryBasicData(this.currentState)
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            changeHead() {
                this.uoloadModal = true
            },
            cancelUpload() {
                this.uoloadModal = false
            },
            sureUpload() {
                this.uoloadModal = false
            },
            viewDetail() {
                let routeUrl = this.$router.resolve({
                    path: "/digitalDetail",
                    query: {
                        id: this.digitalDetail.id,
                        moduleId: this.moduleId
                    }
                });
                window.open(routeUrl.href, '_blank');
            },
            judgeDay(day) {
                switch (day) {
                    case 'MONDAY':
                        return "周一"
                    case 'TUESDAY':
                    return "周二"
                    case 'WEDNESDAY':
                        return "周三"
                    case 'THURSDAY':
                        return "周四"
                    case 'FRIDAY':
                        return "周五"
                    case 'SATURDAY':
                        return "周六"
                    case 'SUNDAY':
                        return "周日"
                } 
            },
            changeState(val) {
                this.currentSelect = val
            },
            uploadImg() {
                if(this.$refs.child.imgBase64==''){
                    this.$message.error('请先上传图片')
                }else{
                    this.uoloadModal = false
                    this.axios.post('/api/file/uploadImg', {
                        base64Img: this.$refs.child.imgBase64,
                    })
                    .then(response=> {
                        if(response.data.code == 0){
                            this.$message.success('上传成功')
                            this.imgId = response.data.data.fileId
                        }else{
                            this.$message.error(response.data.userTip);
                            if(response.data.code == 'A0200'){
                                this.$router.push("/login")
                            }
                        }
                    })
                    .catch(error=> {
                        console.log(error);
                    });
                }
            },
            outLink(id) {
                event.stopPropagation();
                this.linkModal = true
                let shareId = ''
                this.axios.post('/api/externalLinks',{
                    moduleId: this.moduleId,
                    dataId: id
                })
                .then(response=> {
                    if(response.data.code == 0){
                        shareId = response.data.data.id
                        let url = window.location.origin
                        this.linkUrl = url+'/#/DigitalShare?id='+shareId+'&moduleId='+this.moduleId
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            copyLink2(url) {
                this.linkModal = false
                let text = url
                let input = document.getElementById("copyHtml2");
                input.value = text; 
                input.select(); 
                document.execCommand("copy"); 
                this.$message.success('已复制！')
            },
            queryAuthority() {
                this.axios(
                    {
                        method:'get',
                        url:'api/module/'+this.moduleId+'/operationAuthority', 
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.operationAuthority = response.data.data
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            }
        },
        mounted: function() {
            this.sessionData('set','publicMenu','digitalEmployee');
            this.moduleId = this.$route.query.moduleId
            this.moduleName = this.$route.query.name
            this.queryAuthority()
            this.queryBasicData(this.currentState)
        }
    }
</script>

<style scoped lang="less">
    .permission-set{
        height: 100%;
        position: relative;
        .head{
            height: 48px;
            line-height: 48px;
            padding: 0 24px;
            background: #fff;
            border-left: 1px solid #e8ebee;
            z-index: 100;
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            button{
                float: right;
                height: 32px;
                margin: 8px 0 0 8px;
            }
            .title{
                color: rgba(18, 18, 18, 1);
                font-size: 16px;
            }
            i{
                float: right;
                margin-top: 16px;
                font-size: 16px;
                color: rgba(204, 204, 204, 1);
                cursor: pointer;
            }
        }
        .content{
            position: absolute;
            padding: 16px;
            left: 0px;
            top: 0px;
            height: 100%;
            padding-top: 64px;
            width: 100%;
            .content-in{
                width: 100%;
                height: 100%;
                position: relative;
                background: #fff;
                border-radius: 4px;
                padding: 12px 0px 24px 7px;
                .digital-tab{
                    width: 100%;
                    height: 100%;
                    position: relative;
                    .tab-public{
                        height: 100%;
                        width: 100%;
                        position: absolute;
                        overflow-y: auto;
                        padding-bottom: 45px;
                        .no-data{
                            text-align: center;
                            margin-top: 200px;
                            font-size: 18px;
                        }
                        .tab-list{
                            display: inline-block;
                            width: 251px;
                            height: 398px;
                            border-radius: 4px;
                            border: 1px solid rgba(229, 229, 229, 1);
                            margin-left: 17px;
                            margin-bottom: 16px;
                            // overflow: hidden;
                            .tab-head{
                                width: 100%;
                                height: 80px;
                                background: rgba(24, 144, 255, 0.5);
                                padding: 12px;
                                color: #fff;
                                position: relative;
                                span{
                                    display: inline-block;
                                    width: 43px;
                                    height: 24px;
                                    text-align: center;
                                    line-height: 22px;
                                    border-radius: 4px;
                                    float: left;
                                    font-size: 12px;
                                    border: 1px solid #fff;
                                }
                                i{
                                    float: right;
                                    font-size: 18px;
                                    cursor: pointer;
                                    margin-left: 6px;
                                }
                                img{
                                    position: absolute;
                                    left: 50%;
                                    margin-left: -34px;
                                    width: 64px;
                                    bottom: -32px;
                                    border-radius: 32px;
                                }
                            }
                            .tab-content{
                                padding: 42px 24px 0;
                                width: 100%;
                                .sence-title{
                                    color: rgba(18, 18, 18, 1);
                                    font-size: 16px;
                                    text-align: center;
                                    height: 48px;
                                }
                                .sence-robot{
                                    text-align: center;
                                    margin: 12px auto;
                                    div{
                                        display: inline-block;
                                        width: 80px;
                                        height: 24px;
                                        text-align: center;
                                        line-height: 22px;
                                        border: 1px solid rgba(34, 215, 187, 1);
                                        background: rgba(34, 215, 187, 0.1);
                                        font-size: 12px;
                                        border-radius: 4px;
                                        color: rgba(34, 215, 187, 1);
                                    }
                                }
                                .sence-des{
                                    color: rgba(80, 80, 80, 1);
                                    font-size: 12px;
                                    height: 57px;
                                    overflow-y: hidden;
                                }
                                .time-set{
                                    margin: 10px 0;
                                    padding-bottom: 10px;
                                    border-bottom: 1px solid rgba(229, 229, 229, 1);
                                    position: relative;
                                    .work-time{
                                        color: rgba(153, 153, 153, 1);
                                        font-size: 12px;
                                        display: inline-block;
                                        width: 60px;
                                    }
                                    .unset{
                                        color: rgba(80, 80, 80, 1);
                                        font-size: 12px;
                                    }
                                    .task1{
                                        width: 172px;
                                        height: 158px;
                                        overflow: hidden;
                                        border-radius: 4px;
                                        position: absolute;
                                        left: 100px;
                                        top: 5px;
                                        color: #fff;
                                        font-size: 12px;
                                        z-index: 10;
                                        display: none;
                                        .task-content{
                                            width: 100%;
                                            height: 100%;
                                            position: relative;
                                            // padding: 12px;
                                            .content-task{
                                                width: 100%;
                                                height: 100%;
                                                background: rgba(8, 11, 15, 0.7);
                                                position: absolute;
                                                left: 0;
                                                top: 0;
                                            }
                                            .date{
                                                border-bottom: 1px solid rgba(153, 153, 153, 1);
                                                padding-bottom: 10px;
                                                z-index: 12;
                                                color: #fff;
                                            }
                                            .time{
                                                padding-top: 10px;
                                                .afternoon{
                                                    margin-top: 5px;
                                                }
                                            }
                                        }
                                    }
                                }
                                .time-set:hover{
                                    .task1{
                                        display: block;
                                    }
                                }
                                .sence-code{
                                    i{
                                        font-size: 14px;
                                        color: rgba(121, 136, 155, 1);
                                    }
                                    span{
                                        color: rgba(80, 80, 80, 1);
                                        font-size: 12px;
                                        margin-left: 6px;
                                    }
                                }
                                .sence-tag{
                                    display: flex;
                                    margin-top: 6px;
                                    height:32px;
                                    line-height: 16px;
                                    overflow-y: hidden;
                                    .sence-icon{
                                        width: 17px;
                                        font-size: 14px;
                                        color: rgba(121, 136, 155, 1);
                                    }
                                    .sence-tag-list{
                                        flex: 1;
                                        span{
                                            margin-left: 4px;
                                            color: rgba(80, 80, 80, 1);
                                            font-size: 12px;
                                        }
                                    }
                                }
                            }
                        }
                        div.active{
                            border: 1px solid rgba(24, 144, 255, 1);
                        }
                    }
                    .tab2{
                        .tab-head{
                            background: rgba(121, 136, 155, 0.5) !important;
                        }
                    }
                }
                
            }
            
        }
        
    }
    .drawer-public{
        height: 100%;
        position: relative;
        padding-bottom: 60px;
        .drawer-title{
            height: 55px;
            width: 100%;
            span{
                color: rgba(18, 18, 18, 1);
                font-size: 16px;
                line-height: 55px;
            }
            i{
                color: rgba(153, 153, 153, 1);
                font-size: 16px;
                margin-left: 16px;
                cursor: pointer;
                line-height: 50px;
            }
        }
        .drawer-head{
            height: 144px;
            background: #fff;
            padding: 0 24px 0;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            z-index: 100;

        }
        .drawer-content{
            padding: 160px 0 68px 16px;
            width: 100%;
            height: 100%;
            position: absolute;
        }
        .drawer-foot{
            position: absolute;
            left: 0px;
            bottom: 0px;
            width: 100%;
            height: 52px;
            background: #fff;
            box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
            .btn-group{
                text-align: center;
                padding-top: 10px;
                width: 100%;
                button{
                    height: 32px;
                    font-size: 14px;
                }
                button:nth-child(2){
                    margin: 0 16px;
                }
            }
        }
    }
    .drawer-sence{
        .row-head{
            padding-top: 20px;
            border-top: 1px solid rgba(229, 229, 229, 1);
            .col-left{
                div:nth-child(1){
                    color: rgba(188, 188, 188, 1);
                    font-size: 12px;
                }
                div:nth-child(2){
                    color: rgba(80, 80, 80, 1);
                    font-size: 14px;
                    margin-top: 6px;
                }
            }
        }
        .drawer-body-content{
            width: 100%;
            height: 100%;
            border-radius: 4px;
            overflow-y: auto;
            padding-right: 16px;
            .basic-info{
                width: 100%;
                // height: 704px;
                background: #fff;
                border-radius: 4px;
                padding: 24px;
                .title{
                    color: rgba(18, 18, 18, 1);
                    font-size: 14px;
                }
                .col-left{
                    padding-right: 24px;
                }
                .col-right{
                    padding-left: 24px;
                }
                .row-list{
                    .col-list{
                        margin-top: 19px;
                        margin-bottom: 5px;
                        height: 69px;
                        overflow: hidden;
                        .first-content{
                            color: rgba(188, 188, 188, 1);
                            font-size: 12px;
                        }
                        .second-content{
                            color: rgba(51, 51, 51, 1);
                            font-size: 14px;
                            margin-top: 9px;
                            img{
                                width: 21px;
                            }
                            .change-head{
                                color: rgba(24, 144, 255, 1);
                                font-size: 12px;
                                cursor: pointer;
                                margin-left: 24px;
                            }
                        }
                        .selection-range{
                            margin-top: 9px;
                            width: 100%;
                        }
                    }
                }
            }  
            .comments{
                padding: 18px 24px;
                background: #fff;
                margin-top: 16px;
                border-radius: 4px;
                .title{
                    color: rgba(18, 18, 18, 1);;
                    font-size: 14px;
                }
                .textarea-comment{
                    margin-top: 20px;
                    background: rgba(247, 247, 247, 1);
                    border: none;
                    resize: none;
                }
                .release{
                    text-align: right;
                    color: rgba(24, 144, 255, 1);
                    font-size: 14px;
                    margin-top: 4px;
                    span{
                        cursor: pointer;
                    }
                }
            }  
        }
    }
    .modal-public{
        .divider1{
            margin: 0px 0 24px 0;
        }
        .divider2{
            margin: 24px 0 12px;
        }
        .divider3{
            margin: 0px 0 12px;
        }
        .btn-group{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                margin-right: 16px;
            }
        }
        .btn-group2{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                background: #fff;
                color: rgba(121, 136, 155, 0.7);
                border: 1px solid rgba(121, 136, 155, 0.5);
                margin-right: 16px;
            }
            button:nth-child(1):hover{
                background: rgba(121, 136, 155, 0.08);
            }
        }
    }
    .share-modal{
        .copy-link{
            height: 62px;
            input{
                width: 464px;
                float: left;
                height: 40px;
            }
            span{
                float: left;
                display: inline-block;
                width: 88px;
                height: 40px;
                border: 1px solid rgba(121, 136, 155, 0.3);
                cursor: pointer;
                border-radius: 0px 4px 4px 0px;
                line-height: 40px;
                text-align: center;
            }
        }
    }
    .upload-modal{
        .divider1{
            margin: 0px 0 24px 0;
        }
        .divider2{
            margin: 24px 0 12px;
        }
        padding-bottom: 50px;
        .btn-group{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                margin-right: 16px;
            }
        }
    }
</style>