import { render, staticRenderFns } from "./WaitingCallShare.vue?vue&type=template&id=46bcc598&scoped=true&"
import script from "./WaitingCallShare.vue?vue&type=script&lang=js&"
export * from "./WaitingCallShare.vue?vue&type=script&lang=js&"
import style0 from "./WaitingCallShare.vue?vue&type=style&index=0&id=46bcc598&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46bcc598",
  null
  
)

export default component.exports