<template>
    <div class="call-log">
        <div class="head">
            <span class="title">{{moduleName}}</span>
            <a-button v-if="operationAuthority.SFA" type="primary" @click="sfaSet">
                SFA指令
            </a-button>
            <a-button v-if="operationAuthority.NOTICE" type="primary" @click="pushSet">
                推送设置
            </a-button>
            <a-button v-if="operationAuthority.SHARE" type="primary" @click="shareSet">
                共享设置
            </a-button>
            <a-icon type="exclamation-circle" />
        </div>
        <div class="content">
            <div class="content-in">
                <div class="screen-condition">
                    <div class="select-modular">
                        <span class="title">场景名称</span>
                        <span style="color:rgba(255, 70, 70, 1);margin-left:4px">*</span>
                        <a-select default-value="" class="selection-modular" @change="changeSence">
                            <a-select-option :value="item.value" v-for="(item,index) in digitalEmployeeList" :key='index'>
                                {{item.name}}
                            </a-select-option>
                        </a-select>
                    </div>
                </div>
                <div class="extra-content"></div>
                <div class="modular-content">
                    <div class="modular-in">
                        <ViewGroup ref="child1"></ViewGroup>
                        <div class="modular-operation">
                            <DataRange ref="child2"></DataRange>
                            <div class="operation-group">
                                <ScreenColumn ref="child3"></ScreenColumn>
                                <a-divider class="divider" type="vertical" />
                                <Export v-if="operationAuthority.EXPORT" ref="child4"></Export>
                            </div>
                        </div>
                        <div class="table-data">
                            <a-table
                                :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
                                :columns="columns"
                                :data-source="tableData"
                                :pagination='false'
                                :scroll="{ x: 'max-content' }"
                                :loading='loading'
                            >
                                <span slot="dataTitle" slot-scope="text, record">
                                    <a-icon style="color:rgba(121, 136, 155, 0.5);margin-right:6px;float:left;margin-top:4px" @click="viewDetail1(record.id)" type="select" />
                                    <a @click="viewRecords(record)">{{ record.dataTitle }}</a>
                                </span>
                                <div slot="phone" slot-scope="text, record">
                                    <span v-html="record.phone"></span>
                                </div>
                            </a-table>
                            <div class="pagination-table">
                                <a-pagination
                                    show-size-changer
                                    :total="total"
                                    :show-total="total => `共 ${total} 条     `"
                                    v-model="currentPage"
                                    @change="pageChange"
                                    @showSizeChange="onShowSizeChange"
                                />
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="linkModal" title="外链分享" footer='' width='600px'>
            <div class="share-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <div class="copy-link">
                    <a-input id="copyHtml1"  :placeholder="linkUrl" />
                    <span @click="copyLink(linkUrl)">复制链接</span>
                </div>
            </div>
        </a-modal>
        <a-drawer
            title='通话记录-手机号码'
            placement="right"
            :closable="true"
            :visible="phoneVisible"
            :destroyOnClose='true'
            :maskClosable='true'
            @close="closeDrawer"
            :width='800'
            :mask='true'
            :headerStyle="{ border: 'none', height: '56px', position: 'absolute', width: '100%', left: '0', top: '0', zIndex: '0'}"
            :bodyStyle="{ background: 'rgba(240, 242, 243, 1)', height: '100%', position: 'absolute', width: '100%', left: '0', top: '0', zIndex: '10', padding: '0 0 0' }"
        >
            <div class="drawer-sence drawer-public">
                <div class="drawer-head">
                    <div class="drawer-title">
                        <span class="left">{{callLogDetail.moduleName+"-"+(callLogDetail.dataTitle.length>28?callLogDetail.dataTitle.substring(0,28)+'...':callLogDetail.dataTitle)}}</span>
                        <div class="right">
                            <a-icon @click="viewDetail" type="select" />
                            <a-icon v-if="operationAuthority.EXTERNAL_LINKS" @click="viewSharing1" type="share-alt" />
                            <a-icon @click="closeDrawer" type="close" />
                        </div>
                    </div>
                    <a-row class="row-head">
                        <a-col :span="8" class="col-left">
                            <div>创建人：</div>
                            <div>{{callLogDetail.createBy}}</div>
                        </a-col>
                        <a-col :span="8" class="col-left">
                            <div>创建时间：</div>
                            <div>{{callLogDetail.createTime}}</div>
                        </a-col>
                        <a-col :span="8" class="col-left">
                            <div>修改时间：</div>
                            <div>{{callLogDetail.updateTime}}</div>
                        </a-col>
                    </a-row>
                </div>
                <div class="drawer-content">
                    <div class="drawer-body-content">
                        <div class="basic-info">
                            <div class="title">基本信息</div>
                            <a-row class="row-list">
                                <a-col :span="12" class="col-list" :class="{'col-left':index%2==0,'col-right':index%2!=0}" v-for="(item,index) in callLogDetail.detailList" :key='index'>
                                    <div class="first-content">{{item.name}}:</div>

                                    <div v-if="item.type!='USER'&&item.code!='remark'&&item.type!='URL'&&item.attribute!='PHONE'&&item.code!='customer'" class="second-content">{{item.value}}</div>
                                    <div style="color:rgba(24, 144, 255, 1)" v-if="item.code=='customer'" class="second-content">
                                        <span v-if="item.value!='无'" style="cursor: pointer" @click="viewRecords2(item)">{{item.value}}</span>
                                        <span v-if="item.value=='无'" style="cursor: pointer" @click="addNewTask(item)">新增客户</span>
                                    </div>
                                    <div v-if="item.attribute=='PHONE'" class="second-content">
                                        <span class="take-phone" @click="playPhone(item.value)">
                                            {{item.value}}
                                            <img title='呼叫' class="take-phone1" :value='item.value' src='http://eocc.oss-cn-beijing.aliyuncs.com/icon/phone.svg'>
                                            <img title='呼叫' class="take-phone2" :value='item.value' src='http://eocc.oss-cn-beijing.aliyuncs.com/icon/phone1.svg'>
                                        </span>
                                    </div>

                                     <div v-if="item.type=='URL'" class="second-content" style="cursor:pointer;color:#1890FF" :title='item.value'>
                                        <span @click="jumpToUrl(item.value)">{{item.value}}</span>
                                    </div>

                                    <div v-if="item.type=='USER'&&ifEditDrawer==false" class="second-content">{{item.value}}</div>

                                    <div v-if="item.code=='remark'&&ifEditDrawer==false" class="second-content">{{item.value}}</div>

                                    <div class="second-content" v-if="item.type=='USER'&&ifEditDrawer==true&&item.isEditable==1">
                                        <a-tree-select
                                            class="selection-range"
                                            @change="changeState"
                                            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                                            :tree-data="item.userTree"
                                            :placeholder="item.value"
                                            tree-default-expand-all
                                        >
                                        </a-tree-select>
                                    </div>
                                    <div class="second-content" v-if="item.code=='remark'&&ifEditDrawer==true&&item.isEditable==1">
                                        <a-textarea v-model="description" :rows="2" />
                                    </div>
                                </a-col>
                            </a-row>
                        </div>
                        <div class="comments">
                            <Comments ref="commentChild1"></Comments>
                        </div>
                    </div>
                </div>
                <div class="drawer-foot">
                    <div class="btn-group">
                        <a-button v-if="!ifEditDrawer&&operationAuthority.EDIT" type="primary" @click="editDrawer">编辑</a-button>
                        <a-button v-if="ifEditDrawer" type="primary" ghost @click="offEditDrawer">取消</a-button>
                        <a-button v-if="ifEditDrawer" type="primary" @click="saveDrawer">保存</a-button>
                    </div>
                </div>
            </div>
        </a-drawer>
        <a-drawer
            :title='digitalDetail.moduleName+"-"+digitalDetail.dataTitle'
            placement="right"
            :closable="true"
            :visible="drawerVisible"
            :destroyOnClose='true'
            :maskClosable='true'
            @close="onClose"
            :width='800'
            :mask='true'
            :headerStyle="{ border: 'none', height: '56px', position: 'absolute', width: '100%', left: '0', top: '0', zIndex: '0'}"
            :bodyStyle="{ background: 'rgba(240, 242, 243, 1)', height: '100%', position: 'absolute', width: '100%', left: '0', top: '0', zIndex: '10', padding: '0 0 0' }"
        >
            <div class="drawer-sence drawer-public2">
                <div class="drawer-head">
                    <div class="drawer-title">
                        <span class="left">{{digitalDetail.moduleName+"-"+(digitalDetail.dataTitle.length>28?digitalDetail.dataTitle.substring(0,28)+'...':digitalDetail.dataTitle)}}</span>
                        <div class="right">
                            <a-icon @click="viewDetail" type="select" />
                            <a-icon v-if="operationAuthority.EXTERNAL_LINKS" @click="viewSharing2" type="share-alt" />
                            <a-icon @click="onClose" type="close" />
                        </div>
                    </div>
                    <a-row class="row-head">
                        <a-col :span="8" class="col-left">
                            <div class="head-title">创建人：</div>
                            <div style="margin-top: 6px;">
                                <div class="head-content">{{digitalDetail.createBy}}</div>
                            </div>
                        </a-col>
                        <a-col :span="8" class="col-left">
                            <div class="head-title">创建时间：</div>
                            <div class="head-content" style="margin-top: 6px;">{{digitalDetail.createTime}}</div>
                        </a-col>
                        <a-col :span="8" class="col-left">
                            <div class="head-title">修改时间：</div>
                            <div class="head-content" style="margin-top: 6px;">{{digitalDetail.updateTime}}</div>
                        </a-col>
                    </a-row>
                </div>
                <div class="drawer-content">
                    <div class="drawer-body-content2">
                        <div class="basic-info">
                            <div class="title">基本信息</div>
                            <a-row class="row-list">
                                <a-col :span="(item.type=='LONG_TEXT'||item.type=='CUSTOMER_LABEL')?24:12" class="col-list" :class="{'col-left':index%2==0,'col-right':index%2!=0, 'col-time':item.type=='TIME'||item.code=='last_dynamic_user'}" v-for="(item,index) in digitalDetail.detailList" :key='index'>
                                    <div v-if="item.type!='TIME'" class="first-content">
                                        {{item.name}}:
                                        <!-- <a-icon @click="playPhone(item.value)" v-if="item.attribute=='PHONE'" type="phone" style="cursor:pointer"/> -->
                                        <span v-if="item.attribute=='PHONE'" class="take-phone" @click="playPhone(item.value)">
                                            {{item.value}}
                                            <img title='呼叫' class="take-phone1"  :value='item.value' src='http://eocc.oss-cn-beijing.aliyuncs.com/icon/phone.svg'>
                                            <img title='呼叫' class="take-phone2"  :value='item.value' src='http://eocc.oss-cn-beijing.aliyuncs.com/icon/phone1.svg'>
                                        </span>
                                    </div>
                                    
                                    <div v-if="item.type!='TIME'&&item.type!='CUSTOMER_LABEL'&&item.isEdit==false" class="second-content">
                                        {{item.value}}
                                        <a-icon @click="viewTianYan(item.value)" v-if="item.code=='corporate_name'" style="margin-right:4px;cursor:pointer" type="search" />
                                        <span v-if="item.attribute=='PHONE'">{{phoneDetails.city}}|{{phoneDetails.sp.length==0?'未知运营商':phoneDetails.sp}}</span>
                                        <a-icon @click="changeBasicEdit(index)" v-if="item.isEditable==1" class="edit-icon" style="" type="edit" />
                                    </div>

                                    <div v-if="item.type=='CUSTOMER_LABEL'" class="label-content">
                                        <a-button style="margin-right:8px" v-if="item.isEditable==1" @click="addLabel" icon="plus">
                                        添加标签
                                        </a-button>
                                        <span v-for="(item1,index1) in item.value" :style="{'background':item1.color}" :key='index1'>
                                            {{item1.name}}
                                            <a-icon v-if="item.isEditable==1" @click="removeDetaillTag(item1.labelId)" type="close" />
                                        </span>
                                    </div>

                                    <div v-if="item.type=='TEXT'&&item.isEdit==true" class="third-content">
                                        <a-input @blur="fieldInputBlur(item.fieldId,item.value)" :disabled='item.isEditable!=1' v-model="item.value" placeholder="请输入内容" />
                                    </div>
                                    
                                    <div class="user-content" v-if="item.type=='USER'&&item.isEditable==1&&item.isEdit==true">
                                        <a-tree-select
                                            class="selection-range"
                                            v-model="item.valueList"
                                            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                                            :tree-data="item.userTree"
                                            :placeholder="item.value"
                                            tree-default-expand-all
                                            @change="(value) => fieldInputBlur(item.fieldId, value)"
                                        >
                                        </a-tree-select>
                                    </div>
                                    <div v-if="item.type=='LONG_TEXT'&&item.isEdit==true" class="third-content">
                                        <a-textarea @blur="fieldInputBlur(item.fieldId,item.value)" :disabled='item.isEditable!=1' v-model="item.value" placeholder="请输入内容" :rows="2"/>
                                    </div>
                                </a-col>
                                <a-col :span="24">
                                    <a-upload
                                        :multiple="false"
                                        :customRequest="customRequest1"
                                        :showUploadList='false'
                                    >
                                        <a-button class="add-attachment" icon="plus"> 添加附件 </a-button>
                                    </a-upload>
                                </a-col>
                            </a-row>
                            <a-row>
                                <a-col :class="{'pd-24':index%2==0,'pd-8':index%2!=0,}" :span="12" v-for="(item,index) in attachmentList" :key='index'>
                                    <div class="attachment-item">
                                        <img :src="filterImg(item.suffix)" alt="">
                                        <div class="attachment-content">
                                            <div class="name-operation">
                                                <span :title='item.fileName'>{{item.fileName.length>16?item.fileName.substring(0,16)+'...':item.fileName}}</span>
                                                <a-icon @click="deleteFire(item.id)" type="delete" />
                                                <a-icon @click="downloadFire(item.id)" type="download" />
                                            </div>
                                            <div style="clear:both"></div>
                                            <div class="file-size">{{item.size}}</div>
                                        </div>
                                    </div>
                                </a-col>
                            </a-row>
                        </div>
                        <div class="related-clue">
                            <div class="title">
                                <span>关联线索</span>
                                <a-button @click="viewAllRelatedClueData" icon="sync">
                                同步标签
                                </a-button>
                            </div>
                            <a-row class="related-title">
                                <a-col :span="6">客户名称</a-col>
                                <a-col :span="6">负责人</a-col>
                                <a-col :span="6">最后动态时间</a-col>
                                <a-col :span="6">创建日期</a-col>
                            </a-row>
                            <a-row class="related-data" v-for="(item,index) in relatedData" :key='index'>
                                <div v-if="index<5">
                                    <a-col class="related-name" @click="refreshPage(item.id)" :span="6">{{item.name}}</a-col>
                                    <a-col class="related-charge" :span="6">{{item.owner}}</a-col>
                                    <a-col class="related-last-time" :span="6">{{item.lastDynamicTime}}</a-col>
                                    <a-col class="related-create-time" :span="6">{{item.createTime}}</a-col>
                                </div>
                            </a-row>
                        </div>
                        <div class="follow-time">
                            <div class="title">最后动态</div>
                            <a-row class="row-time">
                                <a-col :span="8">
                                    <div class="row-time-title">最后动态时间：</div>
                                    <div class="row-time-value">{{timeObj.last_dynamic_time}}</div>
                                </a-col>
                                <a-col :span="8">
                                    <div class="row-time-title">最后联系人：</div>
                                    <div class="row-time-value">{{timeObj.last_dynamic_user}}</div>
                                </a-col>
                                <a-col :span="8">
                                    <div class="row-time-title">下次联系时间：</div>
                                    <div class="row-time-value">{{timeObj.next_contact_time}}</div>
                                </a-col>
                            </a-row>
                        </div>
                        
                        <div class="dynamic-info">
                            <div class="title">
                                <span>动态信息</span>
                                <a-button @click="addTrack" icon="plus">
                                新增
                                </a-button>
                            </div>
                            <div class="filter-condition">
                                <span @click="selectDynamic('')" :class="{'active':currentActive==''}">全部{{" ("+dynamicNumData.total+")"}}</span>
                                <span @click="selectDynamic(2)" :class="{'active':currentActive==2}">跟踪轨迹{{" ("+dynamicNumData.follow+")"}}</span>
                                <span @click="selectDynamic(3)" :class="{'active':currentActive==3}">通话轨迹{{" ("+dynamicNumData.chat+")"}}</span>
                                <span @click="selectDynamic(1)" :class="{'active':currentActive==1}">数据轨迹{{" ("+dynamicNumData.data+")"}}</span>
                            </div>
                            <div v-for="(item,index) in dynamicList" :key='index'>
                                <div class="dynamic-list" v-if="item.type==currentActive||currentActive==''">
                                    <div class="date">
                                        <span>{{item.createTime.split(" ")[1]==undefined?'':item.createTime.split(" ")[0]}}</span>
                                        <span :style="{'background':item.color}"></span>
                                    </div>
                                    <div class="active-track">
                                        <div class="time">
                                            <span>{{item.operator+''}}{{item.createTime.length==19?item.createTime.split(" ")[1]:item.createTime}}</span>
                                            <div class="next-time" v-if="item.type == 2&&item.nextContactTime!=''&&item.nextContactTime!=null">
                                                <span>下次联系日期：</span>
                                                <span>{{(item.nextContactTime!=''&&item.nextContactTime!=null)?item.nextContactTime.split(" ")[0]:''}}</span>
                                            </div>
                                        </div>
                                        <div class="active-event">
                                            <span>{{item.action}}</span>
                                        </div>
                                        <div class="dynamic-content">
                                            <div style="word-wrap: break-word;word-break: break-all;" v-if="item.isEdit==false">
                                                {{item.content}}
                                                <span v-if="item.content==''&&item.type==2">请输入内容</span>
                                                <a-icon v-if="item.type==2" @click="changeDynamicData(index)" class="dynamic-icon" type="edit" />
                                            </div>
                                            <a-textarea v-if="item.isEdit==true" placeholder="请输入内容" :rows="4" :disabled='currentActive==1' @blur="contentInputBlur(item.id,item.content)" v-model="item.content"/>
                                        </div>
                                        <div v-if="item.type == 2">
                                            <div class="label-content">
                                                <a-button @click="addLabel2(item.id)" icon="plus">
                                                添加标签
                                                </a-button>
                                                <span v-for="(item1,index1) in item.labelList" :style="{'border-color':item1.color,'color':item1.color}" :key='index1'>
                                                    {{item1.name}}
                                                    <a-icon @click="removeDetaillTag2(item.id, item1.labelId)" type="close" />
                                                </span>
                                            </div>
                                        </div>
                                        <div v-if="item.type == 3" class="chat-detail">
                                            <div class="play-voice" @click="viewChatDetail(item.viewUrl)">
                                                <a-icon type="play-circle" />
                                                <span>对话详情</span>
                                            </div>
                                            <span class="voice-content">通话时长{{item.duration}}s</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="comments">
                            <Comments ref="commentChild"></Comments>
                        </div>
                    </div>
                </div>
                <div class="drawer-foot">
                    <div class="btn-group">
                        <a-button v-if="operationAuthority.DELETE" type="primary" ghost @click="deleteDrawer">删除</a-button>
                    </div>
                </div>
            </div>
        </a-drawer>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="relatedClueModal" title="关联线索" footer='' width='720px'>
            <div class="related-clue-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <div>
                    <a-table
                        :row-selection="{ selectedRowKeys: selectedRowKeys2, onChange: onSelectChange2 }"
                        :columns="columns2"
                        :data-source="relatedData"
                        :pagination='false'
                        :scroll="{ x: 'max-content' }"
                    >
                        
                    </a-table>
                </div>
                <a-divider class="divider2" type="horizontal" />
                <div class="related-clue-btn-group">
                    <a-button type="primary" ghost @click="cancelRelatedClue">取消</a-button>
                    <a-button type="primary" @click="sureRelatedClue">同步</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="trackModal" footer='' width='480px'>
            <div class="delete-modal modal-public">
                <div class="sure-off-bind">
                    <span style="margin-left:0px">预设下次联系时间</span>
                </div>
                <div class="tip" style="padding-left:0px;">
                    <a-date-picker format="YYYY-MM-DD HH:mm:ss" v-model="currentDynamicTime"/>
                </div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group2">
                    <a-button ghost @click="offAddTrack">取消</a-button>
                    <a-button type="primary" @click="sureAddTrack">确认</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="deleteModal" footer='' width='480px'>
            <div class="delete-modal modal-public">
                <div class="sure-off-bind">
                    <a-icon type="warning" />
                    <span>您确定要删除这个客户吗？</span>
                </div>
                <div class="tip">删除后，数据无法恢复</div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group2">
                    <a-button ghost @click="cancelDelete">取消</a-button>
                    <a-button type="primary" @click="sureDelete">确认</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="newLabelModal" title="标签" footer='' width='720px'>
            <div class="label-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <a-row>
                    <a-col :span="16">
                        <div class="title">企业标签</div>
                        <div class="company-tag-list" v-for="(item,index) in companyLabelList" :key='index'>
                            <div class="type-name">{{item.name}}</div>
                            <div class="label-list">
                                <span @click="selectPersonalTag(item1.id, index, index1)" :style="{'color':item1.selected?'#fff':'','background':item1.selected?item1.color:''}" v-for="(item1,index1) in item.labelList" :key='index1'>{{item1.name}}</span>
                            </div>
                        </div>
                    </a-col>
                    <a-col :span="8">
                        <div class="title">个人标签</div>
                        <div class="new-tag">
                            <a-input v-model.trim="tagName" placeholder="输入标签名称" />
                            <a-icon @click="addTagName" type="plus-circle" />
                        </div>
                        <div class="tag-list">
                            <span @click="selectPersonalTag(item.id, -1, index)" :style="{'color':item.selected?'#fff':'','background':item.selected?item.color:''}" v-for="(item,index) in personalLabelList" :key='index'>
                                {{item.name}}
                                <a-icon @click="removePersonalTag(item.id)" type="close" />
                            </span>
                        </div>
                    </a-col>
                </a-row>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group">
                    <a-button type="primary" ghost @click="cancelAddTag">取消</a-button>
                    <a-button type="primary" @click="sureAddTag">确定</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="newLabelModal2" title="标签" footer='' width='720px'>
            <div class="label-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <div>
                    <div class="new-tag">
                        <a-input v-model.trim="tagName2" placeholder="输入标签名称" />
                        <a-icon @click="addTagName2" type="plus-circle" />
                    </div>
                    <div class="tag-list">
                        <span @click="selectPersonalTag2(item.id, index)" :style="{'color':item.selected?'#fff':'','background':item.selected?item.color:''}" v-for="(item,index) in personalLabelList2" :key='index'>
                            {{item.name}}
                            <a-icon @click="removePersonalTag2(item.id)" type="close" />
                        </span>
                    </div>
                </div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group">
                    <a-button type="primary" ghost @click="cancelAddTag2">取消</a-button>
                    <a-button type="primary" @click="sureAddTag2">确定</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="deleteAttachmentModal" footer='' width='480px'>
            <div class="delete-modal modal-public">
                <div class="sure-off-bind">
                    <a-icon type="warning" />
                    <span>您确定要删除这个附件吗？</span>
                </div>
                <div class="tip">删除后，数据无法恢复</div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group2">
                    <a-button ghost @click="cancelDeleteAttachment">取消</a-button>
                    <a-button type="primary" @click="sureDeleteAttachment">确认</a-button>
                </div>
            </div>
        </a-modal>
        <a-drawer
            title='新增数据'
            placement="right"
            :closable="true"
            :visible="drawerAddVisiable"
            :destroyOnClose='true'
            :maskClosable='true'
            @close="onClose2"
            :width='800'
            :mask='true'
            :headerStyle="{ border: 'none', height: '56px', position: 'absolute', width: '100%', left: '0', top: '0', zIndex: '100'}"
            :bodyStyle="{ background: 'rgba(240, 242, 243, 1)', height: '100%', position: 'absolute', width: '100%', left: '0', top: '0', zIndex: '10', padding: '56px 0 0' }"
        >
            <div class="drawer-newtask drawer-public1">
                <div class="newtask-body">
                    <div class="newtask-content">
                        <a-row class="row-list">
                            <a-col :span="12" class="col-list col-left">
                                <div class="first-content">客户名称：</div>
                                <div class="second-content">
                                    <a-input v-model="addInfoData.name" placeholder="请输入名称" />
                                </div>
                            </a-col>
                            <a-col :span="12" class="col-list col-right">
                                <div class="first-content">公司名称：</div>
                                <div class="second-content">
                                    <a-input v-model="addInfoData.company" placeholder="请输入名称" />
                                </div>
                            </a-col>
                        </a-row>
                        <a-row class="row-list">
                            <a-col :span="12" class="col-list col-left">
                                <div class="first-content">手机号：</div>
                                <div class="second-content">
                                    <a-input v-model="addInfoData.phone" placeholder="请输入手机号" />
                                </div>
                            </a-col>
                            <a-col :span="12" class="col-list col-right">
                                <div class="first-content">微信号：</div>
                                <div class="second-content">
                                    <a-input v-model="addInfoData.wx" placeholder="请输入微信号" />
                                </div>
                            </a-col>
                        </a-row>
                        <a-row class="row-list">
                            <a-col :span="12" class="col-list col-left">
                                <div class="first-content">邮箱：</div>
                                <div class="second-content">
                                    <a-input v-model="addInfoData.email" placeholder="请输入邮箱" />
                                </div>
                            </a-col>
                            <a-col :span="12" class="col-list col-right">
                                <div class="first-content">负责人：</div>
                                <div class="second-content">
                                    <img style="width:21px;float:left" :src="personalInfo.avatar" alt="">
                                    <span style="margin:0px 0 0 6px;float:left;color:rgba(51, 51, 51, 1)">{{personalInfo.name}}</span>
                                </div>
                            </a-col>
                        </a-row>
                        <a-row class="row-list">
                            <a-col :span="12" class="col-list col-left">
                                <div class="first-content">备注：</div>
                                <div class="second-content">
                                    <a-textarea v-model="addInfoData.extra" class="textarea-des" placeholder="请输入备注" :rows="3"/>
                                </div>
                            </a-col>
                        </a-row>
                    </div>
                </div>
                <div class="drawer-foot">
                    <div class="btn-group">
                        <a-button type="primary" ghost @click="offEditDrawer2">取消</a-button>
                        <a-button type="primary" @click="submitDrawer">提交</a-button>
                    </div>
                </div>
            </div>
        </a-drawer>
    </div>
</template>

<script>
    import ViewGroup from '../../components/ViewGroup'
    import ScreenColumn from '../../components/ScreenColumn'
    import Export from '../../components/Export'
    import DataRange from '../../components/DataRange'
    import Comments from "../../components/Comments";
    import moment from 'moment'
    const columns = []
    const tableData = []
    export default {
        name: 'CallLog',
        components: {ViewGroup, ScreenColumn, Export, DataRange, Comments},
        data() {
            return {
                phoneVisible: false,
                ifEditDrawer: false,
                currentPage: 1,
                pageSize: 10,
                moduleId: '',
                tableData,
                columns,
                digitalEmployeeList: [],
                selectedDigitalEmployee: '',
                total: 0,
                showField: {
                    fieldList: [],
                    valueList: []
                },
                conditionList: [],
                selectedRowKeys: [],
                dataId: '',
                callLogDetail: {
                    dataTitle: ''
                },
                currentSelect: '',
                description: '',
                operationAuthority: '',
                linkModal: false,
                linkUrl: '',
                loading: false,
                moduleName: '',
                digitalDetail: {
                    dataTitle: ''
                },
                drawerVisible: false,
                currentId: '',
                timeObj: {
                    last_dynamic_time: '',
                    next_contact_time: '',
                    last_dynamic_user: ''
                },
                phoneDetails: {
                    city: '',
                    sp: ''
                },
                relatedData: [],
                dynamicNumData: {
                    total: 0,
                    follow: 0,
                    data: 0,
                    chat: 0
                },
                currentActive: 2,
                dynamicList: [],
                attachmentList: [],
                newFormData1: '',
                relatedClueModal: false,
                selectedRowKeys2: [],
                columns2: [
                     {
                        dataIndex: 'id',
                        key: 'id',
                        title: 'id',
                        colSpan: 0,
                        customRender: () => {
                            return {
                                attrs: {
                                    colSpan: 0,
                                },
                            };
                        },
                    },
                    {
                        dataIndex: 'name',
                        scopedSlots: {
                            customRender: 'name'
                        },
                        key: 'name',
                        title: '客户名称'
                    },
                    {
                        dataIndex: 'owner',
                        scopedSlots: {
                            customRender: 'owner'
                        },
                        key: 'owner',
                        title: '负责人'
                    },
                    {
                        dataIndex: 'lastDynamicTime',
                        scopedSlots: {
                            customRender: 'lastDynamicTime'
                        },
                        key: 'lastDynamicTime',
                        title: '最后动态时间'
                    },
                    {
                        dataIndex: 'createTime',
                        scopedSlots: {
                            customRender: 'createTime'
                        },
                        key: 'createTime',
                        title: '创建日期'
                    },
                   
                ],
                currentDynamicTime: null,
                trackModal: false,
                deleteModal: false,
                newLabelModal: false,
                companyLabelList: [],
                tagName: '',
                colorList: ['#256fe7', '#e54444', '#5dc7ff', '#ffaf00', '#23da77', '#a680ff'],
                personalLabelList: [],
                selectedLabelList: [],
                newLabelModal2: false,
                selectedLabelList2: [],
                personalLabelList2: [],
                tagName2: '',
                deleteAttachmentModal: false,
                currentFireId: '',
                drawerAddVisiable: false,
                addInfoData: {
                    name: '',
                    company: '',
                    phone: '',
                    wx: '',
                    email: '',
                    extra: '',
                },
                personalInfo: '',
            }   
        },
        methods: {
            moment,
            jumpToUrl(url) {
                window.open(url)
            },
            changeState(val) {
                this.currentSelect = val
            },
            querySelectData1() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/module/data/detail', 
                        params: {
                            moduleId: this.moduleId,
                            dataId: this.dataId
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.callLogDetail = response.data.data
                        this.$refs.commentChild1.getParentCommentTree(this.moduleId, this.dataId, true)
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            queryBasicData(type) {
                this.loading = true
                if(type == 'init'){
                    this.currentPage = 1
                }
                if(this.selectedDigitalEmployee != ''){
                    this.axios.post('/api/module/data/list', {
                        current: this.currentPage,
                        size: this.pageSize,
                        moduleId: this.moduleId,
                        dataViewId: this.$refs.child1.dataViewId,
                        dataRange: this.$refs.child2.selectedDataRangeValue,
                        conditionList: this.$refs.child3.conditionList,
                        digitalEmployeeId: this.selectedDigitalEmployee
                    })
                    .then(response=> {
                        if(response.data.code == 0){
                            this.loading = false
                            this.tableData = response.data.data.tableData.records
                            this.columns = response.data.data.tableHeader
                            for(let i = 0; i < this.columns.length; i++){
                            if(this.columns[i]['title'] == 'id'){
                                this.columns[i] = {
                                    title: this.columns[i]['title'],
                                    dataIndex: this.columns[i]['dataIndex'],
                                    colSpan:0,  
                                    customRender: () => {
                                        return {
                                            attrs: {
                                                colSpan: 0,
                                            },
                                        };
                                    },
                                }
                            }
                            if(this.columns[i]['dataIndex'] == 'dataTitle'){
                                this.columns[i] = {
                                    title: this.columns[i]['title'],
                                    dataIndex: this.columns[i]['dataIndex'],
                                    scopedSlots: { customRender: 'dataTitle' },
                                }
                            }
                        }
                            this.total = response.data.data.tableData.total
                            setTimeout(()=>{
                                this.$refs.child4.getTotalNum(response.data.data.tableData.total, this.moduleId)
                            },2000)
                            
                        }else{
                            this.$message.error(response.data.userTip);
                            if(response.data.code == 'A0200'){
                                this.$router.push("/login")
                            }
                        }
                    })
                    .catch(error=> {
                        console.log(error);
                    });
                }else{
                    this.loading = false
                }
            },
            viewRecords(record) {
                this.phoneVisible = true;
                this.dataId = record.id
                this.querySelectData1()
            },
            changeSence(value) {
                this.selectedDigitalEmployee = value
                this.queryBasicData()
            },
            pageChange(current, pageSize) {
                this.currentPage = current
                this.pageSize = pageSize
                this.queryBasicData()
            },
            onShowSizeChange(current, pageSize) {
                this.currentPage = current
                this.pageSize = pageSize
                this.queryBasicData()
            },
            queryColumnList() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/module/'+this.moduleId+'/listPage/init', 
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.operationAuthority = response.data.data.operationAuthority
                        this.showField.fieldList = response.data.data.showField.fieldList
                        this.showField.valueList = response.data.data.showField.valueList
                        this.conditionList = response.data.data.conditionList
                        this.$refs.child3.getData(this.showField,this.moduleId,this.conditionList)
                        this.digitalEmployeeList = response.data.data.digitalEmployeeList
                        this.$refs.child2.getDataRangeData(response.data.data.dataRange)
                        this.$refs.child1.getParentData(this.moduleId, response.data.data.dataViewList)
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            closeDrawer() {
                this.phoneVisible = false
            },
            editDrawer() {
                this.ifEditDrawer = true
            },
            offEditDrawer() {
                this.ifEditDrawer = false
            },
            saveDrawer() {
                this.ifEditDrawer = false
                this.axios.post('/api/callRecord/update', {
                    id: this.dataId,
                    owner: this.currentSelect!=''?this.currentSelect:undefined,
                    remark: this.description!=''?this.description:undefined,
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.$message.success('修改成功')
                        this.dataId = ''
                        this.description = ''
                        this.phoneVisible = false
                        // this.querySelectData()
                        this.queryBasicData()
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            shareSet() {
                this.$router.push({
                    path: "/PublicMenu/share",
                    query: {
                        moduleId: 7
                    }
                })
            },
            pushSet() {
                this.$router.push({
                    path: "/PublicMenu/push",
                    query: {
                        moduleId: 7
                    }
                })
            },
            viewDetail1(id) {
                let routeUrl = this.$router.resolve({
                    path: "/callRecordDetail",
                    query: {
                        id: id,
                        moduleId: this.moduleId
                    }
                });
                window.open(routeUrl.href, '_blank');
            },
            viewDetail() {
                let routeUrl = this.$router.resolve({
                    path: "/customerLeadDetail",
                    query: {
                        id: this.currentId,
                        moduleId: 8,
                    }
                });
                window.open(routeUrl.href, '_blank');
            },
            viewSharing1() {
                event.stopPropagation();
                this.linkModal = true
                let shareId = ''
                this.axios.post('/api/externalLinks',{
                    moduleId: this.moduleId,
                    dataId: this.dataId
                })
                .then(response=> {
                    if(response.data.code == 0){
                        shareId = response.data.data.id
                        let url1 = window.location.origin
                        this.linkUrl = url1+'/#/CallRecordShare?id='+shareId+'&moduleId='+this.moduleId
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            onSelectChange(selectedRowKeys) {
                let obj = {}
                obj.deptId = ''
                obj.digitalEmployeeId = this.selectedDigitalEmployee
                obj.dataViewId = this.$refs.child1.dataViewId
                obj.dataRange = this.$refs.child2.selectedDataRangeValue
                obj.conditionList = this.$refs.child3.conditionList
                this.selectedRowKeys = selectedRowKeys;
                this.$refs.child4.getData(this.moduleId, this.selectedRowKeys, obj,'callLog')
            },
            passDataToChild() {
                let obj = {}
                obj.deptId = ''
                obj.digitalEmployeeId = this.selectedDigitalEmployee
                obj.dataViewId = this.$refs.child1.dataViewId
                obj.dataRange = this.$refs.child2.selectedDataRangeValue
                obj.conditionList = this.$refs.child3.conditionList
                this.$refs.child4.getData(this.moduleId, this.selectedRowKeys, obj, 'callLog')
            },
            copyLink(url) {
                this.linkModal = false
                let text = url
                let input = document.getElementById("copyHtml1");
                input.value = text; 
                input.select(); 
                document.execCommand("copy"); 
                this.$message.success('已复制！')
            },
            playPhone(value) {
                this.$emit('fatherMethod', value);
            },
            sfaSet() {
                this.$router.push({
                    path: "/PublicMenu/sfa",
                    query: {
                        moduleId: this.moduleId
                    }
                })
            },

            // <---->
            viewRecords2(record) {
                console.log(record)
                this.phoneVisible = false
                this.drawerVisible = true;
                this.currentId = record.customerId
                this.querySelectData()
                this.queryRelatedData()
                this.queryDynamicData()
                this.queryAttachmentData()
            },
            querySelectData() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/module/data/detail', 
                        params: {
                            moduleId: 8,
                            dataId: this.currentId
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.digitalDetail = response.data.data
                        let arr = response.data.data.detailList
                        console.log(this.digitalDetail)
                        for(let i = 0;i < arr.length; i++){
                            if(arr[i]['code'] == 'phone'){
                                this.queryPhoneDetail(arr[i]['value'])
                            }
                            if(arr[i]['code'] == 'last_dynamic_time'){
                                this.timeObj.last_dynamic_time = arr[i]['value']
                            }
                            if(arr[i]['code'] == 'next_contact_time'){
                                this.timeObj.next_contact_time = arr[i]['value']
                            }
                            if(arr[i]['code'] == 'last_dynamic_user'){
                                this.timeObj.last_dynamic_user = arr[i]['value']
                            }
                        }
                        this.$refs.commentChild.getParentCommentTree(8, this.currentId, true)
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            queryPhoneDetail(value) {
                this.axios(
                    {
                        method:'get',
                        url:"/api/customer/qCellCore", 
                        params: {
                            phone: value,
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        if(response.data.data.data.city==''&&response.data.data.data.province==''){
                            this.phoneDetails.city = '未知号码归属地'
                        }else if(response.data.data.data.city==''&&response.data.data.data.province!=''){
                            this.phoneDetails.city = response.data.data.data.province
                        }else{
                            this.phoneDetails.city = response.data.data.data.city
                        }
                        this.phoneDetails.sp = response.data.data.data.sp
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            queryRelatedData() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/customer/associatedCustomerList', 
                        params: {
                            customerId: this.currentId
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.relatedData = response.data.data
                        for(let i = 0; i < this.relatedData.length; i++){
                            this.relatedData[i]['key'] = this.relatedData[i]['id']
                        }
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            queryDynamicData() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/customer/dynamic/list', 
                        params: {
                            customerId: this.currentId,
                            type: ''
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        let arr = response.data.data
                        let brr = []
                        this.dynamicNumData = {
                            total: arr.length,
                            follow: 0,
                            data: 0,
                            chat: 0
                        }
                        for(let i = 0; i < arr.length; i++){
                            if(arr[i]['type'] == 1){
                               this.dynamicNumData.data++ 
                            }else if(arr[i]['type'] == 2){
                                this.dynamicNumData.follow++ 
                            }else if(arr[i]['type'] == 3){
                                this.dynamicNumData.chat++ 
                            }

                            if(arr[i]['type'] == this.currentActive||this.currentActive==''){
                                if(brr.indexOf(arr[i]['createTime'].split(" ")[0])==-1){
                                    brr.push(arr[i]['createTime'].split(" ")[0])
                                }else{
                                    arr[i]['createTime'] = arr[i]['createTime'].split(" ")[1]
                                }
                            }
                        }
                        this.dynamicList = arr
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            queryAttachmentData() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/customer/attachmentList', 
                        params: {
                            customerId: this.currentId,
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.attachmentList = response.data.data
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            onClose() {
                this.drawerVisible = false
                this.imgId = ''
                this.currentSelect = ''
                this.queryBasicData()
            },
            customRequest1(data) {
                this.newFormData1 = ''
                const formData = new FormData() 
                formData.append('file', data.file)
                this.newFormData1 = formData
                this.axios({
                    method: 'post',
                    url: '/api/file/upload',
                    headers:{
                        'Content-Type':'application/x-www-form-urlencoded'
                    },
                    data: this.newFormData1,
                
                }).then((response) => {
                    if(response.data.code == 0){
                        this.newFormData1 = ''
                        this.addAtachment(response.data.data.fileId)
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                }).catch(function (error) {
                    console.log(error)
                })
            },
            addAtachment(attachment) {
                this.axios.post('/api/customer/attachment', {
                    customerId: this.currentId,
                    attachment: attachment,
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.queryAttachmentData()
                        this.queryDynamicData()
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            viewAllRelatedClueData() {
                this.relatedClueModal = true
            },
            onSelectChange2(selectedRowKeys) {
                this.selectedRowKeys2 = selectedRowKeys;
            },
            cancelRelatedClue() {
                this.relatedClueModal = false
            },
            sureRelatedClue() {
                if(this.selectedRowKeys2.length == 0){
                    this.$message.error('请先选择关联线索')
                }else{
                    this.relatedClueModal = false
                    this.axios.post('/api/customer/syncCustomerLabel', {
                        customerId: this.currentId,
                        associatedCustomerIdList: this.selectedRowKeys2,
                    })
                    .then(response=> {
                        if(response.data.code == 0){
                            this.selectedRowKeys2 = []
                            this.querySelectData()
                            this.$message.success('同步完成')
                        }else{
                            this.$message.error(response.data.userTip);
                            if(response.data.code == 'A0200'){
                                this.$router.push("/login")
                            }
                        }
                    })
                    .catch(error=> {
                        console.log(error);
                    });
                }
                
            },
            addTrack() {
                this.currentDynamicTime = this.timeObj.next_contact_time==''?null:this.timeObj.next_contact_time
                this.trackModal = true
            },
            sureAddTrack() {
                this.axios.post('/api/customer/dynamic', {
                    customerId: this.currentId,
                    nextContactTime: this.currentDynamicTime!=null?this.transformTimestamp(this.currentDynamicTime):''
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.trackModal = false
                        this.queryDynamicData()
                        this.querySelectData()
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            offAddTrack() {
                this.trackModal = false
            },
            transformTimestamp(timestamp) {
                let a = new Date(timestamp).getTime();
                const date = new Date(a);
                const Y = date.getFullYear() + '-';
                const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
                const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
                const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
                const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
                const s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
                const dateString = Y + M + D + h + m + s;
                return dateString;
            },
            viewSharing2() {
                event.stopPropagation();
                this.linkModal = true
                let shareId = ''
                this.axios.post('/api/externalLinks',{
                    moduleId: 8,
                    dataId: this.currentId
                })
                .then(response=> {
                    if(response.data.code == 0){
                        shareId = response.data.data.id
                        let url1 = window.location.origin
                        this.linkUrl = url1+'/#/customerLeadShare?id='+shareId+'&moduleId='+8
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            deleteDrawer() {
                this.drawerVisible = false
                this.deleteModal = true
            },
            sureDelete() {
                this.deleteModal = false
                this.axios.post('/api/module/data/delete/batch', {
                    moduleId: 8,
                    idList: [this.currentId],
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.$message.success('删除成功')
                        this.queryBasicData()
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            cancelDelete() {
                this.deleteModal = false
            },
            changeBasicEdit(index) {
                this.digitalDetail.detailList[index]['isEdit'] = true
            },
            selectDynamic(type) {
                this.currentActive = type
                this.queryDynamicData()
            },
            addLabel() {
                this.newLabelModal = true
                this.selectedLabelList = []
                this.queryCompanyLabelData()
                this.queryPersonalTag()
            },
            addLabel2(id) {
                this.newLabelModal2 = true
                this.selectedLabelList2 = []
                this.dynamicId = id
                this.queryPersonalTag2()
            },
            cancelAddTag() {
                this.newLabelModal = false
                this.selectedLabelList = []
            },
            sureAddTag() {
                this.newLabelModal = false
                this.axios.post('/api/customer/setCustomerLabel', {
                    customerId: this.currentId,
                    labelIdList: this.selectedLabelList,
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.querySelectData()
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            cancelTagDelete2() {
                this.deleteTagModal2 = false
            },
            sureTagDelete2() {
                this.deleteTagModal2 = false
                this.axios.delete('/api/customer/personalLabel',{
                    params: {
                        id: this.currentDeleteId2
                    }
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.selectedLabelList = []
                        this.queryCompanyLabelData()
                        this.queryPersonalTag()
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            removePersonalTag(id) {
                event.stopPropagation()
                this.deleteTagModal2 = true
                this.currentDeleteId2 = id
            },
            removeDetaillTag(id) {
                event.stopPropagation()
                this.axios.delete('/api/customer/deleteCustomerLabel',{
                    params: {
                        customerId: this.currentId,
                        labelId: id
                    }
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.querySelectData()
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            ffAddTrack() {
                this.trackModal = false
            },
            queryPersonalTag2() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/customer/dynamicLabel/list', 
                        params: {
                            dynamicId: this.dynamicId
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.personalLabelList2 = response.data.data
                        for(let i = 0; i < response.data.data.length; i++){
                            if(response.data.data[i]['selected']){
                                this.selectedLabelList2.push(response.data.data[i]['id'])
                            }
                        }
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            selectPersonalTag2(id, index2) {
                let index = this.selectedLabelList2.indexOf(id);
                if(index == -1){
                    this.selectedLabelList2.push(id)
                    this.personalLabelList2[index2]['selected'] = true
                }else{
                    this.selectedLabelList2 = this.selectedLabelList2.filter(item => item != id)
                    this.personalLabelList2[index2]['selected'] = false
                }
            },
            addTagName2() {
                if(this.tagName2.length == 0){
                    this.$message.error('请输入标签名称')
                }else{
                    this.axios.post('/api/customer/dynamicLabel', {
                        name: this.tagName2,
                        color: this.randomColor(),
                    })
                    .then(response=> {
                        if(response.data.code == 0){
                            this.selectedLabelList2 = []
                            this.queryPersonalTag2()
                            this.tagName2 = ''
                        }else{
                            this.$message.error(response.data.userTip);
                            if(response.data.code == 'A0200'){
                                this.$router.push("/login")
                            }
                        }
                    })
                    .catch(error=> {
                        console.log(error);
                    });
                }
            },
            cancelTagDelete() {
                this.deleteTagModal = false
            },
            sureTagDelete() {
                this.deleteTagModal = false
                this.axios.delete('/api/customer/dynamicLabel',{
                    params: {
                        id: this.currentDeleteId
                    }
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.selectedLabelList2 = []
                        this.queryPersonalTag2()
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            removePersonalTag2(id) {
                event.stopPropagation()
                this.deleteTagModal = true
                this.currentDeleteId = id
            },
            cancelAddTag2() {
                this.newLabelModal2 = false
                this.selectedLabelList2 = []
            },
            sureAddTag2() {
                this.newLabelModal2 = false
                this.axios.post('/api/customer/setDynamicLabel', {
                    dynamicId: this.dynamicId,
                    labelIdList: this.selectedLabelList2,
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.queryDynamicData()
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            removeDetaillTag2(dynamicId, id) {
                event.stopPropagation()
                this.axios.delete('/api/customer/deleteDynamicLabel',{
                    params: {
                        labelId: id,
                        dynamicId: dynamicId
                    }
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.queryDynamicData()
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            addTagName() {
                if(this.tagName.length == 0){
                    this.$message.error('请输入标签名称')
                }else{
                    this.axios.post('/api/customer/personalLabel', {
                        name: this.tagName,
                        color: this.randomColor(),
                    })
                    .then(response=> {
                        if(response.data.code == 0){
                            this.selectedLabelList = []
                            this.queryCompanyLabelData()
                            this.queryPersonalTag()
                            this.tagName = ''
                        }else{
                            this.$message.error(response.data.userTip);
                            if(response.data.code == 'A0200'){
                                this.$router.push("/login")
                            }
                        }
                    })
                    .catch(error=> {
                        console.log(error);
                    });
                }
            },
            randomColor() {
                let num = Math.floor(Math.random()*6)
                let color = this.colorList[num]
                return color
            },
            queryPersonalTag() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/customer/personalLabel/list', 
                        params: {
                            customerId: this.currentId
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.personalLabelList = response.data.data
                        for(let i = 0; i < response.data.data.length; i++){
                            if(response.data.data[i]['selected']){
                                this.selectedLabelList.push(response.data.data[i]['id'])
                            }
                        }
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            selectPersonalTag(id, index1, index2) {
                let index = this.selectedLabelList.indexOf(id);
                if(index == -1){
                    this.selectedLabelList.push(id)
                    if(index1 == -1){
                        this.personalLabelList[index2]['selected'] = true
                    }else{
                        this.companyLabelList[index1]['labelList'][index2]['selected'] = true
                    }
                }else{
                    this.selectedLabelList = this.selectedLabelList.filter(item => item != id)
                    if(index1 == -1){
                        this.personalLabelList[index2]['selected'] = false
                    }else{
                        this.companyLabelList[index1]['labelList'][index2]['selected'] = false
                    }
                }
            },
            fieldInputBlur(id, value) {
                this.axios.post('/api/customer/updateCustomer', {
                    customerId: this.currentId,
                    fieldId: id,
                    value: value,
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.querySelectData()
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            queryCompanyLabelData() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/customer/enterpriseLabel/list', 
                        params: {
                            customerId: this.currentId
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.companyLabelList = response.data.data
                        for(let i = 0; i < response.data.data.length; i++){
                            for(let j = 0; j < response.data.data[i]['labelList'].length; j++){
                                if(response.data.data[i]['labelList'][j]['selected']){
                                    this.selectedLabelList.push(response.data.data[i]['labelList'][j]['id'])
                                }
                            }
                        }
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            viewTianYan(value) {
                window.open("https://www.tianyancha.com/search?key="+value, "_blank");
            },
            filterImg(type) {
                let url = "http://eocc.oss-cn-beijing.aliyuncs.com/icon/"
                let suffix = type.toLowerCase();
                if (suffix == "xls" || suffix == "xlsx") {
                    url+="excel.png";
                } else if (suffix == "doc" || suffix == "docx") {
                    url+="word.png";
                } else if (suffix == "zip" || suffix == "rar") {
                    url+="zip.png";
                } else if (suffix == "ppt") {
                    url+="ppt.png";
                } else if (suffix == "pptx") {
                    url+="ppt.png";
                } else if (suffix == "pdf") {
                    url+="pdf.png";
                } else if (suffix == "txt") {
                    url+="txt.png";
                } else {
                    url+="unknown.png";
                }
                return url
            },
            downloadFire(id) {
                window.open('/api/customer/downloadAttachment?id='+id, '_blank');
            },
            deleteFire(id) {
                this.currentFireId = id
                this.deleteAttachmentModal = true
            },
            cancelDeleteAttachment() {
                this.deleteAttachmentModal = false
            },
            sureDeleteAttachment() {
                this.deleteAttachmentModal = false
                this.axios.delete('/api/customer/attachment',{
                    params: {
                        id: this.currentFireId,
                    }
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.$message.success('删除成功')
                        this.queryAttachmentData()
                        this.queryDynamicData()
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            viewChatDetail(url) {
                if(url == ''){
                    this.$message.warning('该对话未接通')
                }else{
                    window.open(url, '_blank');
                }
            },
            addNewTask(item) {
                console.log(item)
                this.phoneVisible = false
                this.drawerAddVisiable = true
                this.addInfoData.phone = this.callLogDetail.dataTitle
            },
            onClose2() {
                this.drawerAddVisiable = false
                this.selectedEmployee = ''
                this.newTaskName = ''
                this.newTaskDes = ''
            },
            offEditDrawer2() {
                this.drawerAddVisiable = false
                this.addInfoData = {
                    name: '',
                    company: '',
                    phone: '',
                    wx: '',
                    email: '',
                    extra: '',
                }
            },
            submitDrawer() {
                this.addTask()
                this.drawerAddVisiable = false
                this.queryBasicData()
            },
            addTask(){
                this.axios.post('/api/customer', {
                    name: this.addInfoData.name,
                    phone: this.addInfoData.phone,
                    corporateName: this.addInfoData.company,
                    wechatNumber: this.addInfoData.wx,
                    email: this.addInfoData.email,
                    remark: this.addInfoData.extra
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.$message.success('新增成功')
                        this.addInfoData = {
                            name: '',
                            company: '',
                            phone: '',
                            wx: '',
                            email: '',
                            extra: '',
                        }
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
        },
        mounted: function() {
            this.sessionData('set','publicMenu','callLog');
            this.moduleId = this.$route.query.moduleId
            this.queryColumnList()
            this.moduleName = this.$route.query.name
            // this.$refs.child1.queryInitData(this.moduleId)
            // document.onclick = (e)=> {
            //     this.orderActive = false
            //     if(e.target.className == 'take-phone'){
            //         this.playPhone(e.target.attributes['value']['nodeValue'])
            //     }
            //     this.$forceUpdate();
            // }
            // document.onmouseover = (e)=>{
            //     if(e.target.className == 'take-phone'){
            //         e.target.src = 'http://eocc.oss-cn-beijing.aliyuncs.com/icon/phone1.svg'
            //     }
            // }
            // document.onmouseout = (e)=>{
            //     if(e.target.className == 'take-phone'){
            //         e.target.src = 'http://eocc.oss-cn-beijing.aliyuncs.com/icon/phone.svg'
            //     }
            // }
        }
    }
</script>

<style scoped lang="less">
    .call-log{
        height: 100%;
        position: relative;
        .head{
            height: 48px;
            line-height: 48px;
            padding: 0 24px;
            background: #fff;
            border-left: 1px solid #e8ebee;
            z-index: 100;
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            button{
                float: right;
                height: 32px;
                margin: 8px 0 0 8px;
            }
            .title{
                color: rgba(18, 18, 18, 1);
                font-size: 16px;
            }
            i{
                float: right;
                margin-top: 16px;
                font-size: 16px;
                color: rgba(204, 204, 204, 1);
                cursor: pointer;
            }
        }
        .content{
            position: absolute;
            padding: 16px;
            left: 0px;
            top: 0px;
            height: 100%;
            padding-top: 64px;
            width: 100%;
            .content-in{
                width: 100%;
                height: 100%;
                position: relative;
                .screen-condition{
                    z-index: 100;
                    height: 80px;
                    background: #fff;
                    padding: 24px 0 0 24px;
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    width: 100%;
                    border-radius: 4px;
                    overflow: hidden;
                    .select-modular{
                        display: inline-block;
                        .title{
                            color: rgba(18, 18, 18, 1);
                            font-size: 14px;
                        }
                        .selection-modular{
                            margin-left: 24px;
                            width: 265px;
                            height: 32px;
                        }
                    }
                }
                .extra-content{
                    height: 16px;
                    width: 100%;
                    background: #f0f2f3;
                    position: absolute;
                    left: 0px;
                    top: 80px;
                    z-index: 100;
                }
                .modular-content{
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    padding-top: 96px;
                    height: 100%;
                    width: 100%;
                    border-radius: 4px;
                    .modular-in{
                        width: 100%;
                        height: 100%;
                        background: #fff;
                        border-radius: 4px;
                        overflow-y: auto;
                        padding: 24px;
                        .modular-operation{
                            margin-top: 21px;
                            .operation-group{
                                display: inline-block;
                                float: right;
                                .divider{
                                    margin: 0 16px 0 0;
                                    height: 32px;
                                }
                            }
                        }
                        .table-data{
                            margin-top: 24px;
                        }
                        .pagination-table{
                            float: right;
                            padding-top: 24px;
                        }
                    }
                    
                }
            }
            
        }
        
    }
    .drawer-public{
        height: 100%;
        position: relative;
        padding-bottom: 60px;
        .drawer-title{
            height: 55px;
            width: 100%;
            span{
                color: rgba(18, 18, 18, 1);
                font-size: 16px;
                line-height: 55px;
            }
            i{
                color: rgba(153, 153, 153, 1);
                font-size: 16px;
                margin-left: 16px;
                cursor: pointer;
                line-height: 50px;
            }
        }
        .drawer-head{
            height: 144px;
            background: #fff;
            padding: 0 24px 0;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            z-index: 100;

        }
        .drawer-content{
            padding: 160px 0 68px 16px;
            width: 100%;
            height: 100%;
            position: absolute;
            .take-phone{
                cursor: pointer;
                .take-phone1{
                    width: 15px;
                    height: 15px;
                    margin-left: 3px;
                }
                .take-phone2{
                    display: none;
                    width: 15px;
                    height: 15px;
                    margin-left: 3px;
                }
            }
            .take-phone:hover{
                .take-phone1{
                    display: none;
                }
                .take-phone2{
                    display: inline-block;
                }
            }
        }
        .drawer-foot{
            position: absolute;
            left: 0px;
            bottom: 0px;
            width: 100%;
            height: 52px;
            background: #fff;
            box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
            .btn-group{
                text-align: center;
                padding-top: 10px;
                width: 100%;
                button{
                    height: 32px;
                    font-size: 14px;
                }
                button:nth-child(2){
                    margin: 0 16px;
                }
            }
        }
    }
    .drawer-sence{
        .row-head{
            padding-top: 20px;
            border-top: 1px solid rgba(229, 229, 229, 1);
            .col-left{
                div:nth-child(1){
                    color: rgba(188, 188, 188, 1);
                    font-size: 12px;
                }
                div:nth-child(2){
                    color: rgba(80, 80, 80, 1);
                    font-size: 14px;
                    margin-top: 6px;
                }
            }
        }
        .drawer-body-content{
            width: 100%;
            height: 100%;
            border-radius: 4px;
            overflow-y: auto;
            padding-right: 16px;
            .basic-info{
                width: 100%;
                // height: 704px;
                background: #fff;
                border-radius: 4px;
                padding: 24px;
                .title{
                    color: rgba(18, 18, 18, 1);
                    font-size: 14px;
                }
                .col-left{
                    padding-right: 24px;
                }
                .col-right{
                    padding-left: 24px;
                }
                .row-list{
                    .col-list{
                        margin-top: 19px;
                        margin-bottom: 5px;
                        height: 69px;
                        overflow: hidden;
                        .first-content{
                            color: rgba(188, 188, 188, 1);
                            font-size: 12px;
                        }
                        .second-content{
                            color: rgba(51, 51, 51, 1);
                            font-size: 14px;
                            margin-top: 9px;
                            img{
                                width: 21px;
                            }
                            .change-head{
                                color: rgba(24, 144, 255, 1);
                                font-size: 12px;
                                cursor: pointer;
                                margin-left: 24px;
                            }
                        }
                        .selection-range{
                            margin-top: 9px;
                            width: 100%;
                        }
                    }
                }
            }  
            .comments{
                padding: 18px 24px;
                background: #fff;
                margin-top: 16px;
                border-radius: 4px;
                .title{
                    color: rgba(18, 18, 18, 1);;
                    font-size: 14px;
                }
                .textarea-comment{
                    margin-top: 20px;
                    background: rgba(247, 247, 247, 1);
                    border: none;
                    resize: none;
                }
                .release{
                    text-align: right;
                    color: rgba(24, 144, 255, 1);
                    font-size: 14px;
                    margin-top: 4px;
                    span{
                        cursor: pointer;
                    }
                }
            }  
        }
    }
    .share-modal{
        .copy-link{
            height: 62px;
            input{
                width: 464px;
                float: left;
                height: 40px;
            }
            span{
                float: left;
                display: inline-block;
                width: 88px;
                height: 40px;
                border: 1px solid rgba(121, 136, 155, 0.3);
                cursor: pointer;
                border-radius: 0px 4px 4px 0px;
                line-height: 40px;
                text-align: center;
            }
        }
    }


    .drawer-public2{
        height: 100%;
        position: relative;
        padding-bottom: 60px;
        .drawer-title{
            height: 55px;
            width: 100%;
            span{
                color: rgba(18, 18, 18, 1);
                font-size: 16px;
                line-height: 55px;
            }
            i{
                color: rgba(153, 153, 153, 1);
                font-size: 16px;
                margin-left: 16px;
                cursor: pointer;
                line-height: 50px;
            }
        }
        .drawer-head{
            height: 144px;
            background: #fff;
            padding: 0 24px 0;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            z-index: 100;

        }
        .drawer-content{
            padding: 160px 0 68px 16px;
            width: 100%;
            height: 100%;
            position: absolute;
            .take-phone{
                cursor: pointer;
                .take-phone1{
                    width: 14px;
                    height: 14px;
                    margin-left: 3px;
                }
                .take-phone2{
                    display: none;
                    width: 14px;
                    height: 14px;
                    margin-left: 3px;
                }
            }
            .take-phone:hover{
                .take-phone1{
                    display: none;
                }
                .take-phone2{
                    display: inline-block;
                }
            }
        }
        .drawer-foot{
            position: absolute;
            left: 0px;
            bottom: 0px;
            width: 100%;
            height: 52px;
            background: #fff;
            box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
            .btn-group{
                text-align: center;
                padding-top: 10px;
                width: 100%;
                button{
                    height: 32px;
                    font-size: 14px;
                }
                button:nth-child(2){
                    margin: 0 16px;
                }
            }
        }
    }
    .upload-modal{
        .divider1{
            margin: 0px 0 24px 0;
        }
        .divider2{
            margin: 24px 0 12px;
        }
        padding-bottom: 50px;
        .btn-group{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                margin-right: 16px;
            }
        }
    }
    .time-modal{
        padding-bottom: 46px;
        .check-list{
            .check-item{
                display: inline-block;
                width: 160px;
                height: 32px;
            }
        }
    }

    .delete-modal{
        padding-top: 24px;
        padding-bottom: 48px;
        .sure-off-bind{
            i{
                color: rgba(255, 170, 0, 1);
                font-size: 24px;
            }
            span{
                color: rgba(18, 18, 18, 1);
                font-size: 20px;
                margin-left: 24px;
            }
        }
        .tip{
            color: rgba(80, 80, 80, 1);
            padding-left: 50px;
            margin: 24px 0;
        }
    }
    .label-modal{
        padding-bottom: 46px;
        .title{
            color: rgba(153, 153, 153, 1);
            font-size: 14px;
            margin-bottom: 16px;
        }
        .company-tag-list{
            margin-top: 8px;
            .type-name{
                color: rgba(18, 18, 18, 1);
                font-size: 14px;
            }
            .label-list{
                span{
                    display: inline-block;
                    height: 28px;
                    line-height: 28px;
                    color: rgba(158, 172, 196, 1);
                    font-size: 13px;
                    padding: 0 16px;
                    margin: 8px 8px 8px 0;
                    background: rgba(158, 172, 196, 0.12);
                    cursor: pointer;
                }
            }
        }
        .new-tag{
            display: flex;
            input{
                flex: 1;
            }
            i{
                font-size: 20px;
                margin-left: 16px;
                margin-top: 6px;
                cursor: pointer;
                color: rgba(121, 136, 155, 1);
            }
        }
        .tag-list{
            span{
                display: inline-block;
                height: 28px;
                line-height: 28px;
                color: rgba(158, 172, 196, 1);
                font-size: 13px;
                padding: 0 16px;
                margin: 8px 8px 8px 0;
                background: rgba(158, 172, 196, 0.12);
                cursor: pointer;
            }
            i{
                margin-left: 8px;
                font-size: 12px;
            }
        }
    }
    .username{
        display: inline-block;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        color: #fff;
        text-align: center;
        line-height: 20px;
        background: rgba(121, 72, 234, 0.6);
        font-size: 12px;
        float: left;
        margin-right:4px;
    }
    .related-clue-modal{
        padding-bottom: 46px; 
    }
    .related-clue{
        padding: 18px 0 24px 24px;
        margin-top: 16px;
        border-radius: 4px;
        background: #fff;
        max-height: 260px;
        overflow-y: hidden;
        .span{
            color: rgba(18, 18, 18, 1);
            font-size: 14px;
        }
        button{
            float: right;
            color: rgba(24, 144, 255, 1);
            font-size: 14px;
            border: none;
        }
        .related-title{
            color: rgba(188, 188, 188, 1);
            font-size: 12px;
            margin-top: 24px;
        }
        .related-data{
            margin-top: 8px;
            .related-name{
                color: rgba(61, 134, 253, 1);
                font-size: 14px;
                cursor: pointer;
            }
            .related-content{
                color: rgba(51, 51, 51, 1);
                font-size: 14px;
            }
        }
    }
    .follow-time{
        height: 136px;
        padding: 18px 0 0 24px;
        margin-top: 16px;
        border-radius: 4px;
        background: #fff;
        .title{
            color: rgba(18, 18, 18, 1);
            font-size: 14px;
        }
        .row-time{
            margin-top: 24px;
            .row-time-title{
                color: rgba(188, 188, 188, 1);
                font-size: 12px;
            }
            .row-time-value{
                color: rgba(51, 51, 51, 1);
                font-size: 14px;
                margin-top: 9px;
            }
        }
    }
    .dynamic-info{
        padding: 18px 0 36px 24px;
        margin-top: 16px;
        border-radius: 4px;
        background: #fff;
        .title{
            span{
                color: rgba(18, 18, 18, 1);
                font-size: 14px;
            }
            button{
                float: right;
                color: rgba(24, 144, 255, 1);
                font-size: 14px;
                border: none;
            }
        }
        .filter-condition{
            margin-top: 23px;
            margin-bottom: 16px;
            span{
                display: inline-block;
                height: 28px;
                line-height: 28px;
                color: rgba(121, 136, 155, 1);
                font-size: 13px;
                margin-right: 8px;
                padding: 0 12px;
                cursor: pointer;
                background: rgba(255, 255, 255, 0.12);
            }
            span.active{
                color: rgba(24, 144, 255, 1);
            }
        }
        .dynamic-list{
            display: flex;
            margin-bottom: 24px;
            .date{
                width: 90px;
                padding-right: 10px;
                color: rgba(153, 153, 153, 1);
                font-size: 12px;
                span:nth-child(2){
                    display: inline-block;
                    width: 8px;
                    height: 8px;
                    border-radius: 4px;
                    float: right;
                    margin-top: 8px;
                }
            }
            .active-track{
                flex: 1;
                .time{
                    color: rgba(153, 153, 153, 1);
                    font-size: 12px;
                }
                .active-event{
                    // color: rgba(153, 153, 153, 1);
                    font-size: 12px;
                    span{
                        background: #1890ff;
                        color: #fff;
                    }
                }
                .dynamic-content{
                    color: rgba(80, 80, 80, 1);
                    font-size: 14px;
                    margin-top: 6px;
                    padding-right: 15px;
                    div{
                        min-height: 21px;
                    }
                    textarea{
                        // display: none;
                    }
                    .dynamic-icon{
                        cursor: pointer;
                        display: none;
                        color: #1890ff;
                    }
                }
                .dynamic-content:hover{
                    .dynamic-icon{
                        display: inline-block;
                    }
                }
                .chat-detail{
                    .play-voice{
                        color: #1890ff;
                        font-size: 12px;
                        text-decoration: underline;
                        display: inline-block;
                        margin-right: 16px;
                        cursor: pointer;
                        i{
                            margin-right: 2px;
                        }
                    }
                    .voice-content{
                        color: #505050;
                        font-size: 12px;
                    }
                }
                .label-content{
                    // margin-top: 9px;
                    button{
                        border: none;
                        background: rgba(158, 172, 196, 0.12);
                        color: rgba(158, 172, 196, 1);
                        font-size: 13px;
                        height: 28px;
                        border: 1px solid rgba(158, 172, 196, 1);
                        margin: 8px 8px 8px 0;
                    }
                    span{
                        display: inline-block;
                        height: 28px;
                        line-height: 28px;
                        border: 1px solid #fff;
                        font-size: 13px;
                        padding: 0 12px;
                        margin: 2px 4px 2px 0;
                        background: #fff;
                        border-radius: 4px;
                        i{
                            cursor: pointer;
                        }
                    }
                }
                .next-time{
                    color: rgba(80, 80, 80, 1);
                    font-size: 14px;
                    float: right;
                    padding-right: 16px;
                }
            }
        }
    }
    .drawer-body-content2{
            width: 100%;
            height: 100%;
            border-radius: 4px;
            overflow-y: auto;
            padding-right: 16px;
            .basic-info{
                width: 100%;
                background: #fff;
                border-radius: 4px;
                padding: 24px 24px 24px 0;
                .title{
                    color: rgba(18, 18, 18, 1);
                    font-size: 14px;
                    padding-left: 24px;
                }
                .col-left{
                    padding-right: 24px;
                }
                .row-list{
                    .col-list{
                        margin-top: 19px;
                        margin-bottom: 5px;
                        min-height: 69px;
                        padding-left: 24px;
                        .first-content{
                            color: rgba(188, 188, 188, 1);
                            font-size: 12px;
                        }
                        .second-content{
                            color: rgba(51, 51, 51, 1);
                            font-size: 14px;
                            margin-top: 9px;
                            .change-head{
                                color: rgba(24, 144, 255, 1);
                                font-size: 12px;
                                cursor: pointer;
                                margin-left: 24px;
                            }
                            .edit-icon{
                                display: none;
                            }
                        }
                        .selection-range{
                            // margin-top: 9px;
                            width: 290px;
                        }
                        .third-content{
                            color: rgba(51, 51, 51, 1);
                            font-size: 14px;
                            margin-top: 4px;
                            // display: none;
                        }
                        .user-content{
                            color: rgba(51, 51, 51, 1);
                            font-size: 14px;
                            margin-top: 4px;
                        }
                        .label-content{
                            margin-top: 9px;
                            button{
                                border: none;
                                background: rgba(158, 172, 196, 0.12);
                                color: rgba(158, 172, 196, 1);
                                font-size: 13px;
                                height: 28px;
                            }
                            span{
                                display: inline-block;
                                height: 28px;
                                line-height: 28px;
                                color: #fff;
                                font-size: 13px;
                                padding: 0 12px;
                                margin: 2px 4px 2px 0;
                                background: rgba(158, 172, 196, 0.12);
                                i{
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                    .col-list:hover{
                        // .second-content{
                        //     display: none;
                        // }
                        // .third-content{
                        //     display: block;
                        // }
                        .edit-icon{
                            display: inline-block;
                            cursor: pointer;
                            color: #1890ff;
                        }
                    }
                    .col-time{
                        display: none !important;
                    }
                }
                .add-attachment{
                    margin-left: 24px;
                    border: none;
                    background: rgba(158, 172, 196, 0.12);
                    color: rgba(158, 172, 196, 1);
                    font-size: 13px;
                }
                .pd-24{
                    padding-left: 24px;
                }
                .pd-8{
                    padding-left: 8px;
                }
                .attachment-item{
                    padding: 9px 12px 0 17px;
                    height: 52px;
                    border: 1px solid rgba(158, 172, 196, 0.3);
                    margin-top: 8px;
                    display: flex;
                    img{
                        width: 36px;
                        height: 36px;
                    }
                    .attachment-content{
                        flex: 1;
                        padding-left: 8px;
                        .name-operation{
                            span{
                                float: left;
                                color: rgba(18, 18, 18, 1);
                                font-size: 13px;
                            }
                            i{
                                float: right;
                                color: rgba(158, 172, 196, 1);
                                font-size: 13px;
                                cursor: pointer;
                                margin-left: 12px;
                            }
                            i:hover{
                                color: #1890ff;
                            }
                        }
                        .file-size{
                            color: rgba(158, 172, 196, 1);
                            font-size: 12px;
                        }
                    }
                }
            }  
            .follow-time{
                height: 136px;
                padding: 18px 0 0 24px;
                margin-top: 16px;
                border-radius: 4px;
                background: #fff;
                .title{
                    color: rgba(18, 18, 18, 1);
                    font-size: 14px;
                }
                .row-time{
                    margin-top: 24px;
                    .row-time-title{
                        color: rgba(188, 188, 188, 1);
                        font-size: 12px;
                    }
                    .row-time-value{
                        color: rgba(51, 51, 51, 1);
                        font-size: 14px;
                        margin-top: 9px;
                    }
                }
            }
            .related-clue{
                padding: 18px 0 24px 24px;
                margin-top: 16px;
                border-radius: 4px;
                background: #fff;
                max-height: 260px;
                overflow-y: hidden;
                .span{
                    color: rgba(18, 18, 18, 1);
                    font-size: 14px;
                }
                button{
                    float: right;
                    color: rgba(24, 144, 255, 1);
                    font-size: 14px;
                    border: none;
                }
                .related-title{
                    color: rgba(188, 188, 188, 1);
                    font-size: 12px;
                    margin-top: 24px;
                }
                .related-data{
                    margin-top: 8px;
                    .related-name{
                        color: rgba(61, 134, 253, 1);
                        font-size: 14px;
                        cursor: pointer;
                    }
                    .related-content{
                        color: rgba(51, 51, 51, 1);
                        font-size: 14px;
                    }
                }
            }
            .dynamic-info{
                padding: 18px 0 36px 24px;
                margin-top: 16px;
                border-radius: 4px;
                background: #fff;
                .title{
                    span{
                        color: rgba(18, 18, 18, 1);
                        font-size: 14px;
                    }
                    button{
                        float: right;
                        color: rgba(24, 144, 255, 1);
                        font-size: 14px;
                        border: none;
                    }
                }
                .filter-condition{
                    margin-top: 23px;
                    margin-bottom: 16px;
                    span{
                        display: inline-block;
                        height: 28px;
                        line-height: 28px;
                        color: rgba(121, 136, 155, 1);
                        font-size: 13px;
                        margin-right: 8px;
                        padding: 0 12px;
                        cursor: pointer;
                        background: rgba(255, 255, 255, 0.12);
                    }
                    span.active{
                        color: rgba(24, 144, 255, 1);
                    }
                }
                .dynamic-list{
                    display: flex;
                    margin-bottom: 24px;
                    .date{
                        width: 90px;
                        padding-right: 10px;
                        color: rgba(153, 153, 153, 1);
                        font-size: 12px;
                        span:nth-child(2){
                            display: inline-block;
                            width: 8px;
                            height: 8px;
                            border-radius: 4px;
                            float: right;
                            margin-top: 8px;
                        }
                    }
                    .active-track{
                        flex: 1;
                        .time{
                            color: rgba(153, 153, 153, 1);
                            font-size: 12px;
                        }
                        .active-event{
                            // color: rgba(153, 153, 153, 1);
                            font-size: 12px;
                            span{
                                background: #1890ff;
                                color: #fff;
                            }
                        }
                        .dynamic-content{
                            color: rgba(80, 80, 80, 1);
                            font-size: 14px;
                            margin-top: 6px;
                            padding-right: 15px;
                            div{
                                min-height: 21px;
                            }
                            textarea{
                                // display: none;
                            }
                            .dynamic-icon{
                                cursor: pointer;
                                display: none;
                                color: #1890ff;
                            }
                        }
                        .dynamic-content:hover{
                            .dynamic-icon{
                                display: inline-block;
                            }
                        }
                        .chat-detail{
                            .play-voice{
                                color: #1890ff;
                                font-size: 12px;
                                text-decoration: underline;
                                display: inline-block;
                                margin-right: 16px;
                                cursor: pointer;
                                i{
                                    margin-right: 2px;
                                }
                            }
                            .voice-content{
                                color: #505050;
                                font-size: 12px;
                            }
                        }
                        .label-content{
                            // margin-top: 9px;
                            button{
                                border: none;
                                background: rgba(158, 172, 196, 0.12);
                                color: rgba(158, 172, 196, 1);
                                font-size: 13px;
                                height: 28px;
                                border: 1px solid rgba(158, 172, 196, 1);
                                margin: 8px 8px 8px 0;
                            }
                            span{
                                display: inline-block;
                                height: 28px;
                                line-height: 28px;
                                border: 1px solid #fff;
                                font-size: 13px;
                                padding: 0 12px;
                                margin: 2px 4px 2px 0;
                                background: #fff;
                                border-radius: 4px;
                                i{
                                    cursor: pointer;
                                }
                            }
                        }
                        .next-time{
                            color: rgba(80, 80, 80, 1);
                            font-size: 14px;
                            float: right;
                            padding-right: 16px;
                        }
                    }
                }
            }
            .comments{
                padding: 18px 24px;
                background: #fff;
                margin-top: 16px;
                border-radius: 4px;
                .title{
                    color: rgba(18, 18, 18, 1);;
                    font-size: 14px;
                }
                .textarea-comment{
                    margin-top: 20px;
                    background: rgba(247, 247, 247, 1);
                    border: none;
                    resize: none;
                }
                .release{
                    text-align: right;
                    color: rgba(24, 144, 255, 1);
                    font-size: 14px;
                    margin-top: 4px;
                    span{
                        cursor: pointer;
                    }
                }
            }  
        }
        .btn-group2{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                background: #fff;
                color: rgba(121, 136, 155, 0.7);
                border: 1px solid rgba(121, 136, 155, 0.5);
                margin-right: 16px;
            }
            button:nth-child(1):hover{
                background: rgba(121, 136, 155, 0.08);
            }
        }
        .related-clue-btn-group{
            text-align: center;
            padding-top: 10px;
            width: 100%;
            button{
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(2){
                margin: 0 16px;
            }
        }
        .btn-group{
            text-align: center;
            padding-top: 10px;
            width: 100%;
            button{
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(2){
                margin: 0 16px;
            }
        }
        .drawer-newtask{
        .newtask-body{
            height: 496px;
            padding: 16px;
            .newtask-content{
                width: 100%;
                height: 100%;
                overflow: hidden;
                background: #fff;
                padding: 5px 24px 24px;
                border-radius: 4px;
                .col-left{
                    padding-right: 24px;
                }
                .col-right{
                    padding-left: 24px;
                }
                .row-list{
                    .col-list{
                        margin-top: 19px;
                        margin-bottom: 5px;
                        .select-admin{
                            width: 336px;
                            height: 32px;
                            margin-top: 9px;
                        }
                        .textarea-remarks{
                            margin-top: 9px;
                        }
                        .first-content{
                            color: rgba(188, 188, 188, 1);
                            font-size: 12px;
                        }
                        .second-content{
                            color: rgba(51, 51, 51, 1);
                            font-size: 14px;
                            margin-top: 9px;
                            .digital-staff{
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
    .drawer-public1{
        height: 100%;
        position: relative;
        padding-bottom: 60px;
        .drawer-head{
            height: 88px;
            background: #fff;
            padding: 0 24px 0;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            z-index: 100;

        }
        .drawer-content{
            padding: 104px 0 68px 16px;
            width: 100%;
            height: 100%;
            position: absolute;
        }
        .drawer-foot{
            position: absolute;
            left: 0px;
            bottom: 0px;
            width: 100%;
            height: 52px;
            background: #fff;
            box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
            .btn-group{
                text-align: center;
                padding-top: 10px;
                width: 100%;
                button{
                    height: 32px;
                    font-size: 14px;
                }
                button:nth-child(2){
                    margin: 0 16px;
                }
            }
        }
    }
</style>