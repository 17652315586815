<template>
    <div class="call-detail">
        <div v-if="!ifPhone">
            <div class="head">
                <span class="title">{{digitalDetail.moduleName+"-"+digitalDetail.dataTitle}}</span>
                <OutLink :moduleId='moduleId' :dataId='currentId' :original='""'></OutLink>
            </div>
            <div class="content">
                <div class="content-in">
                    <div class="modular-content">
                        <div class="left-content">
                            
                        </div>
                        <div class="center-content">
                            <div class="basic-data">
                                <a-row>
                                    <a-col :span="12" style="padding-right:8px">
                                        <a-row>
                                            <a-col style="padding-right:8px" :span="12">
                                                <div class="data-content">
                                                    <div>待呼号码</div>
                                                    <div>{{callCountMap.waitingCall}}</div>
                                                </div>
                                            </a-col>
                                            <a-col style="padding-left:8px" :span="12">
                                                <div class="data-content">
                                                    <div>呼叫中号码</div>
                                                    <div>{{callCountMap.calling}}</div>
                                                </div>
                                            </a-col>
                                        </a-row>
                                    </a-col>
                                    <a-col :span="12" style="padding-left:8px">
                                        <a-row>
                                            <a-col style="padding-right:8px" :span="12">
                                                <div class="data-content">
                                                    <div>已呼号码</div>
                                                    <div>{{callCountMap.called}}</div>
                                                </div>
                                            </a-col>
                                            <a-col style="padding-left:8px" :span="12">
                                                <div class="data-content">
                                                    <div>呼通号码</div>
                                                    <div>{{callCountMap.callSuccess}}</div>
                                                </div>
                                            </a-col>
                                        </a-row>
                                    </a-col>
                                </a-row>
                            </div>
                            <!-- <div class="chart-list">
                                <a-row>
                                    <a-col style="padding-right:8px" :span="12">
                                        <div class="echart-item">
                                            <div class="title">时段分布</div>
                                            <div id="myChart1">

                                            </div>
                                        </div>
                                    </a-col>
                                    <a-col style="padding-left:8px" :span="12">
                                        <div class="echart-item">
                                            <div class="title">标签统计</div>
                                            <div id="myChart2">

                                            </div>
                                        </div>
                                    </a-col>
                                </a-row>
                            </div> -->
                            <div class="basic-info">
                                <div class="title">基本信息</div>
                                <a-row class="row-list">
                                    <a-col :span="12" class="col-list" :class="{'col-left':index%2==0,'col-right':index%2!=0}" v-for="(item,index) in digitalDetail.detailList" :key='index'>
                                        <div class="first-content">{{item.name}}:</div>
                                        <div v-if="item.code!='workTime'" class="second-content">{{item.value}}</div>
                                        <div v-if="item.code=='workTime'" class="second-content">
                                            <span>{{item.value.timeList.length>0?'已设定':'未设定'}}</span>
                                        </div>
                                    </a-col>
                                </a-row>
                                
                            </div>
                            <div class="comments">
                                <Comments ref="commentChild"></Comments>
                            </div>
                        </div>
                        <div class="right-content">
                            <div class="data-info">
                                <div class="related-info">
                                    <span></span>
                                    数据信息
                                </div>
                                <ul>
                                    <li>
                                        <div>创建时间：</div>
                                        <div>{{digitalDetail.createTime}}</div>
                                    </li>
                                    <li>
                                        <div>修改时间：</div>
                                        <div>{{digitalDetail.updateTime}}</div>
                                    </li>
                                    <li>
                                        <div>创建人：</div>
                                        <div>{{digitalDetail.createBy}}</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="ifPhone" style="background:#F0F2F3">
            <div style="background:#fff;height:144px;padding:16px;">
                <div>
                    <span style="font-weight:bold;font-size:18px;">{{digitalDetail.moduleName+"-"+digitalDetail.dataTitle.length>12?digitalDetail.dataTitle.substring(0,12)+'...':digitalDetail.dataTitle}}</span>
                    <OutLinkPhone :moduleId='moduleId' :dataId='currentId' :original='""'></OutLinkPhone>
                </div>
                <ul style="padding-top:16px;font-size:13px;">
                    <li>
                        <div>
                            <span style="display:inline-block;width:70px;color:rgba(188, 188, 188, 1);">创建人：</span>
                            <span style="color:rgba(80, 80, 80, 1);">{{digitalDetail.createBy}}</span>
                        </div>
                    </li>
                    <li style="padding:8px 0">
                        <div>
                            <span style="display:inline-block;width:70px;color:rgba(188, 188, 188, 1);">创建时间：</span>
                            <span style="color:rgba(80, 80, 80, 1);">{{digitalDetail.createTime}}</span>
                        </div>
                    </li>
                    <li>
                        <div>
                            <span style="display:inline-block;width:70px;color:rgba(188, 188, 188, 1);">修改时间：</span>
                            <span style="color:rgba(80, 80, 80, 1);">{{digitalDetail.updateTime}}</span>
                        </div>
                    </li>
                   
                </ul>
            </div>
            <div style="margin-top:7px;padding:16px;background:#fff">
                <a-row style="">
                    <a-col :span="12">
                        <div style="color:rgba(188, 188, 188, 1);font-size:13px;">待呼号码</div>
                        <div style="color:rgba(24, 144, 255, 1);font-size:20px;font-weight:bold;margin-top:6px;">{{callCountMap.waitingCall}}</div>
                    </a-col>
                    <a-col :span="12">
                        <div style="color:rgba(188, 188, 188, 1);font-size:13px;">呼叫中号码</div>
                        <div style="color:rgba(24, 144, 255, 1);font-size:20px;font-weight:bold;margin-top:6px;">{{callCountMap.calling}}</div>
                    </a-col>
                </a-row>
                <a-row style="margin-top:20px;">
                    <a-col :span="12">
                        <div style="color:rgba(188, 188, 188, 1);font-size:13px;">已呼号码</div>
                        <div style="color:rgba(24, 144, 255, 1);font-size:20px;font-weight:bold;margin-top:6px;">{{callCountMap.called}}</div>
                    </a-col>
                    <a-col :span="12">
                        <div style="color:rgba(188, 188, 188, 1);font-size:13px;">呼通号码</div>
                        <div style="color:rgba(24, 144, 255, 1);font-size:20px;font-weight:bold;margin-top:6px;">{{callCountMap.callSuccess}}</div>
                    </a-col>
                </a-row>
            </div>
            <div style="margin-top:7px;padding:16px;background:#fff">
                <div v-for="(item,index) in digitalDetail.detailList" :key='index' style="margin-bottom:20px;">
                    <div style="color:rgba(188, 188, 188, 1);font-size:13px;">{{item.name}}:</div>
                    <div v-if="item.code!='workTime'" style="color:rgba(51, 51, 51, 1);font-size:16px;">{{item.value}}</div>
                    <div v-if="item.code=='workTime'" style="color:rgba(51, 51, 51, 1);font-size:16px;">{{item.value.timeList.length>0?'已设定':'未设定'}}</div>
                </div>
            </div>
            <div style="clean:both"></div>
            <div style="margin-top:7px;padding:16px;background:#fff">
                <div class="comments">
                    <Comments ref="commentChild"></Comments>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Comments from "../../components/Comments";
    import OutLink from "../../components/OutLink";
    import OutLinkPhone from "../../components/OutLinkPhone";
    export default {
        name: 'OutCallShare',
        components: {Comments, OutLink, OutLinkPhone},
        data() {
            return {
                currentId: '',
                moduleId: '',
                digitalDetail: {
                    dataTitle: ''
                },
                callCountMap: {

                },
                ifPhone: false,
            }
        },
        methods: {
            drawLine1() {
                let myChart = this.$echarts.init(document.getElementById('myChart1'))
                myChart.setOption({
                    xAxis: {
                        type: 'category',
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [
                        {
                        data: [120, 200, 150, 80, 70, 110, 130],
                        type: 'bar'
                        }
                    ]
                });
            },
            drawLine2() {
                let myChart = this.$echarts.init(document.getElementById('myChart2'))
                myChart.setOption({
                    tooltip: {
                        trigger: 'item'
                    },
                    legend: {
                        type: 'scroll',
                        orient: 'vertical',
                        right: 10,
                        top: 20,
                        bottom: 20,
                    },
                    series: [
                        {
                        name: 'Access From',
                        type: 'pie',
                        radius: ['40%', '70%'],
                        avoidLabelOverlap: false,
                        itemStyle: {
                            borderRadius: 10,
                            borderColor: '#fff',
                            borderWidth: 2
                        },
                        label: {
                            show: false,
                            position: 'center'
                        },
                        emphasis: {
                            label: {
                            show: true,
                            fontSize: '20',
                            fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: [
                            { value: 1048, name: 'Search Engine' },
                            { value: 735, name: 'Direct' },
                            { value: 580, name: 'Email' },
                            { value: 484, name: 'Union Ads' },
                            { value: 300, name: 'Video Ads' }
                        ]
                        }
                    ]
                });
            },
            querySelectData() {
                this.axios(
                   {
                        method:'get',
                        url:'api/externalLinks/'+this.currentId, 
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.digitalDetail = response.data.data
                        console.log(response.data.data)
                        this.callCountMap = response.data.data.callCountMap
                        this.$refs.commentChild.getParentCommentTree(this.moduleId, response.data.data.id, false)
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
        },
        mounted: function() {
            // this.drawLine1()
            // this.drawLine2()
            // let myChart1 = this.$echarts.init(document.getElementById('myChart1')) 
            // let myChart2 = this.$echarts.init(document.getElementById('myChart2')) 
            // window.addEventListener("resize", () => { 
            //     myChart1.resize()
            //     myChart2.resize() 
            // })
            this.currentId = this.$route.query.id
            this.moduleId = this.$route.query.moduleId
            this.querySelectData()
            let screenWidth = document.body.clientWidth
            if(screenWidth < 900){
                this.ifPhone = true
            }
            window.onresize = () => {
                screenWidth = document.body.clientWidth
                if(screenWidth < 900){
                    this.ifPhone = true
                }else{
                    this.ifPhone = false
                }
            }
        }
    }
</script>

<style scoped lang="less">
    .call-detail{
        height: 100%;
        position: relative;
        background: rgba(240, 242, 243, 1);
        .head{
            height: 48px;
            line-height: 48px;
            padding: 0 24px;
            background: #fff;
            z-index: 100;
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            button{
                float: right;
                width: 64px;
                height: 32px;
                margin: 8px 0 0 8px;
            }
            .title{
                color: rgba(18, 18, 18, 1);
                font-size: 16px;
            }
            i{
                float: right;
                margin-top: 16px;
                font-size: 16px;
                color: rgba(204, 204, 204, 1);
                cursor: pointer;
            }
        }
        .content{
            position: absolute;
            padding: 64px 16px 16px 16px;
            left: 0px;
            top: 0px;
            height: 100%;
            padding-top: 64px;
            width: 100%;
            .content-in{
                width: 100%;
                height: 100%;
                position: relative;
                .modular-content{
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    height: 100%;
                    width: 100%;
                    overflow-y: auto;
                    display: flex;
                    .left-content{
                        width: 288px;
                        height: 100%;
                        border-radius: 4px;
                        padding: 21px 24px;
                    }
                    .center-content{
                        flex: 1;
                        height: 100%;
                        margin: 0 24px;
                        overflow-y: auto;
                        overflow-x: hidden;
                        .basic-data{
                            margin-bottom: 16px;
                            .data-content{
                                width: 100%;
                                height: 144px;
                                background: #fff;
                                padding: 18px 0 0 24px;
                                border-radius: 4px;
                                div:nth-child(1){
                                    color: rgba(18, 18, 18, 1);
                                    font-size: 14px;
                                }
                                div:nth-child(2){
                                    color: rgba(102, 102, 102, 1);
                                    font-size: 36px;
                                    margin-top: 23px;
                                }
                            }
                        }
                        .chart-list{
                            margin-bottom: 16px;
                            .title{
                                color: rgba(18, 18, 18, 1);
                                font-size: 14px;
                            }
                            .echart-item{
                                height: 256px;
                                background: #fff;
                                padding: 18px 0 0 24px;
                                #myChart1,#myChart2{
                                    height: 217px;
                                }
                            }
                        }
                        .basic-info{
                            width: 100%;
                            min-height: 378px;
                            background: #fff;
                            border-radius: 4px;
                            padding: 24px;
                            .title{
                                color: rgba(18, 18, 18, 1);
                                font-size: 14px;
                            }
                            .col-left{
                                padding-right: 24px;
                            }
                            .col-right{
                                padding-left: 24px;
                            }
                            .row-list{
                                .col-list{
                                    margin-top: 19px;
                                    margin-bottom: 5px;
                                    .first-content{
                                        color: rgba(188, 188, 188, 1);
                                        font-size: 12px;
                                    }
                                    .second-content{
                                        color: rgba(51, 51, 51, 1);
                                        font-size: 14px;
                                        margin-top: 9px;
                                        .has-setted{
                                            position: relative;
                                            display: inline-block;
                                            .set-time{
                                                color: rgba(51, 51, 51, 1);
                                                font-size: 14px;
                                                cursor: pointer;
                                            }
                                            .time-box{
                                                position: absolute;
                                                left: 50px;
                                                top: 5px;
                                                width: 136px;
                                                min-height: 148px;
                                                background: rgba(64, 64, 64, 1);
                                                color: #fff;
                                                font-size: 12px;
                                                border-radius: 4px;
                                                padding: 16px;
                                                display: none;
                                            }
                                        }
                                        .has-setted:hover{
                                            .time-box{
                                                display: block;
                                            }
                                        }
                                        img{
                                            width: 21px;
                                        }
                                        .change-head{
                                            color: rgba(24, 144, 255, 1);
                                            font-size: 12px;
                                            cursor: pointer;
                                            margin-left: 24px;
                                        }
                                    }
                                    .selection-range{
                                        margin-top: 9px;
                                        width: 100%;
                                    }
                                }
                            }
                        }  
                        .comments{
                            padding: 18px 24px;
                            background: #fff;
                            margin-top: 16px;
                            border-radius: 4px;
                            .title{
                                color: rgba(18, 18, 18, 1);;
                                font-size: 14px;
                            }
                            .textarea-comment{
                                margin-top: 20px;
                                background: rgba(247, 247, 247, 1);
                                border: none;
                                resize: none;
                            }
                            .release{
                                text-align: right;
                                color: rgba(24, 144, 255, 1);
                                font-size: 14px;
                                margin-top: 4px;
                                span{
                                    cursor: pointer;
                                }
                            }
                        }  
                    }
                    .right-content{
                        width: 288px;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        .data-info{
                            width: 100%;
                            height: 280px;
                            background: #fff;
                            border-radius: 4px;
                            margin-bottom: 16px;
                            padding: 18px 24px;
                            .related-info{
                                color: rgba(18, 18, 18, 1);
                                font-size: 14px;
                                span{
                                    width: 3px;
                                    height: 14px;
                                    float: left;
                                    background: rgba(24, 144, 255, 1);
                                    margin: 4px 7px 0 0;
                                }
                            }
                            ul{
                                li{
                                    padding-top: 24px;
                                    div:nth-child(1){
                                        color: rgba(188, 188, 188, 1);
                                        font-size: 12px;
                                        margin-bottom: 4px;
                                    }
                                    div:nth-child(2){
                                        color: rgba(80, 80, 80, 1);
                                        font-size: 14px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>