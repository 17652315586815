<template>
    <div class="customer-dynamics">
        <div class="customer-title">
            <span class="title">客户动态</span>
            <a-divider class="divider" type="vertical" />
            <div class="btn-group">
                <a-icon type="exclamation-circle" />
                <a-button @click="goBack">返回</a-button>
            </div>
        </div>
        <div class="dynamics-content" id="read">
            <div class="dynamics-list" v-for="(item,index) in trackDynamicListData" :key='index'>
                <img v-if="item.avatar!=''" class="dynamics-head" :src="item.avatar" alt="">
                <span class="dynamics-head1" v-if="item.avatar==''">
                    {{(item.createBy).substr(0, 1)}}
                </span>
                <div class="list-content">
                    <div class="item-name">
                        <span class="name1">{{item.createBy}}</span>
                        <div class="name2">
                            <span class="name3" @click="viewComDetails(item.customerId)">{{item.customerName}}@{{item.corporateName}}</span>
                            <div class="detail-box" @click="dynamicsBoxClick()">
                                <div class="dynamics-title">
                                    <a-icon type="search" />
                                    <span @click="refreshDataList(item.customerId)">客户动态数量 ({{item.customer.dynamicCount}})</span>
                                </div>
                                <a-row class="create-basicinfo">
                                    <a-col :span="12">
                                        <div class="create-title">创建人：</div>
                                        <div class="create-content">{{item.customer.createBy}}</div>
                                    </a-col>
                                    <a-col :span="12">
                                        <div class="create-title">创建时间：</div>
                                        <div class="create-content">{{item.customer.createTime}}</div>
                                    </a-col>
                                </a-row>
                                <a-divider class="customer-divider"/>
                                <a-row class="create-basicinfo">
                                    <a-col :span="12">
                                        <div class="create-title">客户名称：</div>
                                        <div class="create-content">{{item.customer.customerName}}</div>
                                    </a-col>
                                    <a-col :span="12">
                                        <div class="create-title">公司名称：</div>
                                        <div class="create-content">{{item.customer.corporateName}}</div>
                                    </a-col>
                                </a-row>
                                <a-row class="create-label">
                                    <div class="create-title">标签：</div>
                                    <div class="label-list">
                                        <span :style="{'background':item.color}" v-for="(item,index) in item.customer.labelList" :key='index'>{{item.name}}</span>
                                    </div>
                                </a-row>
                            </div>
                        </div>
                    </div>
                    <div class="item-date">{{item.createTime}}</div>
                    <div class="item-content">{{item.content}}</div>
                    <div class="label-content">
                        <span :style="{background:item1.color}" v-for="(item1,index1) in item.labelList" :key='index1'>{{item1.name}}</span>
                    </div>
                    <div class="item-comment">
                        <div class="comment-btn">
                            <a-icon @click="showCommentFarther(index)" type="message" />
                            <span>{{item.commentCount}}</span>
                            <span>评论</span>
                        </div>
                    </div>
                    <a-divider class="comment-divider" />
                    <!-- <a-textarea v-model="item.commentContent" v-if="item.commentBool" class="comment-textarea" placeholder="请输入你的评论" /> -->
                    <div v-if="item.commentBool" class="textarea-comment" contenteditable=true ref="menuItem1">
                
                    </div>
                    <div v-if="item.commentBool" class="comment-operate">
                        <span @click="showUserListBox(index, 'no')" class="symbol-icon">@</span>
                        <div class="selected-user">
                            <div v-for="(item5, index5) in item.selectedUserList" :key='index5'>
                                <span>{{item5.name}}</span>
                                <a-icon @click="removeUserList(index, index5)" type="close" />
                            </div>
                        </div>
                        <div class="cancel-submit">
                            <span @click="cancelFatherComment(index)">取消</span>
                            <span @click="submitComment(item.id, 1, '', index, '')">发布</span>
                        </div>
                        <div contenteditable=false v-if="item.userBool" class="user-list1">
                            <ul>
                                <li @click="chooseUser1(item4,index)" v-for="(item4,index4) in userList" :value="item4.value" :key='index4'>{{item4.name}}</li>
                            </ul>
                        </div>
                    </div>
                    <div class="comment-detail">
                        <div v-for="(item2,index2) in item.commentTree" :key='index2'>
                            <div class="comment-list">
                                <img v-if="item2.avatar!=''" :src="item2.avatar" alt="">
                                <span class="username" v-if="item2.avatar==''">
                                    {{(item2.commentBy).substr(0, 1)}}
                                </span>
                                <div class="comment-info">
                                    <div class="comment-info-content">
                                        <span class="replay-name">{{item2.commentBy}}：</span>
                                        <span class="replay-content" v-html="item2.content"></span>
                                    </div>
                                    <div class="comment-info-date">
                                        <span>{{item2.createTime}}</span>
                                        <a-icon @click="showCommentChildren(index,index2)" type="message" />
                                    </div>
                                    <div v-if="item2.commentChildrenBool">
                                        <!-- <a-textarea v-model="item2.commentChildrenContent" class="comment-replay-textarea" placeholder="请输入你的评论" /> -->
                                        <div class="textarea-comment" contenteditable=true ref="menuItem1">
                
                                        </div>
                                        <div class="comment-operate">
                                            <span @click="showUserListBox(index, index2)" class="symbol-icon">@</span>
                                            <div class="selected-user">
                                                <div v-for="(item6, index6) in item2.selectedUserList" :key='index6'>
                                                    <span>{{item6.name}}</span>
                                                    <a-icon @click="removeUserList2(index, index2, index5)" type="close" />
                                                </div>
                                            </div>
                                            <div class="cancel-submit">
                                                <span @click="cancelChildrenComment(index, index2)">取消</span>
                                                <span @click="submitComment(item.id, 0, item2.id, index, index2)">发布</span>
                                            </div>
                                            <div contenteditable=false v-if="item2.userChildrenBool" class="user-list1">
                                                <ul>
                                                    <li @click="chooseUser2(item4, index, index2)" v-for="(item4,index4) in userList" :value="item4.value" :key='index4'>{{item4.name}}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="comment-list comment-list2" v-for="(item3,index3) in item2.children" :key='index3'>
                                <img v-if="item3.avatar!=''" :src="item3.avatar" alt="">
                                <span class="username" v-if="item3.avatar==''">
                                    {{(item3.commentBy).substr(0, 1)}}
                                </span>
                                <div class="comment-info">
                                    <div class="comment-info-content">
                                        <span class="replay-name">{{item3.commentBy}}：</span>
                                        <span class="replay-content">回复 </span>
                                        <span class="replay-name">{{item3.replyTo}}：</span>
                                        <span class="replay-content" v-html="item3.content"></span>
                                    </div>
                                    <div class="comment-info-date">
                                        <span>{{item3.createTime}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="trackDynamicListData.length==0" class="no-data">暂无数据</div>
        </div>
        <div class="dynamics-screen">
            <div class="dynamic-filtering">
                <div class="dynamic-title">动态筛选</div>
                <a-input-search class="dynamic-input" v-model="filterContent" placeholder="可输入客户姓名/动态内容/公司名称/ID" @search="onSearch" />
                <div class="person-in-charge">
                    <a-tree-select
                        class="tree-select"
                        :expanded-keys="expandedKeys2"
                        :auto-expand-parent="autoExpandParent2"
                        :tree-data="peopleTree"
                        :defaultExpandAll="true"
                        @expand="onExpand2"
                        @select="onSelect"
                        v-model="personCharge"
                        placeholder='线索负责人'
                    />
                </div>
                <div class="date-select">
                    <div @click="showDateBox" class="date-title">时间选择</div>
                    <div class="date-box" v-show='dateShow' @click="stopEvent">
                        <div class="date-list">时间不限</div>
                        <div @click="selectDate(1, '一天内')" :class="{'active':currentSelectedDate==1}" class="date-list">一天内</div>
                        <div @click="selectDate(2, '一周内')" :class="{'active':currentSelectedDate==2}" class="date-list">一周内</div>
                        <div @click="selectDate(3, '一月内')" :class="{'active':currentSelectedDate==3}" class="date-list">一月内</div>
                        <div @click="selectDate(4, '一年内')" :class="{'active':currentSelectedDate==4}" class="date-list">一年内</div>
                        <a-divider class="date-divider" />
                        <div class="design-self">自定义</div>
                        <div class="date-choose">
                            <span class="date-choose-title">从</span>
                            <a-date-picker class="date-choose-picker" format="YYYY-MM-DD HH:mm:ss" valueFormat="YYYY-MM-DD HH:mm:ss" v-model="startDate" @change="startDateChange"/>
                        </div>
                        <div class="date-choose">
                            <span class="date-choose-title">至</span>
                            <a-date-picker class="date-choose-picker" format="YYYY-MM-DD HH:mm:ss" valueFormat="YYYY-MM-DD HH:mm:ss" v-model="endDate" @change="endDateChange"/>
                        </div>
                        <div class="sure-btn">
                            <a-button @click="sureSelectDate">确定</a-button>
                        </div>
                    </div>
                </div>
                <a-divider class="condition-divider" />
                <div class="condition-list">
                    <span class="condition-title">条件</span>
                    <div class="condition-content">
                        <div class="condition-item" v-if="conditionObj.username!=''">
                            <span>{{conditionObj.username}}</span>
                            <a-icon @click="deleteUsername" type="close" />
                        </div>
                        <div class="condition-item" v-if="conditionObj.personInCharge!=''">
                            <span>{{conditionObj.personInCharge}}</span>
                            <a-icon @click="deletePersonInCharge" type="close" />
                        </div>
                        <div class="condition-item" v-if="conditionObj.dateRange!=''">
                            <span>{{conditionObj.dateRange}}</span>
                            <a-icon @click="deleteDateRange" type="close" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="rank-tab">
                <div class="num-tab">
                    <span @click="chooseTab(1)" :class="{'active':currentTab==1}">动态数量排行</span>
                    <span @click="chooseTab(2)" :class="{'active':currentTab==2}">客户数量排行</span>
                </div>
                <div class="date-tab">
                    <span @click="chooseDate(1)" :class="{'active':currentDate==1}">昨日</span>
                    <span @click="chooseDate(2)" :class="{'active':currentDate==2}">今日</span>
                    <span @click="chooseDate(3)" :class="{'active':currentDate==3}">周</span>
                    <span @click="chooseDate(4)" :class="{'active':currentDate==4}">月</span>
                </div>
                <div class="data-list">
                    <ul>
                        <li v-for="(item,index) in rankList" :key='index'>
                            <span :class="{'first-order':index==0,'second-order':index==1,'third-order':index==2,}" class="li-order">{{index+1}}</span>
                            <span class="li-content">{{item.name}}</span>
                            <div>
                                <img src="../../assets/img/fire.svg" alt="">
                                <span>{{item.count}}</span>
                            </div>
                        </li>
                    </ul>
                    <div class="nodata" v-if="rankList.length==0">暂无数据</div>
                </div>
            </div>
        </div>
        <a-drawer
            :title='digitalDetail.moduleName+"-"+digitalDetail.dataTitle'
            placement="right"
            :closable="true"
            :visible="drawerVisible"
            :destroyOnClose='true'
            :maskClosable='true'
            @close="onClose"
            :width='800'
            :mask='true'
            :headerStyle="{ border: 'none', height: '56px', position: 'absolute', width: '100%', left: '0', top: '0', zIndex: '0'}"
            :bodyStyle="{ background: 'rgba(240, 242, 243, 1)', height: '100%', position: 'absolute', width: '100%', left: '0', top: '0', zIndex: '10', padding: '0 0 0' }"
        >
            <div class="drawer-sence drawer-public2">
                <div class="drawer-head">
                    <div class="drawer-title">
                        <span class="left">{{digitalDetail.moduleName+"-"+(digitalDetail.dataTitle.length>28?digitalDetail.dataTitle.substring(0,28)+'...':digitalDetail.dataTitle)}}</span>
                        <div class="right">
                            <a-icon @click="viewDetail" type="select" />
                            <a-icon v-if="operationAuthority.EXTERNAL_LINKS" @click="viewSharing" type="share-alt" />
                            <a-icon @click="onClose" type="close" />
                        </div>
                    </div>
                    <a-row class="row-head">
                        <a-col :span="8" class="col-left">
                            <div class="head-title">创建人：</div>
                            <div style="margin-top: 6px;">
                                <div class="head-content">{{digitalDetail.createBy}}</div>
                            </div>
                        </a-col>
                        <a-col :span="8" class="col-left">
                            <div class="head-title">创建时间：</div>
                            <div class="head-content" style="margin-top: 6px;">{{digitalDetail.createTime}}</div>
                        </a-col>
                        <a-col :span="8" class="col-left">
                            <div class="head-title">修改时间：</div>
                            <div class="head-content" style="margin-top: 6px;">{{digitalDetail.updateTime}}</div>
                        </a-col>
                    </a-row>
                </div>
                <div class="drawer-content">
                    <div class="drawer-body-content">
                        <div class="basic-info">
                            <div class="title">基本信息</div>
                            <a-row class="row-list">
                                <a-col :span="(item.type=='LONG_TEXT'||item.type=='CUSTOMER_LABEL')?24:12" class="col-list" :class="{'col-left':index%2==0,'col-right':index%2!=0, 'col-time':item.type=='TIME'||item.code=='last_dynamic_user'}" v-for="(item,index) in digitalDetail.detailList" :key='index'>
                                    <div v-if="item.type!='TIME'" class="first-content">
                                        {{item.name}}:
                                        <a-icon v-if="item.code=='phone'" type="phone" style="cursor:pointer"/>
                                    </div>
                                    
                                    <div v-if="item.type!='TIME'&&item.type!='CUSTOMER_LABEL'&&item.isEdit==false" class="second-content">
                                        {{item.value}}
                                        <a-icon @click="viewTianYan(item.value)" v-if="item.code=='corporate_name'" style="margin-right:4px;cursor:pointer" type="search" />
                                        <span v-if="item.code=='phone'">{{phoneDetails.city}}|{{phoneDetails.sp.length==0?'未知运营商':phoneDetails.sp}}</span>
                                        <a-icon @click="changeBasicEdit(index)" v-if="item.isEditable==1" class="edit-icon" style="" type="edit" />
                                    </div>

                                    <div v-if="item.type=='CUSTOMER_LABEL'" class="label-content">
                                        <a-button v-if="item.isEditable==1" @click="addLabel" icon="plus">
                                        添加标签
                                        </a-button>
                                        <span v-for="(item1,index1) in item.value" :style="{'background':item1.color}" :key='index1'>
                                            {{item1.name}}
                                            <a-icon v-if="item.isEditable==1" @click="removeDetaillTag(item1.labelId)" type="close" />
                                        </span>
                                    </div>

                                    <div v-if="item.type=='TEXT'&&item.isEdit==true" class="third-content">
                                        <a-input @blur="fieldInputBlur(item.fieldId,item.value)" :disabled='item.isEditable!=1' v-model="item.value" placeholder="请输入内容" />
                                    </div>
                                    
                                    <div class="user-content" v-if="item.type=='USER'&&item.isEditable==1&&item.isEdit==true">
                                        <a-tree-select
                                            class="selection-range"
                                            v-model="item.valueList"
                                            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                                            :tree-data="item.userTree"
                                            :placeholder="item.value"
                                            tree-default-expand-all
                                            @change="(value) => fieldInputBlur(item.fieldId, value)"
                                        >
                                        </a-tree-select>
                                    </div>
                                    <div v-if="item.type=='LONG_TEXT'&&item.isEdit==true" class="third-content">
                                        <a-textarea @blur="fieldInputBlur(item.fieldId,item.value)" :disabled='item.isEditable!=1' v-model="item.value" placeholder="请输入内容" :rows="2"/>
                                    </div>
                                </a-col>
                                <a-col :span="24">
                                    <a-upload
                                        :multiple="false"
                                        :customRequest="customRequest1"
                                        :showUploadList='false'
                                    >
                                        <a-button class="add-attachment" icon="plus"> 添加附件 </a-button>
                                    </a-upload>
                                </a-col>
                            </a-row>
                            <a-row>
                                <a-col :class="{'pd-24':index%2==0,'pd-8':index%2!=0,}" :span="12" v-for="(item,index) in attachmentList" :key='index'>
                                    <div class="attachment-item">
                                        <img :src="filterImg(item.suffix)" alt="">
                                        <div class="attachment-content">
                                            <div class="name-operation">
                                                <span :title='item.fileName'>{{item.fileName.length>16?item.fileName.substring(0,16)+'...':item.fileName}}</span>
                                                <a-icon @click="deleteFire(item.id)" type="delete" />
                                                <a-icon @click="downloadFire(item.id)" type="download" />
                                            </div>
                                            <div style="clear:both"></div>
                                            <div class="file-size">{{item.size}}</div>
                                        </div>
                                    </div>
                                </a-col>
                            </a-row>
                        </div>
                        <div class="related-clue">
                            <div class="title">
                                <span>关联线索</span>
                                <a-button @click="viewAllRelatedClueData" icon="sync">
                                同步标签
                                </a-button>
                            </div>
                            <a-row class="related-title">
                                <a-col :span="6">客户名称</a-col>
                                <a-col :span="6">负责人</a-col>
                                <a-col :span="6">最后动态时间</a-col>
                                <a-col :span="6">创建日期</a-col>
                            </a-row>
                            <a-row class="related-data" v-for="(item,index) in relatedData" :key='index'>
                                <div v-if="index<5">
                                    <a-col class="related-name" @click="refreshPage(item.id)" :span="6">{{item.name}}</a-col>
                                    <a-col class="related-charge" :span="6">{{item.owner}}</a-col>
                                    <a-col class="related-last-time" :span="6">{{item.lastDynamicTime}}</a-col>
                                    <a-col class="related-create-time" :span="6">{{item.createTime}}</a-col>
                                </div>
                            </a-row>
                        </div>
                        <div class="follow-time">
                            <div class="title">最后动态</div>
                            <a-row class="row-time">
                                <a-col :span="8">
                                    <div class="row-time-title">最后动态时间：</div>
                                    <div class="row-time-value">{{timeObj.last_dynamic_time}}</div>
                                </a-col>
                                <a-col :span="8">
                                    <div class="row-time-title">最后联系人：</div>
                                    <div class="row-time-value">{{timeObj.last_dynamic_user}}</div>
                                </a-col>
                                <a-col :span="8">
                                    <div class="row-time-title">下次联系时间：</div>
                                    <div class="row-time-value">{{timeObj.next_contact_time}}</div>
                                </a-col>
                            </a-row>
                        </div>
                        
                        <div class="dynamic-info">
                            <div class="title">
                                <span>动态信息</span>
                                <a-button @click="addTrack" icon="plus">
                                新增
                                </a-button>
                            </div>
                            <div class="filter-condition">
                                <span @click="selectDynamic('')" :class="{'active':currentActive==''}">全部{{" ("+dynamicNumData.total+")"}}</span>
                                <span @click="selectDynamic(2)" :class="{'active':currentActive==2}">跟踪轨迹{{" ("+dynamicNumData.follow+")"}}</span>
                                <span @click="selectDynamic(3)" :class="{'active':currentActive==3}">通话轨迹{{" ("+dynamicNumData.chat+")"}}</span>
                                <span @click="selectDynamic(1)" :class="{'active':currentActive==1}">数据轨迹{{" ("+dynamicNumData.data+")"}}</span>
                            </div>
                            <div v-for="(item,index) in dynamicList" :key='index'>
                                <div class="dynamic-list" v-if="item.type==currentActive||currentActive==''">
                                    <div class="date">
                                        <span>{{item.createTime.split(" ")[1]==undefined?'':item.createTime.split(" ")[0]}}</span>
                                        <span :style="{'background':item.color}"></span>
                                    </div>
                                    <div class="active-track">
                                        <div class="time">
                                            <span>{{item.operator+''}}{{item.createTime.length==19?item.createTime.split(" ")[1]:item.createTime}}</span>
                                            <div class="next-time" v-if="item.type == 2&&item.nextContactTime!=''&&item.nextContactTime!=null">
                                                <span>下次联系日期：</span>
                                                <span>{{(item.nextContactTime!=''&&item.nextContactTime!=null)?item.nextContactTime.split(" ")[0]:''}}</span>
                                            </div>
                                        </div>
                                        <div class="active-event">
                                            <span>{{item.action}}</span>
                                        </div>
                                        <div class="dynamic-content">
                                            <div style="word-wrap: break-word;word-break: break-all;" v-if="item.isEdit==false">
                                                {{item.content}}
                                                <span v-if="item.content==''&&item.type==2">请输入内容</span>
                                                <a-icon v-if="item.type==2" @click="changeDynamicData(index)" class="dynamic-icon" type="edit" />
                                            </div>
                                            <a-textarea v-if="item.isEdit==true" placeholder="请输入内容" :rows="4" :disabled='currentActive==1' @blur="contentInputBlur(item.id,item.content)" v-model="item.content"/>
                                        </div>
                                        <div v-if="item.type == 2">
                                            <div class="label-content">
                                                <a-button @click="addLabel2(item.id)" icon="plus">
                                                添加标签
                                                </a-button>
                                                <span v-for="(item1,index1) in item.labelList" :style="{'border-color':item1.color,'color':item1.color}" :key='index1'>
                                                    {{item1.name}}
                                                    <a-icon @click="removeDetaillTag2(item.id, item1.labelId)" type="close" />
                                                </span>
                                            </div>
                                        </div>
                                        <div v-if="item.type == 3" class="chat-detail">
                                            <div class="play-voice" @click="viewChatDetail(item.viewUrl)">
                                                <a-icon type="play-circle" />
                                                <span>对话详情</span>
                                            </div>
                                            <span class="voice-content">通话时长{{item.duration}}s</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="comments">
                            <Comments ref="commentChild"></Comments>
                        </div>
                    </div>
                </div>
                <div class="drawer-foot">
                    <div class="btn-group">
                        <a-button v-if="operationAuthority.DELETE" type="primary" ghost @click="deleteDrawer">删除</a-button>
                    </div>
                </div>
            </div>
        </a-drawer>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="uoloadModal" title="logo图片" footer='' width='600px'>
            <div class="upload-modal">
                <a-divider class="divider1" type="horizontal" />
                <CropperImage ref="child"></CropperImage>
                <a-divider class="divider2" type="horizontal" />
                <div class="upload-btn">
                    <a-button type="primary" style="float:right" @click="uploadImg()">确定 </a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="linkModal" title="外链分享" footer='' width='600px'>
            <div class="share-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <div class="copy-link">
                    <a-input id="copyHtml1"  :placeholder="linkUrl" />
                    <span @click="copyLink(linkUrl)">复制链接</span>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="deleteModal" footer='' width='480px'>
            <div class="delete-modal modal-public">
                <div class="sure-off-bind">
                    <a-icon type="warning" />
                    <span>您确定要删除这个部门吗？</span>
                </div>
                <div class="tip">删除后，数据无法恢复</div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group2">
                    <a-button ghost @click="cancelDelete">取消</a-button>
                    <a-button type="primary" @click="sureDelete">确认</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="newLabelModal" title="标签" footer='' width='720px'>
            <div class="label-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <a-row>
                    <a-col :span="16">
                        <div class="title">企业标签</div>
                        <div class="company-tag-list" v-for="(item,index) in companyLabelList" :key='index'>
                            <div class="type-name">{{item.name}}</div>
                            <div class="label-list">
                                <span @click="selectPersonalTag(item1.id, index, index1)" :style="{'color':item1.selected?'#fff':'','background':item1.selected?item1.color:''}" v-for="(item1,index1) in item.labelList" :key='index1'>{{item1.name}}</span>
                            </div>
                        </div>
                    </a-col>
                    <a-col :span="8">
                        <div class="title">个人标签</div>
                        <div class="new-tag">
                            <a-input v-model.trim="tagName" placeholder="输入标签名称" />
                            <a-icon @click="addTagName" type="plus-circle" />
                        </div>
                        <div class="tag-list">
                            <span @click="selectPersonalTag(item.id, -1, index)" :style="{'color':item.selected?'#fff':'','background':item.selected?item.color:''}" v-for="(item,index) in personalLabelList" :key='index'>
                                {{item.name}}
                                <a-icon @click="removePersonalTag(item.id)" type="close" />
                            </span>
                        </div>
                    </a-col>
                </a-row>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group">
                    <a-button type="primary" ghost @click="cancelAddTag">取消</a-button>
                    <a-button type="primary" @click="sureAddTag">确定</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="trackModal" footer='' width='480px'>
            <div class="delete-modal modal-public">
                <div class="sure-off-bind">
                    <span style="margin-left:0px">预设下次联系时间</span>
                </div>
                <div class="tip" style="padding-left:0px;">
                    <a-date-picker format="YYYY-MM-DD HH:mm:ss" v-model="currentDynamicTime"/>
                </div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group2">
                    <a-button ghost @click="offAddTrack">取消</a-button>
                    <a-button type="primary" @click="sureAddTrack">确认</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="newLabelModal2" title="标签" footer='' width='720px'>
            <div class="label-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <div>
                    <div class="new-tag">
                        <a-input v-model.trim="tagName2" placeholder="输入标签名称" />
                        <a-icon @click="addTagName2" type="plus-circle" />
                    </div>
                    <div class="tag-list">
                        <span @click="selectPersonalTag2(item.id, index)" :style="{'color':item.selected?'#fff':'','background':item.selected?item.color:''}" v-for="(item,index) in personalLabelList2" :key='index'>
                            {{item.name}}
                            <a-icon @click="removePersonalTag2(item.id)" type="close" />
                        </span>
                    </div>
                </div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group">
                    <a-button type="primary" ghost @click="cancelAddTag2">取消</a-button>
                    <a-button type="primary" @click="sureAddTag2">确定</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="deleteTagModal" footer='' width='480px'>
            <div class="delete-modal modal-public">
                <div class="sure-off-bind">
                    <a-icon type="warning" />
                    <span>您确定要删除这个标签吗？</span>
                </div>
                <div class="tip">删除后，数据无法恢复</div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group2">
                    <a-button ghost @click="cancelTagDelete">取消</a-button>
                    <a-button type="primary" @click="sureTagDelete">确认</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="deleteTagModal2" footer='' width='480px'>
            <div class="delete-modal modal-public">
                <div class="sure-off-bind">
                    <a-icon type="warning" />
                    <span>您确定要删除这个标签吗？</span>
                </div>
                <div class="tip">删除后，数据无法恢复</div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group2">
                    <a-button ghost @click="cancelTagDelete2">取消</a-button>
                    <a-button type="primary" @click="sureTagDelete2">确认</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="relatedClueModal" title="关联线索" footer='' width='720px'>
            <div class="related-clue-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <div>
                    <a-table
                        :row-selection="{ selectedRowKeys: selectedRowKeys2, onChange: onSelectChange2 }"
                        :columns="columns2"
                        :data-source="relatedData"
                        :pagination='false'
                        :scroll="{ x: 'max-content' }"
                    >
                        
                    </a-table>
                </div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group">
                    <a-button type="primary" ghost @click="cancelRelatedClue">取消</a-button>
                    <a-button type="primary" @click="sureRelatedClue">同步</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="deleteAttachmentModal" footer='' width='480px'>
            <div class="delete-modal modal-public">
                <div class="sure-off-bind">
                    <a-icon type="warning" />
                    <span>您确定要删除这个附件吗？</span>
                </div>
                <div class="tip">删除后，数据无法恢复</div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group2">
                    <a-button ghost @click="cancelDeleteAttachment">取消</a-button>
                    <a-button type="primary" @click="sureDeleteAttachment">确认</a-button>
                </div>
            </div>
        </a-modal>
    </div>
</template>
<script>
    import moment from 'moment';
    import Comments from "../../components/Comments";
    export default {
        name: 'CustomerDynamics',
        components: { Comments },
        data() {
            return {
                currentTab: 1,
                currentDate: 1,
                dateShow: false,
                conditionObj: {
                    username: '',
                    personInCharge: '',
                    dateRange: ''
                },
                currentDateType: '',
                filterContent: '',
                currentDateContent: '',
                startDate: '',
                endDate: '',
                currentSelectedDate: '',
                expandedKeys2: [],
                peopleTree: [],
                autoExpandParent2: true,
                deptAndUserMap: '',
                dataList2: [],
                personSelectedId: '',
                personCharge: undefined,
                rankList: [],
                trackDynamicList: [],
                current: 1,
                customerId: '',
                trackDynamicListData: [],
                companyData: '',
                moduleId: '',
                userList: [],
                loadContinue: true,
                digitalDetail: {
                    dataTitle: ''
                },
                currentId: '',
                drawerVisible: false,
                timeList: [],
                weekList: [],
                taskName: undefined,
                taskDes: undefined,
                chargePerson: undefined,
                operationAuthority: '',
                authorityFieldMap: {
                    name: '',
                    digital_employee_id: '',
                    description: ''
                },
                linkModal: false,
                linkUrl: '',
                deleteModal: false,
                addInfoData: {
                    name: '',
                    company: '',
                    phone: '',
                    wx: '',
                    email: '',
                    extra: '',
                },
                personalInfo: '',
                newLabelModal: false,
                companyLabelList: [],
                tagName: '',
                colorList: ['#256fe7', '#e54444', '#5dc7ff', '#ffaf00', '#23da77', '#a680ff'],
                personalLabelList: [],
                selectedLabelList: [],
                timeObj: {
                    last_dynamic_time: '',
                    next_contact_time: '',
                    last_dynamic_user: ''
                },
                relatedData: [],
                currentActive: 2,
                dynamicList: [],
                trackModal: false,
                newLabelModal2: false,
                selectedLabelList2: [],
                personalLabelList2: [],
                tagName2: '',
                orderActive: false,
                screenActive: false,
                sortFieldListData: [],
                sortFieldObj: {
                    sortField: '',
                    sortMode: ''
                },
                currentDynamicTime: null,
                dynamicNumData: {
                    tatal: 0,
                    follow: 0,
                    data: 0,
                    chat: 0
                },
                currentDeleteId: '',
                deleteTagModal: false,
                deleteTagModal2: false,
                currentDeleteId2: '',
                relatedClueModal: false,
                selectedRowKeys2: [],
                columns2: [
                     {
                        dataIndex: 'id',
                        key: 'id',
                        title: 'id',
                        colSpan: 0,
                        customRender: () => {
                            return {
                                attrs: {
                                    colSpan: 0,
                                },
                            };
                        },
                    },
                    {
                        dataIndex: 'name',
                        scopedSlots: {
                            customRender: 'name'
                        },
                        key: 'name',
                        title: '客户名称'
                    },
                    {
                        dataIndex: 'owner',
                        scopedSlots: {
                            customRender: 'owner'
                        },
                        key: 'owner',
                        title: '负责人'
                    },
                    {
                        dataIndex: 'lastDynamicTime',
                        scopedSlots: {
                            customRender: 'lastDynamicTime'
                        },
                        key: 'lastDynamicTime',
                        title: '最后动态时间'
                    },
                    {
                        dataIndex: 'createTime',
                        scopedSlots: {
                            customRender: 'createTime'
                        },
                        key: 'createTime',
                        title: '创建日期'
                    },
                   
                ],
                phoneDetails: {
                    city: '',
                    sp: ''
                },
                loading: false,
                newFormData1: '',
                attachmentList: [],
                currentFireId: '',
                deleteAttachmentModal: false,
                uoloadModal: false,
                imgId: '',
            }
        },
        methods: {
            moment,
            chooseTab(type) {
                this.currentTab = type
                this.queryRankListData()
            },
            chooseDate(type) {
                this.currentDate = type
                this.queryRankListData()
            },
            onSearch() {
                this.conditionObj.username = this.filterContent
                this.current = 1
                this.queryTrackDynamicData('init')
            },
            showDateBox() {
                event.stopPropagation();
                this.dateShow = !this.dateShow
            },
            sureSelectDate() {
                this.dateShow = false
                if(this.startDate != ''&&this.endDate != ''){
                    this.currentSelectedDate = ''
                    this.conditionObj.dateRange = this.startDate + '' + this.endDate
                    this.current = 1
                    this.queryTrackDynamicData('init')
                }
            },
            goBack() {
                this.$router.push("/customerLead")
            },
            stopEvent() {
                event.stopPropagation(); 
            },
            startDateChange() {
                event.stopPropagation(); 
            },
            endDateChange() {
                event.stopPropagation(); 
            },
            selectDate(type, content) {
                this.dateShow = false
                this.currentDateType = type
                this.currentDateContent = content
                this.conditionObj.dateRange = content
                this.currentSelectedDate = type
                this.startDate = ''
                this.endDate = ''
                this.current = 1
                this.queryTrackDynamicData('init')
            },
            deleteUsername() {
                this.conditionObj.username = ''
                this.filterContent = ''
                this.current = 1
                this.queryTrackDynamicData('init')
            },
            deletePersonInCharge() {
                this.conditionObj.personInCharge = ''
                this.personCharge = undefined
                this.personSelectedId = ''
                this.current = 1
                this.queryTrackDynamicData('init')
            },
            deleteDateRange() {
                this.conditionObj.dateRange = ''
                this.currentSelectedDate = ''
                this.startDate = ''
                this.endDate = ''
                this.current = 1
                this.queryTrackDynamicData('init')
            },
            onExpand2(expandedKeys) {
                this.expandedKeys2 = expandedKeys;
                this.autoExpandParent2 = false; 
            },
            onSelect(selectedKeys) {
                if(selectedKeys.length > 0){
                    if(this.deptAndUserMap[selectedKeys]){
                        this.personSelectedId = selectedKeys
                        this.conditionObj.personInCharge = this.deptAndUserMap[selectedKeys]
                        this.current = 1
                        this.queryTrackDynamicData('init')
                    }else{
                        this.$message.error('请重新选择用户');
                    }
                }
            },
            queryDeptAndUserMap() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/user/userMap', 
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.deptAndUserMap = response.data.data
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            getParentData(passDataObj){
                setTimeout(()=>{
                    this.peopleTree = passDataObj.peopleTree
                    this.generateList2(this.peopleTree)
                })
            },
            generateList2(data) {
                for (let i = 0; i < data.length; i++) {
                    const node = data[i];
                    const key = node.key;
                    const title = node.title
                    this.dataList2.push({ key, title: title });
                    if (node.children) {
                        this.generateList2(node.children);
                    }
                }
            },
            queryUserTree() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/user/tree', 
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        let obj = {}
                        obj.peopleTree = response.data.data
                        obj.personSelected = []
                        this.getParentData(obj)
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            queryRankListData() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/customer/rankList', 
                        params: {
                            type: this.currentTab,
                            time: this.currentDate
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.rankList = response.data.data
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            queryTrackDynamicData(index) {
                this.axios(
                    {
                        method:'get',
                        url:'/api/customer/trackDynamic/list', 
                        params: {
                            current: this.current,
                            size: 10,
                            name: this.filterContent,
                            owner: this.personSelectedId,
                            time: this.currentSelectedDate,
                            startTime: this.startDate,
                            endTime: this.endDate,
                            customerId: this.customerId,
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        if(index != 'init'){
                            let num = index%10
                            this.trackDynamicListData[index] = response.data.data.records[num]
                        }else{
                            console.log('总条数：'+response.data.data.total)
                            console.log((response.data.data.current - 1)*10 + response.data.data.records.length)
                            if((response.data.data.current - 1)*10 + response.data.data.records.length == response.data.data.total) {
                                this.loadContinue = false
                            }else{
                                this.loadContinue = true
                            }
                            if(this.current == 1){
                                this.trackDynamicListData = response.data.data.records
                            }else{
                                this.trackDynamicListData = this.trackDynamicListData.concat(response.data.data.records)
                            }
                        }
                        for(let i = 0; i < this.trackDynamicListData.length; i++){
                            this.trackDynamicListData[i]['flag'] = false
                            this.trackDynamicListData[i]['commentBool'] = false
                            this.trackDynamicListData[i]['commentContent'] = ''
                            this.trackDynamicListData[i]['userBool'] = false
                            this.trackDynamicListData[i]['selectedUserList'] = []
                            for(let j = 0; j < this.trackDynamicListData[i]['commentTree'].length; j++){
                                this.trackDynamicListData[i]['commentTree'][j]['commentChildrenBool'] = false
                                this.trackDynamicListData[i]['commentTree'][j]['commentChildrenContent'] = ''
                                this.trackDynamicListData[i]['commentTree'][j]['userChildrenBool'] = false
                                this.trackDynamicListData[i]['commentTree'][j]['selectedUserList'] = []
                            }
                        }
                        this.$forceUpdate()
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            viewComDetails(id) {
                event.stopPropagation(); /*
                for(let i = 0; i < this.trackDynamicListData.length; i++){
                    if(i == index){
                        this.trackDynamicListData[index]['flag'] = true
                    }else{
                        this.trackDynamicListData[i]['flag'] = false
                    }
                }
                */
                this.currentId = id
                // this.queryCompanyDetail(id)
                this.$forceUpdate()
                this.drawerVisible = true;
                this.querySelectData()
                this.queryRelatedData()
                this.queryDynamicData()
                this.queryAttachmentData()
            },
            dynamicsBoxClick() {
                event.stopPropagation(); 
            },
            queryCompanyDetail(id) {
                this.axios(
                    {
                        method:'get',
                        url:'/api/customer/customerInfo', 
                        params: {
                            customerId: id,
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.companyData = response.data.data
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            showCommentFarther(index) {
                for(let i = 0; i < this.trackDynamicListData.length; i++){
                    this.trackDynamicListData[i]['userBool'] = false
                    if(i == index){
                        this.trackDynamicListData[i]['commentBool'] = true
                    }else{
                        this.trackDynamicListData[i]['commentBool'] = false
                    }
                    for(let j = 0; j < this.trackDynamicListData[i]['commentTree'].length; j++){
                        this.trackDynamicListData[i]['commentTree'][j]['commentChildrenBool'] = false
                        this.trackDynamicListData[i]['commentTree'][j]['userChildrenBool'] = false
                    }
                }
                this.$forceUpdate()
            },
            showCommentChildren(index, index2) {
                for(let i = 0; i < this.trackDynamicListData.length; i++){
                    this.trackDynamicListData[i]['commentBool'] = false
                    this.trackDynamicListData[i]['userBool'] = false
                    for(let j = 0; j < this.trackDynamicListData[i]['commentTree'].length; j++){
                        this.trackDynamicListData[i]['commentTree'][j]['userChildrenBool'] = false
                        if(index == i&& index2 == j){
                            this.trackDynamicListData[index]['commentTree'][index2]['commentChildrenBool'] = true
                        }else{
                            this.trackDynamicListData[i]['commentTree'][j]['commentChildrenBool'] = false
                        }
                    }
                }
                this.$forceUpdate()
            },
            cancelFatherComment(index) {
                this.trackDynamicListData[index]['commentBool'] = false
                this.trackDynamicListData[index]['selectedUserList'] = []
                this.$forceUpdate()
            },
            cancelChildrenComment(index, index2) {
                this.trackDynamicListData[index]['commentTree'][index2]['commentChildrenBool'] = false
                this.trackDynamicListData[index]['commentTree'][index2]['selectedUserList'] = []
                this.$forceUpdate()
            },
            submitComment(dataId, isRoot, replyCommentId, index, index2) {
                let content = ''
                let container = document.getElementsByClassName("textarea-comment");
                content = container[0].innerText
                let arr = []
                if(isRoot == 1){
                    this.trackDynamicListData[index]['userBool'] = false
                    for(let i = 0; i < this.trackDynamicListData[index]['selectedUserList'].length; i++){
                        arr.push(this.trackDynamicListData[index]['selectedUserList'][i]['value'])
                    }
                }else{
                    this.trackDynamicListData[index]['commentTree'][index2]['userChildrenBool'] = false
                    for(let i = 0; i < this.trackDynamicListData[index]['commentTree'][index2]['selectedUserList'].length; i++){
                        arr.push(this.trackDynamicListData[index]['commentTree'][index2]['selectedUserList'][i]['value'])
                    }
                }
                this.axios.post('/api/comment', {
                    moduleId: this.moduleId,
                    type: 2,
                    dataId: dataId,
                    content: content,
                    isRoot: isRoot,
                    replyCommentId: replyCommentId,
                    designeeList: arr
                })
                .then(response=> {
                    if(response.data.code == 0){
                        if(index2 != ''){
                            this.trackDynamicListData[index]['commentTree'][index2]['commentChildrenBool'] = false
                        }else{
                            this.trackDynamicListData[index]['commentBool'] = false
                        }
                        this.current = Math.ceil(index/10)
                        this.queryTrackDynamicData(index)
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            queryUserList() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/user/list', 
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.userList = response.data.data
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            chooseUser1(item, index) {
                this.trackDynamicListData[index]['userBool'] = false
                event.stopPropagation(); 
                let flag = true
                for(let i = 0; i < this.trackDynamicListData[index]['selectedUserList'].length; i++){
                    if(this.trackDynamicListData[index]['selectedUserList'][i]['value'] == item.value){
                        flag = false
                    }
                }
                if(flag){
                    this.trackDynamicListData[index]['selectedUserList'].push(item)
                }
                this.$forceUpdate()
                // let container = document.getElementsByClassName("textarea-comment");
                // container[0].innerHTML = (container[0].innerHTML) + '<span class="comment-user1" value='+item.value+' style="color:#1890FF" contenteditable=false>@'+item.name+'</span>';
            },
            chooseUser2(item, index, index2) {
                this.trackDynamicListData[index]['commentTree'][index2]['userChildrenBool'] = false
                // let container = document.getElementsByClassName("textarea-comment");
                // container[0].innerHTML = (container[0].innerHTML) + '<span class="comment-user2" value='+item.value+' style="color:#1890FF" contenteditable=false>@'+item.name+'</span>';
                event.stopPropagation(); 
                let flag = true
                for(let i = 0; i < this.trackDynamicListData[index]['commentTree'][index2]['selectedUserList'].length; i++){
                    if(this.trackDynamicListData[index]['commentTree'][index2]['selectedUserList'][i]['value'] == item.value){
                        flag = false
                    }
                }
                if(flag){
                    this.trackDynamicListData[index]['commentTree'][index2]['selectedUserList'].push(item)
                }
                this.$forceUpdate()
            },
            showUserListBox(index, index2) {
                event.stopPropagation(); 
                if(index2 != 'no') {
                    this.trackDynamicListData[index]['commentTree'][index2]['userChildrenBool'] = true
                }else{
                    this.trackDynamicListData[index]['userBool'] = true
                }
                this.$forceUpdate()
            },
            removeUserList(index, index5) {
                this.trackDynamicListData[index]['selectedUserList'].splice(index5,1)
                this.$forceUpdate()
            },
            removeUserList2(index, index2, index6) {
                this.trackDynamicListData[index]['commentTree'][index2]['selectedUserList'].splice(index6,1)
                this.$forceUpdate()
            },
            handleScroll() {
                let scrollTop = document.getElementById("read").scrollTop;
                let offsetHeight = document.getElementById("read").offsetHeight
                let scrollHeight =  document.getElementById("read").scrollHeight
                console.log('总高度：'+scrollHeight)
                console.log(scrollTop + offsetHeight)
                if(Math.abs(scrollTop + offsetHeight - scrollHeight) < 1 || Math.abs(scrollTop + offsetHeight - scrollHeight) == 1){
                    if(this.loadContinue){
                        this.current ++
                        this.queryTrackDynamicData('init')
                    }
                }
            },
            querySelectData() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/module/data/detail', 
                        params: {
                            moduleId: this.moduleId,
                            dataId: this.currentId
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.digitalDetail = response.data.data
                        let arr = response.data.data.detailList
                        for(let i = 0;i < arr.length; i++){
                            if(arr[i]['code'] == 'phone'){
                                this.queryPhoneDetail(arr[i]['value'])
                            }
                            if(arr[i]['code'] == 'last_dynamic_time'){
                                this.timeObj.last_dynamic_time = arr[i]['value']
                            }
                            if(arr[i]['code'] == 'next_contact_time'){
                                this.timeObj.next_contact_time = arr[i]['value']
                            }
                            if(arr[i]['code'] == 'last_dynamic_user'){
                                this.timeObj.last_dynamic_user = arr[i]['value']
                            }
                        }
                        this.$refs.commentChild.getParentCommentTree(this.moduleId, this.currentId, true)
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            changeBasicEdit(index) {
                this.digitalDetail.detailList[index]['isEdit'] = true
            },
            changeDynamicData(index) {
                this.dynamicList[index]['isEdit'] = true
            },
            viewAllRelatedClueData() {
                this.relatedClueModal = true
            },
            cancelRelatedClue() {
                this.relatedClueModal = false
            },
            sureRelatedClue() {
                if(this.selectedRowKeys2.length == 0){
                    this.$message.error('请先选择关联线索')
                }else{
                    this.relatedClueModal = false
                    this.axios.post('/api/customer/syncCustomerLabel', {
                        customerId: this.currentId,
                        associatedCustomerIdList: this.selectedRowKeys2,
                    })
                    .then(response=> {
                        if(response.data.code == 0){
                            this.selectedRowKeys2 = []
                            this.querySelectData()
                            this.$message.success('同步完成')
                        }else{
                            this.$message.error(response.data.userTip);
                            if(response.data.code == 'A0200'){
                                this.$router.push("/login")
                            }
                        }
                    })
                    .catch(error=> {
                        console.log(error);
                    });
                }
                
            },
            onSelectChange2(selectedRowKeys) {
                this.selectedRowKeys2 = selectedRowKeys;
            },
            viewTianYan(value) {
                window.open("https://www.tianyancha.com/search?key="+value, "_blank");
            },
            queryPhoneDetail(value) {
                this.axios(
                    {
                        method:'get',
                        url:"/api/customer/qCellCore", 
                        params: {
                            phone: value,
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        if(response.data.data.data.city==''&&response.data.data.data.province==''){
                            this.phoneDetails.city = '未知号码归属地'
                        }else if(response.data.data.data.city==''&&response.data.data.data.province!=''){
                            this.phoneDetails.city = response.data.data.data.province
                        }else{
                            this.phoneDetails.city = response.data.data.data.city
                        }
                        this.phoneDetails.sp = response.data.data.data.sp
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            goCustomerDynamics() {
                this.$router.push("/customerDynamics")
            },
            onClose() {
                this.drawerVisible = false
                this.imgId = ''
                this.currentSelect = ''
            },
            deleteDrawer() {
                this.drawerVisible = false
                this.deleteModal = true
            },
            sureDelete() {
                this.deleteModal = false
                this.axios.post('/api/module/data/delete/batch', {
                    moduleId: this.moduleId,
                    idList: [this.currentId],
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.$message.success('删除成功')
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            cancelDelete() {
                this.deleteModal = false
            },
            submitDrawer() {
                this.addTask()
                this.drawerAddVisiable = false
            },
            shareSet() {
                this.$router.push({
                    path: "/PublicMenu/share",
                    query: {
                        moduleId: this.moduleId,
                    }
                })
            },
            pushSet() {
                this.$router.push({
                    path: "/PublicMenu/push",
                    query: {
                        moduleId: 8
                    }
                })
            },
            viewDetail1(id) {
                let routeUrl = this.$router.resolve({
                    path: "/customerLeadDetail",
                    query: {
                        id: id,
                        moduleId: this.moduleId
                    }
                });
                window.open(routeUrl.href, '_blank');
            },
            viewDetail() {
                let routeUrl = this.$router.resolve({
                    path: "/customerLeadDetail",
                    query: {
                        id: this.currentId,
                        moduleId: this.moduleId,
                    }
                });
                window.open(routeUrl.href, '_blank');
            },
            viewSharing() {
                event.stopPropagation();
                this.linkModal = true
                let shareId = ''
                this.axios.post('/api/externalLinks',{
                    moduleId: this.moduleId,
                    dataId: this.currentId
                })
                .then(response=> {
                    if(response.data.code == 0){
                        shareId = response.data.data.id
                        let url1 = window.location.origin
                        this.linkUrl = url1+'/#/customerLeadShare?id='+shareId+'&moduleId='+this.moduleId
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            customRequest1(data) {
                this.newFormData1 = ''
                const formData = new FormData() 
                formData.append('file', data.file)
                this.newFormData1 = formData
                this.axios({
                    method: 'post',
                    url: '/api/file/upload',
                    headers:{
                        'Content-Type':'application/x-www-form-urlencoded'
                    },
                    data: this.newFormData1,
                
                }).then((response) => {
                    if(response.data.code == 0){
                        this.newFormData1 = ''
                        this.addAtachment(response.data.data.fileId)
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                }).catch(function (error) {
                    console.log(error)
                })
            },
            addAtachment(attachment) {
                this.axios.post('/api/customer/attachment', {
                    customerId: this.currentId,
                    attachment: attachment,
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.queryAttachmentData()
                        this.queryDynamicData()
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            addTrack() {
                this.currentDynamicTime = this.timeObj.next_contact_time==''?null:this.timeObj.next_contact_time
                this.trackModal = true
            },
            transformTimestamp(timestamp) {
                let a = new Date(timestamp).getTime();
                const date = new Date(a);
                const Y = date.getFullYear() + '-';
                const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
                const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
                const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
                const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
                const s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
                const dateString = Y + M + D + h + m + s;
                return dateString;
            },
            sureAddTrack() {
                this.axios.post('/api/customer/dynamic', {
                    customerId: this.currentId,
                    nextContactTime: this.currentDynamicTime!=null?this.transformTimestamp(this.currentDynamicTime):''
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.trackModal = false
                        this.queryDynamicData()
                        this.querySelectData()
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            offAddTrack() {
                this.trackModal = false
            },
            queryPersonalTag() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/customer/personalLabel/list', 
                        params: {
                            customerId: this.currentId
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.personalLabelList = response.data.data
                        for(let i = 0; i < response.data.data.length; i++){
                            if(response.data.data[i]['selected']){
                                this.selectedLabelList.push(response.data.data[i]['id'])
                            }
                        }
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            queryPersonalTag2() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/customer/dynamicLabel/list', 
                        params: {
                            dynamicId: this.dynamicId
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.personalLabelList2 = response.data.data
                        for(let i = 0; i < response.data.data.length; i++){
                            if(response.data.data[i]['selected']){
                                this.selectedLabelList2.push(response.data.data[i]['id'])
                            }
                        }
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            addLabel() {
                this.newLabelModal = true
                this.selectedLabelList = []
                this.queryCompanyLabelData()
                this.queryPersonalTag()
            },
            addLabel2(id) {
                this.newLabelModal2 = true
                this.selectedLabelList2 = []
                this.dynamicId = id
                this.queryPersonalTag2()
            },
            selectPersonalTag(id, index1, index2) {
                let index = this.selectedLabelList.indexOf(id);
                if(index == -1){
                    this.selectedLabelList.push(id)
                    if(index1 == -1){
                        this.personalLabelList[index2]['selected'] = true
                    }else{
                        this.companyLabelList[index1]['labelList'][index2]['selected'] = true
                    }
                }else{
                    this.selectedLabelList = this.selectedLabelList.filter(item => item != id)
                    if(index1 == -1){
                        this.personalLabelList[index2]['selected'] = false
                    }else{
                        this.companyLabelList[index1]['labelList'][index2]['selected'] = false
                    }
                }
            },
            cancelAddTag() {
                this.newLabelModal = false
                this.selectedLabelList = []
            },
            sureAddTag() {
                this.newLabelModal = false
                this.axios.post('/api/customer/setCustomerLabel', {
                    customerId: this.currentId,
                    labelIdList: this.selectedLabelList,
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.querySelectData()
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            selectPersonalTag2(id, index2) {
                let index = this.selectedLabelList2.indexOf(id);
                if(index == -1){
                    this.selectedLabelList2.push(id)
                    this.personalLabelList2[index2]['selected'] = true
                }else{
                    this.selectedLabelList2 = this.selectedLabelList2.filter(item => item != id)
                    this.personalLabelList2[index2]['selected'] = false
                }
            },
            addTagName2() {
                if(this.tagName2.length == 0){
                    this.$message.error('请输入标签名称')
                }else{
                    this.axios.post('/api/customer/dynamicLabel', {
                        name: this.tagName2,
                        color: this.randomColor(),
                    })
                    .then(response=> {
                        if(response.data.code == 0){
                            this.selectedLabelList2 = []
                            this.queryPersonalTag2()
                            this.tagName2 = ''
                        }else{
                            this.$message.error(response.data.userTip);
                            if(response.data.code == 'A0200'){
                                this.$router.push("/login")
                            }
                        }
                    })
                    .catch(error=> {
                        console.log(error);
                    });
                }
            },
            cancelTagDelete() {
                this.deleteTagModal = false
            },
            sureTagDelete() {
                this.deleteTagModal = false
                this.axios.delete('/api/customer/dynamicLabel',{
                    params: {
                        id: this.currentDeleteId
                    }
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.selectedLabelList2 = []
                        this.queryPersonalTag2()
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            removePersonalTag2(id) {
                event.stopPropagation()
                this.deleteTagModal = true
                this.currentDeleteId = id
            },
            cancelAddTag2() {
                this.newLabelModal2 = false
                this.selectedLabelList2 = []
            },
            sureAddTag2() {
                this.newLabelModal2 = false
                this.axios.post('/api/customer/setDynamicLabel', {
                    dynamicId: this.dynamicId,
                    labelIdList: this.selectedLabelList2,
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.queryDynamicData()
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            removeDetaillTag(id) {
                event.stopPropagation()
                this.axios.delete('/api/customer/deleteCustomerLabel',{
                    params: {
                        customerId: this.currentId,
                        labelId: id
                    }
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.querySelectData()
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            removeDetaillTag2(dynamicId, id) {
                event.stopPropagation()
                this.axios.delete('/api/customer/deleteDynamicLabel',{
                    params: {
                        labelId: id,
                        dynamicId: dynamicId
                    }
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.queryDynamicData()
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            addTagName() {
                if(this.tagName.length == 0){
                    this.$message.error('请输入标签名称')
                }else{
                    this.axios.post('/api/customer/personalLabel', {
                        name: this.tagName,
                        color: this.randomColor(),
                    })
                    .then(response=> {
                        if(response.data.code == 0){
                            this.selectedLabelList = []
                            this.queryCompanyLabelData()
                            this.queryPersonalTag()
                            this.tagName = ''
                        }else{
                            this.$message.error(response.data.userTip);
                            if(response.data.code == 'A0200'){
                                this.$router.push("/login")
                            }
                        }
                    })
                    .catch(error=> {
                        console.log(error);
                    });
                }
            },
            randomColor() {
                let num = Math.floor(Math.random()*6)
                let color = this.colorList[num]
                return color
            },
            cancelTagDelete2() {
                this.deleteTagModal2 = false
            },
            sureTagDelete2() {
                this.deleteTagModal2 = false
                this.axios.delete('/api/customer/personalLabel',{
                    params: {
                        id: this.currentDeleteId2
                    }
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.selectedLabelList = []
                        this.queryCompanyLabelData()
                        this.queryPersonalTag()
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            cancelDeleteAttachment() {
                this.deleteAttachmentModal = false
            },
            sureDeleteAttachment() {
                this.deleteAttachmentModal = false
                this.axios.delete('/api/customer/attachment',{
                    params: {
                        id: this.currentFireId,
                    }
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.$message.success('删除成功')
                        this.queryAttachmentData()
                        this.queryDynamicData()
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            filterImg(type) {
                let url = "http://eocc.oss-cn-beijing.aliyuncs.com/icon/"
                let suffix = type.toLowerCase();
                if (suffix == "xls" || suffix == "xlsx") {
                    url+="excel.png";
                } else if (suffix == "doc" || suffix == "docx") {
                    url+="word.png";
                } else if (suffix == "zip" || suffix == "rar") {
                    url+="zip.png";
                } else if (suffix == "ppt") {
                    url+="ppt.png";
                } else if (suffix == "pptx") {
                    url+="ppt.png";
                } else if (suffix == "pdf") {
                    url+="pdf.png";
                } else if (suffix == "txt") {
                    url+="txt.png";
                } else {
                    url+="unknown.png";
                }
                return url
            },
            viewChatDetail(url) {
                if(url == ''){
                    this.$message.warning('该对话未接通')
                }else{
                    window.open(url, '_blank');
                }
            },
            refreshPage(id) {
                this.drawerVisible = false
                this.currentId = id
                this.querySelectData()
                this.queryRelatedData()
                this.queryDynamicData()
                setTimeout(()=>{
                    this.drawerVisible = true
                },500)
            },
            fieldInputBlur(id, value) {
                this.axios.post('/api/customer/updateCustomer', {
                    customerId: this.currentId,
                    fieldId: id,
                    value: value,
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.querySelectData()
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            queryCompanyLabelData() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/customer/enterpriseLabel/list', 
                        params: {
                            customerId: this.currentId
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.companyLabelList = response.data.data
                        for(let i = 0; i < response.data.data.length; i++){
                            for(let j = 0; j < response.data.data[i]['labelList'].length; j++){
                                if(response.data.data[i]['labelList'][j]['selected']){
                                    this.selectedLabelList.push(response.data.data[i]['labelList'][j]['id'])
                                }
                            }
                        }
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            queryAttachmentData() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/customer/attachmentList', 
                        params: {
                            customerId: this.currentId,
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.attachmentList = response.data.data
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            downloadFire(id) {
                window.open('/api/customer/downloadAttachment?id='+id, '_blank');
            },
            deleteFire(id) {
                this.currentFireId = id
                this.deleteAttachmentModal = true
            },
            queryRelatedData() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/customer/associatedCustomerList', 
                        params: {
                            customerId: this.currentId
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.relatedData = response.data.data
                        for(let i = 0; i < this.relatedData.length; i++){
                            this.relatedData[i]['key'] = this.relatedData[i]['id']
                        }
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            queryDynamicData() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/customer/dynamic/list', 
                        params: {
                            customerId: this.currentId,
                            type: ''
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        let arr = response.data.data
                        let brr = []
                        this.dynamicNumData = {
                            total: arr.length,
                            follow: 0,
                            data: 0,
                            chat: 0
                        }
                        for(let i = 0; i < arr.length; i++){
                            if(arr[i]['type'] == 1){
                               this.dynamicNumData.data++ 
                            }else if(arr[i]['type'] == 2){
                                this.dynamicNumData.follow++ 
                            }else if(arr[i]['type'] == 3){
                                this.dynamicNumData.chat++ 
                            }

                            if(arr[i]['type'] == this.currentActive||this.currentActive==''){
                                if(brr.indexOf(arr[i]['createTime'].split(" ")[0])==-1){
                                    brr.push(arr[i]['createTime'].split(" ")[0])
                                }else{
                                    arr[i]['createTime'] = arr[i]['createTime'].split(" ")[1]
                                }
                            }
                        }
                        this.dynamicList = arr
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            selectDynamic(type) {
                this.currentActive = type
                this.queryDynamicData()
            },
            refreshDataList(id) {
                this.filterContent = id
                this.onSearch()
            }
        },
        mounted: function() {
            document.onclick = ()=> {
                this.dateShow = false
                for(let i = 0; i < this.trackDynamicListData.length; i++){
                    this.trackDynamicListData[i]['flag'] = false
                    this.trackDynamicListData[i]['userBool'] = false
                    for(let j = 0; j < this.trackDynamicListData[i]['commentTree'].length; j++){
                        this.trackDynamicListData[i]['commentTree'][j]['userChildrenBool'] = false
                    }
                }
                this.$forceUpdate()
            }
            this.moduleId = 8
            this.queryUserTree()
            this.queryDeptAndUserMap()
            this.queryRankListData()
            this.queryTrackDynamicData('init')
            this.queryUserList()
            window.addEventListener("scroll", this.handleScroll, true); 
        },
        destroyed(){
            window.removeEventListener('scroll', this.handleScroll, true);
        },
        
    }
</script>

<style scoped lang="less">
    .customer-dynamics{
        height: 100%;
        padding: 64px 24px 24px 16px;
        display: flex;
        position: relative;
        .customer-title{
            position: absolute;
            left: 0;
            top: 0;
            height: 48px;
            background: #fff;
            padding: 8px 24px;
            width: 100%;
            border-left: 1px solid rgba(0, 0, 0, 0.06);
            .title{
                color: rgba(18, 18, 18, 1);
                font-size: 16px;
                float: left;
                margin-top: 4px;
            }
            .divider{
                height: 18px;
                margin-top: 8px;
                margin-left: 16px;
            }
            .btn-group{
                float: right;
                i{
                    color: rgba(204, 204, 204, 1);
                    font-size: 18px;
                    margin-right: 8px;
                    margin-top: 8px;
                    float: left;
                    cursor: pointer;
                }
                button{
                    color: rgba(121, 136, 155, 1);
                    font-size: 14px;
                    height: 32px;
                    border-color: rgba(121, 136, 155, 1);
                }
            }
        }
        .dynamics-content{
            flex: 1;
            height: 100%;
            overflow-y: auto;
            .dynamics-list{
                min-height: 168px;
                background: #fff;
                margin-bottom: 16px;
                box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
                border-radius: 4px;
                padding: 15px 24px 12px;
                display: flex;
                .dynamics-head{
                    width: 40px;
                    margin-right: 10px;
                    height: 40px;
                }
                .dynamics-head1{
                    width: 40px;
                    margin-right: 10px;
                    height: 40px;
                    background: rgba(121, 72, 234, 0.6);
                    color: #fff;
                    text-align: center;
                    line-height: 40px;
                    border-radius: 20px;
                    font-size: 16px;
                }
                .list-content{
                    flex: 1;
                    position: relative;
                    .item-name{
                        .name1{
                            color: rgba(18, 18, 18, 1);
                            font-size: 14px;
                        }
                        .name2{
                            position: relative;
                            .name3{
                                color: rgba(153, 153, 153, 1);
                                font-size: 12px;
                                float: right;
                                cursor: pointer;
                                display: inline-block;
                            }
                            .name3:hover{
                                color: #2a82e4;
                            }
                            .detail-box{
                                display:none;
                                position: absolute;
                                z-index: 1;
                                right: 0px;
                                top: 15px;
                                width: 480px;
                                min-height: 295px;
                                box-shadow: 0px 2px 32px 0px rgba(0, 0, 0, 0.16);
                                z-index: 100000;
                                background: #fff;
                                padding: 25px 24px;
                                border-radius: 4px;
                                .dynamics-title{
                                    cursor: pointer;
                                    color: rgba(24, 144, 255, 1);
                                    font-size: 14px;
                                    display: inline-block;
                                    line-height: 16px;
                                    i{
                                        margin-right: 4px;
                                    }
                                }
                                .dynamics-title:hover{
                                    border-bottom: 1px solid rgba(24, 144, 255, 1);
                                }
                                .create-basicinfo{
                                    margin-top: 19px;
                                    .create-title{
                                        color: rgba(188, 188, 188, 1);
                                        font-size: 12px;
                                    }
                                    .create-content{
                                        color: rgba(80, 80, 80, 1);
                                        font-size: 14px;
                                        margin-top: 6px;
                                    }
                                }
                                .create-label{
                                    margin-top: 24px;
                                    .create-title{
                                        color: rgba(188, 188, 188, 1);
                                        font-size: 12px;
                                    }
                                    .label-list{
                                        margin-top: 3px;
                                        span{
                                            display: inline-block;
                                            height: 28px;
                                            line-height: 28px;
                                            text-align: center;
                                            color: rgba(255, 255, 255, 1);
                                            font-size: 13px;
                                            padding: 0 12px;
                                            margin-right: 6px;
                                            background: rgba(35, 218, 119, 1);
                                            margin-top: 6px;
                                        }
                                    }
                                }
                                .customer-divider{
                                    margin: 18px 0;
                                }
                            }
                        }
                        .name2:hover{
                            .detail-box{
                                display: inline-block;
                            }
                        }
                    }
                    
                    .item-date{
                        color: rgba(153, 153, 153, 1);
                        font-size: 12px;
                        margin-bottom: 9px;
                    }
                    .item-content{
                        color: rgba(80, 80, 80, 1);
                        font-size: 14px;
                        word-break: break-all;
                    }
                    .label-content{
                        margin-top: 8px;
                        span{
                            margin-right: 8px;
                            color: #fff;
                            font-size: 13px;
                            display: inline-block;
                            height: 28px;
                            line-height: 28px;
                            padding: 0 12px;
                        }
                    }
                    .item-comment{
                        margin-top: 16px;
                        .comment-btn{
                            i{
                                font-size: 13px;
                                color: rgba(158, 172, 196, 1);
                                cursor: pointer;
                            }
                            span:nth-child(2){
                                color: rgba(158, 172, 196, 1);
                                font-size: 13px;
                                margin-left: 2px;
                            }
                            span:nth-child(3){
                                float: right;
                                font-size: 13px;
                                color: rgba(158, 172, 196, 1);
                            }
                        }
                    }
                    .textarea-comment{
                        margin-top: 20px;
                        background: rgba(247, 247, 247, 1);
                        border: none;
                        resize: none;
                        height: 71px;
                        padding: 5px;
                        position: relative;
                    }
                    .comment-divider{
                        background: rgba(229, 229, 229, 1);
                        margin: 11px 0 12px;
                    }
                    .comment-textarea{
                        resize: none;
                        border-color: rgba(121, 136, 155, 0.5);
                        height: 52px;
                    }
                    // .comment-textarea:focus{
                    //     height: 80px;
                    //     border: none;
                    // }
                    .comment-operate{
                        margin-top: 2px;
                        position: relative;
                        .symbol-icon{
                            color: rgba(24, 144, 255, 1);
                            font-size: 14px;
                            cursor: pointer;
                        }
                        .selected-user{
                            display: inline-block;
                            div{
                                display: inline-block;
                                height: 24px;
                                padding: 0 8px;
                                line-height: 24px;
                                color: rgba(80, 80, 80, 1);
                                font-size: 12px;
                                background: rgba(121, 136, 155, 0.1);
                                margin-left: 4px;
                                i{
                                    color: rgba(153, 153, 153, 1);
                                    cursor: pointer;
                                    margin-left: 8px;
                                }
                            }
                        }
                        .cancel-submit{
                            float: right;
                            span{
                                font-size: 14px;
                                margin-left: 16px;
                                cursor: pointer;
                            }
                            span:nth-child(1){
                                color: rgba(158, 172, 196, 1);
                            }
                            span:nth-child(2){
                                color: rgba(24, 144, 255, 1);
                            }
                        }
                        .user-list1{
                            display: inline-block;
                            border: 1px solid #ccc;
                            width:100px;
                            height: 200px;
                            overflow-x: hidden;
                            overflow-y: auto;
                            border-radius: 4px;
                            position: absolute;
                            left: 0px;
                            top: 20px;
                            z-index: 100;
                            background: #fff;
                            ul{
                                li{
                                    line-height: 25px;
                                    cursor: pointer;
                                    padding-left: 8px;
                                }
                                li:hover{
                                    background: #eee;
                                }
                            }
                        }
                    }
                    .comment-detail{
                        margin-top: 11px;
                        .comment-list{
                            display: flex;
                            .username{
                                display: inline-block;
                                width: 32px;
                                height: 32px;
                                border-radius: 16px;
                                color: #fff;
                                text-align: center;
                                line-height: 32px;
                                background: rgba(121, 72, 234, 0.6);
                                font-size: 14px;
                                margin-right: 10px;
                            }
                            img{
                                width: 32px;
                                height: 32px;
                                margin-right: 10px;
                            }
                            .comment-info{
                                flex: 1;
                                .comment-info-content{
                                    .replay-name{
                                        color: rgba(26, 84, 172, 1);
                                        font-size: 12px;
                                    }
                                    .replay-content{
                                        color: rgba(18, 18, 18, 1);
                                        font-size: 12px;
                                    }
                                }
                                .comment-replay-textarea{
                                    margin-top: 7px;
                                    resize: none;
                                    border-color: rgba(121, 136, 155, 0.5);
                                    height: 32px;
                                }
                                // .comment-replay-textarea:focus{
                                //     height: 80px;
                                //     border: none;
                                // }
                                .comment-info-date{
                                    span{
                                        color: rgba(153, 153, 153, 1);
                                        font-size: 12px;
                                    }
                                    i{
                                        color: rgba(158, 172, 196, 1);
                                        font-size: 12px;
                                        margin-left: 12px;
                                        cursor: pointer;
                                    }
                                }
                            }
                        }
                        .comment-list2{
                            padding-left: 42px;
                            margin-top: 7px;
                        }
                    }
                }
            }
            .no-data{
                text-align: center;
                line-height: 300px;
                font-size: 20px;
            }
        }
        .dynamics-screen{
            width: 360px;
            margin-left: 16px;
            .dynamic-filtering{
                min-height: 265px;
                background: #fff;
                border-radius: 4px;
                box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
                padding: 16px 24px;
                .dynamic-title{
                    color: rgba(18, 18, 18, 1);
                    font-size: 14px;
                }
                .dynamic-input{
                    width: 100%;
                    border-color: rgba(121, 136, 155, 0.5);
                    margin-top: 16px;
                    color: rgba(121, 136, 155, 1);
                }
                .person-in-charge{
                    margin: 16px 0;
                    // height: 300px;
                    overflow-x: hidden;
                    overflow-y: auto;
                    padding-top: 16px;
                    .tree-select{
                        width: 100%;
                    }
                }
                .date-select{
                    position: relative;
                    .date-title{
                        color: rgba(121, 136, 155, 1);
                        font-size: 14px;
                        cursor: pointer;
                        display: inline-block;
                    }
                    .date-box{
                        width: 230px;
                        height: 374px;
                        z-index: 10;
                        position: absolute;
                        left: 0px;
                        top: 25px;
                        box-shadow: 0px 2px 32px 0px rgba(0, 0, 0, 0.16);
                        background: #fff;
                        padding: 15px 24px;
                        .date-list{
                            color: rgba(80, 80, 80, 1);
                            font-size: 14px;
                            line-height: 34px;
                            cursor: pointer;
                        }
                        .active{
                            color: rgba(24, 144, 255, 1);
                        }
                        .date-divider{
                            margin: 12px 0;
                            color: rgba(229, 229, 229, 1);
                        }
                        .design-self{
                            color: rgba(80, 80, 80, 1);
                            font-size: 14px;
                        }
                        .date-choose{
                            margin-top: 12px;
                            .date-choose-title{
                                color: rgba(188, 188, 188, 1);
                                font-size: 14px;
                            }
                            .date-choose-picker{
                                width: 165px;
                                margin-left: 3px;
                            }
                        }
                        .sure-btn{
                            margin: 0 auto;
                            margin-top: 12px;
                            text-align: center;
                            button{
                                border: none;
                                background: rgba(121, 136, 155, 0.08);
                                color: rgba(80, 80, 80, 1);
                                height: 28px;
                                width: 108px;
                            }
                        }
                    }
                }
                .condition-divider{
                    background: rgba(229, 229, 229, 1);
                    margin: 24px 0 11px;
                }
                .condition-list{
                    display: flex;
                    .condition-title{
                        display: inline-block;
                        width: 30px;
                        color: rgba(153, 153, 153, 1);
                        font-size: 12px;
                        float: left;
                        margin-top: 14px;
                        margin-right: 4px;
                    }
                    .condition-content{
                        flex: 1;
                        overflow: hidden;
                        .condition-item{
                            display: inline-block;
                            height: 28px;
                            line-height: 28px;
                            text-align: center;
                            margin-top: 8px;
                            color: rgba(121, 136, 155, 1);
                            font-size: 13px;
                            border: 1px solid rgba(121, 136, 155, 0.3);
                            background: rgba(255, 255, 255, 0.12);
                            margin-right: 8px;
                            position: relative;
                            padding: 0 30px 0 12px;
                            span{
                                color: rgba(121, 136, 155, 1);
                                font-size: 12px;
                            }
                            i{
                                font-size: 12px;
                                position: absolute;
                                top: 8px;
                                right: 8px;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
            .rank-tab{
                height: 402px;
                background: #fff;
                border-radius: 4px;
                box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
                margin-top: 16px;
                padding: 16px 24px;
                .num-tab{
                    color: rgba(102, 102, 102, 1);
                    font-size: 14px;
                    span{
                        display: inline-block;
                        margin-right: 32px;
                        line-height: 32px;
                        cursor: pointer;
                    }
                    span.active{
                        color: rgba(42, 130, 228, 1);
                        border-bottom: 3px solid rgba(42, 130, 228, 1);
                    }
                }
                .date-tab{
                    margin-top: 18px;
                    span{
                        display: inline-block;
                        width: 64px;
                        height: 28px;
                        line-height: 28px;
                        text-align: center;
                        color: rgba(121, 136, 155, 1);
                        background: rgba(121, 136, 155, 0.08);
                        margin-right: 8px;
                        cursor: pointer;
                        font-size: 13px;
                    }
                    span.active{
                        color: rgba(24, 144, 255, 1);
                        background: rgba(24, 144, 255, 0.1);
                    }
                }
                .data-list{
                    margin-top: 24px;
                    .first-order{
                        background: rgba(212, 48, 48, 1);
                        color: #fff !important;
                        display: inline-block;
                        width: 16px;
                        height: 16px;
                        text-align: center;
                        line-height: 16px;
                        border-radius: 4px;
                        padding-left: 0 !important;
                    }
                    .second-order{
                        background: rgba(255, 141, 26, 1);
                        color: #fff !important;
                        display: inline-block;
                        width: 16px;
                        height: 16px;
                        text-align: center;
                        line-height: 16px;
                        border-radius: 4px;
                        padding-left: 0 !important;
                    }
                    .third-order{
                        background: rgba(255, 195, 0, 1);
                        color: #fff !important;
                        display: inline-block;
                        width: 16px;
                        height: 16px;
                        text-align: center;
                        line-height: 16px;
                        border-radius: 4px;
                        padding-left: 0 !important;
                    }
                    ul{
                        li{
                            margin-bottom: 12px;
                            width: 80%;
                            height: 33px;
                            .li-order{
                                color: rgba(121, 136, 155, 1);
                                font-size: 14px;
                                font-weight: bold;
                                margin-right: 14px;
                                padding-left: 3px;
                                display: inline-block;
                                width: 16px;
                                text-align: center;
                            }
                            .li-content{
                                color: rgba(18, 18, 18, 1);
                                font-size: 14px;
                            }
                            div{
                                display: inline-block;
                                width: 50px;
                                float: right;
                                img{
                                    width: 16px;
                                    float: left;
                                    margin-top: 3px;
                                }
                                span{
                                    float: left;
                                    margin-left: 2px;
                                }
                            }
                        }
                    }
                    .nodata{
                        text-align: center;
                        line-height: 150px;
                    }
                }
            }
        }
    }
    .drawer-public1{
        height: 100%;
        position: relative;
        padding-bottom: 60px;
        .drawer-head{
            height: 88px;
            background: #fff;
            padding: 0 24px 0;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            z-index: 100;

        }
        .drawer-content{
            padding: 104px 0 68px 16px;
            width: 100%;
            height: 100%;
            position: absolute;
        }
        .drawer-foot{
            position: absolute;
            left: 0px;
            bottom: 0px;
            width: 100%;
            height: 52px;
            background: #fff;
            box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
            .btn-group{
                text-align: center;
                padding-top: 10px;
                width: 100%;
                button{
                    height: 32px;
                    font-size: 14px;
                }
                button:nth-child(2){
                    margin: 0 16px;
                }
            }
        }
    }
    .drawer-sence{
        .row-head{
            padding-top: 20px;
            border-top: 1px solid rgba(229, 229, 229, 1);
            .col-left{
                .head-content{
                    color: rgba(188, 188, 188, 1);
                    font-size: 12px;
                }
                .head-content{
                    color: rgba(80, 80, 80, 1);
                    font-size: 14px;
                    
                }
            }
        }
        .drawer-body-content{
            width: 100%;
            height: 100%;
            border-radius: 4px;
            overflow-y: auto;
            padding-right: 16px;
            .basic-info{
                width: 100%;
                background: #fff;
                border-radius: 4px;
                padding: 24px 24px 24px 0;
                .title{
                    color: rgba(18, 18, 18, 1);
                    font-size: 14px;
                    padding-left: 24px;
                }
                .col-left{
                    padding-right: 24px;
                }
                .row-list{
                    .col-list{
                        margin-top: 19px;
                        margin-bottom: 5px;
                        min-height: 69px;
                        padding-left: 24px;
                        .first-content{
                            color: rgba(188, 188, 188, 1);
                            font-size: 12px;
                        }
                        .second-content{
                            color: rgba(51, 51, 51, 1);
                            font-size: 14px;
                            margin-top: 9px;
                            .change-head{
                                color: rgba(24, 144, 255, 1);
                                font-size: 12px;
                                cursor: pointer;
                                margin-left: 24px;
                            }
                            .edit-icon{
                                display: none;
                            }
                        }
                        .selection-range{
                            // margin-top: 9px;
                            width: 290px;
                        }
                        .third-content{
                            color: rgba(51, 51, 51, 1);
                            font-size: 14px;
                            margin-top: 4px;
                            // display: none;
                        }
                        .user-content{
                            color: rgba(51, 51, 51, 1);
                            font-size: 14px;
                            margin-top: 4px;
                        }
                        .label-content{
                            margin-top: 9px;
                            button{
                                border: none;
                                background: rgba(158, 172, 196, 0.12);
                                color: rgba(158, 172, 196, 1);
                                font-size: 13px;
                                height: 28px;
                            }
                            span{
                                display: inline-block;
                                height: 28px;
                                line-height: 28px;
                                color: #fff;
                                font-size: 13px;
                                padding: 0 12px;
                                margin: 2px 4px 2px 0;
                                background: rgba(158, 172, 196, 0.12);
                                i{
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                    .col-list:hover{
                        // .second-content{
                        //     display: none;
                        // }
                        // .third-content{
                        //     display: block;
                        // }
                        .edit-icon{
                            display: inline-block;
                            cursor: pointer;
                            color: #1890ff;
                        }
                    }
                    .col-time{
                        display: none !important;
                    }
                }
                .add-attachment{
                    margin-left: 24px;
                    border: none;
                    background: rgba(158, 172, 196, 0.12);
                    color: rgba(158, 172, 196, 1);
                    font-size: 13px;
                }
                .pd-24{
                    padding-left: 24px;
                }
                .pd-8{
                    padding-left: 8px;
                }
                .attachment-item{
                    padding: 9px 12px 0 17px;
                    height: 52px;
                    border: 1px solid rgba(158, 172, 196, 0.3);
                    margin-top: 8px;
                    display: flex;
                    img{
                        width: 36px;
                        height: 36px;
                    }
                    .attachment-content{
                        flex: 1;
                        padding-left: 8px;
                        .name-operation{
                            span{
                                float: left;
                                color: rgba(18, 18, 18, 1);
                                font-size: 13px;
                            }
                            i{
                                float: right;
                                color: rgba(158, 172, 196, 1);
                                font-size: 13px;
                                cursor: pointer;
                                margin-left: 12px;
                            }
                            i:hover{
                                color: #1890ff;
                            }
                        }
                        .file-size{
                            color: rgba(158, 172, 196, 1);
                            font-size: 12px;
                        }
                    }
                }
            }  
            .follow-time{
                height: 136px;
                padding: 18px 0 0 24px;
                margin-top: 16px;
                border-radius: 4px;
                background: #fff;
                .title{
                    color: rgba(18, 18, 18, 1);
                    font-size: 14px;
                }
                .row-time{
                    margin-top: 24px;
                    .row-time-title{
                        color: rgba(188, 188, 188, 1);
                        font-size: 12px;
                    }
                    .row-time-value{
                        color: rgba(51, 51, 51, 1);
                        font-size: 14px;
                        margin-top: 9px;
                    }
                }
            }
            .related-clue{
                padding: 18px 0 24px 24px;
                margin-top: 16px;
                border-radius: 4px;
                background: #fff;
                max-height: 260px;
                overflow-y: hidden;
                .span{
                    color: rgba(18, 18, 18, 1);
                    font-size: 14px;
                }
                button{
                    float: right;
                    color: rgba(24, 144, 255, 1);
                    font-size: 14px;
                    border: none;
                }
                .related-title{
                    color: rgba(188, 188, 188, 1);
                    font-size: 12px;
                    margin-top: 24px;
                }
                .related-data{
                    margin-top: 8px;
                    .related-name{
                        color: rgba(61, 134, 253, 1);
                        font-size: 14px;
                        cursor: pointer;
                    }
                    .related-content{
                        color: rgba(51, 51, 51, 1);
                        font-size: 14px;
                    }
                }
            }
            .dynamic-info{
                padding: 18px 0 36px 24px;
                margin-top: 16px;
                border-radius: 4px;
                background: #fff;
                .title{
                    span{
                        color: rgba(18, 18, 18, 1);
                        font-size: 14px;
                    }
                    button{
                        float: right;
                        color: rgba(24, 144, 255, 1);
                        font-size: 14px;
                        border: none;
                    }
                }
                .filter-condition{
                    margin-top: 23px;
                    margin-bottom: 16px;
                    span{
                        display: inline-block;
                        height: 28px;
                        line-height: 28px;
                        color: rgba(121, 136, 155, 1);
                        font-size: 13px;
                        margin-right: 8px;
                        padding: 0 12px;
                        cursor: pointer;
                        background: rgba(255, 255, 255, 0.12);
                    }
                    span.active{
                        color: rgba(24, 144, 255, 1);
                    }
                }
                .dynamic-list{
                    display: flex;
                    margin-bottom: 24px;
                    .date{
                        width: 90px;
                        padding-right: 10px;
                        color: rgba(153, 153, 153, 1);
                        font-size: 12px;
                        span:nth-child(2){
                            display: inline-block;
                            width: 8px;
                            height: 8px;
                            border-radius: 4px;
                            float: right;
                            margin-top: 8px;
                        }
                    }
                    .active-track{
                        flex: 1;
                        .time{
                            color: rgba(153, 153, 153, 1);
                            font-size: 12px;
                        }
                        .active-event{
                            // color: rgba(153, 153, 153, 1);
                            font-size: 12px;
                            span{
                                background: #1890ff;
                                color: #fff;
                            }
                        }
                        .dynamic-content{
                            color: rgba(80, 80, 80, 1);
                            font-size: 14px;
                            margin-top: 6px;
                            padding-right: 15px;
                            div{
                                min-height: 21px;
                            }
                            textarea{
                                // display: none;
                            }
                            .dynamic-icon{
                                cursor: pointer;
                                display: none;
                                color: #1890ff;
                            }
                        }
                        .dynamic-content:hover{
                            .dynamic-icon{
                                display: inline-block;
                            }
                        }
                        .chat-detail{
                            .play-voice{
                                color: #1890ff;
                                font-size: 12px;
                                text-decoration: underline;
                                display: inline-block;
                                margin-right: 16px;
                                cursor: pointer;
                                i{
                                    margin-right: 2px;
                                }
                            }
                            .voice-content{
                                color: #505050;
                                font-size: 12px;
                            }
                        }
                        .label-content{
                            // margin-top: 9px;
                            button{
                                border: none;
                                background: rgba(158, 172, 196, 0.12);
                                color: rgba(158, 172, 196, 1);
                                font-size: 13px;
                                height: 28px;
                                border: 1px solid rgba(158, 172, 196, 1);
                                margin: 8px 8px 8px 0;
                            }
                            span{
                                display: inline-block;
                                height: 28px;
                                line-height: 28px;
                                border: 1px solid #fff;
                                font-size: 13px;
                                padding: 0 12px;
                                margin: 2px 4px 2px 0;
                                background: #fff;
                                border-radius: 4px;
                                i{
                                    cursor: pointer;
                                }
                            }
                        }
                        .next-time{
                            color: rgba(80, 80, 80, 1);
                            font-size: 14px;
                            float: right;
                            padding-right: 16px;
                        }
                    }
                }
            }
            .comments{
                padding: 18px 24px;
                background: #fff;
                margin-top: 16px;
                border-radius: 4px;
                .title{
                    color: rgba(18, 18, 18, 1);;
                    font-size: 14px;
                }
                .textarea-comment{
                    margin-top: 20px;
                    background: rgba(247, 247, 247, 1);
                    border: none;
                    resize: none;
                }
                .release{
                    text-align: right;
                    color: rgba(24, 144, 255, 1);
                    font-size: 14px;
                    margin-top: 4px;
                    span{
                        cursor: pointer;
                    }
                }
            }  
        }
    }
    .drawer-newtask{
        .newtask-body{
            height: 496px;
            padding: 16px;
            .newtask-content{
                width: 100%;
                height: 100%;
                overflow: hidden;
                background: #fff;
                padding: 24px;
                border-radius: 4px;
                .col-left{
                    padding-right: 24px;
                }
                .col-right{
                    padding-left: 24px;
                }
                .row-list{
                    .col-list{
                        margin-top: 19px;
                        margin-bottom: 5px;
                        .select-admin{
                            width: 336px;
                            height: 32px;
                            margin-top: 9px;
                        }
                        .textarea-remarks{
                            margin-top: 9px;
                        }
                        .first-content{
                            color: rgba(188, 188, 188, 1);
                            font-size: 12px;
                        }
                        .second-content{
                            color: rgba(51, 51, 51, 1);
                            font-size: 14px;
                            margin-top: 9px;
                            .digital-staff{
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
    .modal-public{
        .divider1{
            margin: 0px 0 24px 0;
        }
        .divider2{
            margin: 24px 0 12px;
        }
        .btn-group{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                margin-right: 16px;
            }
        }
        .btn-group2{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                background: #fff;
                color: rgba(121, 136, 155, 0.7);
                border: 1px solid rgba(121, 136, 155, 0.5);
                margin-right: 16px;
            }
            button:nth-child(1):hover{
                background: rgba(121, 136, 155, 0.08);
            }
        }
    }
    .create-modal{
        padding-bottom: 46px;
    }
    .drawer-public2{
        height: 100%;
        position: relative;
        padding-bottom: 60px;
        .drawer-title{
            height: 55px;
            width: 100%;
            span{
                color: rgba(18, 18, 18, 1);
                font-size: 16px;
                line-height: 55px;
            }
            i{
                color: rgba(153, 153, 153, 1);
                font-size: 16px;
                margin-left: 16px;
                cursor: pointer;
                line-height: 50px;
            }
        }
        .drawer-head{
            height: 144px;
            background: #fff;
            padding: 0 24px 0;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            z-index: 100;

        }
        .drawer-content{
            padding: 160px 0 68px 16px;
            width: 100%;
            height: 100%;
            position: absolute;
        }
        .drawer-foot{
            position: absolute;
            left: 0px;
            bottom: 0px;
            width: 100%;
            height: 52px;
            background: #fff;
            box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
            .btn-group{
                text-align: center;
                padding-top: 10px;
                width: 100%;
                button{
                    height: 32px;
                    font-size: 14px;
                }
                button:nth-child(2){
                    margin: 0 16px;
                }
            }
        }
    }
    .upload-modal{
        .divider1{
            margin: 0px 0 24px 0;
        }
        .divider2{
            margin: 24px 0 12px;
        }
        padding-bottom: 50px;
        .btn-group{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                margin-right: 16px;
            }
        }
    }
    .time-modal{
        padding-bottom: 46px;
        .check-list{
            .check-item{
                display: inline-block;
                width: 160px;
                height: 32px;
            }
        }
    }
    .share-modal{
        .copy-link{
            height: 62px;
            input{
                width: 464px;
                float: left;
                height: 40px;
            }
            span{
                float: left;
                display: inline-block;
                width: 88px;
                height: 40px;
                border: 1px solid rgba(121, 136, 155, 0.3);
                cursor: pointer;
                border-radius: 0px 4px 4px 0px;
                line-height: 40px;
                text-align: center;
            }
        }
    }
    .delete-modal{
        padding-top: 24px;
        padding-bottom: 48px;
        .sure-off-bind{
            i{
                color: rgba(255, 170, 0, 1);
                font-size: 24px;
            }
            span{
                color: rgba(18, 18, 18, 1);
                font-size: 20px;
                margin-left: 24px;
            }
        }
        .tip{
            color: rgba(80, 80, 80, 1);
            padding-left: 50px;
            margin: 24px 0;
        }
    }
    .label-modal{
        padding-bottom: 46px;
        .title{
            color: rgba(153, 153, 153, 1);
            font-size: 14px;
            margin-bottom: 16px;
        }
        .company-tag-list{
            margin-top: 8px;
            .type-name{
                color: rgba(18, 18, 18, 1);
                font-size: 14px;
            }
            .label-list{
                span{
                    display: inline-block;
                    height: 28px;
                    line-height: 28px;
                    color: rgba(158, 172, 196, 1);
                    font-size: 13px;
                    padding: 0 16px;
                    margin: 8px 8px 8px 0;
                    background: rgba(158, 172, 196, 0.12);
                    cursor: pointer;
                }
            }
        }
        .new-tag{
            display: flex;
            input{
                flex: 1;
            }
            i{
                font-size: 20px;
                margin-left: 16px;
                margin-top: 6px;
                cursor: pointer;
                color: rgba(121, 136, 155, 1);
            }
        }
        .tag-list{
            span{
                display: inline-block;
                height: 28px;
                line-height: 28px;
                color: rgba(158, 172, 196, 1);
                font-size: 13px;
                padding: 0 16px;
                margin: 8px 8px 8px 0;
                background: rgba(158, 172, 196, 0.12);
                cursor: pointer;
            }
            i{
                margin-left: 8px;
                font-size: 12px;
            }
        }
    }
    .username{
        display: inline-block;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        color: #fff;
        text-align: center;
        line-height: 20px;
        background: rgba(121, 72, 234, 0.6);
        font-size: 12px;
        float: left;
        margin-right:4px;
    }
    .related-clue-modal{
        padding-bottom: 46px; 
    }
</style>