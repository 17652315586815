<template>
    <div class="view-group">
        <div class="modular-head">
            <div class="view-group">
                <div>视图分组</div>
                <span @click="selectAllView" :class="{'active':dataViewId==''}">全部数据</span>
                <span :class="{'active':item.isDefault==1}" v-for="(item,index) in dataViewList" :key='index' @click="selectView(item,index)">{{item.name}}</span>
            </div>
            <a-dropdown v-model="visibleView" :trigger="['click']">
                <span class="ant-dropdown-link" @click="e => e.preventDefault()">
                视图操作 <a-icon type="down" />
                </span>
                <a-menu slot="overlay" @click="handleMenuClick">
                    <a-menu-item key="1">
                        创建视图
                    </a-menu-item>
                    <a-menu-item key="2" v-if="dataViewId!=''">
                        编辑视图
                    </a-menu-item>
                    <a-menu-item key="3" v-if="dataViewId!=''">
                        复制视图
                    </a-menu-item>
                    <a-menu-item key="4" v-if="dataViewId!=''">
                        删除视图
                    </a-menu-item>
                    <a-menu-item key="5" v-if="dataViewId!=''">
                        修改视图名称
                    </a-menu-item>
                </a-menu>
            </a-dropdown>
        </div>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="newViewModal" title="创建视图" footer='' width='480px'>
            <div class="new-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <div class="new-modal-list">
                    <div class="changed-name">输入视图名称：</div>
                    <a-input v-model="viewName" class="input-name" placeholder="请输入视图名称" />
                </div>
                <div class="new-modal-list">
                    <div class="changed-name">输入视图排序：</div>
                    <a-input-number v-model="viewOrder" class="input-order" placeholder="请输入视图名称" :min="0"/>
                </div>
                <div class="new-modal-list">
                    <div class="changed-name">是否设置为默认视图：</div>
                    <a-radio-group name="radioGroup" v-model="ifdefaultView">
                        <a-radio :value="1">
                            是
                        </a-radio>
                        <a-radio :value="0">
                            否
                        </a-radio>
                    </a-radio-group>
                </div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group">
                    <a-button type="primary" ghost @click="cancelNewView">取消</a-button>
                    <a-button type="primary" @click="sureNewView">下一步</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="createViewModal" :title="modelTitle" footer='' width='720px'>
            <div class="edit-view-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                 <a-row v-for="(item,index) in viewScreenData" :key='index' style="margin-bottom:12px">
                    <a-col :span="4">
                        {{item.fieldName}}
                    </a-col>
                    <a-col :span="6">
                        <a-select v-model="item.selectedCondition" style="width:100%;height:32px" @change="selectConChange(item,index)">
                            <a-select-option :value="item1.value" v-for="(item1,index1) in item.methodList" :key='index1'>
                                {{item1.name}}
                            </a-select-option>
                        </a-select>
                    </a-col>
                    <a-col :span="14" style="padding-left:12px;">
                        <Linkage ref="child4"></Linkage>
                    </a-col>
                </a-row>
                <div class="reset-condition">
                    <span @click="newSetCondition">重置筛选条件</span>
                </div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group">
                    <a-button type="primary" ghost @click="cancelCreateView">取消</a-button>
                    <a-button type="primary" @click="sureCreateView">确定</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="copyViewModal" title="复制视图" footer='' width='480px'>
            <div class="copy-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <div class="new-modal-list">
                    <div class="changed-name">输入复制后的视图名称：</div>
                    <a-input v-model="copyViewData.name" class="input-name" placeholder="请输入视图名称" />
                </div>
                <div class="new-modal-list">
                    <div class="changed-name">输入复制后的视图排序：</div>
                    <a-input-number v-model="copyViewData.order" class="input-order" placeholder="请输入视图名称" :min="0" :max="10"/>
                </div>
                <div class="new-modal-list">
                    <div class="changed-name">是否设置为默认视图：</div>
                    <a-radio-group name="radioGroup" v-model="copyViewData.ifdefaultView">
                        <a-radio :value="1">
                            是
                        </a-radio>
                        <a-radio :value="0">
                            否
                        </a-radio>
                    </a-radio-group>
                </div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group">
                    <a-button type="primary" ghost @click="cancelCopyView">取消</a-button>
                    <a-button type="primary" @click="sureCopyView">确定</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="changeViewModal" title="修改视图信息" footer='' width='480px'>
            <div class="change-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <div class="new-modal-list">
                    <div class="changed-name">输入修改后的视图名称：</div>
                    <a-input v-model="editNameData.name" class="input-name" placeholder="请输入视图名称" />
                </div>
                <div class="new-modal-list">
                    <div class="changed-name">输入修改后的视图排序：</div>
                    <a-input-number v-model="editNameData.order" class="input-order" placeholder="请输入视图名称" :min="0"/>
                </div>
                <div class="new-modal-list">
                    <div class="changed-name">是否设置为默认视图：</div>
                    <a-radio-group name="radioGroup" v-model="editNameData.ifdefaultView">
                        <a-radio :value="1">
                            是
                        </a-radio>
                        <a-radio :value="0">
                            否
                        </a-radio>
                    </a-radio-group>
                </div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group">
                    <a-button type="primary" ghost @click="cancelChangeView">取消</a-button>
                    <a-button type="primary" @click="sureChangeView">确定</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="deleteViewModal" footer='' width='480px'>
            <div class="delete-modal modal-public">
                <div class="sure-off-bind">
                    <a-icon type="warning" />
                    <span>您确定要删除视图吗？</span>
                </div>
                <div class="tip">数据删除后，将无法恢复</div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group2">
                    <a-button type="primary" ghost @click="cancelDelete">取消</a-button>
                    <a-button type="primary" @click="sureDelete">确认</a-button>
                </div>
            </div>
        </a-modal>
    </div>
</template>

<script>
import Linkage from './Linkage'
export default {
    props:[],
    components: {Linkage},
    data(){
        return{
            currentView: '',
            visibleView: false,
            newViewModal: false,
            copyViewModal: false,
            changeViewModal: false,
            deleteViewModal: false,
            dataViewList: [],
            dataViewId: '',
            viewName: '',
            viewOrder: undefined,
            ifdefaultView: 1,
            moduleId: '',
            createViewModal: false,
            viewScreenData: [],
            currentId: '',
            copyViewData: {
                name: '',
                order: '',
                ifdefaultView: 1
            },
            editNameData: {
                name: '',
                order: '',
                ifdefaultView: 1
            },
            modelTitle: '',
            copyData: [],
        }
    },
    methods: {
        selectConChange(item, index) {
            this.$refs.child4[index].getPassData(item,index)
        },
        queryInitData(type) {
            this.axios(
                {
                    method:'get',
                    url:'/api/view/list/'+this.moduleId, 
                }
            )
            .then(response=> {
                if(response.data.code == 0){
                    this.dataViewList = response.data.data
                    let flag = false, item
                    if(type == 'new'|| type == 'copy' || type == 'edit'){
                        for(let i = 0; i < this.dataViewList.length; i++){
                            this.dataViewList[i]['isDefault'] = 0
                            if(this.dataViewList[i]['id'] == this.dataViewId){
                                item = this.dataViewList[i]
                                this.dataViewList[i]['isDefault'] = 1
                                this.editNameData.name = this.dataViewList[i]['name']
                                this.editNameData.order = this.dataViewList[i]['orderNo']
                                this.editNameData.ifdefaultView = this.dataViewList[i]['isDefault']
                            }
                        }
                    }else{
                        for(let i = 0; i < this.dataViewList.length; i++){
                            if(this.dataViewList[i]['isDefault'] == 1){
                                item = this.dataViewList[i]
                                flag = true
                            }
                        } 
                        if(flag){
                            this.dataViewId = item['id']
                            this.editNameData.name = item.name
                            this.editNameData.order = item.orderNo
                            this.editNameData.ifdefaultView = item.isDefault
                        }else{
                            this.dataViewId = ''
                        }
                    }
                    this.$parent.queryBasicData('init')
                }else{
                    this.$message.error(response.data.userTip);
                    if(response.data.code == 'A0200'){
                        this.$router.push("/login")
                    }
                }
            })
            .catch(error=> {
                console.log(error)
            })
        },
        selectAllView() {
            this.dataViewId = ''
            this.$parent.queryBasicData('init')
            for(let i = 0; i < this.dataViewList.length; i++){
                this.dataViewList[i]['isDefault'] = 0
            } 
        },
        selectView(item,index) {
            this.editNameData.name = item.name
            this.editNameData.order = item.orderNo
            this.editNameData.ifdefaultView = item.isDefault
            this.dataViewId = item.id
            this.$parent.queryBasicData('init')
            for(let i = 0; i < this.dataViewList.length; i++){
                if(i == index){
                    this.dataViewList[i]['isDefault'] = 1
                }else{
                    this.dataViewList[i]['isDefault'] = 0
                }
            } 
        },
        handleMenuClick(e) {
            this.visibleView = false
            if(e.key == 1){
                this.newViewModal = true
            }else if(e.key == 2){
                if(this.dataViewId==''){
                    this.$message.error('请先选择视图');
                }else{
                    this.modelTitle = '编辑视图'
                    this.queryScreenCondition('edit')
                    this.createViewModal = true
                }
            }else if(e.key == 3){
                if(this.dataViewId==''){
                    this.$message.error('请先选择视图');
                }else{
                    this.copyViewModal = true
                }
            }else if(e.key == 4){
                if(this.dataViewId==''){
                    this.$message.error('请先选择视图');
                }else{
                    this.deleteViewModal = true
                }
            }else if(e.key == 5){
                if(this.dataViewId==''){
                    this.$message.error('请先选择视图');
                }else{
                    this.changeViewModal = true
                }
            }
        },
        cancelNewView() {
            this.newViewModal = false
        },
        sureNewView() {
            if(this.viewOrder == undefined){
                this.$message.error('请输入视图排序')
            }else{
                this.newViewModal = false
                this.createViewModal = true
                this.modelTitle = '创建视图'
                this.queryScreenCondition('new')
            }
        },
        cancelCreateView() {
            this.createViewModal = false
        },
        sureCreateView() {
            this.createViewModal = false
            let data = this.$refs.child4
            let arr = []
            for(let i = 0; i < data.length; i++){
                let obj = {}
                if(data[i]['_data']['viewportData']['selectedCondition']!='ALL'&&data[i]['_data']['viewportData']['selectedCondition']!=undefined){
                    obj.fieldId = data[i]['_data']['viewportData']['fieldId']
                    obj.fieldMethod = data[i]['_data']['viewportData']['selectedCondition']
                    obj.valueList = data[i]['_data']['viewportData']['valueList']
                    arr.push(obj)
                }
            }
            if(this.modelTitle == '创建视图'){
                this.doCreateView('new', arr)
            }else{
                this.doCreateView('edit', arr)
            }
        },
        doCreateView(type,arr) {
            this.axios.post('/api/view', {
                id: type=='new'?'':this.dataViewId,
                moduleId: type=='edit'?'':this.moduleId,
                name: this.viewName,
                orderNo: this.viewOrder,
                isDefault: this.ifdefaultView,
                conditionList: arr
            })
            .then(response=> {
                if(response.data.code == 0){
                    let tip
                    if(type=='new'){
                        tip = '新建视图成功'
                        this.dataViewId = response.data.data.id
                        this.queryInitData('new')
                    }else{
                        tip = '修改视图成功'
                        this.queryInitData('edit')
                    }
                    this.$message.success(tip)
                }else{
                    this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                }
            })
            .catch(error=> {
                console.log(error);
            });
        },
        queryScreenCondition(type) {
            this.axios(
                {
                    method:'get',
                    url:'/api/view/condition/init', 
                    params: {
                        moduleId: type=='new'?this.moduleId:'',
                        id: type=='edit'?this.dataViewId:''
                    }
                }
            )
            .then(response=> {
                if(response.data.code == 0){
                    this.viewScreenData = this.deepClone(response.data.data)
                    this.copyData = this.deepClone(response.data.data)
                    if(type == 'edit'){
                        for(let i = 0; i < this.viewScreenData.length; i++){
                            if(this.viewScreenData[i]['selectedCondition']!='ALL'&&this.viewScreenData[i]['selectedCondition']!='ISNULL'){
                                setTimeout(()=>{
                                    this.$refs.child4[i].getPassData(this.viewScreenData[i],i)
                                },100)
                            }
                        }
                    }
                }else{
                    this.$message.error(response.data.userTip);
                    if(response.data.code == 'A0200'){
                        this.$router.push("/login")
                    }
                }
            })
            .catch(error=> {
                console.log(error)
            })
        },
        newSetCondition() {
            let data = this.deepClone(this.copyData)
            this.viewScreenData = data
            for(let i = 0; i < this.viewScreenData.length; i++){
                this.$refs.child4[i].getPassData(this.viewScreenData[i],i)
            }
            this.$forceUpdate()
        },  
        deepClone(obj){
            let _obj = JSON.stringify(obj),
            objClone = JSON.parse(_obj);
            return objClone
        },  
        cancelCopyView() {
            this.copyViewModal = false
        },
        sureCopyView() {
            this.copyViewModal = false
            this.axios.post('/api/view/copy', {
                id: this.dataViewId,
                name: this.copyViewData.name,
                orderNo: this.copyViewData.order,
                isDefault: this.copyViewData.ifdefaultView,
            })
            .then(response=> {
                if(response.data.code == 0){
                    this.$message.success('复制视图成功')
                    this.queryInitData('copy')
                }else{
                    this.$message.error(response.data.userTip);
                    if(response.data.code == 'A0200'){
                        this.$router.push("/login")
                    }
                }
            })
            .catch(error=> {
                console.log(error);
            });
        },
        cancelChangeView() {
            this.changeViewModal = false
        },
        sureChangeView() {
            this.changeViewModal = false
            this.axios.post('/api/view/updateName', {
                id: this.dataViewId,
                name: this.editNameData.name,
                orderNo: this.editNameData.order,
                isDefault: this.editNameData.ifdefaultView,
            })
            .then(response=> {
                if(response.data.code == 0){
                    this.$message.success('修改视图成功')
                    this.queryInitData('edit')
                }else{
                    this.$message.error(response.data.userTip);
                    if(response.data.code == 'A0200'){
                        this.$router.push("/login")
                    }
                }
            })
            .catch(error=> {
                console.log(error);
            });
        },
        cancelDelete() {
            this.deleteViewModal = false
        },
        sureDelete() {
            this.deleteViewModal = false
            this.axios.delete('/api/view/'+this.dataViewId)
            .then(response=> {
                if(response.data.code == 0){
                    this.$message.success('删除成功')
                    this.dataViewId = ''
                    this.queryInitData('delete')
                }else{
                    this.$message.error(response.data.userTip);
                    if(response.data.code == 'A0200'){
                        this.$router.push("/login")
                    }
                }
            })
            .catch(error=> {
                console.log(error);
            });
        },
        getParentData(moduleId, dataViewList) {
            this.moduleId = moduleId
            this.dataViewList = dataViewList
            let flag = false, item
            for(let i = 0; i < this.dataViewList.length; i++){
                if(this.dataViewList[i]['isDefault']==1){
                    flag = true
                    item = this.dataViewList[i]
                }
            }
            if(flag){
                this.dataViewId = item['id']
                this.editNameData.name = item.name
                this.editNameData.order = item.orderNo
                this.editNameData.ifdefaultView = item.isDefault
            }else{
                this.dataViewId = ''
            }
            this.$parent.queryBasicData()
        }
    }
}
</script>
<style scoped lang="less">
    .view-group{
        .modular-head{
            .view-group{
                display: inline-block;
                div{
                    display: inline-block;
                    margin-right: 24px;
                    color: rgba(18, 18, 18, 1);
                    font-size: 14px;
                }
                span{
                    margin-right: 16px;
                    cursor: pointer;
                    font-size: 14px;
                    color: rgba(102, 102, 102, 1);
                }
                span.active{
                    color: rgba(24, 144, 255, 1);
                }
            }
            .ant-dropdown-link{
                margin-left: 8px;
                color: rgba(121, 136, 155, 1);
                font-size: 14px;
                cursor: pointer;
            }
        }
    }
    .modal-public{
        .divider1{
            margin: 0px 0 24px 0;
        }
        .divider2{
            margin: 24px 0 12px;
        }
        .btn-group{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                margin-right: 16px;
            }
        }
        .btn-group2{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                background: #fff;
                color: rgba(121, 136, 155, 0.7);
                border: 1px solid rgba(121, 136, 155, 0.5);
                margin-right: 16px;
            }
            button:nth-child(1):hover{
                background: rgba(121, 136, 155, 0.08);
            }
        }
    }
    .new-modal{
        padding-bottom: 46px;
        .new-modal-list{
            margin-bottom: 20px;
            div{
                color: rgba(153, 153, 153, 1);
                font-size: 14px;
                margin-bottom: 8px;
            }
            .input-name{
                width: 100%;
            }
            .input-order{
                width: 100%;
            }
        }
    }
    .edit-view-modal{
        padding-bottom: 46px;
        .reset-condition{
            text-align: right;
            color: rgba(24, 144, 255, 1);
            font-size: 14px;
            span{
                cursor: pointer;
            }
        }
    }
    .copy-modal{
        padding-bottom: 46px;
        .new-modal-list{
            margin-bottom: 20px;
            div{
                color: rgba(153, 153, 153, 1);
                font-size: 14px;
                margin-bottom: 8px;
            }
            .input-name{
                width: 100%;
            }
            .input-order{
                width: 100%;
            }
        }
    }
    .change-modal{
        padding-bottom: 46px;
        .new-modal-list{
            margin-bottom: 20px;
            div{
                color: rgba(153, 153, 153, 1);
                font-size: 14px;
                margin-bottom: 8px;
            }
            .input-name{
                width: 100%;
            }
            .input-order{
                width: 100%;
            }
        }
    }
    .delete-modal{
        padding-top: 24px;
        padding-bottom: 46px;
        .sure-off-bind{
            i{
                color: rgba(255, 170, 0, 1);
                font-size: 24px;
            }
            span{
                color: rgba(18, 18, 18, 1);
                font-size: 20px;
                margin-left: 24px;
            }
        }
        .tip{
            color: rgba(80, 80, 80, 1);
            padding-left: 50px;
            margin: 24px 0;
        }
    }
</style>