<template>
    <div class="digital-detail">
        <div v-if="!ifPhone">
            <div class="head">
                <span class="title">{{digitalDetail.moduleName+"-"+digitalDetail.dataTitle}}</span>
                <OutLink :moduleId='moduleId' :dataId='currentId' :original='""'></OutLink>
            </div>
            <div class="content">
                <div class="content-in">
                    <div class="modular-content">
                        <div class="left-content"></div>
                        <div class="center-content">
                            <div class="basic-info">
                                <div class="title">基本信息</div>
                                <a-row class="row-list">
                                    <a-col :span="12" class="col-list" :class="{'col-left':index%2==0,'col-right':index%2!=0}" v-for="(item,index) in digitalDetail.detailList" :key='index'>
                                        <div class="first-content">{{item.name}}:</div>
                                        <div v-if="item.type!='IMAGE'&&item.type!='SELECT_SINGLE'&&item.code!='experienceUrl'" class="second-content">{{item.value}}</div>
                                        <div class="second-content" v-if="item.code=='experienceUrl'" style="cursor:pointer;color:#1890FF" :title='item.value'>
                                            <span @click="jumpToUrl(item.value)">{{item.value}}</span>
                                        </div>
                                        <div v-if="item.type=='SELECT_SINGLE'&&ifEditDrawer!=true" class="second-content">{{item.value}}</div>
                                        <div v-if="item.type=='IMAGE'" class="second-content">
                                            <img :src="item.value" alt="">
                                            <a-icon style="color:rgba(121, 136, 155, 1);font-size:16px;margin-left:30px" @click="showBigImg(item.value)" v-if="!ifEditDrawer" type="eye" />
                                            <span @click="changeHead" class="change-head" v-if="ifEditDrawer">更换</span>
                                        </div>
                                        <div v-if="item.type=='SELECT_SINGLE'&&ifEditDrawer==true&&item.isEditable==1">
                                            <a-select class="selection-range" :defaultValue='item.selectedValue'>
                                                <a-select-option :value="item1.value" v-for="(item1,index1) in item.selectList" :key='index1'>
                                                    {{item1.name}}
                                                </a-select-option>
                                            </a-select>
                                        </div>
                                    </a-col>
                                </a-row>
                            </div>
                            <div class="comments">
                                <Comments ref="commentChild"></Comments>
                            </div>
                        </div>
                        <div class="right-content">
                            <div class="data-info">
                                <div class="related-info">
                                    <span></span>
                                    数据信息
                                </div>
                                <ul>
                                    <li>
                                        <div>创建时间：</div>
                                        <div>{{digitalDetail.createTime}}</div>
                                    </li>
                                    <li>
                                        <div>修改时间：</div>
                                        <div>{{digitalDetail.updateTime}}</div>
                                    </li>
                                    <li>
                                        <div>创建人：</div>
                                        <div>{{digitalDetail.createBy}}</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="ifPhone" style="background:#F0F2F3">
            <div style="background:#fff;height:144px;padding:16px;">
                <div>
                    <span style="font-weight:bold;font-size:18px;">{{digitalDetail.moduleName+"-"+digitalDetail.dataTitle.length>12?digitalDetail.dataTitle.substring(0,12)+'...':digitalDetail.dataTitle}}</span>
                    <OutLinkPhone :moduleId='moduleId' :dataId='currentId' :original='""'></OutLinkPhone>
                </div>
                <ul style="padding-top:16px;font-size:13px;">
                    <li>
                        <div>
                            <span style="display:inline-block;width:70px;color:rgba(188, 188, 188, 1);">创建人：</span>
                            <span style="color:rgba(80, 80, 80, 1);">{{digitalDetail.createBy}}</span>
                        </div>
                    </li>
                    <li style="padding:8px 0">
                        <div>
                            <span style="display:inline-block;width:70px;color:rgba(188, 188, 188, 1);">创建时间：</span>
                            <span style="color:rgba(80, 80, 80, 1);">{{digitalDetail.createTime}}</span>
                        </div>
                    </li>
                    <li>
                        <div>
                            <span style="display:inline-block;width:70px;color:rgba(188, 188, 188, 1);">修改时间：</span>
                            <span style="color:rgba(80, 80, 80, 1);">{{digitalDetail.updateTime}}</span>
                        </div>
                    </li>
                   
                </ul>
            </div>
            <div style="margin-top:7px;padding:16px;background:#fff">
                <div v-for="(item,index) in digitalDetail.detailList" :key='index' style="margin-bottom:20px;">
                    <div style="color:rgba(188, 188, 188, 1);font-size:13px;">{{item.name}}:</div>
                    <div v-if="item.type!='IMAGE'&&item.type!='SELECT_SINGLE'&&item.code!='experienceUrl'" style="color:rgba(51, 51, 51, 1);font-size:16px;">{{item.value}}</div>
                    <div v-if="item.code=='experienceUrl'" style="cursor:pointer;color:#1890FF" :title='item.value'>
                        <span @click="jumpToUrl(item.value)">{{item.value}}</span>
                    </div>
                    <div v-if="item.type=='SELECT_SINGLE'&&ifEditDrawer!=true" style="color:rgba(51, 51, 51, 1);font-size:16px;">{{item.value}}</div>
                    <div v-if="item.type=='IMAGE'" style="color:rgba(51, 51, 51, 1);font-size:16px;">
                        <img style="width:40px;" :src="item.value" alt="">
                        <a-icon style="color:rgba(121, 136, 155, 1);font-size:16px;margin-left:30px" @click="showBigImg(item.value)" v-if="!ifEditDrawer" type="eye" />
                    </div>
                    <div v-if="item.type=='SELECT_SINGLE'&&ifEditDrawer==true&&item.isEditable==1" style="color:rgba(51, 51, 51, 1);font-size:16px;">
                        <a-select class="selection-range" :defaultValue='item.selectedValue'>
                            <a-select-option :value="item1.value" v-for="(item1,index1) in item.selectList" :key='index1'>
                                {{item1.name}}
                            </a-select-option>
                        </a-select>
                    </div>
                </div>
            </div>
            <div style="clean:both"></div>
            <div style="margin-top:7px;padding:16px;background:#fff">
                <div class="comments">
                    <Comments ref="commentChild"></Comments>
                </div>
            </div>
        </div>
        <a-modal :centered='true' :visible="previewVisible" :footer="null" @cancel="handleCancel">
            <img alt="example" style="width: 100%" :src="currentUrl" />
        </a-modal>
    </div>
</template>

<script>
    import Comments from "../../components/Comments";
    import OutLink from "../../components/OutLink";
    import OutLinkPhone from "../../components/OutLinkPhone";
    export default {
        name: 'DigitalShare',
        components: {Comments, OutLink, OutLinkPhone},
        data() {
            return {
                ifEditDrawer: false,
                uoloadModal: false,
                cropperName:'',
                previewVisible: false,
                drawerVisibleSence: false,
                data: null,
                columns: null,
                drawerAddVisiable: false,
                currentId: '',
                digitalDetail: {
                    dataTitle: ''
                },
                imgId: '',
                currentSelect: '',
                selectedEmployee: '',
                newTaskName: '',
                newTaskDes: '',
                DiditalEmployeeList: [],
                moduleId: '',
                moduleList: [],
                moduleNumList: [],
                currentSelectModule: null,
                currentPage: 1,
                currentSize: 10,
                total: 0,
                currentUrl: '',
                ifPhone: false,
            }
        },
        methods: {
            jumpToUrl(url) {
                window.open(url)
            },
            querySelectData() {
                this.axios(
                    {
                        method:'get',
                        url:'api/externalLinks/'+this.currentId, 
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.digitalDetail = response.data.data
                        this.$refs.commentChild.getParentCommentTree(this.moduleId, response.data.data.id, false)
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            showBigImg(url) {
                this.currentUrl = url
                if(this.currentUrl == ''){
                    this.$message.error('请先上传头像')
                }else{
                    this.previewVisible = true
                }
            },
            handleCancel() {
                this.previewVisible = false
            },

        },
        mounted: function() {
            this.currentId = this.$route.query.id
            this.moduleId = this.$route.query.moduleId
            this.querySelectData()
            let screenWidth = document.body.clientWidth
            if(screenWidth < 900){
                this.ifPhone = true
            }
            window.onresize = () => {
                screenWidth = document.body.clientWidth
                if(screenWidth < 900){
                    this.ifPhone = true
                }else{
                    this.ifPhone = false
                }
            }
        }
    }
</script>

<style scoped lang="less">
    .digital-detail{
        height: 100%;
        position: relative;
        background: rgba(240, 242, 243, 1);
        .head{
            height: 48px;
            line-height: 48px;
            padding: 0 24px;
            background: #fff;
            z-index: 100;
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            button{
                float: right;
                width: 64px;
                height: 32px;
                margin: 8px 0 0 8px;
            }
            .title{
                color: rgba(18, 18, 18, 1);
                font-size: 16px;
            }
            i{
                float: right;
                margin-top: 16px;
                font-size: 16px;
                color: rgba(204, 204, 204, 1);
                cursor: pointer;
            }
        }
        .content{
            position: absolute;
            padding: 64px 16px 16px 16px;
            left: 0px;
            top: 0px;
            height: 100%;
            width: 100%;
            .content-in{
                width: 100%;
                height: 100%;
                position: relative;
                .modular-content{
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    height: 100%;
                    width: 100%;
                    overflow-y: auto;
                    display: flex;
                    .left-content{
                        width: 288px;
                        height: 100%;
                        // background: #fff;
                        border-radius: 4px;
                        padding: 21px 24px;
                        .related-module{
                            color: rgba(18, 18, 18, 1);
                            font-size: 14px;
                            span{
                                width: 3px;
                                height: 14px;
                                float: left;
                                background: rgba(24, 144, 255, 1);
                                margin: 4px 7px 0 0;
                            }
                        }
                        .module-list{
                            padding-top: 16px;
                            li{
                                cursor: pointer;
                                padding: 8px 0;
                                i{
                                    font-size: 16px;
                                    color: rgba(51, 51, 51, 1);
                                    margin-left: 24px;
                                }
                                div{
                                    display: inline-block;
                                    color: rgba(18, 18, 18, 1);
                                    font-size: 14px;
                                    margin-left: 6px;
                                }
                                span{
                                    display: inline-block;
                                    width: 18px;
                                    height: 18px;
                                    text-align: center;
                                    line-height: 18px;
                                    background: rgba(245, 87, 78, 1);
                                    color: #fff;
                                    border-radius: 9px;
                                    float: right;
                                    margin-right: 10px;
                                }
                            }
                            li.active{
                                background: rgba(24, 144, 255, 0.1);
                                border-radius: 19px;
                                i{
                                    color: rgba(24, 144, 255, 1);
                                }
                                div{
                                    color: rgba(24, 144, 255, 1);
                                }
                            }
                        }
                    }
                    .center-content{
                        flex: 1;
                        height: 100%;
                        margin: 0 24px;
                        overflow-y: auto;
                        .basic-info{
                            width: 100%;
                            // height: 704px;
                            background: #fff;
                            border-radius: 4px;
                            padding: 24px;
                            .title{
                                color: rgba(18, 18, 18, 1);
                                font-size: 14px;
                            }
                            .col-left{
                                padding-right: 24px;
                            }
                            .col-right{
                                padding-left: 24px;
                            }
                            .row-list{
                                .col-list{
                                    margin-top: 19px;
                                    margin-bottom: 5px;
                                    height: 69px;
                                    .first-content{
                                        color: rgba(188, 188, 188, 1);
                                        font-size: 12px;
                                    }
                                    .second-content{
                                        color: rgba(51, 51, 51, 1);
                                        font-size: 14px;
                                        margin-top: 9px;
                                        img{
                                            width: 21px;
                                        }
                                        .change-head{
                                            color: rgba(24, 144, 255, 1);
                                            font-size: 12px;
                                            cursor: pointer;
                                            margin-left: 24px;
                                        }
                                    }
                                    .selection-range{
                                        margin-top: 9px;
                                        width: 100%;
                                    }
                                }
                            }
                        }  
                        .comments{
                            padding: 18px 24px;
                            background: #fff;
                            margin-top: 16px;
                            border-radius: 4px;
                            .title{
                                color: rgba(18, 18, 18, 1);;
                                font-size: 14px;
                            }
                            .textarea-comment{
                                margin-top: 20px;
                                background: rgba(247, 247, 247, 1);
                                border: none;
                                resize: none;
                            }
                            .release{
                                text-align: right;
                                color: rgba(24, 144, 255, 1);
                                font-size: 14px;
                                margin-top: 4px;
                                span{
                                    cursor: pointer;
                                }
                            }
                        }  
                    }
                    .right-content{
                        width: 288px;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        .data-info{
                            width: 100%;
                            height: 280px;
                            background: #fff;
                            border-radius: 4px;
                            margin-bottom: 16px;
                            padding: 18px 24px;
                            .related-info{
                                color: rgba(18, 18, 18, 1);
                                font-size: 14px;
                                span{
                                    width: 3px;
                                    height: 14px;
                                    float: left;
                                    background: rgba(24, 144, 255, 1);
                                    margin: 4px 7px 0 0;
                                }
                            }
                            ul{
                                li{
                                    padding-top: 24px;
                                    div:nth-child(1){
                                        color: rgba(188, 188, 188, 1);
                                        font-size: 12px;
                                        margin-bottom: 4px;
                                    }
                                    div:nth-child(2){
                                        color: rgba(80, 80, 80, 1);
                                        font-size: 14px;
                                    }
                                }
                            }
                        }
                        .fast-operate{
                            width: 100%;
                            flex: 1;
                            background: #fff;
                            padding: 18px 24px;
                            .fast-do{
                                color: rgba(18, 18, 18, 1);
                                font-size: 14px;
                                span{
                                    width: 3px;
                                    height: 14px;
                                    float: left;
                                    background: rgba(24, 144, 255, 1);
                                    margin: 4px 7px 0 0;
                                }
                            }
                            .add-task{
                                width: 100%;
                                height: 70px;
                                padding: 10px 0 0 0;
                                box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
                                text-align: center;
                                margin-top: 24px;
                                cursor: pointer;
                                i{
                                    color: rgba(24, 144, 255, 1);
                                    margin-bottom: 4px;
                                }
                                div{
                                    color: rgba(80, 80, 80, 1);
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .modal-public{
        .divider1{
            margin: 0px 0 24px 0;
        }
        .divider2{
            margin: 24px 0 12px;
        }
        .divider3{
            margin: 0px 0 12px;
        }
        .btn-group{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                margin-right: 16px;
            }
        }
        .btn-group2{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                background: #fff;
                color: rgba(121, 136, 155, 0.7);
                border: 1px solid rgba(121, 136, 155, 0.5);
                margin-right: 16px;
            }
            button:nth-child(1):hover{
                background: rgba(121, 136, 155, 0.08);
            }
        }
    }
</style>