<template>
    <div class="index">
        <div class="head">
            <div @click="jumpTo" class="logo">
                <img :src="basicInfo.logo" alt="">
                <span>{{basicInfo.appName}}</span>
            </div>
            <LoginOut></LoginOut>
        </div>
        <div class="content">
            <router-view @grandMethod="grandMethod"></router-view>
            <div v-if="callingState=='init'" class="call-icon call-icon1" @click="startCall">
                <img src="../assets/img/phone.png" alt="">
            </div>
            <div v-if="callingState=='ending'" class="call-icon call-icon2">
                <img src="../assets/img/phone.png" alt="">
            </div>
            <div v-if="callingState=='normal'" class="call-icon call-icon3" @click="continueCall">
                <img src="../assets/img/phone.png" alt="">
            </div>
            <div v-if="callingState=='calling'" class="call-icon call-icon4" @click="showCalling">
                <img src="../assets/img/phone.png" alt="">
            </div>
            <div class="online-state" v-if="currentLineState==1">
                <a-icon type="check" />
            </div>
            <div class="offline-state" v-if="currentLineState==2">
                <a-icon type="minus" />
            </div>
            <div class="dial-body" v-if="dialModal">
                <div class="dial-content">
                    <div class="dial-head">
                        <span v-if="callState!=0" class="no-configured">正常</span>
                        <span v-if="callState==0" class="no-configured2">未连接</span>
                        <div  class="line-current-state">
                            <a-select v-if="callState!=0" v-model="currentLineState" @change="changeState" class="line-current-select">
                                <a-select-option value='1'>
                                    上线
                                </a-select-option>
                                <a-select-option value='2'>
                                    下线
                                </a-select-option>
                            </a-select>
                        </div>
                        <div class="go-configured">
                            <span @click="goConfigure">选择外显号码</span>
                            <a-icon @click="closeConfig" type="close" />
                        </div>
                    </div>
                    <a-textarea
                        :placeholder="dialPanelContent"
                        class="num-textarea"
                        v-model.trim="callNumber"
                        @blur="offBlur"
                        v-focus="statefocus"
                        @focus='onfocusText'
                        :disabled='isCalling'
                    />
                    <div class="calling-state" @click="focusTextarea">
                        <div v-if="callState==2" class="calling-2">呼叫中</div>
                        <div v-if="callState==3" class="calling-3">正在通话中</div>
                        <div v-if="callState==4" class="calling-4">呼叫失败</div>
                        <div v-if="callState==5" class="calling-4">已挂断</div>
                        <div class="userinfo-data" v-if="callState==2||callState==3||callState==6">
                            <div class="userinfo-list">
                                <div class="no-in-database" v-if="userInfoData==null">未录入</div>
                                <div class="no-charge-person" v-if="userInfoData==null">无负责人</div>
                                <div class="no-in-database" v-if="userInfoData!=null&&userInfoData!=''">{{userInfoData.customerName}}</div>
                                <div class="no-charge-person" v-if="userInfoData!=null&&userInfoData!=''">{{userInfoData.owner}}</div>
                            </div>
                            <div class="userinfo-icon">
                                <a-icon v-if="userInfoData==null" @click="addUser" type="user-add" />
                                <a-icon v-if="userInfoData!=null&&userInfoData!=''" @click="showCustomerDetail" type="solution" />
                            </div>
                        </div>
                    </div>
                    <div class="dial-keypad">
                        <div class="dial-list">
                            <span :class="{'active':currentActive==1}" @click="addNum(1)">1</span>
                            <span :class="{'active':currentActive==2}" @click="addNum(2)">2</span>
                            <span :class="{'active':currentActive==3}" @click="addNum(3)">3</span>
                        </div>
                        <div class="dial-list">
                            <span :class="{'active':currentActive==4}" @click="addNum(4)">4</span>
                            <span :class="{'active':currentActive==5}" @click="addNum(5)">5</span>
                            <span :class="{'active':currentActive==6}" @click="addNum(6)">6</span>
                        </div>
                        <div class="dial-list">
                            <span :class="{'active':currentActive==7}" @click="addNum(7)">7</span>
                            <span :class="{'active':currentActive==8}" @click="addNum(8)">8</span>
                            <span :class="{'active':currentActive==9}" @click="addNum(9)">9</span>
                        </div>
                        <div class="dial-list">
                            <div class="dial-none">.</div>
                            <span :class="{'active':currentActive==0}" @click="addNum(0)">0</span>
                            <span :class="{'active':currentActive=='clear'}" @click="clearNumber('clear')" class="dial-clear">清除</span>
                        </div>
                    </div>
                    <div class="btn-call" v-if="!incomingState">
                        <span class="abnormal" v-if="callState==0" @click="stateTip">
                            <a-icon type="phone" />
                            <!-- 灰色状态添加点击事件，显示版本受限 -->
                        </span>
                        <span class="waiting-call" v-if="callState==1" @click="doCall">
                            <a-icon type="phone" />
                        </span>
                        <span class="hang-up" v-if="callState==2||callState==3" @click="doHangup">
                            <a-icon type="phone" />
                        </span>
                    </div>
                    <div v-if="incomingState" class="accepting-btn">
                        <span v-if="!hasAccepted" class="waiting-call" @click="sureIncoming">
                            <a-icon type="phone" />
                        </span>
                        <span class="hang-up" @click="cancelIncoming">
                            <a-icon type="phone" />
                        </span>
                    </div>
                    <div class="show-chat-btn" v-if="chatInterfaceState==2">
                        <a-icon type="right" @click="showChatInterface(3)" />
                    </div>
                    <div class="chat-content" v-if="chatInterfaceState==3">
                        <div class="chat-head">
                            <span>机器与客户聊天记录</span>
                            <a-icon type="close" @click="showChatInterface(2)" />
                        </div>
                        <div class="chat-body">
                            <div v-for="(item,index) in chatList" :key='index'>
                                <div v-if="item.role=='ROBOT'" class="robot-content">
                                    <img src="../assets/img/chat.png" alt="">
                                    <div class="chat-details">
                                        <div>{{item.word}}</div>
                                    </div>
                                </div>
                                <div v-if="item.role=='USER'" class="user-content">
                                    <img src="../assets/img/head.png" alt="">
                                    <div class="chat-details">
                                        <div>{{item.word}}</div>
                                    </div>
                                </div>
                                <div class="clean"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <audio class="hide" id="peervideom0" autoplay playsinline/>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="extensionModal" title="选择外显号码" footer='' width='820px'>
            <div class="normal-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <div class="sort-condition">
                    <a-input v-model="searchNumber" @input='inputChange()' placeholder="搜索号码" />
                </div>
                <div class="number-table">
                    <div>单选</div>
                    <div>号码</div>
                    <div>
                        近90天<br>外呼次数
                        <span @click="selectSortTimes()">
                            <a-icon :class="{'active':sortField=='callCount'&&sortMode=='asc'}" type="arrow-up" />
                            <a-icon :class="{'active':sortField=='callCount'&&sortMode=='desc'}" type="arrow-down" />
                        </span>
                    </div>
                    <div>有效期</div>
                    <div>
                        剩余<br>分钟数
                        <span @click="selectSortMinutes()">
                            <a-icon :class="{'active':sortField=='remainingMinutes'&&sortMode=='asc'}" type="arrow-up" />
                            <a-icon :class="{'active':sortField=='remainingMinutes'&&sortMode=='desc'}" type="arrow-down" />
                        </span>
                    </div>
                    <div>备注</div>
                </div>
                <a-radio-group v-model="numValue" @change="changeNumber">
                    <a-radio class="radio-list" v-for="(item,index) in callingNumberList" :key='index' :style="radioStyle" :value="item.id">
                        <span>{{item.number}}</span>
                        <span>{{item.callCount}}</span>
                        <span>{{item.expirationDate}}</span>
                        <span>{{item.remainingMinutes}}</span>
                        <span>{{item.remark}}</span>
                    </a-radio>
                </a-radio-group>
                <div class="pagination-table">
                    <a-pagination
                        show-size-changer
                        :total="totalNumber"
                        :show-total="totalNumber => `共 ${totalNumber} 条     `"
                        v-model="currentPage2"
                        @change="pageChange2"
                        @showSizeChange="onShowSizeChange2"
                        :pageSizeOptions="['10', '50', '100', '200']"
                    />
                </div>
                <div style="clear:both"></div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group">
                    <a-button type="primary" ghost @click="cancelNormal">取消</a-button>
                    <a-button type="primary" @click="sureNormal">确认</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="noNumModal" footer='' width='480px'>
            <div class="delete-modal modal-public">
                <div class="sure-off-bind">
                    <a-icon type="warning" />
                    <span>{{tipTitle}}</span>
                </div>
                <div class="tip">{{tipMessage}}</div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group2">
                    <a-button ghost @click="cancelNoNum">取消</a-button>
                    <a-button type="primary" @click="sureNoNum">确认</a-button>
                </div>
            </div>
        </a-modal>
        <a-drawer
            title='客户线索-新增数据'
            placement="right"
            :closable="true"
            :visible="drawerAddVisiable"
            :destroyOnClose='true'
            :maskClosable='true'
            @close="onClose2"
            :width='800'
            :mask='true'
            :headerStyle="{ border: 'none', height: '56px', position: 'absolute', width: '100%', left: '0', top: '0', zIndex: '100'}"
            :bodyStyle="{ background: 'rgba(240, 242, 243, 1)', height: '100%', position: 'absolute', width: '100%', left: '0', top: '0', zIndex: '10', padding: '56px 0 0' }"
        >
            <div class="drawer-newtask drawer-public1">
                <div class="newtask-body">
                    <div class="newtask-content">
                        <a-row class="row-list">
                            <a-col :span="12" class="col-list col-left">
                                <div class="first-content">客户名称：</div>
                                <div class="second-content">
                                    <a-input v-model="addInfoData.name" placeholder="请输入名称" />
                                </div>
                            </a-col>
                            <a-col :span="12" class="col-list col-right">
                                <div class="first-content">公司名称：</div>
                                <div class="second-content">
                                    <a-input v-model="addInfoData.company" placeholder="请输入名称" />
                                </div>
                            </a-col>
                        </a-row>
                        <a-row class="row-list">
                            <a-col :span="12" class="col-list col-left">
                                <div class="first-content">手机号：</div>
                                <div class="second-content">
                                    <a-input v-model="addInfoData.phone" placeholder="请输入手机号" />
                                </div>
                            </a-col>
                            <a-col :span="12" class="col-list col-right">
                                <div class="first-content">微信号：</div>
                                <div class="second-content">
                                    <a-input v-model="addInfoData.wx" placeholder="请输入微信号" />
                                </div>
                            </a-col>
                        </a-row>
                        <a-row class="row-list">
                            <a-col :span="12" class="col-list col-left">
                                <div class="first-content">邮箱：</div>
                                <div class="second-content">
                                    <a-input v-model="addInfoData.email" placeholder="请输入邮箱" />
                                </div>
                            </a-col>
                            <a-col :span="12" class="col-list col-right">
                                <div class="first-content">负责人：</div>
                                <div class="second-content">
                                    <img style="width:21px;float:left" :src="personalInfo.avatar" alt="">
                                    <span style="margin:0px 0 0 6px;float:left;color:rgba(51, 51, 51, 1)">{{personalInfo.name}}</span>
                                </div>
                            </a-col>
                        </a-row>
                        <a-row class="row-list">
                            <a-col :span="24" class="col-list col-left">
                                <div class="first-content">备注：</div>
                                <div class="second-content">
                                    <a-textarea v-model="addInfoData.extra" class="textarea-des" placeholder="请输入备注" :rows="3"/>
                                </div>
                            </a-col>
                        </a-row>
                    </div>
                </div>
                <div class="drawer-foot">
                    <div class="btn-group">
                        <a-button type="primary" ghost @click="offEditDrawer">取消</a-button>
                        <a-button type="primary" @click="submitDrawer">提交</a-button>
                    </div>
                </div>
            </div>
        </a-drawer>
        <a-drawer
            :title='digitalDetail.moduleName+"-"+digitalDetail.dataTitle'
            placement="right"
            :closable="true"
            :visible="drawerVisible"
            :destroyOnClose='true'
            :maskClosable='true'
            @close="onClose"
            :width='800'
            :mask='true'
            :headerStyle="{ border: 'none', height: '56px', position: 'absolute', width: '100%', left: '0', top: '0', zIndex: '0'}"
            :bodyStyle="{ background: 'rgba(240, 242, 243, 1)', height: '100%', position: 'absolute', width: '100%', left: '0', top: '0', zIndex: '10', padding: '0 0 0' }"
        >
            <div class="drawer-sence drawer-public2">
                <div class="drawer-head">
                    <div class="drawer-title">
                        <span class="left">{{digitalDetail.moduleName+"-"+(digitalDetail.dataTitle.length>28?digitalDetail.dataTitle.substring(0,28)+'...':digitalDetail.dataTitle)}}</span>
                        <div class="right">
                            <a-icon @click="viewDetail" type="select" />
                            <a-icon v-if="operationAuthority.EXTERNAL_LINKS" @click="viewSharing" type="share-alt" />
                            <a-icon @click="onClose" type="close" />
                        </div>
                    </div>
                    <a-row class="row-head">
                        <a-col :span="8" class="col-left">
                            <div class="head-title">创建人：</div>
                            <div style="margin-top: 6px;">
                                <div class="head-content">{{digitalDetail.createBy}}</div>
                            </div>
                        </a-col>
                        <a-col :span="8" class="col-left">
                            <div class="head-title">创建时间：</div>
                            <div class="head-content" style="margin-top: 6px;">{{digitalDetail.createTime}}</div>
                        </a-col>
                        <a-col :span="8" class="col-left">
                            <div class="head-title">修改时间：</div>
                            <div class="head-content" style="margin-top: 6px;">{{digitalDetail.updateTime}}</div>
                        </a-col>
                    </a-row>
                </div>
                <div class="drawer-content">
                    <div class="drawer-body-content">
                        <div class="basic-info">
                            <div class="title">基本信息</div>
                            <a-row class="row-list">
                                <a-col :span="(item.type=='LONG_TEXT'||item.type=='CUSTOMER_LABEL')?24:12" class="col-list" :class="{'col-left':index%2==0,'col-right':index%2!=0, 'col-time':item.type=='TIME'||item.code=='last_dynamic_user'}" v-for="(item,index) in digitalDetail.detailList" :key='index'>
                                    <div v-if="item.type!='TIME'" class="first-content">
                                        {{item.name}}:
                                        <span v-if="item.attribute=='PHONE'" class="take-phone" @click="playPhone(item.value)">
                                            {{item.value}}
                                            <img title='呼叫' class="take-phone1"  :value='item.value' src='http://eocc.oss-cn-beijing.aliyuncs.com/icon/phone.svg'>
                                            <img title='呼叫' class="take-phone2"  :value='item.value' src='http://eocc.oss-cn-beijing.aliyuncs.com/icon/phone1.svg'>
                                        </span>
                                    </div>
                                    
                                    <div v-if="item.type!='TIME'&&item.type!='CUSTOMER_LABEL'&&item.isEdit==false" class="second-content">
                                        {{item.value}}
                                        <a-icon @click="viewTianYan(item.value)" v-if="item.code=='corporate_name'" style="margin-right:4px;cursor:pointer" type="search" />
                                        <span v-if="item.attribute=='PHONE'">{{phoneDetails.city}}|{{phoneDetails.sp.length==0?'未知运营商':phoneDetails.sp}}</span>
                                        <a-icon @click="changeBasicEdit(index)" v-if="item.isEditable==1" class="edit-icon" style="" type="edit" />
                                    </div>

                                    <div v-if="item.type=='CUSTOMER_LABEL'" class="label-content">
                                        <a-button v-if="item.isEditable==1" @click="addLabel" icon="plus">
                                        添加标签
                                        </a-button>
                                        <span v-for="(item1,index1) in item.value" :style="{'background':item1.color}" :key='index1'>
                                            {{item1.name}}
                                            <a-icon v-if="item.isEditable==1" @click="removeDetaillTag(item1.labelId)" type="close" />
                                        </span>
                                    </div>

                                    <div v-if="item.type=='TEXT'&&item.isEdit==true" class="third-content">
                                        <a-input @blur="fieldInputBlur(item.fieldId,item.value)" :disabled='item.isEditable!=1' v-model="item.value" placeholder="请输入内容" />
                                    </div>
                                    
                                    <div class="user-content" v-if="item.type=='USER'&&item.isEditable==1&&item.isEdit==true">
                                        <a-tree-select
                                            class="selection-range"
                                            v-model="item.valueList"
                                            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                                            :tree-data="item.userTree"
                                            :placeholder="item.value"
                                            tree-default-expand-all
                                            @change="(value) => fieldInputBlur(item.fieldId, value)"
                                        >
                                        </a-tree-select>
                                    </div>
                                    <div v-if="item.type=='LONG_TEXT'&&item.isEdit==true" class="third-content">
                                        <a-textarea @blur="fieldInputBlur(item.fieldId,item.value)" :disabled='item.isEditable!=1' v-model="item.value" placeholder="请输入内容" :rows="2"/>
                                    </div>
                                </a-col>
                                <a-col :span="24">
                                    <a-upload
                                        :multiple="false"
                                        :customRequest="customRequest1"
                                        :showUploadList='false'
                                    >
                                        <a-button class="add-attachment" icon="plus"> 添加附件 </a-button>
                                    </a-upload>
                                </a-col>
                            </a-row>
                            <a-row>
                                <a-col :class="{'pd-24':index%2==0,'pd-8':index%2!=0,}" :span="12" v-for="(item,index) in attachmentList" :key='index'>
                                    <div class="attachment-item">
                                        <img :src="filterImg(item.suffix)" alt="">
                                        <div class="attachment-content">
                                            <div class="name-operation">
                                                <span :title='item.fileName'>{{item.fileName.length>16?item.fileName.substring(0,16)+'...':item.fileName}}</span>
                                                <a-icon @click="deleteFire(item.id)" type="delete" />
                                                <a-icon @click="downloadFire(item.id)" type="download" />
                                            </div>
                                            <div style="clear:both"></div>
                                            <div class="file-size">{{item.size}}</div>
                                        </div>
                                    </div>
                                </a-col>
                            </a-row>
                        </div>
                        <div class="related-clue">
                            <div class="title">
                                <span>关联线索</span>
                                <a-button @click="viewAllRelatedClueData" icon="sync">
                                同步标签
                                </a-button>
                            </div>
                            <a-row class="related-title">
                                <a-col :span="6">客户名称</a-col>
                                <a-col :span="6">负责人</a-col>
                                <a-col :span="6">最后动态时间</a-col>
                                <a-col :span="6">创建日期</a-col>
                            </a-row>
                            <a-row class="related-data" v-for="(item,index) in relatedData" :key='index'>
                                <div v-if="index<5">
                                    <a-col class="related-name" @click="refreshPage(item.id)" :span="6">{{item.name}}</a-col>
                                    <a-col class="related-charge" :span="6">{{item.owner}}</a-col>
                                    <a-col class="related-last-time" :span="6">{{item.lastDynamicTime}}</a-col>
                                    <a-col class="related-create-time" :span="6">{{item.createTime}}</a-col>
                                </div>
                            </a-row>
                        </div>
                        <div class="follow-time">
                            <div class="title">最后动态</div>
                            <a-row class="row-time">
                                <a-col :span="8">
                                    <div class="row-time-title">最后动态时间：</div>
                                    <div class="row-time-value">{{timeObj.last_dynamic_time}}</div>
                                </a-col>
                                <a-col :span="8">
                                    <div class="row-time-title">最后联系人：</div>
                                    <div class="row-time-value">{{timeObj.last_dynamic_user}}</div>
                                </a-col>
                                <a-col :span="8">
                                    <div class="row-time-title">下次联系时间：</div>
                                    <div class="row-time-value">{{timeObj.next_contact_time}}</div>
                                </a-col>
                            </a-row>
                        </div>
                        
                        <div class="dynamic-info">
                            <div class="title">
                                <span>动态信息</span>
                                <a-button @click="addTrack" icon="plus">
                                新增
                                </a-button>
                            </div>
                            <div class="filter-condition">
                                <span @click="selectDynamic('')" :class="{'active':currentTypeActive==''}">全部{{" ("+dynamicNumData.total+")"}}</span>
                                <span @click="selectDynamic(2)" :class="{'active':currentTypeActive==2}">跟踪轨迹{{" ("+dynamicNumData.follow+")"}}</span>
                                <span @click="selectDynamic(3)" :class="{'active':currentTypeActive==3}">通话轨迹{{" ("+dynamicNumData.chat+")"}}</span>
                                <span @click="selectDynamic(1)" :class="{'active':currentTypeActive==1}">数据轨迹{{" ("+dynamicNumData.data+")"}}</span>
                            </div>
                            <div v-for="(item,index) in dynamicList" :key='index'>
                                <div class="dynamic-list" v-if="item.type==currentTypeActive||currentTypeActive==''">
                                    <div class="date">
                                        <span>{{item.createTime.split(" ")[1]==undefined?'':item.createTime.split(" ")[0]}}</span>
                                        <span :style="{'background':item.color}"></span>
                                    </div>
                                    <div class="active-track">
                                        <div class="time">
                                            <span>{{item.operator+''}}{{item.createTime.length==19?item.createTime.split(" ")[1]:item.createTime}}</span>
                                            <div class="next-time" v-if="item.type == 2&&item.nextContactTime!=''&&item.nextContactTime!=null">
                                                <span>下次联系日期：</span>
                                                <span>{{(item.nextContactTime!=''&&item.nextContactTime!=null)?item.nextContactTime.split(" ")[0]:''}}</span>
                                            </div>
                                        </div>
                                        <div class="active-event">
                                            <span>{{item.action}}</span>
                                        </div>
                                        <div class="dynamic-content">
                                            <div style="word-wrap: break-word;word-break: break-all;" v-if="item.isEdit==false">
                                                {{item.content}}
                                                <span v-if="item.content==''&&item.type==2">请输入内容</span>
                                                <a-icon v-if="item.type==2" @click="changeDynamicData(index)" class="dynamic-icon" type="edit" />
                                            </div>
                                            <a-textarea v-if="item.isEdit==true" placeholder="请输入内容" :rows="4" :disabled='currentTypeActive==1' @blur="contentInputBlur(item.id,item.content)" v-model="item.content"/>
                                        </div>
                                        <div v-if="item.type == 2">
                                            <div class="label-content">
                                                <a-button @click="addLabel2(item.id)" icon="plus">
                                                添加标签
                                                </a-button>
                                                <span v-for="(item1,index1) in item.labelList" :style="{'border-color':item1.color,'color':item1.color}" :key='index1'>
                                                    {{item1.name}}
                                                    <a-icon @click="removeDetaillTag2(item.id, item1.labelId)" type="close" />
                                                </span>
                                            </div>
                                        </div>
                                        <div v-if="item.type == 3" class="chat-detail">
                                            <div class="play-voice" @click="viewChatDetail(item.viewUrl)">
                                                <a-icon type="play-circle" />
                                                <span>对话详情</span>
                                            </div>
                                            <span class="voice-content">通话时长{{item.duration}}s</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="comments">
                            <Comments ref="commentChild"></Comments>
                        </div>
                    </div>
                </div>
                <div class="drawer-foot">
                    <div class="btn-group">
                        <a-button v-if="operationAuthority.DELETE" type="primary" ghost @click="deleteDrawer">确定</a-button>
                    </div>
                </div>
            </div>
        </a-drawer>
        <audio controls style="position:absolute;left:-1000px;top:-1000px;" autoplay :src="musicSrc"></audio>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="incomingModal" footer='' width='480px'>
            <div class="delete-modal modal-public">
                <div class="sure-off-bind">
                    <a-icon type="warning" />
                    <span>来电显示</span>
                </div>
                <div class="tip">{{incomingMessage}}</div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group2">
                    <a-button ghost @click="cancelIncoming">取消</a-button>
                    <a-button type="primary" @click="sureIncoming">确认</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="relatedClueModal" title="关联线索" footer='' width='720px'>
            <div class="related-clue-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <div>
                    <a-table
                        :row-selection="{ selectedRowKeys: selectedRowKeys2, onChange: onSelectChange2 }"
                        :columns="columns2"
                        :data-source="relatedData"
                        :pagination='false'
                        :scroll="{ x: 'max-content' }"
                    >
                        
                    </a-table>
                </div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group">
                    <a-button type="primary" ghost @click="cancelRelatedClue">取消</a-button>
                    <a-button type="primary" @click="sureRelatedClue">同步</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="deleteAttachmentModal" footer='' width='480px'>
            <div class="delete-modal modal-public">
                <div class="sure-off-bind">
                    <a-icon type="warning" />
                    <span>您确定要删除这个附件吗？</span>
                </div>
                <div class="tip">删除后，数据无法恢复</div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group2">
                    <a-button ghost @click="cancelDeleteAttachment">取消</a-button>
                    <a-button type="primary" @click="sureDeleteAttachment">确认</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="linkModal" title="外链分享" footer='' width='600px'>
            <div class="share-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <div class="copy-link">
                    <a-input id="copyHtml1"  :placeholder="linkUrl" />
                    <span @click="copyLink(linkUrl)">复制链接</span>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="trackModal" footer='' width='480px'>
            <div class="delete-modal modal-public">
                <div class="sure-off-bind">
                    <span style="margin-left:0px">预设下次联系时间</span>
                </div>
                <div class="tip" style="padding-left:0px;">
                    <a-date-picker format="YYYY-MM-DD HH:mm:ss" v-model="currentDynamicTime"/>
                </div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group2">
                    <a-button ghost @click="offAddTrack">取消</a-button>
                    <a-button type="primary" @click="sureAddTrack">确认</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="newLabelModal" title="标签" footer='' width='720px'>
            <div class="label-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <a-row>
                    <a-col :span="16">
                        <div class="title">企业标签</div>
                        <div class="company-tag-list" v-for="(item,index) in companyLabelList" :key='index'>
                            <div class="type-name">{{item.name}}</div>
                            <div class="label-list">
                                <span @click="selectPersonalTag(item1.id, index, index1)" :style="{'color':item1.selected?'#fff':'','background':item1.selected?item1.color:''}" v-for="(item1,index1) in item.labelList" :key='index1'>{{item1.name}}</span>
                            </div>
                        </div>
                    </a-col>
                    <a-col :span="8">
                        <div class="title">个人标签</div>
                        <div class="new-tag">
                            <a-input v-model.trim="tagName" placeholder="输入标签名称" />
                            <a-icon @click="addTagName" type="plus-circle" />
                        </div>
                        <div class="tag-list">
                            <span @click="selectPersonalTag(item.id, -1, index)" :style="{'color':item.selected?'#fff':'','background':item.selected?item.color:''}" v-for="(item,index) in personalLabelList" :key='index'>
                                {{item.name}}
                                <a-icon @click="removePersonalTag(item.id)" type="close" />
                            </span>
                        </div>
                    </a-col>
                </a-row>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group">
                    <a-button type="primary" ghost @click="cancelAddTag">取消</a-button>
                    <a-button type="primary" @click="sureAddTag">确定</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="newLabelModal2" title="标签" footer='' width='720px'>
            <div class="label-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <div>
                    <div class="new-tag">
                        <a-input v-model.trim="tagName2" placeholder="输入标签名称" />
                        <a-icon @click="addTagName2" type="plus-circle" />
                    </div>
                    <div class="tag-list">
                        <span @click="selectPersonalTag2(item.id, index)" :style="{'color':item.selected?'#fff':'','background':item.selected?item.color:''}" v-for="(item,index) in personalLabelList2" :key='index'>
                            {{item.name}}
                            <a-icon @click="removePersonalTag2(item.id)" type="close" />
                        </span>
                    </div>
                </div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group">
                    <a-button type="primary" ghost @click="cancelAddTag2">取消</a-button>
                    <a-button type="primary" @click="sureAddTag2">确定</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="deleteTagModal" footer='' width='480px'>
            <div class="delete-modal modal-public">
                <div class="sure-off-bind">
                    <a-icon type="warning" />
                    <span>您确定要删除这个标签吗？</span>
                </div>
                <div class="tip">删除后，数据无法恢复</div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group2">
                    <a-button ghost @click="cancelTagDelete">取消</a-button>
                    <a-button type="primary" @click="sureTagDelete">确认</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="deleteTagModal2" footer='' width='480px'>
            <div class="delete-modal modal-public">
                <div class="sure-off-bind">
                    <a-icon type="warning" />
                    <span>您确定要删除这个标签吗？</span>
                </div>
                <div class="tip">删除后，数据无法恢复</div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group2">
                    <a-button ghost @click="cancelTagDelete2">取消</a-button>
                    <a-button type="primary" @click="sureTagDelete2">确认</a-button>
                </div>
            </div>
        </a-modal>
    </div>
</template>

<script>
    import LoginOut from './../components/LoginOut' 
    import Comments from "./../components/Comments";
    import moment from 'moment'
    export default {
        name: 'Index',
        components: { LoginOut, Comments },
        data() {
            return {
                basicInfo: '',
                extensionModal: false,
                dialModal: false,
                numValue: null,
                radioStyle: {
                    display: 'block',
                    height: '30px',
                    lineHeight: '30px',
                    color: 'rgba(80, 80, 80, 1)',
                    fontWeight: 'normal'
                },
                noNumModal: false,
                //集成内容
                janus: null,
                sipcall: null,
                opaqueId: "siptest-"+window.Janus.randomString(12),
                localTracks: {},
                remoteTracks: {},
                spinner: null,
                selectedApproach: 'secret',
                registered: false,
                masterId: null,
                helpers: {},
                helpersCount: 0,
                incoming: null,
                callNumber: '',
                extensionData: '',
                callingNumberList: [],
                dialPanelContent: '请输入号码',
                callState: 1,//0异常，1无呼叫状态，2呼叫中，3正在通话中，4呼叫失败, 5挂断, 6呼入电话
                userInfoData: '',
                drawerAddVisiable: false,
                addInfoData: {
                    name: '',
                    company: '',
                    phone: '',
                    wx: '',
                    email: '',
                    extra: '',
                },
                personalInfo: '',
                currentActive: null,
                musicSrc: '',
                callingState: 'init',
                tipMessage: '',
                tipTitle: '',
                statefocus:false,
                incomingModal: false,
                incomingMessage: '',
                currentJsep: null,
                incomingState: false,
                hasAccepted: false,
                timer2: null,
                isCalling: false,
                customerInfoData: {
                    name: '',
                    company: '',
                    phone: '',
                    wx: '',
                    email: '',
                    extra: '',
                },
                digitalDetail: {
                    dataTitle: ''
                },
                timeObj: {
                    last_dynamic_time: '',
                    next_contact_time: '',
                    last_dynamic_user: ''
                },
                phoneDetails: {
                    city: '',
                    sp: ''
                },
                operationAuthority: '',
                currentId: '',
                linkModal: false,
                linkUrl: '',
                imgId: '',
                currentSelect: '',
                drawerVisible: false,
                filterContent: '',
                newFormData1: '',
                attachmentList: [],
                currentFireId: '',
                deleteAttachmentModal: false,
                relatedClueModal: false,
                selectedRowKeys2: [],
                columns2: [
                     {
                        dataIndex: 'id',
                        key: 'id',
                        title: 'id',
                        colSpan: 0,
                        customRender: () => {
                            return {
                                attrs: {
                                    colSpan: 0,
                                },
                            };
                        },
                    },
                    {
                        dataIndex: 'name',
                        scopedSlots: {
                            customRender: 'name'
                        },
                        key: 'name',
                        title: '客户名称'
                    },
                    {
                        dataIndex: 'owner',
                        scopedSlots: {
                            customRender: 'owner'
                        },
                        key: 'owner',
                        title: '负责人'
                    },
                    {
                        dataIndex: 'lastDynamicTime',
                        scopedSlots: {
                            customRender: 'lastDynamicTime'
                        },
                        key: 'lastDynamicTime',
                        title: '最后动态时间'
                    },
                    {
                        dataIndex: 'createTime',
                        scopedSlots: {
                            customRender: 'createTime'
                        },
                        key: 'createTime',
                        title: '创建日期'
                    },
                   
                ],
                relatedData: [],
                dynamicNumData: {
                    total: 0,
                    follow: 0,
                    data: 0,
                    chat: 0
                },
                total: 0,
                dynamicList: [],
                companyLabelList: [],
                selectedLabelList: [],
                currentTypeActive: 2,
                trackModal: false,
                currentDynamicTime: null,
                newLabelModal: false,
                newLabelModal2: false,
                tagName2: '',
                tagName: '',
                personalLabelList2: [],
                personalLabelList: [],
                colorList: ['#256fe7', '#e54444', '#5dc7ff', '#ffaf00', '#23da77', '#a680ff'],
                deleteTagModal: false,
                deleteTagModal2: false,
                currentDeleteId: '',
                currentDeleteId2: '',
                chatList: [
                    {
                        role: 'robot',
                        content: 'zhes'
                    },
                    {
                        role: 'user',
                        content: 'sdfosjdflkjsdlfkjsdlkfjsldfkjsldkfjsdfsldkfjsdf'
                    },
                    {
                        role: 'robot',
                        content: '这是侧孔机健康快乐家里开 离开家拉开距离离开家略亏吉林科技离开家略亏了了垃圾了了'
                    },
                    {
                        role: 'user',
                        content: 'sdfosjdflkjsdsdfosjdflkjsdlfkjsdlkfjsldfkjsldkfjsdfsldkfjsdflfkjsdlkfjsldfkjsldkfjsdfsldkfjsdf'
                    },
                    {
                        role: 'robot',
                        content: 'sdfjhskjdfhskdjfh'
                    },
                    {
                        role: 'user',
                        content: 'sdfosjdflkjsdlfkjsdlkfjsldfkjsldkfjsdfsldkfjsdf'
                    },
                    {
                        role: 'robot',
                        content: '这是侧孔机健康快乐家里开 离开家拉开距离离开家略亏吉林科技离开家略亏了了垃圾了了'
                    },
                    {
                        role: 'user',
                        content: 'sdfosjdflkjsdsdfosjdflkjsdlfkjsdlkfjsldfkjsldkfjsdfsldkfjsdflfkjsdlkfjsldfkjsldkfjsdfsldkfjsdf'
                    },
                    {
                        role: 'robot',
                        content: 'sdfjhskjdfhskdjfh'
                    },
                    {
                        role: 'user',
                        content: 'sdfosjdflkjsdlfkjsdlkfjsldfkjsldkfjsdfsldkfjsdf'
                    },
                ],
                chatInterfaceState: 1,//1不显示，2显示三角图标，3显示对话内容
                callLineState: null,
                currentLineState: null,
                searchNumber: '',
                currentPage2: 1,
                pageSize2: 10,
                sortField: 'remainingMinutes',
                sortMode: 'desc',
                timer1: null,
                totalNumber: 0
            }
        },
        methods: {
            moment,
            onfocusText() {
                this.dialPanelContent = ''
            },
            offBlur(){
                this.statefocus = false;
                if(this.extensionData.status == 0){
                    this.dialPanelContent = '版本受限'
                }else if(this.extensionData.status == 1){
                    this.dialPanelContent = '未激活'
                }else if(this.extensionData.status == 2){
                    this.dialPanelContent = '分机号：'+ this.extensionData.extensionCode + '\n' + '您还未选择外显号码，无法拨打电话'
                }else if(this.extensionData.status == 3){
                    this.dialPanelContent = '分机号：'+ this.extensionData.extensionCode.substring(0,10) + '\n' + '外显号：' + this.extensionData.callingNumber
                }
            },
            focusTextarea() {
                if(!this.isCalling){
                    this.statefocus = true;
                    this.dialPanelContent = ''
                }
            },
            stateTip() {
                if(this.extensionData.status == 0){
                    this.tipMessage = '该版本受限，请联系管理员'
                    this.tipTitle = '版本受限'
                }else if(this.extensionData.status == 1){
                    this.tipMessage = '您尚未激活，请联系管理员'
                    this.tipTitle = '未激活'
                }else if(this.extensionData.status == 2){
                    this.tipMessage = '您还未选择外显号码，无法拨打电话'
                    this.tipTitle = '无外显号码'
                }else{
                    this.tipMessage = '注册失败，请联系管理员'
                    this.tipTitle = '注册失败'
                }
                this.noNumModal = true
            },
            queryBasicInfo() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/organization', 
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.basicInfo = response.data.data
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            jumpTo() {
                window.open(this.basicInfo.website)
            },
            inputChange() {
                clearTimeout(this.timer1)
                this.timer1 = setTimeout(()=>{
                    this.queryDisplayNumber()
                },1500)
            },
            pageChange2(current, pageSize) {
                this.currentPage2 = current
                this.pageSize2 = pageSize
                this.queryDisplayNumber()
            },
            onShowSizeChange2(current, pageSize) {
                this.currentPage2 = current
                this.pageSize2 = pageSize
                this.queryDisplayNumber()
            },
            selectSortTimes() {
                if(this.sortField=='callCount'&&this.sortMode=='asc'){
                    this.sortField = 'callCount'
                    this.sortMode = 'desc'
                }else if(this.sortField=='callCount'&&this.sortMode=='desc'){
                    this.sortField=''
                    this.sortMode=''
                }else{
                    this.sortField = 'callCount'
                    this.sortMode = 'asc'
                }
                this.queryDisplayNumber()
            },
            selectSortMinutes() {
                if(this.sortField=='remainingMinutes'&&this.sortMode=='asc'){
                    this.sortField = 'remainingMinutes'
                    this.sortMode = 'desc'
                }else if(this.sortField=='remainingMinutes'&&this.sortMode=='desc'){
                    this.sortField=''
                    this.sortMode=''
                }else{
                    this.sortField = 'remainingMinutes'
                    this.sortMode = 'asc'
                }
                this.queryDisplayNumber()
            },
            queryDisplayNumber() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/callingNumber/list', 
                        params: {
                            number: this.searchNumber,
                            current: this.currentPage2,
                            size: this.pageSize2,
                            sortField: this.sortField,
                            sortMode: this.sortMode
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.callingNumberList = response.data.data.records
                        this.totalNumber = response.data.data.total
                        if(this.callingNumberList.length > 0){
                            this.extensionModal = true
                        }else{
                            this.tipMessage = '您还没有外显号码，请联系管理员分配'
                            this.tipTitle = '未检测到外显号码'
                            this.noNumModal = true
                        }
                        for(let i = 0; i < this.callingNumberList.length; i++){
                            if(this.callingNumberList[i]['isSelected']){
                                this.numValue = this.callingNumberList[i]['id']
                            }
                        }
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            goConfigure() {
                if(this.extensionData.status == 0){
                    this.tipMessage = '该版本受限，请联系管理员'
                    this.tipTitle = '版本受限'
                    this.noNumModal = true
                    return false
                }else if(this.extensionData.status == 1){
                    this.tipMessage = '您尚未激活，请联系管理员'
                    this.tipTitle = '未激活'
                    this.noNumModal = true
                    return false
                }
                if(this.callingState == 'calling'){
                    this.$message.error('当前处于呼叫状态，不可修改外显号码！')
                    return false
                }
                this.queryDisplayNumber()
            },
            cancelNormal() {
                this.extensionModal = false
                this.numValue = null
            },
            sureNormal() {
                if(this.numValue == null){
                    this.$message.error('请选择外显号码');
                }else{
                    this.extensionModal = false
                    this.axios.post('/api/extension/chooseCallingNumber', {
                        callingNumberId: this.numValue,
                    })
                    .then(response=> {
                        if(response.data.code == 0){
                            this.$message.success('配置成功')
                            setTimeout(()=>{
                                this.startCall()
                            },2000)
                        }else{
                            this.$message.error(response.data.userTip);
                        }
                    })
                    .catch(error=> {
                        console.log(error);
                    });
                }
            },
            closeConfig() {
                if(this.callingState!='calling'){
                    this.callNumber = ''
                }
                this.dialModal = false
            },
            clearNumber(num) {
                if(this.callingState!='calling'){
                    this.callNumber = ''
                    this.currentActive = num
                    this.musicSrc = 'http://eocc.oss-cn-beijing.aliyuncs.com/icon/'+num+'.mp3' + '?' + this.getTimestamp()
                    setTimeout(()=>{
                        this.currentActive = null
                    },200)
                }
            },
            addNum(num) {
                if(this.callingState!='calling'){
                    this.callNumber = this.callNumber + '' + num
                    this.currentActive = num
                    this.musicSrc = 'http://eocc.oss-cn-beijing.aliyuncs.com/icon/'+num+'.mp3' + '?' + this.getTimestamp()
                    setTimeout(()=>{
                        this.currentActive = null
                    },200)
                }
            },
            getTimestamp() {
                return (new Date()).getTime();
            },
            changeNumber(e) {
                this.numValue = e.target.value
            },
            cancelNoNum() {
                this.noNumModal = false
            },
            sureNoNum() {
                this.noNumModal = false
            },
            startCall() {
                this.queryExtensionInfo('init')
                this.dialModal = true  
            },
            hasActivated() {
                if(!window.Janus.isWebrtcSupported()) {
                    this.$message.error("No WebRTC support... ")
                    return;
                }
                this.janus = new window.Janus(
                    {
                        server: window.server,
                        iceServers: window.iceServers,
                        success:()=> {
                            this.callingState = 'normal'
                            this.currentLineState = '1'
                            this.janus.attach(
                                {
                                    plugin: "janus.plugin.sip",
                                    opaqueId: this.opaqueId,
                                    success: (pluginHandle)=> {
                                        this.sipcall = pluginHandle;
                                        this.registerUsername()
                                    },
                                    error: (error)=> {
                                        this.callingState = 'ending'
                                        setTimeout(()=>{
                                            this.callingState = 'normal' 
                                        },1500)
                                        this.$message.error("  -- Error attaching plugin... " + error);
                                    },
                                    iceState: (state)=> {
                                        console.log("ICE state changed to " + state);
                                    },
                                    mediaState: (medium, on, mid)=> {
                                        console.log("Janus " + (on ? "started" : "stopped") + " receiving our " + medium + " (mid=" + mid + ")");
                                    },
                                    webrtcState: (on)=> {
                                        console.log("Janus says our WebRTC PeerConnection is " + (on ? "up" : "down") + " now");
                                        if(!on){
                                            this.updateIsWorking(0)
                                        }
                                    },
                                    slowLink: (uplink, lost, mid)=> {
                                        this.$message.warning("Janus reports problems " + (uplink ? "sending" : "receiving") +
                                            " packets on mid " + mid + " (" + lost + " lost packets)");
                                    },
                                    onlocaltrack: (track, on)=> {
                                        var trackId = track.id.replace(/[{}]/g, "");
                                        if(!on) {
                                            var stream = this.localTracks[trackId];
                                            if(stream) {
                                                try {
                                                    var tracks = stream.getTracks();
                                                    for(var i in tracks) {
                                                        var mst = tracks[i];
                                                        if(mst)
                                                            mst.stop();
                                                    }
                                                } catch(e) {
                                                    console.log(e)
                                                }
                                            }
                                            delete this.localTracks[trackId];
                                            return;
                                        }
                                        if(this.sipcall.webrtcStuff.pc.iceConnectionState !== "completed" &&this.sipcall.webrtcStuff.pc.iceConnectionState !== "connected") {
                                            // this.$message.success("正在呼叫")
                                        }
                                    },
                                    onremotetrack: (track, mid, on)=> {
                                        if(!on) {
                                            // Track removed, get rid of the stream and the rendering
                                            var stream = this.remoteTracks[mid];
                                            if(stream) {
                                                try {
                                                    var tracks = stream.getTracks();
                                                    for(var i in tracks) {
                                                        var mst = tracks[i];
                                                        if(mst)
                                                            mst.stop();
                                                    }
                                                } catch(e) {
                                                    console.log(e)
                                                }
                                            }
                                            delete this.remoteTracks[mid];
                                            return;
                                        }
                                        if(track.kind === "audio") {
                                            stream = new window.MediaStream([track]);
                                            this.remoteTracks[mid] = stream;
                                            window.Janus.attachMediaStream(document.getElementById("peervideom0"), stream);
                                        }
                                    },
                                    oncleanup: ()=> {
                                        if(this.sipcall) {
                                            delete this.sipcall.callId;
                                            delete this.sipcall.doAudio;
                                        }
                                        this.localTracks = {};
                                        this.remoteTracks = {};
                                        this.incomingModal = false
                                        this.incomingState = false
                                        this.hasAccepted = false
                                        this.isCalling = false
                                        clearInterval(this.timer2);
                                    },
                                    onmessage: (msg, jsep)=> {
                                        var error = msg["error"];
                                        if(error) {
                                            if(this.registered) {
                                                // Reset status
                                                this.sipcall.hangup();
                                            }
                                            this.$message.error(error);
                                            return;
                                        }
                                        var callId = msg["call_id"];
                                        var result = msg["result"];
                                        if(result && result["event"]) {
                                            var event = result["event"];
                                            if(event === 'registration_failed') {
                                                console.log('registration_failed')
                                                this.callState = 0
                                                this.$message.error('注册失败，请联系管理员')
                                                this.callingState = 'init'
                                                return;
                                            }
                                            if(event === 'registered') {
                                                if(!this.registered) {
                                                    this.registered = true;
                                                    this.masterId = result["master_id"];
                                                }
                                            } else if(event === 'calling') {
                                                this.callState = 2
                                            } else if(event === 'progress') {
                                                if(jsep) {
                                                    this.sipcall.handleRemoteJsep({ jsep: jsep, error: this.aa() });
                                                }
                                            } else if(event === 'accepted') {
                                                this.callState = 3
                                                if(jsep) {
                                                    this.sipcall.handleRemoteJsep({ jsep: jsep, error: this.aa() });
                                                }
                                                this.sipcall.callId = callId;
                                            } else if(event === 'updatingcall') {
                                                console.log("updatingcall")
                                            } else if(event === 'message') {
                                                // We got a MESSAGE
                                                var sender = result["displayname"] ? result["displayname"] : result["sender"];
                                                var content = result["content"];
                                                content = content.replace(new RegExp('<', 'g'), '&lt');
                                                content = content.replace(new RegExp('>', 'g'), '&gt');
                                                this.$message.success(content, "Message from " + sender);
                                            } else if(event === 'info') {
                                                console.log('info')
                                            } else if(event === 'notify') {
                                               console.log('notify')
                                            } else if(event === 'transfer') {
                                                console.log('transfer')
                                            } else if(event === 'hangup') {
                                                this.callingState = 'ending'
                                                setTimeout(()=>{
                                                    this.callingState = 'normal'
                                                },2000)
                                                this.callState = 5
                                                this.musicSrc =  'http://eocc.oss-cn-beijing.aliyuncs.com/icon/hang-up.mp3' + '?' + this.getTimestamp()
                                                setTimeout(()=>{
                                                    this.callState = 1
                                                },2000)
                                                this.sipcall.hangup();
                                            } else if(event === 'messagedelivery') {
                                                let reason = result["reason"];
                                                let code = result["code"];
                                                let callid = msg['call_id'];
                                                if (code == 200) {
                                                    this.$message.success(`${callid} Delivery Status: ${code} ${reason}`);
                                                } else {
                                                    this.$message.success(`${callid} Delivery Status: ${code} ${reason}`);
                                                }
                                            } else if(event === 'incomingcall') {
                                                this.dialModal = true
                                                this.incomingState = true
                                                this.isCalling = true
                                                this.sipcall.callId = callId;
                                                this.currentJsep = jsep
                                                this.callNumber = result["displayname"].substring(1,result["displayname"].length-1)
                                                this.callState = 6
                                                this.queryUserInfo()
                                                this.showChatInterface(3)
                                                setTimeout(()=>{
                                                    this.callNumber = '呼入电话：' + this.callNumber
                                                },200)
                                                // this.incomingModal = true
                                                this.musicSrc =  'http://eocc.oss-cn-beijing.aliyuncs.com/icon/incoming.mp3' + '?' + this.getTimestamp()
                                                this.timer2 = setInterval(()=>{
                                                    this.musicSrc =  'http://eocc.oss-cn-beijing.aliyuncs.com/icon/incoming.mp3' + '?' + this.getTimestamp()
                                                },3000)
                                            }
                                        }
                                    },
                                });
                        },
                        error: ()=> {
                            console.log("执行error")
                            // window.location.reload();
                        },
                        destroyed: ()=> {
                            console.log("执行destroyed")
                            // window.location.reload();
                        }
                });
            },
            registerUsername() {
                // let register = {
                //     request: "register",
                //     username: 'sip:8001231016@223.112.32.61:5070',
                //     authuser: '8001231016',
                //     display_name: '8001231016',
                //     secret: 'admin123',
                //     proxy: 'sip:223.112.32.61:5070'
                // }
                let register = {
                    request: "register",
                    username: 'sip:'+this.extensionData.extensionCode+'@'+this.extensionData.sipServer,
                    authuser: this.extensionData.extensionCode,
                    display_name: this.extensionData.extensionCode,
                    secret: this.extensionData.password,
                    proxy: 'sip:'+this.extensionData.sipServer
                }
                console.log(register)
                this.sipcall.send({ message: register })
                return;
            },
            doCall() {
                var regex = /^[0-9]+$/; // 直接量正则表达式
                if (regex.test(this.callNumber)) {
                    this.queryUserInfo()
                    this.actuallyDoCall(this.sipcall, 'sip:'+this.callNumber+'@'+this.extensionData.sipServer, false);
                    this.callingState = 'calling'
                    this.isCalling = true
                }else{
                    this.$message.error('输入的号码有误，请重新输入！')
                }
            },
            actuallyDoCall(handle, uri, doVideo) {
                handle.doAudio = true;
                handle.doVideo = doVideo;
                let tracks = [{ type: 'audio', capture: true, recv: true }];
                handle.createOffer(
                    {
                        tracks: tracks,
                        success: (jsep)=> {
                            this.updateIsWorking(1)
                            this.musicSrc =  'http://eocc.oss-cn-beijing.aliyuncs.com/icon/wait.mp3' + '?' + this.getTimestamp()
                            var body = { request: "call", uri: uri };
                            body["autoaccept_reinvites"] = false;
                            handle.send({ message: body, jsep: jsep });
                        },
                        error: (error)=> {
                            console.log("WebRTC error...", error);
                        }
                    });
            },
            aa(){
                console.log('触发接通事件')
            },
            doHangup() {
                this.isCalling = false
                let hangup = { request: "hangup" };
                this.sipcall.send({ message: hangup });
                this.sipcall.hangup();
                this.updateIsWorking(0)
            },
            queryExtensionInfo(params) {
                this.axios(
                    {
                        method:'get',
                        url:'/api/extension/info', 
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.extensionData = response.data.data//1未激活，2激活没有外显号码，3正常
                        if(response.data.data.status == 0){
                            this.callState = 0
                            this.dialPanelContent = '版本受限'
                            this.tipMessage = '该版本受限，请联系管理员'
                            this.tipTitle = '版本受限'
                            this.noNumModal = true
                        }else if(response.data.data.status == 1){
                            this.dialPanelContent = '未激活'
                            this.callState = 0
                            this.tipMessage = '您尚未激活，请联系管理员'
                            this.tipTitle = '未激活'
                            this.noNumModal = true
                        }else if(response.data.data.status == 2){
                            this.dialPanelContent = '分机号：'+ response.data.data.extensionCode + '\n' + '您还未选择外显号码，无法拨打电话'
                            this.callState = 0
                        }else if(response.data.data.status == 3){
                            this.callState = 1
                            this.dialPanelContent = '分机号：'+ response.data.data.extensionCode.substring(0,10) + '\n' + '外显号：' + response.data.data.callingNumber
                            if(params == 'init'){
                                this.hasActivated()
                            }else{
                                this.callingState = 'normal'
                            }
                        }
                        setTimeout(()=>{
                            this.currentLineState = response.data.data.onlineStatus+''
                        },500)
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error)
                }) 
            },
            queryUserInfo() {
                this.axios(
                   {
                        method:'get',
                        url:'/api/customer/queryCustomerByPhone', 
                        params: {
                            phone: this.callNumber,
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.userInfoData = response.data.data
                        if(response.data.data != null){
                            this.currentId = response.data.data.customerId
                            this.queryColumnList()
                            this.querySelectData()
                        }
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            addUser() {
                this.drawerAddVisiable = true
                this.addInfoData.phone = this.callNumber
            },
            onClose2() {
                this.drawerAddVisiable = false
                this.addInfoData = {
                    name: '',
                    company: '',
                    phone: '',
                    wx: '',
                    email: '',
                    extra: '',
                }
            },
            addTask(){
                this.axios.post('/api/customer', {
                    name: this.addInfoData.name,
                    phone: this.addInfoData.phone,
                    corporateName: this.addInfoData.company,
                    wechatNumber: this.addInfoData.wx,
                    email: this.addInfoData.email,
                    remark: this.addInfoData.extra
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.$message.success('添加成功')
                        this.addInfoData = {
                            name: '',
                            company: '',
                            phone: '',
                            wx: '',
                            email: '',
                            extra: '',
                        }
                        this.queryUserInfo()
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            submitDrawer() {
                this.addTask()
                this.drawerAddVisiable = false
            },
            offEditDrawer() {
                this.drawerAddVisiable = false
                this.addInfoData = {
                    name: '',
                    company: '',
                    phone: '',
                    wx: '',
                    email: '',
                    extra: '',
                }
            },
            queryPersonalInfo() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/user', 
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.personalInfo = response.data.data
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            grandMethod(val){
                if(this.callingState == 'init'){
                    this.callNumber = val
                    this.startCall()
                }else if(this.callingState == 'normal'){
                    this.callNumber = val
                    this.dialModal = true
                }else if(this.callingState == 'calling'){
                    this.$message.error('当前正在通话中，请稍后再拨！');
                }
            },
            continueCall() {
                this.dialModal = true
                if(this.callState != 6){
                    this.queryExtensionInfo('continue')
                }
            },
            showCalling() {
                this.dialModal = true
            },
            cancelIncoming() {
                clearInterval(this.timer2)
                console.log("执行挂断")
                this.callNumber = ''
                this.incoming = null;
                var body = { request: "decline" };
                this.sipcall.send({ message: body });
                let hangup = { request: "hangup" };
                this.sipcall.send({ message: hangup });
                this.sipcall.hangup();
                this.updateIsWorking(0)
            },
            sureIncoming() {
                clearInterval(this.timer2);
                this.hasAccepted = true
                var doAudio = true
                var offerlessInvite = false;
                this.updateIsWorking(1)
                if(this.currentJsep) {
                    doAudio = (this.currentJsep.sdp.indexOf("m=audio ") > -1);
                } else {
                    console.log("This call doesn't contain an offer... we'll need to provide one ourselves");
                    offerlessInvite = true;
                }
                this.incoming = null;
                var sipcallAction = (offerlessInvite ? this.sipcall.createOffer : this.sipcall.createAnswer);
                // We want bidirectional audio and/or video
                let tracks = [];
                if(doAudio){
                    tracks.push({ type: 'audio', capture: true, recv: true })
                }
                let that = this
                sipcallAction(
                    {
                        jsep: this.currentJsep,
                        tracks: tracks,
                        success: function(jsep) {
                            that.sipcall.doAudio = doAudio;
                            var body = { request: "accept" };
                            body["autoaccept_reinvites"] = false;
                            that.sipcall.send({ message: body, jsep: jsep });
                        },
                        error: function(error) {
                            console.log("WebRTC error... " + error.message);
                            var body = { request: "decline", code: 480 };
                            that.sipcall.send({ message: body });
                        }
                    });
            },
            showCustomerDetail() {
                this.drawerVisible = true
                this.customerInfoData.phone = this.callNumber
                this.querySelectData()
                this.queryRelatedData()
                this.queryDynamicData()
                this.queryAttachmentData()
            },
            querySelectData() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/module/data/detail', 
                        params: {
                            moduleId: 8,
                            dataId: this.currentId
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.digitalDetail = response.data.data
                        let arr = response.data.data.detailList
                        for(let i = 0;i < arr.length; i++){
                            if(arr[i]['code'] == 'phone'){
                                this.queryPhoneDetail(arr[i]['value'])
                            }
                            if(arr[i]['code'] == 'last_dynamic_time'){
                                this.timeObj.last_dynamic_time = arr[i]['value']
                            }
                            if(arr[i]['code'] == 'next_contact_time'){
                                this.timeObj.next_contact_time = arr[i]['value']
                            }
                            if(arr[i]['code'] == 'last_dynamic_user'){
                                this.timeObj.last_dynamic_user = arr[i]['value']
                            }
                        }
                        this.$refs.commentChild.getParentCommentTree(8, this.currentId, true)
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            queryPhoneDetail(value) {
                this.axios(
                    {
                        method:'get',
                        url:"/api/customer/qCellCore", 
                        params: {
                            phone: value,
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        if(response.data.data.data.city==''&&response.data.data.data.province==''){
                            this.phoneDetails.city = '未知号码归属地'
                        }else if(response.data.data.data.city==''&&response.data.data.data.province!=''){
                            this.phoneDetails.city = response.data.data.data.province
                        }else{
                            this.phoneDetails.city = response.data.data.data.city
                        }
                        this.phoneDetails.sp = response.data.data.data.sp
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            queryColumnList() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/module/8/listPage/init', 
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.operationAuthority = response.data.data.operationAuthority
                    }else{
                        this.$message.error(response.data.userTip)
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            deleteDrawer() {
                this.drawerVisible = false
                this.deleteModal = true
            },
            viewChatDetail(url) {
                if(url == ''){
                    this.$message.warning('该对话未接通')
                }else{
                    window.open(url, '_blank');
                }
            },
            sureDelete() {
                this.deleteModal = false
                this.axios.post('/api/module/data/delete/batch', {
                    moduleId: 8,
                    idList: [this.currentId],
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.$message.success('删除成功')
                        this.queryBasicData()
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            cancelDelete() {
                this.deleteModal = false
            },
            viewDetail() {
                let routeUrl = this.$router.resolve({
                    path: "/customerLeadDetail",
                    query: {
                        id: this.currentId,
                        moduleId: 8,
                    }
                });
                window.open(routeUrl.href, '_blank');
            },
            viewSharing() {
                event.stopPropagation();
                this.linkModal = true
                let shareId = ''
                this.axios.post('/api/externalLinks',{
                    moduleId: 8,
                    dataId: this.currentId
                })
                .then(response=> {
                    if(response.data.code == 0){
                        shareId = response.data.data.id
                        let url1 = window.location.origin
                        this.linkUrl = url1+'/#/customerLeadShare?id='+shareId+'&moduleId=8'
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            onClose() {
                this.drawerVisible = false
                this.imgId = ''
                this.currentSelect = ''
                this.queryUserInfo()
            },
            playPhone(value) {
                // this.$emit('fatherMethod', value);
                this.$emit('grandMethod',value)
                console.log(value)
            },
            viewTianYan(value) {
                window.open("https://www.tianyancha.com/search?key="+value, "_blank");
            },
            changeBasicEdit(index) {
                this.digitalDetail.detailList[index]['isEdit'] = true
            },
            removeDetaillTag(id) {
                event.stopPropagation()
                this.axios.delete('/api/customer/deleteCustomerLabel',{
                    params: {
                        customerId: this.currentId,
                        labelId: id
                    }
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.querySelectData()
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            fieldInputBlur(id, value) {
                this.axios.post('/api/customer/updateCustomer', {
                    customerId: this.currentId,
                    fieldId: id,
                    value: value,
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.querySelectData()
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            customRequest1(data) {
                this.newFormData1 = ''
                const formData = new FormData() 
                formData.append('file', data.file)
                this.newFormData1 = formData
                this.axios({
                    method: 'post',
                    url: '/api/file/upload',
                    headers:{
                        'Content-Type':'application/x-www-form-urlencoded'
                    },
                    data: this.newFormData1,
                
                }).then((response) => {
                    if(response.data.code == 0){
                        this.newFormData1 = ''
                        this.addAtachment(response.data.data.fileId)
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                }).catch(function (error) {
                    console.log(error)
                })
            },
            addAtachment(attachment) {
                this.axios.post('/api/customer/attachment', {
                    customerId: this.currentId,
                    attachment: attachment,
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.queryAttachmentData()
                        this.queryDynamicData()
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            queryAttachmentData() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/customer/attachmentList', 
                        params: {
                            customerId: this.currentId,
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.attachmentList = response.data.data
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            downloadFire(id) {
                window.open('/api/customer/downloadAttachment?id='+id, '_blank');
            },
            deleteFire(id) {
                this.currentFireId = id
                this.deleteAttachmentModal = true
            },
            cancelDeleteAttachment() {
                this.deleteAttachmentModal = false
            },
            sureDeleteAttachment() {
                this.deleteAttachmentModal = false
                this.axios.delete('/api/customer/attachment',{
                    params: {
                        id: this.currentFireId,
                    }
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.$message.success('删除成功')
                        this.queryAttachmentData()
                        this.queryDynamicData()
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            filterImg(type) {
                let url = "http://eocc.oss-cn-beijing.aliyuncs.com/icon/"
                let suffix = type.toLowerCase();
                if (suffix == "xls" || suffix == "xlsx") {
                    url+="excel.png";
                } else if (suffix == "doc" || suffix == "docx") {
                    url+="word.png";
                } else if (suffix == "zip" || suffix == "rar") {
                    url+="zip.png";
                } else if (suffix == "ppt") {
                    url+="ppt.png";
                } else if (suffix == "pptx") {
                    url+="ppt.png";
                } else if (suffix == "pdf") {
                    url+="pdf.png";
                } else if (suffix == "txt") {
                    url+="txt.png";
                } else {
                    url+="unknown.png";
                }
                return url
            },
            queryDynamicData() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/customer/dynamic/list', 
                        params: {
                            customerId: this.currentId,
                            type: ''
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        let arr = response.data.data
                        let brr = []
                        this.dynamicNumData = {
                            total: arr.length,
                            follow: 0,
                            data: 0,
                            chat: 0
                        }
                        for(let i = 0; i < arr.length; i++){
                            if(arr[i]['type'] == 1){
                               this.dynamicNumData.data++ 
                            }else if(arr[i]['type'] == 2){
                                this.dynamicNumData.follow++ 
                            }else if(arr[i]['type'] == 3){
                                this.dynamicNumData.chat++ 
                            }

                            if(arr[i]['type'] == this.currentTypeActive||this.currentTypeActive==''){
                                if(brr.indexOf(arr[i]['createTime'].split(" ")[0])==-1){
                                    brr.push(arr[i]['createTime'].split(" ")[0])
                                }else{
                                    arr[i]['createTime'] = arr[i]['createTime'].split(" ")[1]
                                }
                            }
                        }
                        this.dynamicList = arr
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            viewAllRelatedClueData() {
                this.relatedClueModal = true
            },
            cancelRelatedClue() {
                this.relatedClueModal = false
            },
            sureRelatedClue() {
                if(this.selectedRowKeys2.length == 0){
                    this.$message.error('请先选择关联线索')
                }else{
                    this.relatedClueModal = false
                    this.axios.post('/api/customer/syncCustomerLabel', {
                        customerId: this.currentId,
                        associatedCustomerIdList: this.selectedRowKeys2,
                    })
                    .then(response=> {
                        if(response.data.code == 0){
                            this.selectedRowKeys2 = []
                            this.querySelectData()
                            this.$message.success('同步完成')
                        }else{
                            this.$message.error(response.data.userTip);
                            if(response.data.code == 'A0200'){
                                this.$router.push("/login")
                            }
                        }
                    })
                    .catch(error=> {
                        console.log(error);
                    });
                }
                
            },
            addTrack() {
                this.currentDynamicTime = this.timeObj.next_contact_time==''?null:this.timeObj.next_contact_time
                this.trackModal = true
            },
            onSelectChange2(selectedRowKeys) {
                this.selectedRowKeys2 = selectedRowKeys;
            },
            addLabel() {
                this.newLabelModal = true
                this.selectedLabelList = []
                this.queryCompanyLabelData()
                this.queryPersonalTag()
            },
            randomColor() {
                let num = Math.floor(Math.random()*6)
                let color = this.colorList[num]
                return color
            },
            queryPersonalTag() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/customer/personalLabel/list', 
                        params: {
                            customerId: this.currentId
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.personalLabelList = response.data.data
                        for(let i = 0; i < response.data.data.length; i++){
                            if(response.data.data[i]['selected']){
                                this.selectedLabelList.push(response.data.data[i]['id'])
                            }
                        }
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            selectPersonalTag(id, index1, index2) {
                let index = this.selectedLabelList.indexOf(id);
                if(index == -1){
                    this.selectedLabelList.push(id)
                    if(index1 == -1){
                        this.personalLabelList[index2]['selected'] = true
                    }else{
                        this.companyLabelList[index1]['labelList'][index2]['selected'] = true
                    }
                }else{
                    this.selectedLabelList = this.selectedLabelList.filter(item => item != id)
                    if(index1 == -1){
                        this.personalLabelList[index2]['selected'] = false
                    }else{
                        this.companyLabelList[index1]['labelList'][index2]['selected'] = false
                    }
                }
            },
            cancelAddTag() {
                this.newLabelModal = false
                this.selectedLabelList = []
            },
            sureAddTag() {
                this.newLabelModal = false
                this.axios.post('/api/customer/setCustomerLabel', {
                    customerId: this.currentId,
                    labelIdList: this.selectedLabelList,
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.querySelectData()
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            cancelTagDelete2() {
                this.deleteTagModal2 = false
            },
            cancelTagDelete() {
                this.deleteTagModal = false
            },
            sureTagDelete() {
                this.deleteTagModal = false
                this.axios.delete('/api/customer/dynamicLabel',{
                    params: {
                        id: this.currentDeleteId
                    }
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.selectedLabelList2 = []
                        this.queryPersonalTag2()
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            sureTagDelete2() {
                this.deleteTagModal2 = false
                this.axios.delete('/api/customer/personalLabel',{
                    params: {
                        id: this.currentDeleteId2
                    }
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.selectedLabelList = []
                        this.queryCompanyLabelData()
                        this.queryPersonalTag()
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            removePersonalTag(id) {
                event.stopPropagation()
                this.deleteTagModal2 = true
                this.currentDeleteId2 = id
            },
            removeDetaillTag2(dynamicId, id) {
                event.stopPropagation()
                this.axios.delete('/api/customer/deleteDynamicLabel',{
                    params: {
                        labelId: id,
                        dynamicId: dynamicId
                    }
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.queryDynamicData()
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            queryCompanyLabelData() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/customer/enterpriseLabel/list', 
                        params: {
                            customerId: this.currentId
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.companyLabelList = response.data.data
                        for(let i = 0; i < response.data.data.length; i++){
                            for(let j = 0; j < response.data.data[i]['labelList'].length; j++){
                                if(response.data.data[i]['labelList'][j]['selected']){
                                    this.selectedLabelList.push(response.data.data[i]['labelList'][j]['id'])
                                }
                            }
                        }
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            selectDynamic(type) {
                this.currentTypeActive = type
                this.queryDynamicData()
            },
            sureAddTrack() {
                this.axios.post('/api/customer/dynamic', {
                    customerId: this.currentId,
                    nextContactTime: this.currentDynamicTime!=null?this.transformTimestamp(this.currentDynamicTime):''
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.trackModal = false
                        this.queryDynamicData()
                        this.querySelectData()
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            offAddTrack() {
                this.trackModal = false
            },
            transformTimestamp(timestamp) {
                let a = new Date(timestamp).getTime();
                const date = new Date(a);
                const Y = date.getFullYear() + '-';
                const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
                const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
                const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
                const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
                const s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
                const dateString = Y + M + D + h + m + s;
                return dateString;
            },
            addLabel2(id) {
                this.newLabelModal2 = true
                this.selectedLabelList2 = []
                this.dynamicId = id
                this.queryPersonalTag2()
            },
            queryPersonalTag2() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/customer/dynamicLabel/list', 
                        params: {
                            dynamicId: this.dynamicId
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.personalLabelList2 = response.data.data
                        for(let i = 0; i < response.data.data.length; i++){
                            if(response.data.data[i]['selected']){
                                this.selectedLabelList2.push(response.data.data[i]['id'])
                            }
                        }
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            selectPersonalTag2(id, index2) {
                let index = this.selectedLabelList2.indexOf(id);
                if(index == -1){
                    this.selectedLabelList2.push(id)
                    this.personalLabelList2[index2]['selected'] = true
                }else{
                    this.selectedLabelList2 = this.selectedLabelList2.filter(item => item != id)
                    this.personalLabelList2[index2]['selected'] = false
                }
            },
            removePersonalTag2(id) {
                event.stopPropagation()
                this.deleteTagModal = true
                this.currentDeleteId = id
            },
            cancelAddTag2() {
                this.newLabelModal2 = false
                this.selectedLabelList2 = []
            },
            sureAddTag2() {
                this.newLabelModal2 = false
                this.axios.post('/api/customer/setDynamicLabel', {
                    dynamicId: this.dynamicId,
                    labelIdList: this.selectedLabelList2,
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.queryDynamicData()
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            addTagName() {
                if(this.tagName.length == 0){
                    this.$message.error('请输入标签名称')
                }else{
                    this.axios.post('/api/customer/personalLabel', {
                        name: this.tagName,
                        color: this.randomColor(),
                    })
                    .then(response=> {
                        if(response.data.code == 0){
                            this.selectedLabelList = []
                            this.queryCompanyLabelData()
                            this.queryPersonalTag()
                            this.tagName = ''
                        }else{
                            this.$message.error(response.data.userTip);
                            if(response.data.code == 'A0200'){
                                this.$router.push("/login")
                            }
                        }
                    })
                    .catch(error=> {
                        console.log(error);
                    });
                }
            },
            addTagName2() {
                if(this.tagName2.length == 0){
                    this.$message.error('请输入标签名称')
                }else{
                    this.axios.post('/api/customer/dynamicLabel', {
                        name: this.tagName2,
                        color: this.randomColor(),
                    })
                    .then(response=> {
                        if(response.data.code == 0){
                            this.selectedLabelList2 = []
                            this.queryPersonalTag2()
                            this.tagName2 = ''
                        }else{
                            this.$message.error(response.data.userTip);
                        }
                    })
                    .catch(error=> {
                        console.log(error);
                    });
                }
            },
            queryRelatedData() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/customer/associatedCustomerList', 
                        params: {
                            customerId: this.currentId
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.relatedData = response.data.data
                        for(let i = 0; i < this.relatedData.length; i++){
                            this.relatedData[i]['key'] = this.relatedData[i]['id']
                        }
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            copyLink(url) {
                this.linkModal = false
                let text = url
                let input = document.getElementById("copyHtml1");
                input.value = text; 
                input.select(); 
                document.execCommand("copy"); 
                this.$message.success('已复制！')
            },
            showChatInterface(bool) {
                if(bool != 3){
                    this.chatInterfaceState = bool
                }
                let num = ''
                if(this.callNumber.indexOf("：")!=-1){
                    num = this.callNumber.split("：")[1]
                }else{
                    num = this.callNumber
                }
                if(bool==3) {
                    this.axios(
                        {
                            method:'get',
                            url:'/api/extension/dialogue', 
                            params: {
                                phone: num,
                            }
                        }
                    )
                    .then(response=> {
                        if(response.data.code == 0){
                            if(response.data.data == null){
                                this.chatInterfaceState = 1
                            }else{
                                this.chatList = response.data.data.dialogue
                                this.chatInterfaceState = 3
                            }
                        }else{
                            this.$message.error(response.data.userTip);
                            if(response.data.code == 'A0200'){
                                this.$router.push("/login")
                            }
                        }
                    })
                    .catch(error=> {
                        console.log(error)
                    })
                }
            },
            changeState(value) {
                this.currentLineState = value
                this.axios.post('/api/extension/updateOnlineStatus', {
                    onlineStatus: value,
                })
                .then(response=> {
                    if(response.data.code == 0){
                        if(value == 1){
                            this.$message.success('已上线');
                        }else{
                            this.$message.warning('已下线');
                        }
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            updateIsWorking(value) {
                this.axios.post('/api/extension/updateIsWorking', {
                    isWorking: value,
                })
                .then(response=> {
                    if(response.data.code != 0){
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            }
        },
        directives:{
            Focus:{
                update:function(el,{value}){
                    if(value){ // if(true)就聚焦
                        el.focus();
                    } 
                }
            }
        },
        mounted: function() {
            this.queryBasicInfo()
            this.queryPersonalInfo()
        }
    }
</script>

<style scoped lang="less">
    .index{
        // display: flex;
        height: 100%;
        // flex-direction: column;
        position: relative;
        .head{
            height: 56px;
            width: 100%;
            padding: 10px 24px;
            border-bottom: 1px solid rgba(232, 235, 238, 1);
            position: absolute;
            z-index: 100;
            .logo{
                height: 36px;
                line-height: 36px;
                display: inline-block;
                cursor: pointer;
                img{
                    width: 36px;
                    height: 36px;
                    float: left;
                }
                span{
                    font-size: 18px;
                    color: rgba(51, 51, 51, 1);
                    font-weight: bold;
                    margin-left: 12px;
                    float: left;
                }
            }
        }
        .content{
            // flex: 1;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            padding-top: 56px;
            width: 100%;
            .call-icon{
                position: absolute;
                bottom: 28px;
                left: 28px;
                width: 52px;
                height: 52px;
                border-radius: 26px;
                color: #fff;
                line-height: 45px;
                text-align: center;
                font-size: 24px;
                z-index: 100;
                cursor: pointer;
                img{
                    width: 26px;
                }
            }
            .call-icon1{
                background: rgba(121, 136, 155, 1);
                box-shadow: 0px 2px 12px 2px rgba(121, 136, 155, 0.24);
            }
            .call-icon2{
                background: rgba(212, 48, 48, 1);
                box-shadow: 0px 2px 12px 2px rgba(212, 48, 48, 0.24);
            }
            .call-icon3{
                background: rgba(35, 218, 119, 1);
                box-shadow: 0px 2px 12px 2px rgba(10, 191, 91, 0.24);
            }
            .call-icon4{
                background: rgba(24, 144, 255, 1);
                box-shadow: 0px 2px 12px 2px rgba(10, 191, 91, 0.24);
            }
            .call2{
                position: absolute;
                bottom: 28px;
                left: 88px;
            }
            .online-state{
                width: 20px;
                height: 20px;
                border-radius: 10px;
                background: rgba(42, 130, 228, 1);
                position: absolute;
                bottom: 30px;
                left: 60px;
                z-index: 1000;
                text-align: center;
                line-height: 20px;
                i{
                    font-size: 12px;
                    color: #fff;
                }
            }
            .offline-state{
                width: 20px;
                height: 20px;
                border-radius: 10px;
                background: rgba(212, 48, 48, 1);
                position: absolute;
                bottom: 30px;
                left: 60px;
                z-index: 1000;
                text-align: center;
                line-height: 20px;
                i{
                    font-size: 12px;
                    color: #fff;
                }
            }
            .dial-body{
                position: absolute;
                left: 28px;
                bottom: 100px;
                box-shadow: 0px 2px 32px 0px rgba(0, 0, 0, 0.16);
                width: 320px;
                height: 538px;
                background: #fff;
                border-radius: 4px;
                z-index: 100;
                .dial-content{
                    width: 100%;
                    height: 100%;
                    padding: 18px 24px;
                    position: relative;
                    .show-chat-btn{
                        display: inline-block;
                        width: 36px;
                        height: 36px;
                        border-radius: 18px;
                        background: #fff;
                        position: absolute;
                        right: -56px;
                        top: 50%;
                        margin-top: -18px;
                        text-align: center;
                        line-height: 36px;
                        box-shadow: 0px 2px 32px 2px rgba(121, 136, 155, 0.24);
                        cursor: pointer;
                    }
                    .chat-content{
                        width: 640px;
                        height: 538px;
                        box-shadow: 0px 2px 32px 0px rgba(0, 0, 0, 0.16);
                        position: absolute;
                        right: -660px;
                        top: 0px;
                        background: #fff;
                        border-radius: 4px;
                        padding: 20px 12px;
                        .chat-head{
                            span{
                                font-size: 14px;
                                color: rgba(18, 18, 18, 1);
                            }
                            i{
                                color: rgba(153, 153, 153, 1);
                                font-size: 16px;
                                float: right;
                                cursor: pointer;
                            }
                        }
                        .chat-body{
                            height: 475px;
                            overflow-y: auto;
                            .robot-content{
                                float: left;
                                width: 440px;
                                margin-top: 17px;
                                img{
                                    float: left;
                                    width: 32px;
                                    margin-right: 8px;
                                }
                                .chat-details{
                                    float: left;
                                    color: rgba(80, 80, 80, 1);
                                    width: 302px;
                                    div{
                                        display: inline-block;
                                        font-size: 14px;
                                        background: rgba(121, 136, 155, 0.1);
                                        padding: 8px 12px;
                                        border-radius: 4px;
                                    }
                                }
                            }
                            .user-content{
                                float: right;
                                width: 440px;
                                margin-top: 17px;
                                img{
                                    float: right;
                                    width: 32px;
                                    margin-left: 8px;
                                }
                                .chat-details{
                                    float: right;
                                    width: 302px;
                                    div{
                                        display: inline-block;
                                        background: rgba(34, 215, 187, 1);
                                        font-size: 14px;
                                        border-radius: 4px;
                                        color: #fff;
                                        padding: 8px 12px;
                                        word-break: break-all;
                                    }
                                }
                            }
                            .clean{
                                clear: both;
                            }
                        }
                    }
                }
                .dial-head{
                    .no-configured{
                        display: inline-block;
                        width: 62px;
                        height: 26px;
                        text-align: center;
                        line-height: 26px;
                        color: rgba(24, 144, 255, 1);
                        background: rgba(24, 144, 255, 0.05);
                        border-radius: 4px;
                        font-size: 14px;
                        float: left;
                        border: 1px solid rgba(24, 144, 255, 0.5);
                    }
                    .no-configured2{
                        display: inline-block;
                        width: 62px;
                        height: 26px;
                        text-align: center;
                        line-height: 26px;
                        color: rgba(212, 48, 48, 1);
                        background: rgba(212, 48, 48, 0.05);
                        border-radius: 4px;
                        font-size: 14px;
                        float: left;
                        border: 1px solid rgba(212, 48, 48, 0.5);
                    }
                    .line-current-state{
                        display: inline-block;
                        border: none;
                        width: 70px;
                        height: 26px;
                        overflow: hidden;
                        position: relative;
                        background: #fff;
                        .line-current-select{
                            position: absolute;
                            left: -2px;
                            top: -2px;
                            width: 74px;
                        }
                    }
                    .go-configured{
                        float: right;
                        margin-top: 5px;
                        span{
                            color: rgba(24, 144, 255, 1);
                            font-size: 14px;
                            margin-right: 16px;
                            float: left;
                            text-decoration: underline;
                            cursor: pointer;
                        }
                        i{
                            color: rgba(153, 153, 153, 1);
                            font-size: 16px;
                            float: right;
                            margin-top: 3px;
                            font-weight: bold;
                            cursor: pointer;
                        }
                    }
                }
                .num-textarea{
                    width: 100%;
                    height: 150px;
                    margin-top: 16px;
                    border: 1px solid rgba(229, 229, 229, 1);
                    resize:none;
                    padding: 18px 16px;
                    // font-size: 20px;
                    // font-weight: bold;
                }
                .calling-state{
                    position: absolute;
                    display: inline-block;
                    width: 270px;
                    height: 93px;
                    // background: red;
                    top: 110px;
                    left: 25px;
                    padding: 10px 16px 0;
                    .calling-2{
                        color: rgba(255, 141, 26, 1);
                        font-size: 14px;
                    }
                    .calling-3{
                        color: rgba(10, 191, 91, 1);
                        font-size: 14px;
                    }
                    .calling-4{
                        color: rgba(212, 48, 48, 1);
                        font-size: 14px;
                    }
                    .userinfo-data{
                        margin-top: 17px;
                        display: flex;
                        .userinfo-list{
                            flex: 1;
                            .no-in-database{
                                color: rgba(18, 18, 18, 1);
                                font-size: 16px;
                            }
                            .no-charge-person{
                                color: rgba(121, 136, 155, 0.7);
                                font-size: 13px;
                                margin-top: 4px;
                            }
                        }
                        .userinfo-icon{
                            display: inline-block;
                            width: 24px;
                            margin-top: 10px;
                            i{
                                font-size: 18px;
                                cursor: pointer;
                            }
                        }
                    }
                }
                .dial-keypad{
                    display: inline-block;
                    width: 240px;
                    margin: 0 16px;
                    margin-top: 15px;
                    background: rgba(229, 229, 229, 0.3);
                    height: 224px;
                    border-radius: 6px;
                    padding: 16px 0 0 19px;
                    .dial-list{
                        margin-bottom: 12px;
                        span{
                            display: inline-block;
                            width: 56px;
                            height: 38px;
                            text-align: center;
                            line-height: 38px;
                            border-radius: 6px;
                            color: rgba(18, 18, 18, 1);
                            font-size: 22px;
                            margin-right: 16px;
                            background: rgba(229, 229, 229, 1);
                            cursor: pointer;
                        }
                        div.dial-none{
                            background: rgba(229, 229, 229, 0);
                            cursor: default;
                            color: rgba(229, 229, 229, 0);
                            display: inline-block;
                            width: 56px;
                            height: 38px;
                            margin-right: 16px;
                        }
                        span.dial-clear{
                            font-size: 19px;
                        }
                        span:hover{
                            background: rgba(238, 238, 238, 1);
                        }
                        span.active{
                            background: rgba(208, 208, 208, 1);
                        }
                    }
                }
                .btn-call{
                    margin-top: 16px;
                    text-align: center;
                    span{
                        display: inline-block;
                        width: 54px;
                        height: 54px;
                        line-height: 54px;
                        font-size: 28px;
                        box-shadow: 0px 2px 32px 0px rgba(0, 0, 0, 0.16);
                        background: rgba(121, 136, 155, 0);;
                        border-radius: 27px;
                        text-align: center;
                    }
                    span.abnormal{
                        cursor: pointer;
                        color: rgba(121, 136, 155, 1);
                        i{
                            transform:rotate(90deg)
                        }
                    }
                    span.waiting-call{
                        cursor: pointer;
                        color: rgba(67, 207, 124, 1);
                        i{
                            transform:rotate(90deg)
                        }
                    }
                    span.hang-up{
                        cursor: pointer;
                        color: rgba(255, 87, 51, 1);
                        i{
                            transform:rotate(225deg)
                        }
                    }
                }
                .accepting-btn{
                    margin-top: 16px;
                    text-align: center;
                    span{
                        display: inline-block;
                        width: 54px;
                        height: 54px;
                        line-height: 54px;
                        font-size: 28px;
                        box-shadow: 0px 2px 32px 0px rgba(0, 0, 0, 0.16);
                        background: rgba(121, 136, 155, 0);;
                        border-radius: 27px;
                        text-align: center;
                    }
                    span.waiting-call{
                        cursor: pointer;
                        margin-right: 36px;
                        color: rgba(67, 207, 124, 1);
                        i{
                            transform:rotate(90deg)
                        }
                    }
                    span.hang-up{
                        cursor: pointer;
                        color: rgba(255, 87, 51, 1);
                        i{
                            transform:rotate(225deg)
                        }
                    }
                }
            }
        }
    }
    .modal-public{
        .divider1{
            margin: 0px 0 12px 0;
        }
        .divider2{
            margin: 24px 0 12px;
        }
        .btn-group{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                margin-right: 16px;
            }
        }
        .btn-group2{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                background: #fff;
                color: rgba(121, 136, 155, 0.7);
                border: 1px solid rgba(121, 136, 155, 0.5);
                margin-right: 16px;
            }
            button:nth-child(1):hover{
                background: rgba(121, 136, 155, 0.08);
            }
        }
    }
    .normal-modal{
        padding-bottom: 46px;
        .number-table{
            height: 40px;
            background: #F2F3F5;
            div{
                display: inline-block;
                text-align: center;
                float: left;
            }
            div:nth-child(1){
                width: 45px;
                line-height: 40px;
            }
            div:nth-child(2){
                width: 150px;
                line-height: 40px;
            }
            div:nth-child(3){
                width: 130px;
            }
            div:nth-child(4){
                width: 150px;
                line-height: 40px;
            }
            div:nth-child(5){
                width: 120px;
            }
            div:nth-child(6){
                width: 150px;
                line-height: 40px;
            }
            span{
                float: right;
                cursor: pointer;
                i{
                    color: rgba(121, 136, 155, 0.5);
                }
                i.active{
                    color: rgba(42, 130, 228, 1);
                }
            }
        }
        .pagination-table{
            float: right;
            padding-top: 16px;
        }
        .sort-condition{
            margin-bottom: 24px;
            input{
                width: 300px;
            }
            .sort-list{
                display: inline-block;
                padding: 0 12px;
                height: 32px;
                line-height: 32px;
                background: #fafafa;
                margin-left: 10px;
                font-size: 14px;
                cursor: pointer;
                border-radius: 4px;
            }
            span.active{
                background: #1890FF;
                color: #fff;
            }
        }
        .radio-list{
            padding-left: 10px;
            span{
                display: inline-block;
                text-align: center;
            }
            span:nth-child(1){
                width: 150px;
                margin-left: 20px;
            }
            span:nth-child(2){
                width: 130px;
            }
            span:nth-child(3){
                width: 150px;
            }
            span:nth-child(4){
                width: 120px;
            }
            span:nth-child(5){
                width: 150px;
            }
        }
    }
    .delete-modal{
        padding-top: 24px;
        padding-bottom: 48px;
        .sure-off-bind{
            i{
                color: rgba(255, 170, 0, 1);
                font-size: 24px;
            }
            span{
                color: rgba(18, 18, 18, 1);
                font-size: 20px;
                margin-left: 24px;
            }
        }
        .tip{
            color: rgba(80, 80, 80, 1);
            padding-left: 50px;
            margin: 24px 0;
        }
    }
    .drawer-newtask{
        .newtask-body{
            height: 496px;
            padding: 16px;
            .newtask-content{
                width: 100%;
                height: 100%;
                overflow: hidden;
                background: #fff;
                padding: 5px 24px 24px;
                border-radius: 4px;
                .col-left{
                    padding-right: 24px;
                }
                .col-right{
                    padding-left: 24px;
                }
                .row-list{
                    .col-list{
                        margin-top: 19px;
                        margin-bottom: 5px;
                        .select-admin{
                            width: 336px;
                            height: 32px;
                            margin-top: 9px;
                        }
                        .textarea-remarks{
                            margin-top: 9px;
                        }
                        .first-content{
                            color: rgba(188, 188, 188, 1);
                            font-size: 12px;
                        }
                        .second-content{
                            color: rgba(51, 51, 51, 1);
                            font-size: 14px;
                            margin-top: 9px;
                            .digital-staff{
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
    .drawer-public1{
        height: 100%;
        position: relative;
        padding-bottom: 60px;
        .drawer-head{
            height: 88px;
            background: #fff;
            padding: 0 24px 0;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            z-index: 100;

        }
        .drawer-content{
            padding: 104px 0 68px 16px;
            width: 100%;
            height: 100%;
            position: absolute;
        }
        .drawer-foot{
            position: absolute;
            left: 0px;
            bottom: 0px;
            width: 100%;
            height: 52px;
            background: #fff;
            box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
            .btn-group{
                text-align: center;
                padding-top: 10px;
                width: 100%;
                button{
                    height: 32px;
                    font-size: 14px;
                }
                button:nth-child(2){
                    margin: 0 16px;
                }
            }
        }
    }
    .drawer-sence{
        .row-head{
            padding-top: 20px;
            border-top: 1px solid rgba(229, 229, 229, 1);
            .col-left{
                .head-content{
                    color: rgba(188, 188, 188, 1);
                    font-size: 12px;
                }
                .head-content{
                    color: rgba(80, 80, 80, 1);
                    font-size: 14px;
                    
                }
            }
        }
        .drawer-body-content{
            width: 100%;
            height: 100%;
            border-radius: 4px;
            overflow-y: auto;
            padding-right: 16px;
            .basic-info{
                width: 100%;
                background: #fff;
                border-radius: 4px;
                padding: 24px 24px 24px 0;
                .title{
                    color: rgba(18, 18, 18, 1);
                    font-size: 14px;
                    padding-left: 24px;
                }
                .col-left{
                    padding-right: 24px;
                }
                .row-list{
                    .col-list{
                        margin-top: 19px;
                        margin-bottom: 5px;
                        min-height: 69px;
                        padding-left: 24px;
                        .first-content{
                            color: rgba(188, 188, 188, 1);
                            font-size: 12px;
                        }
                        .second-content{
                            color: rgba(51, 51, 51, 1);
                            font-size: 14px;
                            margin-top: 9px;
                            .change-head{
                                color: rgba(24, 144, 255, 1);
                                font-size: 12px;
                                cursor: pointer;
                                margin-left: 24px;
                            }
                            .edit-icon{
                                display: none;
                            }
                        }
                        .selection-range{
                            // margin-top: 9px;
                            width: 290px;
                        }
                        .third-content{
                            color: rgba(51, 51, 51, 1);
                            font-size: 14px;
                            margin-top: 4px;
                            // display: none;
                        }
                        .user-content{
                            color: rgba(51, 51, 51, 1);
                            font-size: 14px;
                            margin-top: 4px;
                        }
                        .label-content{
                            margin-top: 9px;
                            button{
                                border: none;
                                background: rgba(158, 172, 196, 0.12);
                                color: rgba(158, 172, 196, 1);
                                font-size: 13px;
                                height: 28px;
                            }
                            span{
                                display: inline-block;
                                height: 28px;
                                line-height: 28px;
                                color: #fff;
                                font-size: 13px;
                                padding: 0 12px;
                                margin: 2px 4px 2px 0;
                                background: rgba(158, 172, 196, 0.12);
                                i{
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                    .col-list:hover{
                        // .second-content{
                        //     display: none;
                        // }
                        // .third-content{
                        //     display: block;
                        // }
                        .edit-icon{
                            display: inline-block;
                            cursor: pointer;
                            color: #1890ff;
                        }
                    }
                    .col-time{
                        display: none !important;
                    }
                }
                .add-attachment{
                    margin-left: 24px;
                    border: none;
                    background: rgba(158, 172, 196, 0.12);
                    color: rgba(158, 172, 196, 1);
                    font-size: 13px;
                }
                .pd-24{
                    padding-left: 24px;
                }
                .pd-8{
                    padding-left: 8px;
                }
                .attachment-item{
                    padding: 9px 12px 0 17px;
                    height: 52px;
                    border: 1px solid rgba(158, 172, 196, 0.3);
                    margin-top: 8px;
                    display: flex;
                    img{
                        width: 36px;
                        height: 36px;
                    }
                    .attachment-content{
                        flex: 1;
                        padding-left: 8px;
                        .name-operation{
                            span{
                                float: left;
                                color: rgba(18, 18, 18, 1);
                                font-size: 13px;
                            }
                            i{
                                float: right;
                                color: rgba(158, 172, 196, 1);
                                font-size: 13px;
                                cursor: pointer;
                                margin-left: 12px;
                            }
                            i:hover{
                                color: #1890ff;
                            }
                        }
                        .file-size{
                            color: rgba(158, 172, 196, 1);
                            font-size: 12px;
                        }
                    }
                }
            }  
            .follow-time{
                height: 136px;
                padding: 18px 0 0 24px;
                margin-top: 16px;
                border-radius: 4px;
                background: #fff;
                .title{
                    color: rgba(18, 18, 18, 1);
                    font-size: 14px;
                }
                .row-time{
                    margin-top: 24px;
                    .row-time-title{
                        color: rgba(188, 188, 188, 1);
                        font-size: 12px;
                    }
                    .row-time-value{
                        color: rgba(51, 51, 51, 1);
                        font-size: 14px;
                        margin-top: 9px;
                    }
                }
            }
            .related-clue{
                padding: 18px 0 24px 24px;
                margin-top: 16px;
                border-radius: 4px;
                background: #fff;
                max-height: 260px;
                overflow-y: hidden;
                .span{
                    color: rgba(18, 18, 18, 1);
                    font-size: 14px;
                }
                button{
                    float: right;
                    color: rgba(24, 144, 255, 1);
                    font-size: 14px;
                    border: none;
                }
                .related-title{
                    color: rgba(188, 188, 188, 1);
                    font-size: 12px;
                    margin-top: 24px;
                }
                .related-data{
                    margin-top: 8px;
                    .related-name{
                        color: rgba(61, 134, 253, 1);
                        font-size: 14px;
                        cursor: pointer;
                    }
                    .related-content{
                        color: rgba(51, 51, 51, 1);
                        font-size: 14px;
                    }
                }
            }
            .dynamic-info{
                padding: 18px 0 36px 24px;
                margin-top: 16px;
                border-radius: 4px;
                background: #fff;
                .title{
                    span{
                        color: rgba(18, 18, 18, 1);
                        font-size: 14px;
                    }
                    button{
                        float: right;
                        color: rgba(24, 144, 255, 1);
                        font-size: 14px;
                        border: none;
                    }
                }
                .filter-condition{
                    margin-top: 23px;
                    margin-bottom: 16px;
                    span{
                        display: inline-block;
                        height: 28px;
                        line-height: 28px;
                        color: rgba(121, 136, 155, 1);
                        font-size: 13px;
                        margin-right: 8px;
                        padding: 0 12px;
                        cursor: pointer;
                        background: rgba(255, 255, 255, 0.12);
                    }
                    span.active{
                        color: rgba(24, 144, 255, 1);
                    }
                }
                .dynamic-list{
                    display: flex;
                    margin-bottom: 24px;
                    .date{
                        width: 90px;
                        padding-right: 10px;
                        color: rgba(153, 153, 153, 1);
                        font-size: 12px;
                        span:nth-child(2){
                            display: inline-block;
                            width: 8px;
                            height: 8px;
                            border-radius: 4px;
                            float: right;
                            margin-top: 8px;
                        }
                    }
                    .active-track{
                        flex: 1;
                        .time{
                            color: rgba(153, 153, 153, 1);
                            font-size: 12px;
                        }
                        .active-event{
                            // color: rgba(153, 153, 153, 1);
                            font-size: 12px;
                            span{
                                background: #1890ff;
                                color: #fff;
                            }
                        }
                        .dynamic-content{
                            color: rgba(80, 80, 80, 1);
                            font-size: 14px;
                            margin-top: 6px;
                            padding-right: 15px;
                            div{
                                min-height: 21px;
                            }
                            textarea{
                                // display: none;
                            }
                            .dynamic-icon{
                                cursor: pointer;
                                display: none;
                                color: #1890ff;
                            }
                        }
                        .dynamic-content:hover{
                            .dynamic-icon{
                                display: inline-block;
                            }
                        }
                        .chat-detail{
                            .play-voice{
                                color: #1890ff;
                                font-size: 12px;
                                text-decoration: underline;
                                display: inline-block;
                                margin-right: 16px;
                                cursor: pointer;
                                i{
                                    margin-right: 2px;
                                }
                            }
                            .voice-content{
                                color: #505050;
                                font-size: 12px;
                            }
                        }
                        .label-content{
                            // margin-top: 9px;
                            button{
                                border: none;
                                background: rgba(158, 172, 196, 0.12);
                                color: rgba(158, 172, 196, 1);
                                font-size: 13px;
                                height: 28px;
                                border: 1px solid rgba(158, 172, 196, 1);
                                margin: 8px 8px 8px 0;
                            }
                            span{
                                display: inline-block;
                                height: 28px;
                                line-height: 28px;
                                border: 1px solid #fff;
                                font-size: 13px;
                                padding: 0 12px;
                                margin: 2px 4px 2px 0;
                                background: #fff;
                                border-radius: 4px;
                                i{
                                    cursor: pointer;
                                }
                            }
                        }
                        .next-time{
                            color: rgba(80, 80, 80, 1);
                            font-size: 14px;
                            float: right;
                            padding-right: 16px;
                        }
                    }
                }
            }
            .comments{
                padding: 18px 24px;
                background: #fff;
                margin-top: 16px;
                border-radius: 4px;
                .title{
                    color: rgba(18, 18, 18, 1);;
                    font-size: 14px;
                }
                .textarea-comment{
                    margin-top: 20px;
                    background: rgba(247, 247, 247, 1);
                    border: none;
                    resize: none;
                }
                .release{
                    text-align: right;
                    color: rgba(24, 144, 255, 1);
                    font-size: 14px;
                    margin-top: 4px;
                    span{
                        cursor: pointer;
                    }
                }
            }  
        }
    }
    .drawer-public2{
        height: 100%;
        position: relative;
        padding-bottom: 60px;
        .drawer-title{
            height: 55px;
            width: 100%;
            span{
                color: rgba(18, 18, 18, 1);
                font-size: 16px;
                line-height: 55px;
            }
            i{
                color: rgba(153, 153, 153, 1);
                font-size: 16px;
                margin-left: 16px;
                cursor: pointer;
                line-height: 50px;
            }
        }
        .drawer-head{
            height: 144px;
            background: #fff;
            padding: 0 24px 0;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            z-index: 100;

        }
        .drawer-content{
            padding: 160px 0 68px 16px;
            width: 100%;
            height: 100%;
            position: absolute;
            .take-phone{
                cursor: pointer;
                .take-phone1{
                    width: 14px;
                    height: 14px;
                    margin-left: 3px;
                }
                .take-phone2{
                    display: none;
                    width: 14px;
                    height: 14px;
                    margin-left: 3px;
                }
            }
            .take-phone:hover{
                .take-phone1{
                    display: none;
                }
                .take-phone2{
                    display: inline-block;
                }
            }
        }
        .drawer-foot{
            position: absolute;
            left: 0px;
            bottom: 0px;
            width: 100%;
            height: 52px;
            background: #fff;
            box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
            .btn-group{
                text-align: center;
                padding-top: 10px;
                width: 100%;
                button{
                    height: 32px;
                    font-size: 14px;
                }
                button:nth-child(2){
                    margin: 0 16px;
                }
            }
        }
    }
    .create-modal{
        padding-bottom: 46px;
    }
    .drawer-public2{
        height: 100%;
        position: relative;
        padding-bottom: 60px;
        .drawer-title{
            height: 55px;
            width: 100%;
            span{
                color: rgba(18, 18, 18, 1);
                font-size: 16px;
                line-height: 55px;
            }
            i{
                color: rgba(153, 153, 153, 1);
                font-size: 16px;
                margin-left: 16px;
                cursor: pointer;
                line-height: 50px;
            }
        }
        .drawer-head{
            height: 144px;
            background: #fff;
            padding: 0 24px 0;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            z-index: 100;

        }
        .drawer-content{
            padding: 160px 0 68px 16px;
            width: 100%;
            height: 100%;
            position: absolute;
            .take-phone{
                cursor: pointer;
                .take-phone1{
                    width: 14px;
                    height: 14px;
                    margin-left: 3px;
                }
                .take-phone2{
                    display: none;
                    width: 14px;
                    height: 14px;
                    margin-left: 3px;
                }
            }
            .take-phone:hover{
                .take-phone1{
                    display: none;
                }
                .take-phone2{
                    display: inline-block;
                }
            }
        }
        .drawer-foot{
            position: absolute;
            left: 0px;
            bottom: 0px;
            width: 100%;
            height: 52px;
            background: #fff;
            box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
            .btn-group{
                text-align: center;
                padding-top: 10px;
                width: 100%;
                button{
                    height: 32px;
                    font-size: 14px;
                }
                button:nth-child(2){
                    margin: 0 16px;
                }
            }
        }
    }
    .upload-modal{
        .divider1{
            margin: 0px 0 24px 0;
        }
        .divider2{
            margin: 24px 0 12px;
        }
        padding-bottom: 50px;
        .btn-group{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                margin-right: 16px;
            }
        }
    }
    .time-modal{
        padding-bottom: 46px;
        .check-list{
            .check-item{
                display: inline-block;
                width: 160px;
                height: 32px;
            }
        }
    }
    .share-modal{
        .copy-link{
            height: 62px;
            input{
                width: 464px;
                float: left;
                height: 40px;
            }
            span{
                float: left;
                display: inline-block;
                width: 88px;
                height: 40px;
                border: 1px solid rgba(121, 136, 155, 0.3);
                cursor: pointer;
                border-radius: 0px 4px 4px 0px;
                line-height: 40px;
                text-align: center;
            }
        }
    }
    .delete-modal{
        padding-top: 24px;
        padding-bottom: 48px;
        .sure-off-bind{
            i{
                color: rgba(255, 170, 0, 1);
                font-size: 24px;
            }
            span{
                color: rgba(18, 18, 18, 1);
                font-size: 20px;
                margin-left: 24px;
            }
        }
        .tip{
            color: rgba(80, 80, 80, 1);
            padding-left: 50px;
            margin: 24px 0;
        }
    }
    .label-modal{
        padding-bottom: 46px;
        .title{
            color: rgba(153, 153, 153, 1);
            font-size: 14px;
            margin-bottom: 16px;
        }
        .company-tag-list{
            margin-top: 8px;
            .type-name{
                color: rgba(18, 18, 18, 1);
                font-size: 14px;
            }
            .label-list{
                span{
                    display: inline-block;
                    height: 28px;
                    line-height: 28px;
                    color: rgba(158, 172, 196, 1);
                    font-size: 13px;
                    padding: 0 16px;
                    margin: 8px 8px 8px 0;
                    background: rgba(158, 172, 196, 0.12);
                    cursor: pointer;
                }
            }
        }
        .new-tag{
            display: flex;
            input{
                flex: 1;
            }
            i{
                font-size: 20px;
                margin-left: 16px;
                margin-top: 6px;
                cursor: pointer;
                color: rgba(121, 136, 155, 1);
            }
        }
        .tag-list{
            span{
                display: inline-block;
                height: 28px;
                line-height: 28px;
                color: rgba(158, 172, 196, 1);
                font-size: 13px;
                padding: 0 16px;
                margin: 8px 8px 8px 0;
                background: rgba(158, 172, 196, 0.12);
                cursor: pointer;
            }
            i{
                margin-left: 8px;
                font-size: 12px;
            }
        }
    }
    .related-clue-modal{
        padding-bottom: 46px; 
    }
</style>
