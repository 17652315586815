<template>
    <div class="index">
        <div class="container">
	<div class="row">
		<div class="col-md-12">
			<div class="page-header">
				<h1>Plugin Demo: SIP Gateway
					<button class="btn btn-default" autocomplete="off" id="start123">Start</button>
                    <a-button id="qqq" class="call2" type="primary">
                拨号
            </a-button>
				</h1>
			</div>
            <!-- <audio class="hide" id="audio123" autoplay playsinline/> -->
			<div class="container" id="details">
				<div class="row">
					<div class="col-md-12">
						<h3>Demo details</h3>
						<p>This demo shows how you can make use of the SIP plugin to interact with a SIP
						Proxy (e.g., Kamailio or OpenSIPS) or PBX (e.g., Asterisk or FreeSwitch) in order to
						place or receive calls to and from other SIP clients. Specifically, it uses the
						Sofia-based SIP plugin. Notice the plugin only exchange SIP messages from within the
						plugin itself: no SIP is done in JavaScript, except for references to SIP URIs.</p>
						<p>When started, the demo will allow you to insert a minimum set of information
						required to REGISTER the web page as a SIP client at a SIP Proxy or PBX you specify.
						This will allow you to call SIP URIs, or receive calls through the SIP Server itself.
						During a call, you'll also be able to interact with the PBX via DTMF tones, e.g.,
						to drive an Interactive Voice Response (IVR) menu that you're being presented with.</p>
						<div class="alert alert-info"><b>Note well!</b> Please notice that, while audio support
						has been tested extensively, video hasn't as much, and as such may not work as expected.
						</div>
						<p>Press the <code>Start</code> button above to launch the demo.</p>
					</div>
				</div>
			</div>
			<div class="container hide" id="sipcall">
				<div class="row">
					<div class="col-md-12">
						<div class="col-md-6 container hide" id="login">
							<div class="input-group margin-bottom-sm">
								<!-- <span class="input-group-addon"><i class="fa fa-cloud-upload fa-fw"></i></span> -->
								<input class="form-control" type="text" placeholder="SIP Registrar (e.g., sip:host:port)" autocomplete="off" id="server" onkeypress="checkEnter(this, event);" />
							</div>
							<div class="input-group margin-bottom-sm">
								<!-- <span class="input-group-addon"><i class="fa fa-user fa-fw"></i></span> -->
								<input class="form-control" type="text" placeholder="SIP Identity (e.g., sip:goofy@example.com)" autocomplete="off" id="username" onkeypress="checkEnter(this, event);" />
							</div>
							<div class="input-group margin-bottom-sm">
								<!-- <span class="input-group-addon"><i class="fa fa-user-plus fa-fw"></i></span> -->
								<input class="form-control" type="text" placeholder="Username (e.g., goofy, overrides the one in the SIP identity if provided)" autocomplete="off" id="authuser" onkeypress="checkEnter(this, event);" />
							</div>
							<div class="input-group margin-bottom-sm">
								<!-- <span class="input-group-addon"><i class="fa fa-key fa-fw"></i></span> -->
								<input class="form-control" type="password" placeholder="Secret (e.g., mysupersecretpassword)" autocomplete="off" id="password" onkeypress="checkEnter(this, event);" />
							</div>
							<div class="input-group margin-bottom-sm">
								<!-- <span class="input-group-addon"><i class="fa fa-quote-right fa-fw"></i></span> -->
								<input class="form-control" type="text" placeholder="Display name (e.g., Alice Smith)" autocomplete="off" id="displayname" onkeypress="checkEnter(this, event);" />
							</div>
							<div class="btn-group btn-group-sm" style="width: 100%">
								<button class="btn btn-primary" autocomplete="off" id="register" style="width: 30%">Register</button>
								<div class="btn-group btn-group-sm" style="width: 70%">
									<button autocomplete="off" id="registerset" class="btn btn-default dropdown-toggle" data-toggle="dropdown" style="width: 100%">
										Registration approach<span class="caret"></span>
									</button>
									<ul id="registerlist" class="dropdown-menu" role="menu">
										<li><a href='#' id='secret'>Register using plain secret</a></li>
										<li><a href='#' id='ha1secret'>Register using HA1 secret</a></li>
										<li><a href='#' id='guest'>Register as a guest (no secret)</a></li>
									</ul>
								</div>
							</div>
						</div>
						<div class="col-md-6 container hide" id="phone">
							<div class="input-group margin-bottom-sm">
								<span class="input-group-addon"><i class="fa fa-phone fa-fw"></i></span>
								<input class="form-control" type="text" placeholder="SIP URI to call (e.g., sip:1000@example.com)" autocomplete="off" id="peer" onkeypress="checkEnter(this, event);" />
							</div>
							<button class="btn btn-success margin-bottom-sm" autocomplete="off" id="call">Call</button> 
							<input autocomplete="off" id="dovideo" type="checkbox" />Use Video
						</div>
					</div>
				</div>
				<div id="videos" class="hide">
					<div class="col-md-6">
						<div class="panel panel-default">
							<div class="panel-heading">
								<h3 class="panel-title">You</h3>
							</div>
							<div class="panel-body" id="videoleft"></div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="panel panel-default">
							<div class="panel-heading">
								<h3 class="panel-title">Remote UA</h3>
							</div>
							<div class="panel-body" id="videoright"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<hr>
</div>
    </div>
</template>

<script>
    export default {
        name: 'test',
        
        data() {
            return {
                
            }
        },
        methods: {
            
        },
        mounted: function() {
            
        }
    }
</script>

<style scoped lang="less">
    
</style>
