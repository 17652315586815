import { render, staticRenderFns } from "./WaitingCallDetail.vue?vue&type=template&id=52bd1080&scoped=true&"
import script from "./WaitingCallDetail.vue?vue&type=script&lang=js&"
export * from "./WaitingCallDetail.vue?vue&type=script&lang=js&"
import style0 from "./WaitingCallDetail.vue?vue&type=style&index=0&id=52bd1080&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52bd1080",
  null
  
)

export default component.exports