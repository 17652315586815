<template>
    <div class="share-set">
        <div class="head">
            <span class="title">推送设置</span>
            <a-button @click="backTo">
                返回
            </a-button>
            <a-button type="primary" @click="addSharing">
                新增
            </a-button>
            <a-icon type="exclamation-circle" />
        </div>
        <div class="content">
            <div class="content-in">
                <div class="modular-content">
                    <div class="modular-list" v-for="(item,index) in strategyData" :key="index">
                        <a-row>
                            <a-col :span="12">
                                <div class="name">{{item.name}}</div>
                            </a-col>
                            <a-col :span="12">
                                <div class="btn-group3">
                                    <a-switch :checked='item.status==1?true:false' @change="checked=>switchChange(checked,item.id)" />
                                    <span class="switch-state">
                                        <span v-if="item.status==1">已启用</span>
                                        <span v-if="item.status==2">未启用</span>
                                    </span>
                                    <a-button @click="copyList(item.id)">复制</a-button>
                                    <a-button @click="editList(item.id)">编辑</a-button>
                                    <a-button @click="deleteList(item.id)">删除</a-button>
                                </div>
                            </a-col>
                        </a-row>
                        <a-divider class="divider" type="horizontal" />
                        <div class="row-list">
                            <a-row class="row-item">
                                <a-col :span="15">
                                    <div class="jurisdiction" v-for="(item1,index1) in item.conditionList" :key="index1">
                                        <span>{{item1.fieldName}}</span>
                                        <span style="margin:0 12px">{{item1.method}}</span>
                                        <span>{{item1.values}}</span>
                                    </div>
                                </a-col>
                                <a-col :span="9">
                                    <div class="personnel">
                                        <a-icon type="user" />
                                        <span>数据所有者：</span>
                                        <span>{{item.owners}}</span>
                                    </div>
                                    <div class="personnel">
                                        <a-icon type="user" />
                                        <span>数据查看者：</span>
                                        <span>{{item.viewers}}</span>
                                    </div>
                                </a-col>
                            </a-row>
                        </div>
                        <a-row class="related-info">
                            <a-col :span="9">
          
                            </a-col>
                            <a-col :span="15">
                                <div>
                                    <span>创建人：</span>
                                    <span>{{item.createBy}}</span>
                                    <a-divider type="vertical" />
                                    <span>创建时间：</span>
                                    <span>{{item.createTime}}</span>
                                    <a-divider type="vertical" />
                                    <span>修改人：</span>
                                    <span>{{item.updateBy}}</span>
                                    <a-divider type="vertical" />
                                    <span>修改时间：</span>
                                    <span>{{item.updateTime}}</span>
                                </div>
                            </a-col>
                        </a-row>
                    </div>
                </div>
            </div>
        </div>
        <a-drawer
            :title='drawerName'
            placement="right"
            :closable="true"
            :visible="visible"
            :destroyOnClose='true'
            :maskClosable='true'
            @close="onClose"
            :width='800'
            :mask='true'
            :headerStyle="{ border: 'none', height: '56px', position: 'absolute', width: '100%', left: '0', top: '0', zIndex: '100'}"
            :bodyStyle="{ background: 'rgba(240, 242, 243, 1)', height: '100%', position: 'absolute', width: '100%', left: '0', top: '0', zIndex: '10', padding: '72px 0 0' }"
        >
            <div class="drawer-set">
                <div class="drawer-content">
                    <div class="drawer-body-content">
                        <div class="item-list">
                            <div class="title">输入设置名称：</div>
                            <a-input v-model="shareName" placeholder="请输入权限名称" />
                        </div>
                        <div class="item-list">
                            <div class="title">操作人：</div>
                            <div class="affect-list">
                                <div style="display:inline-block;width:680px;height:32px;border:1px solid #d9d9d9;border-radius:4px;overflow:hidden;line-height:32px;padding-left:11px">
                                    <span v-for="(item,index) in child1Data.departmentSelected" :key="index" style="margin-right:6px">{{deptAndUserMap[item+'']}}</span>
                                    <span v-for="(item,index) in child1Data.personSelected" :key="index" style="margin-right:6px">{{deptAndUserMap[item+'']}}</span>
                                </div>
                                <a-icon @click="chooseAffectedOwner" class="affect-icon" type="zoom-in" />
                            </div>
                        </div>
                        <div class="item-list">
                            <div class="title">触发事件(单选)：</div>
                            <div class="affect-thing">
                                <div :class="{'active':currentAffected=='CREATE'}" @click="selectAffect('CREATE')" class="affect-item">
                                    <div>创建时</div>
                                    <div>新增的一条数据的时候</div>
                                </div>
                                <div :class="{'active':currentAffected=='UPDATE'}" @click="selectAffect('UPDATE')" class="affect-item">
                                    <div>修改时</div>
                                    <div>修改数据任意字段的时候</div>
                                </div>
                                <!-- <div :class="{'active':currentAffected=='COMMENT'}" @click="selectAffect('COMMENT')" class="affect-item">
                                    <div>评论时</div>
                                    <div>事件描述事件描述事件描述事件描述</div>
                                </div> -->
                            </div>
                        </div>
                        <div class="item-list">
                            <div class="title">数据条件：</div>
                            <a-row v-for="(item,index) in viewScreenData" :key='index' style="margin-bottom:12px">
                                <a-col :span="4">
                                    {{item.fieldName}}
                                </a-col>
                                <a-col :span="6">
                                    <a-select v-model="item.selectedCondition" style="width:100%;height:32px" @change="selectConChange(item,index)">
                                        <a-select-option :value="item1.value" v-for="(item1,index1) in item.methodList" :key='index1'>
                                            {{item1.name}}
                                        </a-select-option>
                                    </a-select>
                                </a-col>
                                <a-col :span="14" style="padding-left:12px;">
                                    <Linkage ref="child4"></Linkage>
                                </a-col>
                            </a-row>
                            <div class="reset-condition">
                                <span @click="newSetCondition">重置筛选条件</span>
                            </div>
                        </div>
                        <div class="item-list">
                            <div class="title">推送内容：</div>
                            <div class="affect-list">
                                <a-textarea v-model="pushContent" class="push-content" placeholder="请输入推送内容" :rows="3" />
                            </div>
                        </div>
                        <div class="item-list">
                            <div class="title">接收人：</div>
                            <div class="affect-list">
                                <div style="display:inline-block;width:680px;height:32px;border:1px solid #d9d9d9;border-radius:4px;overflow:hidden;line-height:32px">
                                    <span v-for="(item,index) in child2Data.departmentSelected" :key="index" style="margin-right:6px">{{deptAndUserMap[item+'']}}</span>
                                    <span v-for="(item,index) in child2Data.personSelected" :key="index" style="margin-right:6px">{{deptAndUserMap[item+'']}}</span>
                                </div>
                                <a-icon @click="chooseAffectedViewer" class="affect-icon" type="zoom-in" />
                            </div>
                        </div>
                        <div class="item-list">
                            <div class="title">接收渠道：</div>
                            <div class="receive-channel">
                                <div class="receive-item active">
                                    <div>微信</div>
                                    <div>以消息的形式推送到用户绑定的微信上</div>
                                </div>
                                <div class="receive-item disabled">
                                    <div>短信</div>
                                    <div>以短信的形式推送到用户设定的手机上</div>
                                </div>
                                <div class="receive-item disabled">
                                    <div>邮箱</div>
                                    <div>以邮件的形式推送到用户设定的邮箱上</div>
                                </div>
                                <div class="receive-item disabled">
                                    <div>消息</div>
                                    <div>以站内信的形式推送到用户消息模块</div>
                                </div>
                            </div>
                        </div>
                        <div class="item-list">
                            <div class="title">推送方式：</div>
                            <div class="receive-channel">
                                <a-radio-group name="radioGroup" v-model="mode">
                                    <a-radio :value="1">
                                    随机推送
                                    </a-radio>
                                    <a-radio :value="2">
                                    全部推送
                                    </a-radio>
                                </a-radio-group>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="drawer-foot">
                    <div class="btn-group">
                        <a-button type="primary" ghost @click="cancelDrawer">取消</a-button>
                        <a-button type="primary" @click="sureDrawer">保存</a-button>
                    </div>
                </div>
            </div>
        </a-drawer>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="copyShareSetModal" title="复制共享设置" footer='' width='480px'>
            <div class="edit-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <div class="changed-name">输入复制后新的共享设置名称：</div>
                <a-input v-model="newName" placeholder="请输入内容" />
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group">
                    <a-button type="primary" ghost @click="cancelCopyShare">取消</a-button>
                    <a-button type="primary" @click="sureCopyShare">确认</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="deleteSettingModal" footer='' width='480px'>
            <div class="delete-setting-modal modal-public">
                <div class="sure-off-bind">
                    <a-icon type="warning" />
                    <span>您确定要删除这个设置吗？</span>
                </div>
                <div class="tip">删除后，数据将无法恢复</div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group2">
                    <a-button ghost @click="cancelDelete">取消</a-button>
                    <a-button type="primary" @click="sureDelete">确认</a-button>
                </div>
            </div>
        </a-modal>
        
        <a-modal :centered='true' :maskClosable='false' v-model.trim="offBindAffectedOwner" footer='' title="影响到的人" width='720px'>
            <div class="offbindaffect-modal modal-public">
                <Department ref="child1"></Department>
                <div class="btn-group2">
                    <a-button ghost @click="cancelBindAffectedOwner">取消</a-button>
                    <a-button type="primary" @click="sureBindAffectedOwner">确认</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="offBindAffectedViewer" footer='' title="影响到的人" width='720px'>
            <div class="offbindaffect-modal modal-public">
                <Department ref="child2"></Department>
                <div class="btn-group2">
                    <a-button ghost @click="cancelBindAffectedViewer">取消</a-button>
                    <a-button type="primary" @click="sureBindAffectedViewer">确认</a-button>
                </div>
            </div>
        </a-modal>
    </div>
</template>

<script>
    import Department from '../../components/Department';
    import Linkage from '../../components/Linkage'
    export default {
        name: 'Share',
        components: { Department, Linkage },
        data() {
            return {
                copyShareSetModal: false,
                deleteSettingModal: false,
                visible: false,
                drawerName: '',
                offBindAffectedOwner: false,
                offBindAffectedViewer: false,
                moduleId: '',
                strategyData: [],
                currentStrategyId: '',
                newName: '',
                shareName: '',
                passDataObjOwner: {
                    departmentTree: null,
                    peopleTree: null,
                    departmentSelected: null,
                    personSelected: null
                },
                passDataObjViewer: {
                    departmentTree: null,
                    peopleTree: null,
                    departmentSelected: null,
                    personSelected: null
                },
                child1Data: {
                    departmentSelected: [],
                    personSelected: [],
                },
                child2Data: {
                    departmentSelected: [],
                    personSelected: [],
                },
                viewScreenData: [],
                conditionList: [],
                type: '',
                currentAffected: '',
                pushContent: '',
                deptAndUserMap: '',
                copyData: [],
                mode: 2
            }
        },
        methods: {
            queryBasicData() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/strategy/notice/list/'+this.moduleId, 
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.strategyData = response.data.data
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            queryTreeData(type) {
                this.axios(
                    {
                        method:'get',
                        url:'/api/strategy/notice/init', 
                        params: {
                            moduleId: type=='new'?this.moduleId:undefined,
                            id: type=='edit'?this.currentStrategyId:undefined
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.shareName = response.data.data.name
                        this.passDataObjOwner.departmentTree = response.data.data.operator.deptTree
                        this.passDataObjOwner.peopleTree = response.data.data.operator.userTree
                        this.passDataObjOwner.departmentSelected = response.data.data.operator.deptValueList
                        this.passDataObjOwner.personSelected = response.data.data.operator.userValueList
                        this.child1Data.departmentSelected = response.data.data.operator.deptValueList
                        this.child1Data.personSelected = response.data.data.operator.userValueList

                        this.passDataObjViewer.departmentTree = response.data.data.receiver.deptTree
                        this.passDataObjViewer.peopleTree = response.data.data.receiver.userTree
                        this.passDataObjViewer.departmentSelected = response.data.data.receiver.deptValueList
                        this.passDataObjViewer.personSelected = response.data.data.receiver.userValueList
                        this.child2Data.departmentSelected = response.data.data.receiver.deptValueList
                        this.child2Data.personSelected = response.data.data.receiver.userValueList
                        this.viewScreenData =  this.deepClone(response.data.data.conditionList)
                        this.copyData = this.deepClone(response.data.data.conditionList)
                        this.currentAffected = response.data.data.trigger
                        this.pushContent = response.data.data.content
                        this.mode = response.data.data.mode
                        setTimeout(()=>{
                            if(type == 'edit'){
                                for(let i = 0; i < this.viewScreenData.length; i++){
                                    this.$refs.child4[i].getPassData(this.viewScreenData[i],i)
                                }
                            }
                        })

                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            newSetCondition() {
                let data = this.deepClone(this.copyData)
                this.viewScreenData = data
                for(let i = 0; i < this.viewScreenData.length; i++){
                    this.$refs.child4[i].getPassData(this.viewScreenData[i],i)
                }
                this.$forceUpdate()
            },  
            deepClone(obj){
                let _obj = JSON.stringify(obj),
                objClone = JSON.parse(_obj);
                return objClone
            },  
            onClose() {
                this.visible = false;
                this.initDrawer()
            },
            copyList(id) {
                this.copyShareSetModal = true
                this.currentStrategyId = id
            },
            editList(id) {
                this.visible = true;
                this.drawerName = '编辑共享设置'
                this.visible = true;
                this.currentStrategyId = id
                this.queryTreeData('edit')
                this.type = 'edit'
            },
            deleteList(id) {
                this.deleteSettingModal = true
                this.currentStrategyId = id
            },
            cancelCopyShare() {
                this.copyShareSetModal = false
            },
            sureCopyShare() {
                this.copyShareSetModal = false
                this.axios.post('/api/strategy/copy', {
                    id: this.currentStrategyId,
                    name: this.newName,
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.$message.success('复制成功')
                        this.queryBasicData()
                        this.newName = ''
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            cancelDelete() {
                this.deleteSettingModal = false
            },
            sureDelete() {
                this.deleteSettingModal = false
                this.axios.delete('/api/strategy/'+this.currentStrategyId)
                .then(response=> {
                    if(response.data.code == 0){
                        this.$message.success('删除成功')
                        this.queryBasicData()
                        this.currentStrategyId = ''
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            cancelDrawer() {
                this.visible = false
                this.initDrawer()
            },
            sureDrawer() {
                
                let data = this.$refs.child4
                let arr = []
                for(let i = 0; i < data.length; i++){
                    let obj = {}
                    if(data[i]['_data']['viewportData']['selectedCondition']!='ALL'&&data[i]['_data']['viewportData']['selectedCondition']!=undefined){
                        obj.fieldId = data[i]['_data']['viewportData']['fieldId']
                        obj.fieldMethod = data[i]['_data']['viewportData']['selectedCondition']
                        obj.valueList = data[i]['_data']['viewportData']['valueList']
                        arr.push(obj)
                    }
                }
                this.conditionList = arr
                this.axios.post('/api/strategy/notice', {
                    id: this.type=='new'?undefined:this.currentStrategyId,
                    moduleId: this.type=='new'?this.moduleId:undefined,
                    name: this.shareName,
                    operator: {
                        deptValueList: this.child1Data.departmentSelected,
                        userValueList: this.child1Data.personSelected,
                    },
                    conditionList: this.conditionList,
                    receiver: {
                        deptValueList: this.child2Data.departmentSelected,
                        userValueList: this.child2Data.personSelected,
                    },
                    trigger: this.currentAffected,
                    content: this.pushContent,
                    channel: 'WECHAT',
                    mode: this.mode
                })
                .then(response=> {
                    if(response.data.code == 0){
                        let tip
                        if(this.type == 'new'){
                            tip = '新增成功'
                        }else{
                            tip = '修改成功'
                        }
                        this.$message.success(tip)
                        this.initDrawer()
                        this.queryBasicData()
                        this.visible = false
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            initDrawer() {
                this.child1Data.departmentSelected = []
                this.child1Data.personSelected = []
                this.child2Data.departmentSelected = []
                this.child2Data.personSelected = []
                this.conditionList = []
                this.shareName = ''
                this.currentStrategyId = ''

                this.passDataObjOwner = {
                    departmentTree: null,
                    peopleTree: null,
                    departmentSelected: null,
                    personSelected: null
                }

                this.passDataObjViewer = {
                    departmentTree: null,
                    peopleTree: null,
                    departmentSelected: null,
                    personSelected: null
                }
            },
            chooseAffectedOwner() {
                this.offBindAffectedOwner = true
                setTimeout(()=>{
                    this.$refs.child1.getParentData(this.passDataObjOwner)
                })
            },
            chooseAffectedViewer() {
                this.offBindAffectedViewer = true
                setTimeout(()=>{
                    this.$refs.child2.getParentData(this.passDataObjViewer)
                })
            },
            cancelBindAffectedOwner() {
                this.offBindAffectedOwner = false
            },
            sureBindAffectedOwner() {
                this.child1Data.departmentSelected = this.$refs.child1.departmentSelected
                this.child1Data.personSelected = this.$refs.child1.personSelected
                this.offBindAffectedOwner = false
            },
            cancelBindAffectedViewer() {
                this.offBindAffectedViewer = false
                this.initDrawer()
            },
            sureBindAffectedViewer() {
                this.child2Data.departmentSelected = this.$refs.child2.departmentSelected
                this.child2Data.personSelected = this.$refs.child2.personSelected
                this.offBindAffectedViewer = false
            },
            selectConChange(item, index) {
                if(item.fieldAttribute == 'RECORD_LABEL'){
                    let data = this.$refs.child4
                    let num = ''
                    for(let i = 0; i < this.viewScreenData.length; i++){
                        if(this.viewScreenData[i]['fieldAttribute'] == 'DIGITAL_EMPLOYEE_ID'){
                            num = i
                        }
                    }
                    if(data[num]['_data']['viewportData']!=''){
                        if(data[num]['_data']['viewportData']['valueList'].length > 0){
                            this.axios(
                                {
                                    method:'get',
                                    url:'/api/employee/label/list?digitalEmployeeId='+data[num]['_data']['viewportData']['valueList'][0], 
                                }
                            )
                            .then(response=> {
                                if(response.data.code == 0){
                                    this.callingLabelList = response.data.data
                                    item.selectList = this.callingLabelList
                                    this.$refs.child4[index].getPassData(item,index)
                                }else{
                                    this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                                }
                            })
                            .catch(error=> {
                                console.log(error)
                            })
                        }else{
                            this.$message.warning('标签数据基于数字员工！')
                        }
                    }else{
                        this.$message.warning('标签数据基于数字员工！')
                    }
                }else{
                    this.$refs.child4[index].getPassData(item,index)
                }
            },
            addSharing() {
                this.type = 'new'
                this.visible = true;
                this.drawerName = '新增共享设置'
                this.queryTreeData('new')
            },
            backTo() {
                this.$router.go(-1)
            },
            switchChange(checked,id) {
                let status
                if(checked){
                    status = 1
                }else{
                    status = 2
                }
                this.axios.post('/api/strategy/updateStatus',
                {
                    id: id,
                    status: status,
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.queryBasicData()
                        let tip
                        if(checked){
                            tip = '已启用'
                        }else{
                            tip = '已停用'
                        }
                        this.$message.success(tip)
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            selectAffect(state) {
                this.currentAffected = state
            },
            queryDeptAndUserMap() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/user/deptAndUserMap', 
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.deptAndUserMap = response.data.data
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            }
        },
        mounted: function() {
            this.sessionData('set','personalCenter','permissionSet');
            this.moduleId = this.$route.query.moduleId
            this.queryBasicData()
            this.queryDeptAndUserMap()
            
        }
    }
</script>

<style scoped lang="less">
    .share-set{
        height: 100%;
        position: relative;
        .head{
            height: 48px;
            line-height: 48px;
            padding: 0 24px;
            background: #fff;
            border-left: 1px solid #e8ebee;
            z-index: 100;
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            button{
                float: right;
                width: 64px;
                height: 32px;
                margin: 8px 0 0 8px;
            }
            .title{
                color: rgba(18, 18, 18, 1);
                font-size: 16px;
            }
            i{
                float: right;
                margin-top: 16px;
                font-size: 16px;
                color: rgba(204, 204, 204, 1);
                cursor: pointer;
            }
        }
        .content{
            position: absolute;
            padding: 16px;
            left: 0px;
            top: 0px;
            height: 100%;
            padding-top: 64px;
            width: 100%;
            .content-in{
                width: 100%;
                height: 100%;
                position: relative;
                .modular-content{
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    height: 100%;
                    width: 100%;
                    overflow-y: auto;
                    .modular-list{
                        width: 100%;
                        height: 230px;
                        padding: 24px;
                        background: #fff;
                        border-radius: 4px;
                        margin-bottom: 16px;
                        .name{
                            color: rgba(18, 18, 18, 1);
                            font-size: 16px;
                        }
                        .btn-group3{
                            float: right;
                            button{
                                margin-left: 8px;
                            }
                            .switch-state{
                                color: rgba(121, 136, 155, 0.5);
                                font-size: 14px;
                                margin: 0 8px;
                            }
                        }
                        .divider{
                            margin: 10px 0 16px;
                        }
                        .jurisdiction{
                            color: rgba(80, 80, 80, 1);;
                            font-size: 14px;
                        }
                        .personnel{
                            i{
                                color: rgba(121, 136, 155, 1);
                                font-size: 14px;
                            }
                            span{
                                color: rgba(80, 80, 80, 1);;
                                font-size: 12px;
                                margin-left: 8px;
                            }
                        }
                        .related-info{
                            margin-top: 16px;
                            div{
                                text-align: right;
                                color: rgba(188, 188, 188, 1);
                                font-size: 12px;
                            }
                        }
                        .row-list{
                            height: 100px;
                            overflow-y: auto;
                            .row-item{
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
            }
            
        }
        
    }
    .modal-public{
        .divider1{
            margin: 0px 0 24px 0;
        }
        .divider2{
            margin: 24px 0 12px;
        }
        .btn-group{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                margin-right: 16px;
            }
        }
        .btn-group2{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                background: #fff;
                color: rgba(121, 136, 155, 0.7);
                border: 1px solid rgba(121, 136, 155, 0.5);
                margin-right: 16px;
            }
            button:nth-child(1):hover{
                background: rgba(121, 136, 155, 0.08);
            }
        }
    }
    .edit-modal{
        padding-bottom: 46px;
        .changed-name{
            color: rgba(153, 153, 153, 1);
            font-size: 14px;
        }
        input{
            margin-top: 8px;
        }
    }
    .delete-setting-modal{
        padding-top: 24px;
        padding-bottom: 46px;
        .sure-off-bind{
            i{
                color: rgba(255, 170, 0, 1);
                font-size: 24px;
            }
            span{
                color: rgba(18, 18, 18, 1);
                font-size: 20px;
                margin-left: 24px;
            }
        }
        .tip{
            color: rgba(80, 80, 80, 1);
            padding-left: 50px;
            margin: 24px 0;
        }
    }
    .offbindaffect-modal{
        border-top: 1px solid #e8e8e8;
        padding-top: 24px;
        padding-bottom: 46px;
        .affect-modal-left{
            width: 100%;
            height: 100%;
            border-right: 1px solid #e8e8e8;
            padding-right: 24px;
            .department-tree,.person-tree{
                height: 300px;
                overflow-x: hidden;
                overflow-y: auto;
                padding-top: 16px;
            }
        }
        .affect-modal-right{
            width: 100%;
            height: 100%;
            padding-left: 24px;
            .title{
                margin-bottom: 24px;
                span:nth-child(1){
                    color: rgba(153, 153, 153, 1);
                    font-size: 14px;
                }
                span:nth-child(2){
                    color: rgba(24, 144, 255, 1);
                    font-size: 14px;
                    cursor: pointer;
                    float: right;
                }
            }
            .selected-list{
                height: 346px;
                overflow-y: auto;
                .selected-item{
                    display: inline-block;
                    height: 32px;
                    padding: 0 6px;
                    background: rgba(121, 136, 155, 0.1);
                    border-radius: 4px;
                    margin-right: 8px;
                    margin-bottom: 8px;
                    line-height: 32px;
                    img:nth-child(1){
                        color: rgba(121, 136, 155, 1);
                        width: 18px;
                        float: left;
                        margin-top: 7px;
                    }
                    span:nth-child(2){
                        color: rgba(80, 80, 80, 1);
                        font-size: 14px;
                        margin-left: 4px;
                    }
                    i:nth-child(3){
                        color: rgba(153, 153, 153, 1);
                        font-size: 14px;
                        cursor: pointer;
                        margin-left: 8px;
                    }
                }
            }
        }
    }
    .drawer-set{
        height: 100%;
        display: flex;
        position: relative;
        .drawer-content{
            padding: 0 16px 68px;
            width: 100%;
            .drawer-body-content{
                width: 100%;
                height: 100%;
                padding: 24px;
                background: #fff;
                border-radius: 4px;
                overflow-y: auto;
                .item-list{
                    margin-bottom: 24px;
                    .title{
                        color: rgba(188, 188, 188, 1);
                        font-size: 12px;
                        margin-bottom: 11px;
                    }
                    .affect-list{
                        .affect-input{
                            width: 680px;
                        }
                        .affect-icon{
                            color: rgba(121, 136, 155, 1);
                            font-size: 20px;
                            float: right;
                            margin-top: 6px;
                            cursor: pointer;
                        }
                    }
                    .affect-thing{
                        .affect-item{
                            display: inline-block;
                            width: 168px;
                            height: 82px;
                            border-radius: 4px;
                            border: 1px solid rgba(121, 136, 155, 0.3);
                            padding: 11px 16px;
                            margin-right: 16px;
                            cursor: pointer;
                            div:nth-child(1){
                                color: rgba(80, 80, 80, 1);
                                font-size: 14px;
                            }
                            div:nth-child(2){
                                color: rgba(153, 153, 153, 1);
                                font-size: 12px;
                                margin-top: 4px;
                            }
                        }
                        .affect-item:hover{
                            border: 1px solid rgba(121, 136, 155, 1);
                        }
                        div.active{
                            border: 1px solid rgba(24, 144, 255, 1);
                        }
                        div.active:hover{
                            border: 1px solid rgba(24, 144, 255, 1);
                        }
                    }
                    .affect-checkbox{

                    }
                    .receive-channel{
                        .receive-item{
                            display: inline-block;
                            width: 166px;
                            height: 82px;
                            border-radius: 4px;
                            border: 1px solid rgba(121, 136, 155, 0.3);
                            padding: 11px 16px;
                            margin-right: 16px;
                            cursor: pointer;
                            div:nth-child(1){
                                color: rgba(80, 80, 80, 1);
                                font-size: 14px;
                            }
                            div:nth-child(2){
                                color: rgba(153, 153, 153, 1);
                                font-size: 12px;
                                margin-top: 4px;
                            }
                        }
                        .receive-item:nth-last-child(1){
                            margin-right: 0px;
                        }
                        div.disabled{
                            background: rgba(121, 136, 155, 0.08);
                            cursor: default;
                        }
                        div.active{
                            border: 1px solid rgba(24, 144, 255, 1);
                        }
                    }
                    .reset-condition{
                        text-align: right;
                        color: rgba(24, 144, 255, 1);
                        font-size: 14px;
                        span{
                            cursor: pointer;
                        }
                    }
                }
            }
        }
        .drawer-foot{
            position: absolute;
            left: 0px;
            bottom: 0px;
            width: 100%;
            height: 52px;
            background: #fff;
            box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
            .btn-group{
                text-align: center;
                padding-top: 10px;
                width: 100%;
                button{
                    width: 64px;
                    height: 32px;
                    font-size: 14px;
                }
                button:nth-child(1){
                    margin-right: 16px;
                }
            }
        }
    }
</style>