<template>
    <div class="verification">
        <!-- <div class="img-verificate">
            <slide-verify 
                ref="slideblock"
                @again="onAgain"
                @success="onSuccess"
                @fail="onFail"
                :accuracy="accuracy"
                :slider-text="text"
            >
            </slide-verify>
        </div> -->
        <div class="code-verificate">
            <div class="img-prove">
                <div>请输入验证码：</div>
                <a-input v-model.trim="verificationCode" class="" type='number' placeholder="请输入验证码" />
            </div>
            <div class="change">
                <div class="change-one"></div>
                <a-button v-if="!ifCount" :disabled='!ifPass' @click="getVeificationCode" class="number-code">获取验证码</a-button>
                <div class="count-data" v-if="ifCount" disabled>{{countNum}}s后重新获取</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            countNum: 60,
            ifCount: false,
            timer: null,
            verificationCode: null,
            text: '向右滑动',
            accuracy: 2,
            ifPass: true
        }
    },
    props:{
        message: {
            type: Object,
            required: true
        }
    },
    methods: {
        getVeificationCode() {
            if(this.message.bindedPhoneNum != null&&this.message.bindedPhoneNum != ''){
                this.ifCount = true
                this.timer = setInterval(()=>{
                    this.countNum -- 
                    if(this.countNum == 0){
                        clearInterval(this.timer)
                        this.countNum = 60
                        this.ifCount = false
                    }
                },1000)
                this.axios.post('/api/verifyCode', {
                    type: this.message.type,
                    phone: this.message.bindedPhoneNum,
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.$message.success('已发送')
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            }else{
                this.$message.error('请先输入手机号码')
            }
        },
        // onSuccess(times){
        //     this.ifPass = true
        //     this.$message.success('验证通过，耗时 '+ times + '毫秒')
        // },
        // onFail(){
        //     this.ifPass = false
        // },
        // onAgain() {
        //     this.$message.error('请再次尝试')
        //     this.$refs.slideblock.reset();
        // },
    }
}
</script>
<style scoped lang="less">
    .verification{
        margin-bottom: 32px;
        .img-verificate{
            font-size: 14px;
            color: rgba(153, 153, 153, 1);
            margin-top: 16px;
            .img-prove{
                display: inline-block;
                width: 320px;
                input{
                    margin-top: 8px;
                    height: 32px;
                }
            }
            .change{
                float: right;
                display: inline-block;
                width: 96px;
                margin-left: 8px;
                .change-one{
                    display: inline-block;
                    height: 18px;
                    width: 100%;
                }
                .number-code{
                    display: inline-block;
                    width: 96px;
                    height: 32px;
                    background: #ccc;
                    margin-top: 6px;
                    // cursor: pointer;
                }
            }
        }
        .code-verificate{
            font-size: 14px;
            color: rgba(153, 153, 153, 1);
            margin-top: 16px;
            .img-prove{
                display: inline-block;
                width: 320px;
                input{
                    margin-top: 8px;
                    height: 32px;
                }
            }
            .change{
                float: right;
                display: inline-block;
                width: 96px;
                margin-left: 8px;
                .change-one{
                    display: inline-block;
                    height: 24px;
                    width: 100%;
                    font-size: 12px;
                }
                .number-code{
                    display: inline-block;
                    width: 96px;
                    height: 32px;
                    background: rgba(24, 144, 255, 0.1);
                    border: 1px solid rgba(24, 144, 255, 1);
                    color: rgba(24, 144, 255, 1);
                    border-radius: 4px;
                    // cursor: pointer;
                    text-align: center;
                    line-height: 32px;
                }
                .count-data{
                    display: inline-block;
                    width: 96px;
                    height: 32px;
                    background: rgba(121, 136, 155, 0.08);
                    border: 1px solid rgba(121, 136, 155, 0.3);
                    color: rgba(121, 136, 155, 0.5);
                    border-radius: 4px;
                    cursor: pointer;
                    text-align: center;
                    line-height: 32px;
                }
            }
        }
    }
</style>
