<template>
    <div class="public-personal-center">
        <div class="left-menu">
            <div @click="backTo" class="back-to">
                <a-icon type="left" />
                <span>返回首页</span>
            </div>
            <ul class="menu-list">
                <li @click="chooseItem(item.component)" v-for="(item,index) in menuList" :key='index'>
                    <router-link :to="{path:'/'+item.component, query:{moduleId:item.moduleId,name:item.name}}">
                        <div :class="{'active':currentItem==item.component}">
                            <a-icon :type="item.icon" />
                            <span>{{item.name}}</span>
                        </div>
                    </router-link>
                </li>
            </ul>
        </div>
        <div class="right-content">
            <router-view @fatherMethod='fatherMethod'></router-view>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PublicPersonalCenter',
        data() {
            return {
                currentItem: 'personalInfo',
                menuList: [],
                firsrId: '',
            }
        },
        methods: {
            chooseItem(item) {
                this.currentItem = item
            },
            backTo() {
                sessionStorage.removeItem('menuKey')
                this.$router.push({
                    path: "/customerLead",
                    query:{
                        moduleId: this.firsrId
                    }
                })
            },
            queryMenuList() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/menu/backstage/list', 
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.menuList = response.data.data
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
                this.axios(
                    {
                        method:'get',
                        url:'/api/menu/list', 
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        let arr = response.data.data
                        if(arr.length > 0){
                            this.firsrId = 8
                        }
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            fatherMethod(val) {
                this.$emit('grandMethod',val)
            },
        },
        mounted: function() {
            this.currentItem = sessionStorage.getItem('personalCenter')
            this.queryMenuList()
        },
    }
</script>

<style scoped lang="less">
    .public-personal-center{
        display: flex;
        width: 100%;
        height: 100%;
        background: rgba(240, 242, 243, 1);
        .left-menu{
            width: 240px;
            height: 100%;
            background: #fff;
            .back-to{
                height: 48px;
                line-height: 48px;
                padding-left: 18px;
                cursor: pointer;
                i{
                    margin-right: 6px;
                }
            }
            .menu-list{
                li{
                    width: 100%;
                    height: 48px;
                    
                    a{
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        height: 48px;
                        color: rgba(18, 18, 18, 1);
                        font-size: 14px;
                        padding: 4px 0;
                        div{
                            line-height: 40px;
                            padding-left: 18px;
                            i{
                                margin-right: 6px;
                            }
                        }
                        div.active{
                            color: rgba(24, 144, 255, 1);
                            background: rgba(24, 144, 255, 0.2);
                        }
                    }
                }
            }
        }
        .right-content{
            flex: 1;
            height: 100%;
        }
    }
</style>