<template>
    <div class="login-out">
        <a-dropdown>
            <span class="avatar">
                <img v-if="avatar!=''" :src="avatar" alt="">
                <div class="username" v-if="avatar==''">
                    {{(name).substr(0, 1)}}
                </div>
                <span class="name-text">{{name}}</span>
                <a-icon type="down" />
            </span>
            <a-menu slot="overlay" class="">
                <a-menu-item>
                    <router-link to="/PersonalInfo">
                        <div>
                            <a-icon style="margin-right:4px" type="user"/>
                            <span>个人中心</span>
                        </div>
                    </router-link>
                </a-menu-item>
                <a-menu-divider/>
                <a-menu-item>
                    <a href="javascript:;" @click="queryEnterpriseData">
                        <a-icon style="margin-right:4px" type="swap"/>
                        <span>切换企业</span>
                    </a>
                </a-menu-item>
                <a-menu-divider/>
                <a-menu-item>
                    <!-- <div @click="goToMiddleCenter">
                        <a-icon type="appstore"/>
                        <span>进入中台</span>
                    </div> -->
                    <a href="javascript:;" @click="goToMiddleCenter">
                        <a-icon style="margin-right:4px" type="appstore"/>
                        <span>进入中台</span>
                    </a>
                </a-menu-item>
                <a-menu-divider/>
                <a-menu-item>
                    <a href="javascript:;" @click="loginOut">
                        <a-icon style="margin-right:4px" type="poweroff"/>
                        <span>退出登录</span>
                    </a>
                </a-menu-item>
            </a-menu>
        </a-dropdown>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="enterpriseModal" title="修改企业" footer='' width='480px'>
            <div class="normal-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <div class="choose-enterprise">选择企业</div>
                <div class="enterprise-list">
                    <div v-for="(item,index) in enterpriseList" :key='index' :class="{'active':currentSelectedId==item.id,'no-choose':currentId==item.id}" @click="chooseEnterprise(item.id)">{{item.name}}</div>
                </div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group">
                    <a-button type="primary" ghost @click="cancelNormal">取消</a-button>
                    <a-button type="primary" @click="sureNormal">确认</a-button>
                </div>
            </div>
        </a-modal>
    </div>
</template>

<script>
export default {
    props:[],
    data(){
        return{
            name: '',
            avatar: '',
            enterpriseModal: false,
            enterpriseList: [],
            currentSelectedId: '',
            currentId: ''
        }
    },
    methods: {
        loginOut() {
            this.$router.push("/login")
            sessionStorage.removeItem('token')
        },
        queryEnterpriseData() {
            this.axios(
                {
                    method:'get',
                    url:'/api/organization/listByLoginUser', 
                }
            )
            .then(response=> {
                if(response.data.code == 0){
                    this.enterpriseList = response.data.data.orgList
                    this.currentId = response.data.data.currentOrgId
                    if(this.enterpriseList.length > 1){
                        this.enterpriseModal = true
                    }else{
                        this.$message.warning('当前只有一家企业，无需切换')
                    }
                }else{
                    this.$message.error(response.data.userTip);
                }
            })
            .catch(error=> {
                console.log(error)
            })
        },
        cancelNormal() {
            this.enterpriseModal = false
            this.currentSelectedId = ''
        },
        sureNormal() {
            if(this.currentSelectedId == ''){
                this.$message.error('请选择企业')
            }else{
                this.enterpriseModal = false
                this.axios.post('/api/login/changeUser', {
                    orgId: this.currentSelectedId,
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.sessionData('set','token',response.data.data.token);
                        this.sessionData('set','name',response.data.data.name);
                        this.sessionData('set','avatar',response.data.data.avatar);
                        
                        location.reload() 
                        // this.$router.push("/PersonalInfo")
                        this.$router.push({
                            path: "/customerLead",
                            query: {
                                moduleId: 8
                            }
                        })
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            }
        },
        chooseEnterprise(id) {
            if(id != this.currentId){
                this.currentSelectedId = id
            }
        },
        goToMiddleCenter() {
            let host = window.location.host
            this.axios(
                {
                    method:'get',
                    url:'/api/intelligence/superLoginUrl', 
                    params: {
                        hostName: host
                    }
                }
            )
            .then(response=> {
                if(response.data.code == 0){
                    let url = response.data.data.superLoginUrl
                    window.location.href = url 
                }else{
                    this.$message.error(response.data.message);
                }
            })
            .catch(error=> {
                console.log(error);
            })
        }
    },
    mounted: function() {
        this.name = sessionStorage.getItem('name')
        this.avatar = sessionStorage.getItem('avatar')
    }
}
</script>
<style scoped lang="less">
    .login-out{
        display: inline-block;
        float: right;
        padding-top: 4px;
        cursor: pointer;
        .avatar{
            .username{
                display: inline-block;
                width: 32px;
                height: 32px;
                border-radius: 16px;
                color: #fff;
                text-align: center;
                line-height: 32px;
                background: rgba(121, 72, 234, 0.6);
                font-size: 16px;
                margin-right: 8px;
            }
            img{
                width: 32px;
                height: 32px;
                margin-right: 8px;
                border-radius: 16px;
            }
            i{
                margin-left: 16px;
            }
        }
    }
    .modal-public{
        .divider1{
            margin: 0px 0 24px 0;
        }
        .divider2{
            margin: 24px 0 12px;
        }
    }
    .normal-modal{
        padding-bottom: 11px;
        .choose-enterprise{
            color: rgba(153, 153, 153, 1);
            font-size: 14px;
            margin-bottom: 7px;
        }
        .enterprise-list{
            min-height: 86px;
            max-height: 216px;
            overflow-y: auto;
            div{
                width: 100%;
                height: 56px;
                line-height: 56px;
                border: 1px solid rgba(121, 136, 155, 0.3);
                border-radius: 4px;
                color: rgba(204, 204, 204, 1);
                padding-left: 10px;
                cursor: pointer;
                margin-bottom: 16px;
            }
            div:hover{
                border: 1px solid rgba(121, 136, 155, 1);
                color: rgba(121, 136, 155, 1);
            }
            div.active{
                border: 1px solid rgba(24, 144, 255, 1);
                background: rgba(24, 144, 255, 0.1);
                color: rgba(24, 144, 255, 1);
            }
            div.no-choose{
                cursor: not-allowed;
            }
        }
        .btn-group{
            text-align: right;
            button{
                margin-left: 8px;
            }
        }
    }
</style>
