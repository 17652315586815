<template>
    <div class="call-detail">
        <div class="head">
            <span class="title">{{digitalDetail.moduleName+"-"+digitalDetail.dataTitle}}</span>
            <div style="float:right;">
                <a-icon style="margin:0 0px 0 24px;float:left;margin-top:20px" type="exclamation-circle" />
                <OutLink v-if="operationAuthority.EXTERNAL_LINKS" :moduleId='moduleId' :dataId='currentId' :original='"CustomerLeadShare"'></OutLink>
            </div>
        </div>
        <div class="content">
            <div class="content-in">
                <div class="modular-content">
                    <div class="left-content">
                        <div class="related-module">
                            <span></span>
                            相关模块
                        </div>
                        <ul class="module-list">
                            <li :class="{'active':currentSelectModule==index}" @click="viewSenceDetail(index,item)" v-for="(item,index) in moduleNumList" :key='index'>
                                <a-icon type="profile" />
                                <div>{{item.name}}</div>
                                <span :title="item.num">{{item.num}}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="center-content">
                        <div class="basic-info">
                            <div class="title">基本信息</div>
                            <a-row class="row-list">
                                <a-col :span="(item.type=='LONG_TEXT'||item.type=='CUSTOMER_LABEL')?24:12" class="col-list" :class="{'col-left':index%2==0,'col-right':index%2!=0, 'col-time':item.type=='TIME'||item.code=='last_dynamic_user'}" v-for="(item,index) in digitalDetail.detailList" :key='index'>
                                    
                                    <div v-if="item.type!='TIME'" class="first-content">{{item.name}}:</div>
                                    
                                    <div v-if="item.type!='TIME'&&item.type!='CUSTOMER_LABEL'&&item.isEdit==false" class="second-content">
                                        {{item.value}}
                                        <a-icon @click="changeBasicEdit(index)" v-if="item.isEditable==1" class="edit-icon" style="" type="edit" />
                                    </div>

                                    <div v-if="item.type=='CUSTOMER_LABEL'" class="label-content">
                                        <a-button v-if="item.isEditable==1" @click="addLabel" icon="plus">
                                        添加标签
                                        </a-button>
                                        <span v-for="(item1,index1) in item.value" :style="{'background':item1.color}" :key='index1'>
                                            {{item1.name}}
                                            <a-icon v-if="item.isEditable==1" @click="removeDetaillTag(item1.labelId)" type="close" />
                                        </span>
                                    </div>

                                    <div v-if="item.type=='TEXT'&&item.isEdit==true" class="third-content">
                                        <a-input @blur="fieldInputBlur(item.fieldId,item.value)" :disabled='item.isEditable!=1' v-model="item.value" placeholder="请输入内容" />
                                    </div>
                                    
                                    <div class="user-content" v-if="item.type=='USER'&&item.isEditable==1&&item.isEdit==true">
                                        <a-tree-select
                                            class="selection-range"
                                            v-model="item.valueList"
                                            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                                            :tree-data="item.userTree"
                                            :placeholder="item.value"
                                            tree-default-expand-all
                                            @change="(value) => fieldInputBlur(item.fieldId, value)"
                                        >
                                        </a-tree-select>
                                    </div>
                                    <div v-if="item.type=='LONG_TEXT'&&item.isEdit==true" class="third-content">
                                        <a-textarea @blur="fieldInputBlur(item.fieldId,item.value)" :disabled='item.isEditable!=1' v-model="item.value" placeholder="请输入内容" :rows="2"/>
                                    </div>
                                </a-col>
                                <a-col :span="24">
                                    <a-upload
                                        :multiple="false"
                                        :customRequest="customRequest1"
                                        :showUploadList='false'
                                    >
                                        <a-button class="add-attachment" icon="plus"> 添加附件 </a-button>
                                    </a-upload>
                                </a-col>
                            </a-row>
                            <a-row>
                                <a-col :class="{'pd-24':index%2==0,'pd-8':index%2!=0,}" :span="12" v-for="(item,index) in attachmentList" :key='index'>
                                    <div class="attachment-item">
                                        <img :src="filterImg(item.suffix)" alt="">
                                        <div class="attachment-content">
                                            <div class="name-operation">
                                                <span :title='item.fileName'>{{item.fileName.length>16?item.fileName.substring(0,16)+'...':item.fileName}}</span>
                                                <a-icon @click="deleteFire(item.id)" type="delete" />
                                                <a-icon @click="downloadFire(item.previewUrl)" type="download" />
                                            </div>
                                            <div style="clear:both"></div>
                                            <div class="file-size">{{item.size}}</div>
                                        </div>
                                    </div>
                                </a-col>
                            </a-row>
                        </div>
                        <div class="related-clue">
                            <div class="title">
                                <span>关联线索</span>
                                <a-button @click="viewAllRelatedClueData" icon="sync">
                                同步标签
                                </a-button>
                            </div>
                            <a-row class="related-title">
                                <a-col :span="6">客户名称</a-col>
                                <a-col :span="6">负责人</a-col>
                                <a-col :span="6">最后动态时间</a-col>
                                <a-col :span="6">创建日期</a-col>
                            </a-row>
                            <a-row class="related-data" v-for="(item,index) in relatedData" :key='index'>
                                <div v-if="index<5">
                                    <a-col class="related-name" @click="refreshPage(item.id)" :span="6">{{item.name}}</a-col>
                                    <a-col class="related-charge" :span="6">{{item.owner}}</a-col>
                                    <a-col class="related-last-time" :span="6">{{item.lastDynamicTime}}</a-col>
                                    <a-col class="related-create-time" :span="6">{{item.createTime}}</a-col>
                                </div>
                            </a-row>
                        </div>
                        <div class="follow-time">
                            <div class="title">最后动态</div>
                            <a-row class="row-time">
                                <a-col :span="8">
                                    <div class="row-time-title">最后动态时间：</div>
                                    <div class="row-time-value">{{timeObj.last_dynamic_time}}</div>
                                </a-col>
                                <a-col :span="8">
                                    <div class="row-time-title">最后联系人：</div>
                                    <div class="row-time-value">{{timeObj.last_dynamic_user}}</div>
                                </a-col>
                                <a-col :span="8">
                                    <div class="row-time-title">下次联系时间：</div>
                                    <div class="row-time-value">{{timeObj.next_contact_time}}</div>
                                </a-col>
                            </a-row>
                        </div>
                        <div class="dynamic-info">
                            <div class="title">
                                <span>动态信息</span>
                                <a-button @click="addTrack" icon="plus">
                                新增
                                </a-button>
                            </div>
                            <div class="filter-condition">
                                <span @click="selectDynamic('')" :class="{'active':currentActive==''}">全部{{" ("+dynamicNumData.total+")"}}</span>
                                <span @click="selectDynamic(2)" :class="{'active':currentActive==2}">跟踪轨迹{{" ("+dynamicNumData.follow+")"}}</span>
                                <span @click="selectDynamic(3)" :class="{'active':currentActive==3}">通话轨迹{{" ("+dynamicNumData.chat+")"}}</span>
                                <span @click="selectDynamic(1)" :class="{'active':currentActive==1}">数据轨迹{{" ("+dynamicNumData.data+")"}}</span>
                            </div>
                            <div v-for="(item,index) in dynamicList" :key='index'>
                                <div class="dynamic-list" v-if="item.type==currentActive||currentActive==''">
                                    <div class="date">
                                        <span>{{item.createTime.split(" ")[1]==undefined?'':item.createTime.split(" ")[0]}}</span>
                                        <span :style="{'background':item.color}"></span>
                                    </div>
                                    <div class="active-track">
                                        <div class="time">
                                            <span>{{item.operator+''}}{{item.createTime.length==19?item.createTime.split(" ")[1]:item.createTime}}</span>
                                            <div class="next-time" v-if="item.type == 2&&item.nextContactTime!=''&&item.nextContactTime!=null">
                                                <span>下次联系日期：</span>
                                                <span>{{(item.nextContactTime!=''&&item.nextContactTime!=null)?item.nextContactTime.split(" ")[0]:''}}</span>
                                            </div>
                                        </div>
                                        <div class="active-event">
                                            <span>{{item.action}}</span>
                                        </div>
                                        <div class="dynamic-content">
                                            <div style="word-wrap: break-word;word-break: break-all;" v-if="item.isEdit==false">
                                                {{item.content}}
                                                <span v-if="item.content==''&&item.type==2">请输入内容</span>
                                                <a-icon v-if="item.type==2" @click="changeDynamicData(index)" class="dynamic-icon" type="edit" />
                                            </div>
                                            <a-textarea v-if="item.isEdit==true" placeholder="请输入内容" :rows="4" :disabled='currentActive==1' @blur="contentInputBlur(item.id,item.content)" v-model="item.content"/>
                                        </div>
                                        <div v-if="item.type == 2">
                                            <div class="label-content">
                                                <a-button @click="addLabel2(item.id)" icon="plus">
                                                添加标签
                                                </a-button>
                                                <span v-for="(item1,index1) in item.labelList" :style="{'border-color':item1.color,'color':item1.color}" :key='index1'>
                                                    {{item1.name}}
                                                    <a-icon @click="removeDetaillTag2(item.id, item1.labelId)" type="close" />
                                                </span>
                                            </div>
                                            
                                        </div>
                                        <div v-if="item.type == 3" class="chat-detail">
                                            <div class="play-voice" @click="viewChatDetail(item.viewUrl)">
                                                <a-icon type="play-circle" />
                                                <span>对话详情</span>
                                            </div>
                                            <span class="voice-content">通话时长{{item.duration}}s</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="comments">
                            <Comments ref="commentChild"></Comments>
                        </div>
                    </div>
                    <div class="right-content">
                        <div class="data-info">
                            <div class="related-info">
                                <span></span>
                                数据信息
                            </div>
                            <ul>
                                <li>
                                    <div>创建时间：</div>
                                    <div>{{digitalDetail.createTime}}</div>
                                </li>
                                <li>
                                    <div>修改时间：</div>
                                    <div>{{digitalDetail.updateTime}}</div>
                                </li>
                                <li>
                                    <div>创建人：</div>
                                    <div>{{digitalDetail.createBy}}</div>
                                </li>
                            </ul>
                        </div>
                        <div class="fast-operate">
                            <div class="fast-do">
                                <span></span>
                                快速操作
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="footer-edit">
            <a-button type="primary" ghost @click="deleteSence">删除</a-button>
        </div> -->
        <a-modal :centered='true' :maskClosable='false' v-model.trim="setTimeModal" title="时间设定" footer='' width='1168px'>
            <div class="time-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <div class="check-list">
                    <a-checkbox-group v-model="weekList">
                        <div class="check-item">
                            <a-checkbox value="MONDAY">
                                周一
                            </a-checkbox>
                        </div>
                        <div class="check-item">
                            <a-checkbox value="TUESDAY">
                                周二
                            </a-checkbox>
                        </div> 
                        <div class="check-item">
                            <a-checkbox value="WEDNESDAY">
                                周三
                            </a-checkbox>
                        </div>
                        <div class="check-item">
                            <a-checkbox value="THURSDAY">
                                周四
                            </a-checkbox>
                        </div> 
                        <div class="check-item">
                            <a-checkbox value="FRIDAY">
                                周五
                            </a-checkbox>
                        </div>
                        <div class="check-item">
                            <a-checkbox value="SATURDAY">
                                周六
                            </a-checkbox>
                        </div> 
                        <div class="check-item">
                            <a-checkbox value="SUNDAY">
                                周日
                            </a-checkbox>
                        </div>
                    </a-checkbox-group>
                </div>
                <div style="margin-bottom:12px;" v-for="(item,index) in timeList" :key='index'>
                    <div style="display:inline-block;width:160px" v-for="(item1,index1) in item" :key='index1'>
                        <a-time-picker style="width:72px;" v-model="item1.beginTime"  format="HH:mm" />
                        <span>-</span>
                        <a-time-picker style="width:72px;" v-model="item1.endTime"  format="HH:mm" />
                    </div>
                </div>
                <div style="margin-top:12px;">
                    <span @click="addTime" style="display:inline-block;width:144px;height:32px;color:rgba(24, 144, 255, 1);border:1px dotted rgba(24, 144, 255, 1);text-align:center;line-height:30px;cursor:pointer;border-radius:4px">+ 添加时间</span>
                </div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group">
                    <a-button type="primary" ghost @click="cancelCopyShare">取消</a-button>
                    <a-button type="primary" @click="sureCopyShare">确认</a-button>
                </div>
            </div>
        </a-modal>
        <a-drawer
            title=''
            placement="right"
            :closable="true"
            :visible="drawerVisibleSence"
            :destroyOnClose='true'
            :maskClosable='true'
            @close="onCloseDrawerSence"
            :width='800'
            :mask='true'
            :headerStyle="{ border: 'none', height: '56px', position: 'absolute', width: '100%', left: '0', top: '0', zIndex: '0'}"
            :bodyStyle="{ background: 'rgba(240, 242, 243, 1)', height: '100%', position: 'absolute', width: '100%', left: '0', top: '0', zIndex: '10', padding: '0 0 0' }"
        >
            <div class="drawer-sence drawer-public">
                <div class="drawer-head">
                    <div class="drawer-title">
                        <span class="left">{{digitalDetail.moduleName+"-"+(digitalDetail.dataTitle.length>28?digitalDetail.dataTitle.substring(0,28)+'...':digitalDetail.dataTitle)}}</span>
                        <div class="right">
                            <a-icon @click="onCloseDrawerSence" type="close" />
                        </div>
                    </div>
                </div>
                <div class="drawer-content">
                    <div class="drawer-body-content">
                        <a-table
                            :columns="columns"
                            :data-source="data"
                            :pagination='false'
                            :scroll="{ x: 'max-content' }"
                        />
                        <a-pagination
                            class="pagination-table"
                            show-size-changer
                            :total="totalData"
                            :show-total="totalData => `共 ${totalData} 条     `"
                            v-model="currentPage"
                            @change="pageChange"
                            @showSizeChange="onShowSizeChange"
                        />
                    </div>
                </div>
            </div>
        </a-drawer>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="join1Modal" title="加入号码-从别的模块导入" footer='' width='720px'>
            <div class="join1-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <a-row class="condition">
                    <a-col :span="12">
                        <div class="title title1">选择模块：</div>
                        <div class="content1">
                            <a-select placeholder='请选择模块' @change="changeSelectedModule" class="select-module">
                                <a-select-option :value="item.value" v-for="(item,index) in moduleList" :key='index'>
                                    {{item.name}}
                                </a-select-option>
                            </a-select>
                        </div>
                    </a-col>
                    <a-col :span="12">
                        <div class="title title3">选择字段：</div>
                        <div class="content3">
                            <a-select placeholder='请选择字段' v-model="currentSelectedWord" class="select-module">
                                <a-select-option :value="item.value" v-for="(item,index) in wordList" :key='index'>
                                    {{item.name}}
                                </a-select-option>
                            </a-select>
                        </div>
                    </a-col>
                    <a-col v-if="currentSelectedModule==7" :span="12" style="margin-top:12px;">
                        <div class="title title1">场景名称：</div>
                        <div class="content1">
                            <a-select placeholder='请选择场景' @change="changeSenceName" class="select-module">
                               <a-select-option :value="item.value" v-for="(item,index) in digitalEmployeeList" :key='index'>
                                {{item.name}}
                                </a-select-option>
                            </a-select>
                        </div>
                    </a-col>
                </a-row>
                <a-row v-for="(item,index) in viewScreenData" :key='index' style="margin-bottom:12px">
                    <a-col :span="4">
                        {{item.fieldName}}
                    </a-col>
                    <a-col :span="6">
                        <a-select v-model="item.selectedCondition" style="width:100%;height:32px" @change="selectConChange(item,index)">
                            <a-select-option :value="item1.value" v-for="(item1,index1) in item.methodList" :key='index1'>
                                {{item1.name}}
                            </a-select-option>
                        </a-select>
                    </a-col>
                    <a-col :span="14" style="padding-left:12px;">
                        <Linkage ref="child4"></Linkage>
                    </a-col>
                </a-row>
                <div style="text-align:right;color:rgba(24, 144, 255, 1);font-size:14px;margin-top:16px;">
                    <span style="cursor:pointer" @click="newSetCondition">重置筛选条件</span>
                </div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group">
                    <a-button type="primary" ghost @click="cancelJoin1">取消</a-button>
                    <a-button type="primary" @click="sureJoin1">下一步</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="join2Modal" title="加入号码-从别的模块导入" footer='' width='720px'>
            <div class="join2-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <div style="color:rgba(153, 153, 153, 1);font-size:14px;">共筛选出数据：</div>
                <div style="color:rgba(24, 144, 255, 1);font-size:14px;margin-top:8px">{{changedSelectedTotal}}条</div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group">
                    <a-button type="primary" ghost @click="cancelJoin2">取消</a-button>
                    <a-button type="primary" @click="sureJoin2">确认</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="normalModal" title="加入号码-普通导入" footer='' width='600px'>
            <div class="normal-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <div class="tab-normal">
                    <a-tabs default-active-key="1" v-model='currentTab'>
                        <a-tab-pane key="1" tab="文本输入">
                            <a-textarea v-model.trim="phoneInput" placeholder="输入号码" style="height:222px" />
                            <div style="margin-top:24px;color:rgba(153, 153, 153, 1);font-size:14px">输入的文本符合以下规范:</div>
                            <div style="color:rgba(80, 80, 80, 1);margin-top:8px;font-size:12px">一行一个电话号码<br>每行十一个数字</div>
                        </a-tab-pane>
                        <a-tab-pane key="2" tab="EXCEL" force-render>
                            <div>
                                <span style="color:rgba(80, 80, 80, 1);font-size:14px">*为保证数据导入顺利，请先</span>
                                <a-button @click="downloadTemplate" icon="download" style="margin-left:8px">
                                下载模板
                                </a-button>
                            </div>
                            <div style="min-height:166px;margin-top:24px">
                                <a-upload-dragger
                                    key={Math.random()}
                                    name="file"
                                    :multiple="false"
                                    :customRequest="customRequest"
                                    @change="onUpload"
                                    ref="pathClear"
                                >
                                    <p class="ant-upload-drag-icon">
                                    <a-icon type="upload" />
                                    </p>
                                    <p class="ant-upload-text">
                                    点击或将文件拖拽到这里上传
                                    </p>
                                    <p class="ant-upload-hint">
                                    支持扩展名：.xls .xlsx
                                    </p>
                                </a-upload-dragger>
                            </div>
                            <div style="margin-top:16px;color:rgba(153, 153, 153, 1);font-size:14px">上传的 Excel 表符合以下规范::</div>
                            <div style="color:rgba(80, 80, 80, 1);margin-top:8px;font-size:12px">文件大小不超过20MB<br>仅支持 (*.xls 和 *.xlsx)文件<br>请确保您需要导入的sheet表头中不包含空的单元格，否则该sheet页数据系统将不做导入</div>
                        </a-tab-pane>
                    </a-tabs>
                </div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group">
                    <a-button type="primary" ghost @click="cancelNormal">取消</a-button>
                    <a-button type="primary" @click="sureNormal">确认</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="newLabelModal2" title="标签" footer='' width='720px'>
            <div class="label-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <div>
                    <div class="new-tag">
                        <a-input v-model.trim="tagName2" placeholder="输入标签名称" />
                        <a-icon @click="addTagName2" type="plus-circle" />
                    </div>
                    <div class="tag-list">
                        <span @click="selectPersonalTag2(item.id, index)" :style="{'color':item.selected?'#fff':'','background':item.selected?item.color:''}" v-for="(item,index) in personalLabelList2" :key='index'>
                            {{item.name}}
                            <a-icon @click="removePersonalTag2(item.id)" type="close" />
                        </span>
                    </div>
                </div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group">
                    <a-button type="primary" ghost @click="cancelAddTag2">取消</a-button>
                    <a-button type="primary" @click="sureAddTag2">确定</a-button>
                </div>
            </div>
        </a-modal>
         <a-modal :centered='true' :maskClosable='false' v-model.trim="newLabelModal" title="标签" footer='' width='720px'>
            <div class="label-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <a-row>
                    <a-col :span="16">
                        <div class="title">企业标签</div>
                        <div class="company-tag-list" v-for="(item,index) in companyLabelList" :key='index'>
                            <div class="type-name">{{item.name}}</div>
                            <div class="label-list">
                                <span @click="selectPersonalTag(item1.id, index, index1)" :style="{'color':item1.selected?'#fff':'','background':item1.selected?item1.color:''}" v-for="(item1,index1) in item.labelList" :key='index1'>{{item1.name}}</span>
                            </div>
                        </div>
                    </a-col>
                    <a-col :span="8">
                        <div class="title">个人标签</div>
                        <div class="new-tag">
                            <a-input v-model.trim="tagName" placeholder="输入标签名称" />
                            <a-icon @click="addTagName" type="plus-circle" />
                        </div>
                        <div class="tag-list">
                            <span @click="selectPersonalTag(item.id, -1, index)" :style="{'color':item.selected?'#fff':'','background':item.selected?item.color:''}" v-for="(item,index) in personalLabelList" :key='index'>
                                {{item.name}}
                                <a-icon @click="removePersonalTag(item.id)" type="close" />
                            </span>
                        </div>
                    </a-col>
                </a-row>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group">
                    <a-button type="primary" ghost @click="cancelAddTag">取消</a-button>
                    <a-button type="primary" @click="sureAddTag">确定</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="trackModal" footer='' width='480px'>
            <div class="delete-modal modal-public">
                <div class="sure-off-bind">
                    <span style="margin-left:0px">预设下次联系时间</span>
                </div>
                <div class="tip" style="padding-left:0px;">
                    <a-date-picker format="YYYY-MM-DD HH:mm:ss" v-model="currentDynamicTime"/>
                </div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group2">
                    <a-button ghost @click="offAddTrack">取消</a-button>
                    <a-button type="primary" @click="sureAddTrack">确认</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="deleteTagModal" footer='' width='480px'>
            <div class="delete-modal modal-public">
                <div class="sure-off-bind">
                    <a-icon type="warning" />
                    <span>您确定要删除这个标签吗？</span>
                </div>
                <div class="tip">删除后，数据无法恢复</div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group2">
                    <a-button ghost @click="cancelTagDelete">取消</a-button>
                    <a-button type="primary" @click="sureTagDelete">确认</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="deleteTagModal2" footer='' width='480px'>
            <div class="delete-modal modal-public">
                <div class="sure-off-bind">
                    <a-icon type="warning" />
                    <span>您确定要删除这个标签吗？</span>
                </div>
                <div class="tip">删除后，数据无法恢复</div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group2">
                    <a-button ghost @click="cancelTagDelete2">取消</a-button>
                    <a-button type="primary" @click="sureTagDelete2">确认</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="relatedClueModal" title="关联线索" footer='' width='720px'>
            <div class="related-clue-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <div>
                    <a-table
                        :row-selection="{ selectedRowKeys: selectedRowKeys2, onChange: onSelectChange2 }"
                        :columns="columns2"
                        :data-source="relatedData"
                        :pagination='false'
                        :scroll="{ x: 'max-content' }"
                    >
                        
                    </a-table>
                </div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group">
                    <a-button type="primary" ghost @click="cancelRelatedClue">取消</a-button>
                    <a-button type="primary" @click="sureRelatedClue">同步</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="deleteAttachmentModal" footer='' width='480px'>
            <div class="delete-modal modal-public">
                <div class="sure-off-bind">
                    <a-icon type="warning" />
                    <span>您确定要删除这个附件吗？</span>
                </div>
                <div class="tip">删除后，数据无法恢复</div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group2">
                    <a-button ghost @click="cancelDeleteAttachment">取消</a-button>
                    <a-button type="primary" @click="sureDeleteAttachment">确认</a-button>
                </div>
            </div>
        </a-modal>
    </div>
</template>
<script>
    import moment from 'moment';
    import Comments from "../../components/Comments";
    import Linkage from '../../components/Linkage'
    import OutLink from "../../components/OutLink";
    export default {
        name: 'OutCallDetail',
        components: {Comments, Linkage, OutLink},
        data() {
            return {
                setTimeModal: false,
                drawerVisibleSence: false,
                drawerVisibleRecord: false,
                data: null,
                columns: null,
                join1Modal: false,
                join2Modal: false,
                normalModal: false,
                currentId: '',
                moduleId: '',
                digitalDetail: {
                    dataTitle: ''
                },
                moduleNumList: [],
                total: 0,
                currentSelectModule: null,
                timeList: [],
                weekList: [],
                taskName: undefined,
                taskDes: undefined,
                chargePerson: undefined,
                callType: null,
                phoneInput: '',
                currentTab: '1',
                newFormData: '',
                moduleList: [],
                currentSelectedModule: '',
                wordList: [],
                currentSelectedWord: undefined,
                currentSelectedSence: undefined,
                digitalEmployeeList: [],
                viewScreenData: [],
                conditionList: [],
                changedSelectedTotal: null,
                operationAuthority: '',
                copyData: [],
                totalData: 0,
                timer: null,
                currentCondition: 1,
                trackModal: false,
                newLabelModal2: false,
                selectedLabelList2: [],
                personalLabelList2: [],
                tagName2: '',
                timeObj: {
                    last_dynamic_time: '',
                    next_contact_time: '',
                    last_dynamic_user: ''
                },
                currentActive: 2,
                dynamicList: [],
                colorList: ['#256fe7', '#e54444', '#5dc7ff', '#ffaf00', '#23da77', '#a680ff'],
                selectedLabelList: [],
                newLabelModal: false,
                companyLabelList: [],
                tagName: '',
                personalLabelList: [],
                currentDynamicTime: null,
                dynamicNumData: {
                    tatal: 0,
                    follow: 0,
                    data: 0,
                    chat: 0
                },
                currentDeleteId: '',
                deleteTagModal: false,
                deleteTagModal2: false,
                currentDeleteId2: '',
                relatedClueModal: false,
                selectedRowKeys2: [],
                columns2: [
                    {
                        dataIndex: 'id',
                        key: 'id',
                        title: 'id',
                        colSpan: 0,
                        customRender: () => {
                            return {
                                attrs: {
                                    colSpan: 0,
                                },
                            };
                        },
                    },
                    {
                        dataIndex: 'name',
                        scopedSlots: {
                            customRender: 'name'
                        },
                        key: 'name',
                        title: '客户名称'
                    },
                    {
                        dataIndex: 'owner',
                        scopedSlots: {
                            customRender: 'owner'
                        },
                        key: 'owner',
                        title: '负责人'
                    },
                    {
                        dataIndex: 'lastDynamicTime',
                        scopedSlots: {
                            customRender: 'lastDynamicTime'
                        },
                        key: 'lastDynamicTime',
                        title: '最后动态时间'
                    },
                    {
                        dataIndex: 'createTime',
                        scopedSlots: {
                            customRender: 'createTime'
                        },
                        key: 'createTime',
                        title: '创建日期'
                    },
                ],
                relatedData: [],
                currentPage: 1,
                filterContent: '',
                newFormData1: '',
                attachmentList: [],
                currentFireId: '',
                deleteAttachmentModal: false,
            }
        },
        methods: {
            moment,
            customRequest1(data) {
                this.newFormData1 = ''
                const formData = new FormData() 
                formData.append('file', data.file)
                this.newFormData1 = formData
                this.axios({
                    method: 'post',
                    url: '/api/file/upload',
                    headers:{
                        'Content-Type':'application/x-www-form-urlencoded'
                    },
                    data: this.newFormData1,
                
                }).then((response) => {
                    if(response.data.code == 0){
                        this.newFormData1 = ''
                        this.addAtachment(response.data.data.fileId)
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                }).catch(function (error) {
                    console.log(error)
                })
            },
            addAtachment(attachment) {
                this.axios.post('/api/customer/attachment', {
                    customerId: this.currentId,
                    attachment: attachment,
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.queryAttachmentData()
                        this.queryDynamicData()
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            queryAttachmentData() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/customer/attachmentList', 
                        params: {
                            customerId: this.currentId,
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.attachmentList = response.data.data
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            downloadFire(url) {
                window.open(url, '_blank');
            },
            deleteFire(id) {
                this.currentFireId = id
                this.deleteAttachmentModal = true
            },
            cancelDeleteAttachment() {
                this.deleteAttachmentModal = false
            },
            sureDeleteAttachment() {
                this.deleteAttachmentModal = false
                this.axios.delete('/api/customer/attachment',{
                    params: {
                        id: this.currentFireId,
                    }
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.$message.success('删除成功')
                        this.queryAttachmentData()
                        this.queryDynamicData()
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            filterImg(type) {
                let url = "http://eocc.oss-cn-beijing.aliyuncs.com/icon/"
                let suffix = type.toLowerCase();
                if (suffix == "xls" || suffix == "xlsx") {
                    url+="excel.png";
                } else if (suffix == "doc" || suffix == "docx") {
                    url+="word.png";
                } else if (suffix == "zip" || suffix == "rar") {
                    url+="zip.png";
                } else if (suffix == "ppt") {
                    url+="ppt.png";
                } else if (suffix == "pptx") {
                    url+="ppt.png";
                } else if (suffix == "pdf") {
                    url+="pdf.png";
                } else if (suffix == "txt") {
                    url+="txt.png";
                } else {
                    url+="unknown.png";
                }
                return url
            },
            deleteSence() {
                this.axios.post('/api/module/data/delete/batch', {
                    moduleId: this.moduleId,
                    idList: [this.currentId],
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.$message.success('删除成功')
                        this.$router.go(-1)
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            saveEdit() {
                this.axios.post('/api/callTask/update', {
                    id: this.currentId,
                    name: this.taskName,
                    description: this.taskDes,
                    owner: this.chargePerson,
                    workTime: {
                        weekList: this.weekList,
                        timeList: this.timeList,
                    },
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.$message.success('保存成功')
                        this.taskName = undefined,
                        this.taskDes = undefined,
                        this.chargePerson = undefined,
                        this.querySelectData()
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            setTimeOperate() {
                this.setTimeModal = true
            },
            onCloseDrawerSence() {
                this.drawerVisibleSence = false
                this.currentSelectModule = null
            },
            viewSenceDetail(index,item) {
                this.drawerVisibleSence = true
                this.currentSelectModule = index
                this.associatedModuleId = item.id
                this.queryTableData()
            },
            queryTableData() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/module/associatedModule/data/List', 
                        params: {
                            moduleId: this.moduleId,
                            dataId: this.currentId,
                            associatedModuleId: this.associatedModuleId,
                            current: this.currentPage,
                            size: this.currentSize,
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.columns = response.data.data.tableHeader
                        this.data = response.data.data.tableData.records
                        this.totalData = response.data.data.tableData.total
                        for(let i = 0; i < this.columns.length; i++){
                            if(this.columns[i]['title'] == 'id'){
                                this.columns[i] = {
                                    title: this.columns[i]['title'],
                                    dataIndex: this.columns[i]['dataIndex'],
                                    colSpan:0,  
                                    customRender: () => {
                                        return {
                                            attrs: {
                                                colSpan: 0,
                                            },
                                        };
                                    },
                                }
                            }
                        }
                        this.total = response.data.data.tableData.total
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            onShowSizeChange(current, pageSize) {
                this.currentPage = current
                this.currentSize = pageSize
                this.queryTableData()
            },
            pageChange(current, pageSize) {
                this.currentPage = current
                this.pageSize = pageSize
                this.queryTableData()
            },
            cancelJoin1() {
                this.join1Modal = false
            },
            sureJoin1() {
                this.join1Modal = false
                this.join2Modal = true
                let data = this.$refs.child4
                let arr = []
                for(let i = 0; i < data.length; i++){
                    let obj = {}
                    if(data[i]['_data']['viewportData']['selectedCondition']!='ALL'&&data[i]['_data']['viewportData']['selectedCondition']!=undefined){
                        obj.fieldId = data[i]['_data']['viewportData']['fieldId']
                        obj.fieldMethod = data[i]['_data']['viewportData']['selectedCondition']
                        obj.valueList = data[i]['_data']['viewportData']['valueList']
                        arr.push(obj)
                    }
                }
                this.conditionList = arr
                this.axios.post('/api/waitingCall/import/otherModule/dataCount', {
                    moduleId: this.currentSelectedModule,
                    callTaskId: this.currentId,
                    digitalEmployeeId: this.currentSelectedSence,
                    phoneFieldId: this.currentSelectedWord,
                    conditionList: this.conditionList,
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.changedSelectedTotal = response.data.data.count
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            cancelJoin2() {
                this.join2Modal = false
            },
            sureJoin2() {
                this.join2Modal = false
                if(this.changedSelectedTotal != 0){
                    this.axios.post('/api/waitingCall/import/otherModule', {
                        moduleId: this.currentSelectedModule,
                        callTaskId: this.currentId,
                        digitalEmployeeId: this.currentSelectedSence,
                        phoneFieldId: this.currentSelectedWord,
                        conditionList: this.conditionList,
                    })
                    .then(response=> {
                        if(response.data.code == 0){
                            this.$message.success('导入成功')
                        }else{
                            this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                        }
                    })
                    .catch(error=> {
                        console.log(error);
                    });
                }
            },
            cancelNormal() {
                this.normalModal = false
            },
            sureNormal() {
                this.normalModal = false
                if(this.currentTab == 1){
                    let arr = this.phoneInput.split('\n')
                    let newArr = []
                    for(let i = 0; i < arr.length; i++){
                        if(arr[i].replace(/\s/g,"").length != 0){
                            newArr.push(arr[i])
                        }
                    }
                    this.axios.post('/api/waitingCall/import/text', {
                        callTaskId: this.currentId,
                        phoneList: newArr,
                    })
                    .then(response=> {
                        if(response.data.code == 0){
                            this.$message.success('导入成功')
                            this.phoneInput = ''
                        }else{
                            this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                        }
                    })
                    .catch(error=> {
                        console.log(error);
                    });
                }else{
                    this.axios({
                        method: 'post',
                        url: '/api/waitingCall/import/excel',
                        headers:{
                            'Content-Type':'application/x-www-form-urlencoded'
                        },
                        data: this.newFormData,
                    
                    }).then((response) => {
                        if(response.data.code == 0){
                            this.$message.success('导入成功')
                            this.newFormData = ''
                            this.queryDataNum()
                        }else{
                            this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                        }
                    }).catch(function (error) {
                        console.log(error)
                    })
                    
                }
            },
            onUpload() {
                this.$refs.pathClear.value = ''
            },
            customRequest(data) { // 上传提交
                this.newFormData = ''
                const formData = new FormData() 
                formData.append('file', data.file)
                formData.append('callTaskId', this.currentId)
                this.newFormData = formData
            },
            querySelectData() {
                this.axios(
                   {
                        method:'get',
                        url:'/api/module/data/detail', 
                        params: {
                            moduleId: this.moduleId,
                            dataId: this.currentId
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.digitalDetail = response.data.data
                        let arr = response.data.data.detailList
                        for(let i = 0;i < arr.length; i++){
                            if(arr[i]['code'] == 'last_dynamic_time'){
                                this.timeObj.last_dynamic_time = arr[i]['value']
                            }
                            if(arr[i]['code'] == 'next_contact_time'){
                                this.timeObj.next_contact_time = arr[i]['value']
                            }
                            if(arr[i]['code'] == 'last_dynamic_user'){
                                this.timeObj.last_dynamic_user = arr[i]['value']
                            }
                        }
                        this.$refs.commentChild.getParentCommentTree(this.moduleId, this.currentId, true)
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            queryModuleList(arr) {
                this.axios(
                    {
                        method:'get',
                        url:'/api/module/associatedModuleList', 
                        params: {
                            moduleId: this.moduleId,
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        let data = response.data.data
                        for(let i = 0; i < data.length; i++){
                            for(let j = 0; j < arr.length; j++){
                                if(data[i]['id'] == arr[j]['moduleId']){
                                    data[i]['num'] = arr[j]['count']
                                }
                            }
                        }
                        this.moduleNumList = data
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            queryDataNum() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/module/associatedModule/data/countList', 
                        params: {
                            moduleId: this.moduleId,
                            dataId: this.currentId
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        let data = response.data.data
                        let arr = []
                        for(let i = 0; i < data.length; i++){
                            let obj = {}
                            obj.count = data[i]['count']
                            obj.moduleId = data[i]['id']
                            arr.push(obj)
                        }
                        this.queryModuleList(arr)
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            cancelCopyShare() {
                this.setTimeModal = false
            },
            sureCopyShare() {
                this.setTimeModal = false
                for(let i = 0; i < this.timeList.length; i++){
                    for(let j = 0; j < this.timeList[i].length; j++){
                        if(this.timeList[i][j]['beginTime']!=null){
                            this.timeList[i][j]['beginTime'] = moment(this.timeList[i][j]['beginTime'], 'HH:mm').format('HH:mm')
                        }
                        if(this.timeList[i][j]['endTime']!=null){
                            this.timeList[i][j]['endTime'] = moment(this.timeList[i][j]['endTime'], 'HH:mm').format('HH:mm')
                        }
                    }
                }
            },
            changeTime(item) {
                this.timeList = item.value.timeList
                for(let i = 0; i < this.timeList.length; i++){
                    for(let j = 0; j < this.timeList[i].length; j++){
                        if(this.timeList[i][j]['beginTime']!=null){
                            this.timeList[i][j]['beginTime'] = moment(this.timeList[i][j]['beginTime'], 'HH:mm')
                            this.timeList[i][j]['endTime'] = moment(this.timeList[i][j]['endTime'], 'HH:mm')
                        }
                    }
                }
                this.weekList = item.value.weekList
                this.setTimeModal = true
            },
            addTime() {
                let arr = []
                arr = [
                    {
                        beginTime: null,
                        endTime: null
                    },
                    {
                        beginTime: null,
                        endTime: null
                    },
                    {
                        beginTime: null,
                        endTime: null
                    },
                    {
                        beginTime: null,
                        endTime: null
                    },
                    {
                        beginTime: null,
                        endTime: null
                    },
                    {
                        beginTime: null,
                        endTime: null
                    },
                    {
                        beginTime: null,
                        endTime: null
                    },
                ]
                this.timeList.push(arr)
            },
            changeTaskName(e) {
                this.taskName = e.target.value
            },
            changeDes(e) {
                this.taskDes = e.target.value
            },
            changePerson(e) {
                this.chargePerson = e
            },
            downloadTemplate() {
                window.open('/api/waitingCall/download/template/'+this.currentId, '_blank');
            },
            getModuleList() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/module/list/containPhone', 
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.moduleList = response.data.data
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            changeSelectedModule(val) {
                this.currentSelectedModule = val
                this.wordList = []
                this.currentSelectedWord = undefined
                this.queryWord()
                this.queryScreenData()
                if(val == 7){
                    this.querySenceList()
                }else{
                    this.currentSelectedSence = undefined
                }
            },
            changeSenceName(val) {
                this.currentSelectedSence = val
                this.queryScreenData2()
            },
            queryWord() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/module/'+this.currentSelectedModule+'/phoneField/list', 
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.wordList = response.data.data
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            querySenceList() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/employee/select/list', 
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.digitalEmployeeList = response.data.data
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            changeSelectedWord(val) {
                this.currentSelectedWord = val
            },
            queryScreenData() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/module/'+this.currentSelectedModule+'/conditionList', 
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.viewScreenData = this.deepClone(response.data.data)
                        this.copyData = this.deepClone(response.data.data)
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            queryScreenData2() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/module/'+this.currentSelectedModule+'/conditionList', 
                        params: {
                            digitalEmployeeId: this.currentSelectedSence,
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.viewScreenData = this.deepClone(response.data.data)
                        this.copyData = this.deepClone(response.data.data)
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            newSetCondition() {
                let data = this.deepClone(this.copyData)
                this.viewScreenData = data
                for(let i = 0; i < this.viewScreenData.length; i++){
                    this.$refs.child4[i].getPassData(this.viewScreenData[i],i)
                }
                this.$forceUpdate()
            },  
            deepClone(obj){
                let _obj = JSON.stringify(obj),
                objClone = JSON.parse(_obj);
                return objClone
            },
            selectConChange(item, index) {
                this.$refs.child4[index].getPassData(item,index)
            },
            queryAuthority() {
                this.axios(
                    {
                        method:'get',
                        url:'api/module/'+this.moduleId+'/operationAuthority', 
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.operationAuthority = response.data.data
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            chooseCondition(type) {
                this.currentCondition = type
            },
            queryPersonalTag2() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/customer/dynamicLabel/list', 
                        params: {
                            dynamicId: this.dynamicId
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.personalLabelList2 = response.data.data
                        for(let i = 0; i < response.data.data.length; i++){
                            if(response.data.data[i]['selected']){
                                this.selectedLabelList2.push(response.data.data[i]['id'])
                            }
                        }
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            addLabel2(id) {
                this.newLabelModal2 = true
                this.selectedLabelList2 = []
                this.dynamicId = id
                this.queryPersonalTag2()
            },
            selectPersonalTag2(id, index2) {
                let index = this.selectedLabelList2.indexOf(id);
                if(index == -1){
                    this.selectedLabelList2.push(id)
                    this.personalLabelList2[index2]['selected'] = true
                }else{
                    this.selectedLabelList2 = this.selectedLabelList2.filter(item => item != id)
                    this.personalLabelList2[index2]['selected'] = false
                }
            },
            addTagName2() {
                if(this.tagName2.length == 0){
                    this.$message.error('请输入标签名称')
                }else{
                    this.axios.post('/api/customer/dynamicLabel', {
                        name: this.tagName2,
                        color: this.randomColor(),
                    })
                    .then(response=> {
                        if(response.data.code == 0){
                            this.selectedLabelList2 = []
                            this.queryPersonalTag2()
                            this.tagName2 = ''
                        }else{
                            this.$message.error(response.data.userTip);
                            if(response.data.code == 'A0200'){
                                this.$router.push("/login")
                            }
                        }
                    })
                    .catch(error=> {
                        console.log(error);
                    });
                }
            },
            cancelTagDelete() {
                this.deleteTagModal = false
            },
            sureTagDelete() {
                this.deleteTagModal = false
                this.axios.delete('/api/customer/dynamicLabel',{
                    params: {
                        id: this.currentDeleteId
                    }
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.selectedLabelList2 = []
                        this.queryPersonalTag2()
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            removePersonalTag2(id) {
                event.stopPropagation()
                this.deleteTagModal = true
                this.currentDeleteId = id
            },
            cancelAddTag2() {
                this.newLabelModal2 = false
                this.selectedLabelList2 = []
            },
            sureAddTag2() {
                this.newLabelModal2 = false
                this.axios.post('/api/customer/setDynamicLabel', {
                    dynamicId: this.dynamicId,
                    labelIdList: this.selectedLabelList2,
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.queryDynamicData()
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            removeDetaillTag2(dynamicId, id) {
                event.stopPropagation()
                this.axios.delete('/api/customer/deleteDynamicLabel',{
                    params: {
                        labelId: id,
                        dynamicId: dynamicId
                    }
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.queryDynamicData()
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            onChangeNextDate(id, dateString) {
                console.log(id, dateString);
                this.axios.post('/api/customer/updateDynamicNextContactTime', {
                    dynamicId: id,
                    nextContactTime: dateString,
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.queryDynamicData()
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            addTrack() {
                this.currentDynamicTime = this.timeObj.next_contact_time==''?null:this.timeObj.next_contact_time
                this.trackModal = true
            },
            transformTimestamp(timestamp) {
                let a = new Date(timestamp).getTime();
                const date = new Date(a);
                const Y = date.getFullYear() + '-';
                const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
                const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
                const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
                const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
                const s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
                const dateString = Y + M + D + h + m + s;
                return dateString;
            },
            sureAddTrack() {
                this.trackModal = false
                this.axios.post('/api/customer/dynamic', {
                    customerId: this.currentId,
                    nextContactTime: this.currentDynamicTime!=null?this.transformTimestamp(this.currentDynamicTime):''
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.queryDynamicData()
                        this.querySelectData()
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            offAddTrack() {
                this.trackModal = false
            },
            selectDynamic(type) {
                this.currentActive = type
                this.queryDynamicData()
            },
            addLabel() {
                this.newLabelModal = true
                this.selectedLabelList = []
                this.queryCompanyLabelData()
                this.queryPersonalTag()
            },
            queryCompanyLabelData() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/customer/enterpriseLabel/list', 
                        params: {
                            customerId: this.currentId
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.companyLabelList = response.data.data
                        for(let i = 0; i < response.data.data.length; i++){
                            for(let j = 0; j < response.data.data[i]['labelList'].length; j++){
                                if(response.data.data[i]['labelList'][j]['selected']){
                                    this.selectedLabelList.push(response.data.data[i]['labelList'][j]['id'])
                                }
                            }
                        }
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            queryPersonalTag() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/customer/personalLabel/list', 
                        params: {
                            customerId: this.currentId
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.personalLabelList = response.data.data
                        for(let i = 0; i < response.data.data.length; i++){
                            if(response.data.data[i]['selected']){
                                this.selectedLabelList.push(response.data.data[i]['id'])
                            }
                        }
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            queryDynamicData() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/customer/dynamic/list', 
                        params: {
                            customerId: this.currentId,
                            type: ''
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        let arr = response.data.data
                        let brr = []
                        this.dynamicNumData = {
                            total: arr.length,
                            follow: 0,
                            data: 0,
                            chat: 0
                        }
                        for(let i = 0; i < arr.length; i++){
                            if(arr[i]['type'] == 1){
                               this.dynamicNumData.data++ 
                            }else if(arr[i]['type'] == 2){
                                this.dynamicNumData.follow++ 
                            }else if(arr[i]['type'] == 3){
                                this.dynamicNumData.chat++ 
                            }

                            if(arr[i]['type'] == this.currentActive||this.currentActive==''){
                                if(brr.indexOf(arr[i]['createTime'].split(" ")[0])==-1){
                                    brr.push(arr[i]['createTime'].split(" ")[0])
                                }else{
                                    arr[i]['createTime'] = arr[i]['createTime'].split(" ")[1]
                                }
                            }
                        }
                        this.dynamicList = arr
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            randomColor() {
                let num = Math.floor(Math.random()*6)
                let color = this.colorList[num]
                return color
            },
            selectPersonalTag(id, index1, index2) {
                let index = this.selectedLabelList.indexOf(id);
                if(index == -1){
                    this.selectedLabelList.push(id)
                    if(index1 == -1){
                        this.personalLabelList[index2]['selected'] = true
                    }else{
                        this.companyLabelList[index1]['labelList'][index2]['selected'] = true
                    }
                }else{
                    this.selectedLabelList = this.selectedLabelList.filter(item => item != id)
                    if(index1 == -1){
                        this.personalLabelList[index2]['selected'] = false
                    }else{
                        this.companyLabelList[index1]['labelList'][index2]['selected'] = false
                    }
                }
            },
            cancelAddTag() {
                this.newLabelModal = false
                this.selectedLabelList = []
            },
            sureAddTag() {
                this.newLabelModal = false
                this.axios.post('/api/customer/setCustomerLabel', {
                    customerId: this.currentId,
                    labelIdList: this.selectedLabelList,
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.querySelectData()
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            cancelTagDelete2() {
                this.deleteTagModal2 = false
            },
            sureTagDelete2() {
                this.deleteTagModal2 = false
                this.axios.delete('/api/customer/personalLabel',{
                    params: {
                        id: this.currentDeleteId2
                    }
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.selectedLabelList = []
                        this.queryCompanyLabelData()
                        this.queryPersonalTag()
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            removePersonalTag(id) {
                event.stopPropagation()
                this.deleteTagModal2 = true
                this.currentDeleteId2 = id
            },
            removeDetaillTag(id) {
                event.stopPropagation()
                this.axios.delete('/api/customer/deleteCustomerLabel',{
                    params: {
                        customerId: this.currentId,
                        labelId: id
                    }
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.querySelectData()
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            addTagName() {
                if(this.tagName.length == 0){
                    this.$message.error('请输入标签名称')
                }else{
                    this.axios.post('/api/customer/personalLabel', {
                        name: this.tagName,
                        color: this.randomColor(),
                    })
                    .then(response=> {
                        if(response.data.code == 0){
                            this.selectedLabelList = []
                            this.queryCompanyLabelData()
                            this.queryPersonalTag()
                            this.tagName = ''
                        }else{
                            this.$message.error(response.data.userTip);
                            if(response.data.code == 'A0200'){
                                this.$router.push("/login")
                            }
                        }
                    })
                    .catch(error=> {
                        console.log(error);
                    });
                }
            },
            fieldInputBlur(id, value) {
                this.axios.post('/api/customer/updateCustomer', {
                    customerId: this.currentId,
                    fieldId: id,
                    value: value,
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.querySelectData()
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            contentInputBlur(id, content) {
                this.axios.post('/api/customer/updateDynamicContent', {
                    dynamicId: id,
                    content: content,
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.queryDynamicData()
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            changeBasicEdit(index) {
                this.digitalDetail.detailList[index]['isEdit'] = true
            },
            changeDynamicData(index) {
                this.dynamicList[index]['isEdit'] = true
            },
            viewAllRelatedClueData() {
                this.relatedClueModal = true
            },
            cancelRelatedClue() {
                this.relatedClueModal = false
            },
            sureRelatedClue() {
                if(this.selectedRowKeys2.length == 0){
                    this.$message.error('请先选择关联线索')
                }else{
                    this.relatedClueModal = false
                    this.axios.post('/api/customer/syncCustomerLabel', {
                        customerId: this.currentId,
                        associatedCustomerIdList: this.selectedRowKeys2,
                    })
                    .then(response=> {
                        if(response.data.code == 0){
                            this.selectedRowKeys2 = []
                            this.querySelectData()
                            this.$message.success('同步完成')
                        }else{
                            this.$message.error(response.data.userTip);
                            if(response.data.code == 'A0200'){
                                this.$router.push("/login")
                            }
                        }
                    })
                    .catch(error=> {
                        console.log(error);
                    });
                }
                
            },
            onSelectChange2(selectedRowKeys) {
                this.selectedRowKeys2 = selectedRowKeys;
            },
            queryRelatedData() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/customer/associatedCustomerList', 
                        params: {
                            customerId: this.currentId
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.relatedData = response.data.data
                        for(let i = 0; i < this.relatedData.length; i++){
                            this.relatedData[i]['key'] = this.relatedData[i]['id']
                        }
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            refreshPage(id) {
                this.currentId = id
                this.querySelectData()
                this.queryRelatedData()
                this.queryDynamicData()
            },
            viewChatDetail(url) {
                console.log(url)
                if(url == ''){
                    this.$message.warning('该对话未接通')
                }else{
                    window.open(url, '_blank');
                }
            },
        },
        mounted: function() {
            this.currentId = this.$route.query.id
            this.moduleId = this.$route.query.moduleId
            this.queryAuthority()
            this.querySelectData()
            this.queryDataNum()
            this.getModuleList()
            this.queryDynamicData()
            this.queryRelatedData()
            this.queryAttachmentData()
        },
        beforeRouteLeave(to, from, next){
            next();
            clearInterval(this.timer);
        },
    }
</script>

<style scoped lang="less">
    .call-detail{
        height: 100%;
        position: relative;
        background: rgba(240, 242, 243, 1);
        .head{
            height: 48px;
            line-height: 48px;
            padding: 0 24px;
            background: #fff;
            z-index: 100;
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            button{
                // float: right;
                // width: 64px;
                height: 32px;
                margin: 8px 0 0 8px;
            }
            .title{
                color: rgba(18, 18, 18, 1);
                font-size: 16px;
            }
            i{
                // float: right;
                margin-top: 16px;
                font-size: 16px;
                color: rgba(204, 204, 204, 1);
                cursor: pointer;
            }
        }
        .content{
            position: absolute;
            padding: 64px 16px 24px 16px;
            left: 0px;
            top: 0px;
            height: 100%;
            padding-top: 64px;
            width: 100%;
            .content-in{
                width: 100%;
                height: 100%;
                position: relative;
                .modular-content{
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    height: 100%;
                    width: 100%;
                    overflow-y: auto;
                    display: flex;
                    .left-content{
                        width: 288px;
                        height: 100%;
                        background: #fff;
                        border-radius: 4px;
                        padding: 21px 24px;
                        .related-module{
                            color: rgba(18, 18, 18, 1);
                            font-size: 14px;
                            span{
                                width: 3px;
                                height: 14px;
                                float: left;
                                background: rgba(24, 144, 255, 1);
                                margin: 4px 7px 0 0;
                            }
                        }
                        .module-list{
                            padding-top: 16px;
                            li{
                                cursor: pointer;
                                padding: 8px 0;
                                i{
                                    font-size: 16px;
                                    color: rgba(51, 51, 51, 1);
                                    margin-left: 24px;
                                }
                                div{
                                    display: inline-block;
                                    color: rgba(18, 18, 18, 1);
                                    font-size: 14px;
                                    margin-left: 6px;
                                }
                                span{
                                    display: inline-block;
                                    width: 18px;
                                    height: 18px;
                                    text-align: center;
                                    line-height: 18px;
                                    background: rgba(245, 87, 78, 1);
                                    color: #fff;
                                    border-radius: 9px;
                                    float: right;
                                    margin-right: 10px;
                                }
                            }
                            li.active{
                                background: rgba(24, 144, 255, 0.1);
                                border-radius: 19px;
                                i{
                                    color: rgba(24, 144, 255, 1);
                                }
                                div{
                                    color: rgba(24, 144, 255, 1);
                                }
                            }
                        }
                    }
                    .center-content{
                        flex: 1;
                        height: 100%;
                        margin: 0 24px;
                        overflow-y: auto;
                        overflow-x: hidden;
                        .basic-info{
                            width: 100%;
                            // height: 704px;
                            background: #fff;
                            border-radius: 4px;
                            padding: 24px 24px 24px 0;
                            .title{
                                color: rgba(18, 18, 18, 1);
                                font-size: 14px;
                                padding-left: 24px;
                            }
                            .col-left{
                                padding-right: 24px;
                            }
                            .col-right{
                                // padding-left: 24px;
                            }
                            .row-list{
                                .col-list{
                                    margin-top: 19px;
                                    margin-bottom: 5px;
                                    min-height: 69px;
                                    padding-left: 24px;
                                    .first-content{
                                        color: rgba(188, 188, 188, 1);
                                        font-size: 12px;
                                    }
                                    .second-content{
                                        color: rgba(51, 51, 51, 1);
                                        font-size: 14px;
                                        margin-top: 9px;
                                        img{
                                            width: 21px;
                                        }
                                        .change-head{
                                            color: rgba(24, 144, 255, 1);
                                            font-size: 12px;
                                            cursor: pointer;
                                            margin-left: 24px;
                                        }
                                        .edit-icon{
                                            display: none;
                                        }
                                    }
                                    .selection-range{
                                        // margin-top: 9px;
                                        width: 100%;
                                    }
                                    .third-content{
                                        color: rgba(51, 51, 51, 1);
                                        font-size: 14px;
                                        margin-top: 4px;
                                        // display: none;
                                    }
                                    .user-content{
                                        color: rgba(51, 51, 51, 1);
                                        font-size: 14px;
                                        margin-top: 4px;
                                    }
                                    .label-content{
                                        margin-top: 9px;
                                        button{
                                            border: none;
                                            background: rgba(158, 172, 196, 0.12);
                                            color: rgba(158, 172, 196, 1);
                                            font-size: 13px;
                                            height: 28px;
                                        }
                                        span{
                                            display: inline-block;
                                            height: 28px;
                                            line-height: 28px;
                                            color: #fff;
                                            font-size: 13px;
                                            padding: 0 12px;
                                            margin: 2px 4px 2px 0;
                                            background: rgba(158, 172, 196, 0.12);
                                            i{
                                                cursor: pointer;
                                            }
                                        }
                                    }
                                }
                                .col-list:hover{
                                    .edit-icon{
                                        display: inline-block;
                                        cursor: pointer;
                                        color: #1890ff;
                                    }
                                }
                                .col-time{
                                    display: none !important;
                                }
                            }
                            .add-attachment{
                                margin-left: 24px;
                                border: none;
                                background: rgba(158, 172, 196, 0.12);
                                color: rgba(158, 172, 196, 1);
                                font-size: 13px;
                            }
                            .pd-24{
                                padding-left: 24px;
                            }
                            .pd-8{
                                padding-left: 8px;
                            }
                            .attachment-item{
                                padding: 9px 12px 0 17px;
                                height: 52px;
                                border: 1px solid rgba(158, 172, 196, 0.3);
                                margin-top: 8px;
                                display: flex;
                                img{
                                    width: 36px;
                                    height: 36px;
                                }
                                .attachment-content{
                                    flex: 1;
                                    padding-left: 8px;
                                    .name-operation{
                                        span{
                                            float: left;
                                            color: rgba(18, 18, 18, 1);
                                            font-size: 13px;
                                        }
                                        i{
                                            float: right;
                                            color: rgba(158, 172, 196, 1);
                                            font-size: 13px;
                                            cursor: pointer;
                                            margin-left: 12px;
                                        }
                                        i:hover{
                                            color: #1890ff;
                                        }
                                    }
                                    .file-size{
                                        color: rgba(158, 172, 196, 1);
                                        font-size: 12px;
                                    }
                                }
                            }
                        }  
                        .follow-time{
                            height: 136px;
                            padding: 18px 0 0 24px;
                            margin-top: 16px;
                            border-radius: 4px;
                            background: #fff;
                            .title{
                                color: rgba(18, 18, 18, 1);
                                font-size: 14px;
                            }
                            .row-time{
                                margin-top: 24px;
                                .row-time-title{
                                    color: rgba(188, 188, 188, 1);
                                    font-size: 12px;
                                }
                                .row-time-value{
                                    color: rgba(51, 51, 51, 1);
                                    font-size: 14px;
                                    margin-top: 9px;
                                }
                            }
                        }
                        .related-clue{
                            padding: 18px 0 24px 24px;
                            margin-top: 16px;
                            border-radius: 4px;
                            background: #fff;
                            max-height: 260px;
                            overflow-y: hidden;
                            .span{
                                color: rgba(18, 18, 18, 1);
                                font-size: 14px;
                            }
                            button{
                                float: right;
                                color: rgba(24, 144, 255, 1);
                                font-size: 14px;
                                border: none;
                            }
                            .related-title{
                                color: rgba(188, 188, 188, 1);
                                font-size: 12px;
                                margin-top: 24px;
                            }
                            .related-data{
                                margin-top: 8px;
                                .related-name{
                                    color: rgba(61, 134, 253, 1);
                                    font-size: 14px;
                                    cursor: pointer;
                                }
                                .related-content{
                                    color: rgba(51, 51, 51, 1);
                                    font-size: 14px;
                                }
                            }
                        }
                        .dynamic-info{
                            padding: 18px 0 36px 24px;
                            margin-top: 16px;
                            border-radius: 4px;
                            background: #fff;
                            .title{
                                span{
                                    color: rgba(18, 18, 18, 1);
                                    font-size: 14px;
                                }
                                button{
                                    float: right;
                                    color: rgba(24, 144, 255, 1);
                                    font-size: 14px;
                                    border: none;
                                }
                            }
                            .filter-condition{
                                margin-top: 23px;
                                margin-bottom: 16px;
                                span{
                                    display: inline-block;
                                    height: 28px;
                                    line-height: 28px;
                                    color: rgba(121, 136, 155, 1);
                                    font-size: 13px;
                                    margin-right: 8px;
                                    padding: 0 12px;
                                    cursor: pointer;
                                    background: rgba(255, 255, 255, 0.12);
                                }
                                span.active{
                                    color: rgba(24, 144, 255, 1);
                                }
                            }
                            .dynamic-list{
                                display: flex;
                                margin-bottom: 24px;
                                .date{
                                    width: 90px;
                                    padding-right: 10px;
                                    color: rgba(153, 153, 153, 1);
                                    font-size: 12px;
                                    span:nth-child(2){
                                        display: inline-block;
                                        width: 8px;
                                        height: 8px;
                                        border-radius: 4px;
                                        float: right;
                                        margin-top: 8px;
                                    }
                                }
                                .active-track{
                                    flex: 1;
                                    .time{
                                        color: rgba(153, 153, 153, 1);
                                        font-size: 12px;
                                    }
                                    .active-event{
                                        // color: rgba(153, 153, 153, 1);
                                        font-size: 12px;
                                        span{
                                            background: #1890ff;
                                            color: #fff;
                                        }
                                    }
                                    .dynamic-content{
                                        color: rgba(80, 80, 80, 1);
                                        font-size: 14px;
                                        margin-top: 6px;
                                        padding-right: 15px;
                                        div{
                                            min-height: 21px;
                                        }
                                        textarea{
                                            // display: none;
                                        }
                                        .dynamic-icon{
                                            cursor: pointer;
                                            display: none;
                                            color: #1890ff;
                                        }
                                    }
                                    .dynamic-content:hover{
                                        .dynamic-icon{
                                            display: inline-block;
                                        }
                                    }
                                    .chat-detail{
                                        .play-voice{
                                            color: #1890ff;
                                            font-size: 12px;
                                            text-decoration: underline;
                                            display: inline-block;
                                            margin-right: 16px;
                                            cursor: pointer;
                                            i{
                                                margin-right: 2px;
                                            }
                                        }
                                        .voice-content{
                                            color: #505050;
                                            font-size: 12px;
                                        }
                                    }
                                    .label-content{
                                        // margin-top: 9px;
                                        button{
                                            border: none;
                                            background: rgba(158, 172, 196, 0.12);
                                            color: rgba(158, 172, 196, 1);
                                            font-size: 13px;
                                            height: 28px;
                                            border: 1px solid rgba(158, 172, 196, 1);
                                            margin: 8px 8px 8px 0;
                                        }
                                        span{
                                            display: inline-block;
                                            height: 28px;
                                            line-height: 28px;
                                            border: 1px solid #fff;
                                            font-size: 13px;
                                            padding: 0 12px;
                                            margin: 2px 4px 2px 0;
                                            background: #fff;
                                            border-radius: 4px;
                                            i{
                                                cursor: pointer;
                                            }
                                        }
                                    }
                                    .next-time{
                                        color: rgba(80, 80, 80, 1);
                                        font-size: 14px;
                                        float: right;
                                        padding-right: 16px;
                                    }
                                }
                            }
                        }
                        .comments{
                            padding: 18px 24px;
                            background: #fff;
                            margin-top: 16px;
                            border-radius: 4px;
                            .title{
                                color: rgba(18, 18, 18, 1);;
                                font-size: 14px;
                            }
                            .textarea-comment{
                                margin-top: 20px;
                                background: rgba(247, 247, 247, 1);
                                border: none;
                                resize: none;
                            }
                            .release{
                                text-align: right;
                                color: rgba(24, 144, 255, 1);
                                font-size: 14px;
                                margin-top: 4px;
                                span{
                                    cursor: pointer;
                                }
                            }
                        }  
                    }
                    .right-content{
                        width: 288px;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        .data-info{
                            width: 100%;
                            height: 280px;
                            background: #fff;
                            border-radius: 4px;
                            margin-bottom: 16px;
                            padding: 18px 24px;
                            .related-info{
                                color: rgba(18, 18, 18, 1);
                                font-size: 14px;
                                span{
                                    width: 3px;
                                    height: 14px;
                                    float: left;
                                    background: rgba(24, 144, 255, 1);
                                    margin: 4px 7px 0 0;
                                }
                            }
                            ul{
                                li{
                                    padding-top: 24px;
                                    div:nth-child(1){
                                        color: rgba(188, 188, 188, 1);
                                        font-size: 12px;
                                        margin-bottom: 4px;
                                    }
                                    div:nth-child(2){
                                        color: rgba(80, 80, 80, 1);
                                        font-size: 14px;
                                    }
                                }
                            }
                        }
                        .fast-operate{
                            width: 100%;
                            flex: 1;
                            background: #fff;
                            padding: 18px 24px;
                            .fast-do{
                                color: rgba(18, 18, 18, 1);
                                font-size: 14px;
                                span{
                                    width: 3px;
                                    height: 14px;
                                    float: left;
                                    background: rgba(24, 144, 255, 1);
                                    margin: 4px 7px 0 0;
                                }
                            }
                        }
                    }
                }
            }
        }
        .footer-edit{
            height: 52px;
            line-height: 52px;
            text-align: center;
            position: absolute;
            bottom: 0px;
            left: 0px;
            z-index: 100;
            width: 100%;
            background: #fff;
            box-shadow: 0px 0px 6px 0px rgba(0 0 0 0.06);
            button{
                margin-right: 8px;
            }
        }
    }
    .modal-public{
        .divider1{
            margin: 0px 0 24px 0;
        }
        .divider2{
            margin: 24px 0 12px;
        }
        .btn-group{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                margin-right: 16px;
            }
        }
        .btn-group2{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                background: #fff;
                color: rgba(121, 136, 155, 0.7);
                border: 1px solid rgba(121, 136, 155, 0.5);
                margin-right: 16px;
            }
            button:nth-child(1):hover{
                background: rgba(121, 136, 155, 0.08);
            }
        }
    }
    .time-modal{
        padding-bottom: 46px;
        .check-list{
            .check-item{
                display: inline-block;
                width: 158px;
                height: 32px;
            }
        }
    }
    .drawer-public{
        height: 100%;
        position: relative;
        padding-bottom: 60px;
        .drawer-title{
            height: 55px;
            width: 100%;
            span{
                color: rgba(18, 18, 18, 1);
                font-size: 16px;
                line-height: 55px;
            }
            i{
                color: rgba(153, 153, 153, 1);
                font-size: 16px;
                margin-left: 16px;
                cursor: pointer;
                line-height: 50px;
            }
        }
        .drawer-head{
            height: 56px;
            background: #fff;
            padding: 0 24px 0;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            z-index: 100;

        }
        .drawer-content{
            padding: 71px 16px 16px 16px;
            width: 100%;
            height: 100%;
            position: absolute;
        }
        .drawer-foot{
            position: absolute;
            left: 0px;
            bottom: 0px;
            width: 100%;
            height: 52px;
            background: #fff;
            box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
            .btn-group{
                text-align: center;
                padding-top: 10px;
                width: 100%;
                button{
                    height: 32px;
                    font-size: 14px;
                }
                button:nth-child(2){
                    margin: 0 16px;
                }
            }
        }
    }
    .drawer-sence{
        .row-head{
            padding-top: 20px;
            border-top: 1px solid rgba(229, 229, 229, 1);
            .col-left{
                .head-content{
                    color: rgba(188, 188, 188, 1);
                    font-size: 12px;
                }
                .head-content{
                    color: rgba(80, 80, 80, 1);
                    font-size: 14px;
                    
                }
            }
        }
        .drawer-body-content{
            width: 100%;
            height: 100%;
            border-radius: 4px;
            overflow-y: auto;
            padding: 24px;
            background: #fff;
            .pagination-table{
                float: right;
                padding-top: 24px;
            }
        }
    }
    .join1-modal{
        padding-bottom: 46px;
        .condition{
            margin-bottom: 24px;
            .title{
                color: rgba(153, 153, 153, 1);
                font-size: 14px;
                margin: 0 0 8px;
            }
            .title1{
                padding-right: 32px;
            }
            .title2{
                padding: 0 16px;
            }
            .title3{
                padding-left: 32px;
            }
            .content1{
                padding-right: 32px;
                color: rgba(121, 136, 155, 1);
                .select-module{
                    width: 100%;
                }
            }
            .content2{
                padding: 0 16px;
                color: rgba(153, 153, 153, 1);
            }
            .content3{
                padding-left: 32px;
                color: rgba(153, 153, 153, 1);
                .select-module{
                    width: 100%;
                }
            }
        }
    }
    .join2-modal{
        padding-bottom: 46px;

    }
    .normal-modal{
        padding-bottom: 46px;
        .tab-normal{
            height: 400px;
        }
    }
    .label-modal{
        padding-bottom: 46px;
        .title{
            color: rgba(153, 153, 153, 1);
            font-size: 14px;
            margin-bottom: 16px;
        }
        .company-tag-list{
            margin-top: 8px;
            .type-name{
                color: rgba(18, 18, 18, 1);
                font-size: 14px;
            }
            .label-list{
                span{
                    display: inline-block;
                    height: 28px;
                    line-height: 28px;
                    color: rgba(158, 172, 196, 1);
                    font-size: 13px;
                    padding: 0 16px;
                    margin: 8px 8px 8px 0;
                    background: rgba(158, 172, 196, 0.12);
                    cursor: pointer;
                }
            }
        }
        .new-tag{
            display: flex;
            input{
                flex: 1;
            }
            i{
                font-size: 20px;
                margin-left: 16px;
                margin-top: 6px;
                cursor: pointer;
                color: rgba(121, 136, 155, 1);
            }
        }
        .tag-list{
            span{
                display: inline-block;
                height: 28px;
                line-height: 28px;
                color: rgba(158, 172, 196, 1);
                font-size: 13px;
                padding: 0 16px;
                margin: 8px 8px 8px 0;
                background: rgba(158, 172, 196, 0.12);
                cursor: pointer;
            }
            i{
                margin-left: 8px;
                font-size: 12px;
            }
        }
    }
    .delete-modal{
        padding-top: 24px;
        padding-bottom: 48px;
        .sure-off-bind{
            i{
                color: rgba(255, 170, 0, 1);
                font-size: 24px;
            }
            span{
                color: rgba(18, 18, 18, 1);
                font-size: 20px;
                margin-left: 24px;
            }
        }
        .tip{
            color: rgba(80, 80, 80, 1);
            padding-left: 50px;
            margin: 24px 0;
        }
    }
    .username{
        display: inline-block;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        color: #fff;
        text-align: center;
        line-height: 20px;
        background: rgba(121, 72, 234, 0.6);
        font-size: 12px;
        float: left;
        margin-right:4px;
    }
    .related-clue-modal{
        padding-bottom: 46px; 
    }
</style>