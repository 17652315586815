<template>
    <div class="more-operation">
        <a-dropdown style="margin-left:8px" :trigger="['click']">
            <a-menu slot="overlay" @click="moreOperate">
                <a-menu-item v-if="moduleId!=3&&operationAuthority.DELETE" key="1" class="more-operate-item more-operate-item1">
                    <div>删除选中</div>
                    <div>(已选{{idList.length}}条)</div>
                </a-menu-item>
                <a-menu-item v-if="moduleId!=6&&operationAuthority.EDIT" key="2" class="more-operate-item more-operate-item1">
                    <div>修改选中</div>
                    <div>(已选{{idList.length}}条)</div>
                </a-menu-item>
                <a-menu-item v-if="operationAuthority.DELETE||operationAuthority.EDIT" key="3" class="more-operate-item more-operate-item1">
                    <div>高级批量</div>
                </a-menu-item>
                <a-menu-item v-if="moduleId==8&&operationAuthority.EDIT" key="4" class="more-operate-item">
                    <div>客户导入</div>
                </a-menu-item>
            </a-menu>
            <a-button> 更多操作 <a-icon type="down" /> </a-button>
        </a-dropdown>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="deleteChoosedModal" title="删除选中" footer='' width='720px'>
            <div class="delete-choosed-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <div class="des">共筛选出数据（数据最大操作上限为10000条，超过将只会执行前10000条）：</div>
                <div class="data-num">{{idList.length}}条</div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group">
                    <a-button type="primary" ghost @click="cancelDeleteChoosed">取消</a-button>
                    <a-button type="primary" @click="sureDeleteChoosed">确定</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="changeChoosedModal" title="修改选中" footer='' width='720px'>
            <div class="change-choosed-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <div class="des">共筛选出数据（数据最大操作上限为10000条，超过将只会执行前10000条）：</div>
                <div class="data-num">{{idList.length}}条</div>
                <div class='perfect-change'>完善修改后的值：</div>
                <div>
                    <a-row v-for="(item,index) in viewScreenData1" :key='index' style="margin-bottom:12px">
                        <a-col :span="4">
                            {{item.fieldName}}
                        </a-col>
                        <a-col :span="6">
                            <a-select v-model="item.selectedCondition" style="width:100%;height:32px" @change="selectConChange1(item,index)">
                                <a-select-option :value="item1.value" v-for="(item1,index1) in item.methodList" :key='index1'>
                                    {{item1.name}}
                                </a-select-option>
                            </a-select>
                        </a-col>
                        <a-col :span="14" style="padding-left:12px;">
                            <Linkage ref="child4"></Linkage>
                        </a-col>
                    </a-row>
                </div>
                <div class="reset-changed">
                    <span @click="newSetCondition1">重置筛选条件</span>
                </div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group">
                    <a-button type="primary" ghost @click="cancelChangeChoosed">取消</a-button>
                    <a-button type="primary" @click="sureChangeChoosed">确定</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="seniorBatchModal" title="高级批量-选择操作" footer='' width='720px'>
            <div class="senior-batch-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <div class="head">选择您要进行的操作：</div>
                <div class="select-box">
                    <div v-if="moduleId!=6&&operationAuthority.EDIT" @click="selectOperate('change')" :class="{'active':currentOperate=='change'}" class="batch-operate batch-box">
                        <div class="title">批量修改</div>
                        <div class="des"></div>
                    </div>
                    <div  v-if="moduleId!=3&&operationAuthority.DELETE" @click="selectOperate('delete')" :class="{'active':currentOperate=='delete'}" class="batch-operate">
                        <div class="title">批量删除</div>
                        <div class="des"></div>
                    </div>
                </div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group">
                    <a-button type="primary" ghost @click="cancelSeniorBatch">取消</a-button>
                    <a-button type="primary" @click="sureSeniorBatch">下一步</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="setScreenModal" title="高级批量-设置筛选条件" footer='' width='720px'>
            <div class="set-screen-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <a-row v-for="(item,index) in conditionListAnother" :key='index' style="margin-bottom:12px">
                    <a-col :span="4">
                        {{item.fieldName}}
                    </a-col>
                    <a-col :span="6">
                        <a-select v-model="item.selectedCondition" style="width:100%;height:32px" @change="selectConChange2(item,index)">
                            <a-select-option :value="item1.value" v-for="(item1,index1) in item.methodList" :key='index1'>
                                {{item1.name}}
                            </a-select-option>
                        </a-select>
                    </a-col>
                    <a-col :span="14" style="padding-left:12px;">
                        <Linkage ref="child5"></Linkage>
                    </a-col>
                </a-row>
                <div class="reset-changed">
                    <span @click="newSetCondition2">重置筛选条件</span>
                </div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group">
                    <a-button type="primary" ghost @click="cancelSetScreen">取消</a-button>
                    <a-button type="primary" @click="sureSetScreen">确定</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="changeSelectedModal" title="修改选中" footer='' width='720px'>
            <div class="change-selected-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <div class="des">共筛选出数据（数据最大操作上限为10000条，超过将只会执行前10000条）：</div>
                <div class="data-num">{{changedSelectedTotal}}条</div>
                <div>
                    <a-row v-for="(item,index) in viewScreenData2" :key='index' style="margin-bottom:12px">
                        <a-col :span="4">
                            {{item.fieldName}}
                        </a-col>
                        <a-col :span="6">
                            <a-select v-model="item.selectedCondition" style="width:100%;height:32px" @change="selectConChange3(item,index)">
                                <a-select-option :value="item1.value" v-for="(item1,index1) in item.methodList" :key='index1'>
                                    {{item1.name}}
                                </a-select-option>
                            </a-select>
                        </a-col>
                        <a-col :span="14" style="padding-left:12px;">
                            <Linkage ref="child6"></Linkage>
                        </a-col>
                    </a-row>
                </div>
                <div class="reset-changed">
                    <span @click="newSetCondition3">重置筛选条件</span>
                </div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group">
                    <a-button type="primary" ghost @click="cancelChangeSelected">取消</a-button>
                    <a-button type="primary" @click="sureChangeSelected">确定</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="deleteScreenModal" title="高级批量-设置筛选条件" footer='' width='720px'>
            <div class="set-screen-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <a-row v-for="(item,index) in conditionListOriginal" :key='index' style="margin-bottom:12px">
                    <a-col :span="4">
                        {{item.fieldName}}
                    </a-col>
                    <a-col :span="6">
                        <a-select v-model="item.selectedCondition" style="width:100%;height:32px" @change="selectConChange4(item,index)">
                            <a-select-option :value="item1.value" v-for="(item1,index1) in item.methodList" :key='index1'>
                                {{item1.name}}
                            </a-select-option>
                        </a-select>
                    </a-col>
                    <a-col :span="14" style="padding-left:12px;">
                        <Linkage ref="child7"></Linkage>
                    </a-col>
                </a-row>
                <div class="reset-changed">
                    <span @click="newSetCondition4">重置筛选条件</span>
                </div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group">
                    <a-button type="primary" ghost @click="cancelDeleteScreen">取消</a-button>
                    <a-button type="primary" @click="sureDeleteScreen">确定</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="deleteChoosed2Modal" title="删除选中" footer='' width='720px'>
            <div class="delete-choosed-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <div class="des">共筛选出数据（数据最大操作上限为10000条，超过将只会执行前10000条）：</div>
                <div class="data-num">{{deleteSelectedTotal}}条</div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group">
                    <a-button type="primary" ghost @click="cancelDelete2Screen">取消</a-button>
                    <a-button type="primary" @click="sureDelete2Screen">确定</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="normalModal" title="加入号码-普通导入" footer='' width='600px'>
            <div class="normal-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <div class="tab-normal">
                    <div>
                        <span style="color:rgba(80, 80, 80, 1);font-size:14px">*为保证数据导入顺利，请先</span>
                        <a-button @click="downloadTemplate" icon="download" style="margin-left:8px">
                        下载模板
                        </a-button>
                    </div>
                    <div style="min-height:166px;margin-top:24px">
                        <a-upload-dragger
                            key={Math.random()}
                            name="file"
                            :multiple="false"
                            :customRequest="customRequest"
                            @change="onUpload"
                            ref="pathClear"
                        >
                            <p class="ant-upload-drag-icon">
                            <a-icon type="upload" />
                            </p>
                            <p class="ant-upload-text">
                            点击或将文件拖拽到这里上传
                            </p>
                            <p class="ant-upload-hint">
                            支持扩展名：.xls .xlsx
                            </p>
                        </a-upload-dragger>
                    </div>
                    <div style="margin-top:16px;color:rgba(153, 153, 153, 1);font-size:14px">上传的 Excel 表符合以下规范::</div>
                    <div style="color:rgba(80, 80, 80, 1);margin-top:8px;font-size:12px">文件大小不超过20MB<br>仅支持 (*.xls 和 *.xlsx)文件<br>请确保您需要导入的sheet表头中不包含空的单元格，否则该sheet页数据系统将不做导入</div>
                </div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group">
                    <a-button type="primary" ghost @click="cancelNormal">取消</a-button>
                    <a-button type="primary" @click="sureNormal">确认</a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="errorTipModal" title="导入成功" footer='' width='600px'>
            <div class="error-tip-modal modal-public">
                <a-divider class="divider1" type="horizontal" />
                <div class="tab-normal">
                    <div class="sure-off-bind">
                        <a-icon type="check" />
                        <span>成功导入{{exportResponseData.successCount}}条</span>
                    </div>
                    <div class="tip">
                        <span style="padding-left:50px;">已存在重复数{{exportResponseData.dbRepeatCount}}条,</span>
                        <span>文件内重复数{{exportResponseData.fileRepeatCount}}条,</span>
                        <span>号码错误数{{exportResponseData.errorCount}}条</span>
                    </div>
                    <div class="import-failed">
                        <span>未导入号码</span>
                        <span @click="copyAppid(exportResponseData.errorPhoneList)">复制号码</span>
                    </div>
                    <div class="num-box">
                        <div v-for="(item,index) in exportResponseData.errorPhoneList" :key='index'>{{item}}</div>
                    </div>
                </div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group">
                    <a-button type="primary" ghost @click="cancelErrorTip">取消</a-button>
                    <a-button type="primary" @click="sureErrorTip">确认</a-button>
                </div>
            </div>
        </a-modal>
        <textarea id="copyHtml1"></textarea>

    </div>
</template>

<script>
import Linkage from './Linkage'
export default {
    props:[],
    components: {Linkage},
    data(){
        return{
            deleteChoosedModal: false,
            changeChoosedModal: false,
            changeSelectedModal: false,
            setScreenModal: false,
            seniorBatchModal: false,
            currentOperate: '',
            moduleId: '',
            idList: [],
            viewScreenData1: [],
            viewScreenData2: [],
            conditionListOriginal: [],
            conditionListAnother: [],
            changedSelectedTotal: 0,
            beforeChangedConditionList: [],
            deleteScreenModal: false,
            deleteSelectedTotal: 0,
            deleteChoosed2Modal: false,
            deleteScreenCondition: [],
            operationAuthority: '',
            copyData1: [],
            copyData2: [],
            copyData3: [],
            copyData4: [],
            normalModal: false,
            newFormData: '',
            errorTipModal: false,
            errorInfo: '',
            exportResponseData: ''
        }
    },
    methods: {
        moreOperate(e) {
            if(e.key == 1){
                if(this.idList.length == 0){
                    this.$message.error('请先选择')
                }else{
                    this.deleteChoosedModal = true
                }
            }else if(e.key == 2){
                if(this.idList.length == 0){
                    this.$message.error('请先选择')
                }else{
                    this.changeChoosedModal = true
                }
            }else if(e.key == 3){
                this.seniorBatchModal = true
                this.currentOperate = ''
            }else if(e.key == 4){
                this.normalModal = true
            }
        },
        deleteSelectedItem() {
            this.axios.post('/api/module/data/delete/batch', {
                moduleId: this.moduleId,
                idList: this.idList,
            })
            .then(response=> {
                if(response.data.code == 0){
                    this.$message.success('删除成功')
                    this.$parent.selectedRowKeys = []
                    this.idList = []
                    this.$parent.queryBasicData()
                }else{
                    this.$message.error(response.data.userTip);
                    if(response.data.code == 'A0200'){
                        this.$router.push("/login")
                    }
                }
            })
            .catch(error=> {
                console.log(error);
            });
        },
        queryEditData() {
            this.axios(
                {
                    method:'get',
                    url:'/api/module/'+this.moduleId+'/editable/conditionList', 
                }
            )
            .then(response=> {
                if(response.data.code == 0){
                    this.viewScreenData1 = this.deepClone(response.data.data)
                    this.viewScreenData2 = this.deepClone(response.data.data)
                    this.copyData1 = this.deepClone(response.data.data)
                    this.copyData3 = this.deepClone(response.data.data)
                }else{
                    this.$message.error(response.data.userTip);
                    if(response.data.code == 'A0200'){
                        this.$router.push("/login")
                    }
                }
            })
            .catch(error=> {
                console.log(error)
            })
        },
        deepClone(obj){
            let _obj = JSON.stringify(obj),
            objClone = JSON.parse(_obj);
            return objClone
        },  
        newSetCondition1() {
            let data = this.deepClone(this.copyData1)
            this.viewScreenData1 = data
            for(let i = 0; i < this.viewScreenData1.length; i++){
                this.$refs.child4[i].getPassData(this.viewScreenData1[i],i)
            }
            this.$forceUpdate()
        }, 
        newSetCondition2() {
            let data = this.deepClone(this.copyData2)
            this.conditionListAnother = data
            for(let i = 0; i < this.conditionListAnother.length; i++){
                this.$refs.child5[i].getPassData(this.conditionListAnother[i],i)
            }
            this.$forceUpdate()
        }, 
        newSetCondition3() {
            let data = this.deepClone(this.copyData3)
            this.viewScreenData2 = data
            for(let i = 0; i < this.viewScreenData2.length; i++){
                this.$refs.child6[i].getPassData(this.viewScreenData2[i],i)
            }
            this.$forceUpdate()
        }, 
        newSetCondition4() {
            let data = this.deepClone(this.copyData4)
            this.conditionListOriginal = data
            for(let i = 0; i < this.conditionListOriginal.length; i++){
                this.$refs.child7[i].getPassData(this.conditionListOriginal[i],i)
            }
            this.$forceUpdate()
        }, 
        selectConChange1(item, index) {
            this.$refs.child4[index].getPassData(item,index)
        },
        selectConChange2(item, index) {
            this.$refs.child5[index].getPassData(item,index)
        },
        selectConChange3(item, index) {
            this.$refs.child6[index].getPassData(item,index)
        },
        selectConChange4(item, index) {
            this.$refs.child7[index].getPassData(item,index)
        },
        cancelDeleteChoosed() {
            this.deleteChoosedModal = false
        },
        sureDeleteChoosed() {
            this.deleteChoosedModal = false
            this.deleteSelectedItem()
        },
        cancelChangeChoosed() {
            this.changeChoosedModal = false
        },
        sureChangeChoosed() {
            this.changeChoosedModal = false
            let data = this.$refs.child4
            let arr = []
            for(let i = 0; i < data.length; i++){
                let obj = {}
                if(data[i]['_data']['viewportData']['selectedCondition']!='ALL'&&data[i]['_data']['viewportData']['selectedCondition']!=undefined){
                    obj.fieldId = data[i]['_data']['viewportData']['fieldId']
                    obj.fieldMethod = data[i]['_data']['viewportData']['selectedCondition']
                    obj.valueList = data[i]['_data']['viewportData']['valueList']
                    arr.push(obj)
                }else if(data[i]['_data']['viewportData']['selectedCondition']=='ISNULL'){
                    obj.fieldId = data[i]['_data']['viewportData']['fieldId']
                    obj.fieldMethod = data[i]['_data']['viewportData']['selectedCondition']
                    arr.push(obj)
                }
            }
            this.axios.post('/api/module/data/update/batch', {
                moduleId: this.moduleId,
                idList: this.idList,
                updateFieldList: arr
            })
            .then(response=> {
                if(response.data.code == 0){
                    this.$message.success('修改成功')
                    this.$parent.queryBasicData()
                }else{
                    this.$message.error(response.data.userTip);
                    if(response.data.code == 'A0200'){
                        this.$router.push("/login")
                    }
                }
            })
            .catch(error=> {
                console.log(error);
            });
        },
        cancelSetScreen() {
            this.setScreenModal = false
        },
        sureSetScreen() {
            this.setScreenModal = false
            this.changeSelectedModal = true
            let data = this.$refs.child5
            let arr = []
            for(let i = 0; i < data.length; i++){
                let obj = {}
                if(data[i]['_data']['viewportData']['selectedCondition']!='ALL'&&data[i]['_data']['viewportData']['selectedCondition']!=undefined){
                    obj.fieldId = data[i]['_data']['viewportData']['fieldId']
                    obj.fieldMethod = data[i]['_data']['viewportData']['selectedCondition']
                    obj.valueList = data[i]['_data']['viewportData']['valueList']
                    arr.push(obj)
                }else if(data[i]['_data']['viewportData']['selectedCondition']=='ISNULL'){
                    obj.fieldId = data[i]['_data']['viewportData']['fieldId']
                    obj.fieldMethod = data[i]['_data']['viewportData']['selectedCondition']
                    arr.push(obj)
                }
            }
            this.beforeChangedConditionList = arr
            this.axios.post('/api/module/data/count', {
                moduleId: this.moduleId,
                conditionList: arr
            })
            .then(response=> {
                if(response.data.code == 0){
                    this.changedSelectedTotal = response.data.data.count
                }else{
                    this.$message.error(response.data.userTip);
                    if(response.data.code == 'A0200'){
                        this.$router.push("/login")
                    }
                }
            })
            .catch(error=> {
                console.log(error);
            });
        },
        cancelChangeSelected() {
            this.changeSelectedModal = false
        },
        sureChangeSelected() {
            this.changeSelectedModal = false
            let data = this.$refs.child6
            let arr = []
            for(let i = 0; i < data.length; i++){
                let obj = {}
                if(data[i]['_data']['viewportData']['selectedCondition']!='ALL'&&data[i]['_data']['viewportData']['selectedCondition']!=undefined){
                    obj.fieldId = data[i]['_data']['viewportData']['fieldId']
                    obj.fieldMethod = data[i]['_data']['viewportData']['selectedCondition']
                    obj.valueList = data[i]['_data']['viewportData']['valueList']
                    arr.push(obj)
                }
            }
            this.axios.post('/api/module/data/update/batch', {
                moduleId: this.moduleId,
                conditionList: this.beforeChangedConditionList,
                updateFieldList: arr
            })
            .then(response=> {
                if(response.data.code == 0){
                    this.$message.success('修改成功')
                    this.$parent.queryBasicData()
                }else{
                    this.$message.error(response.data.userTip);
                    if(response.data.code == 'A0200'){
                        this.$router.push("/login")
                    }
                }
            })
            .catch(error=> {
                console.log(error);
            });
        },
        sureSeniorBatch() {
            if(this.currentOperate  == ''){
                this.$message.warning('请先选择需要的操作')
            }else{
                this.seniorBatchModal = false
                if(this.currentOperate == 'change'){
                    this.setScreenModal = true
                }else{
                    this.deleteScreenModal = true
                }
            }
        },
        cancelSeniorBatch() {
            this.seniorBatchModal = false
        },
        selectOperate(e) {
            this.currentOperate = e
        },
        getData(moduleId, idList) {
            this.moduleId = moduleId
            this.idList = idList
        },
        getScreenData(moduleId,data,operationAuthority) {
            this.moduleId = moduleId
            this.conditionListOriginal = this.deepClone(data)
            this.conditionListAnother = this.deepClone(data)
            this.copyData2 = this.deepClone(data)
            this.copyData4 = this.deepClone(data)
            this.operationAuthority = operationAuthority
            this.queryEditData()
        },
        cancelDeleteScreen() {
            this.deleteScreenModal = false
        },
        sureDeleteScreen() {
            this.deleteScreenModal = false
            let data = this.$refs.child7
            let arr = []
            for(let i = 0; i < data.length; i++){
                let obj = {}
                if(data[i]['_data']['viewportData']['selectedCondition']!='ALL'&&data[i]['_data']['viewportData']['selectedCondition']!=undefined){
                    obj.fieldId = data[i]['_data']['viewportData']['fieldId']
                    obj.fieldMethod = data[i]['_data']['viewportData']['selectedCondition']
                    obj.valueList = data[i]['_data']['viewportData']['valueList']
                    arr.push(obj)
                }
            }
            this.deleteScreenCondition = arr
            this.axios.post('/api/module/data/count', {
                moduleId: this.moduleId,
                conditionList: arr
            })
            .then(response=> {
                if(response.data.code == 0){
                    this.deleteSelectedTotal = response.data.data.count
                    this.deleteChoosed2Modal = true
                }else{
                    this.$message.error(response.data.userTip);
                    if(response.data.code == 'A0200'){
                        this.$router.push("/login")
                    }
                }
            })
            .catch(error=> {
                console.log(error);
            });
        },
        sureDelete2Screen() {
            this.deleteChoosed2Modal = false
            this.axios.post('/api/module/data/delete/batch', {
                moduleId: this.moduleId,
                conditionList: this.deleteScreenCondition,
            })
            .then(response=> {
                if(response.data.code == 0){
                    this.$message.success('删除成功')
                    this.$parent.queryBasicData()
                    this.deleteScreenCondition = []
                }else{
                    this.$message.error(response.data.userTip);
                    if(response.data.code == 'A0200'){
                        this.$router.push("/login")
                    }
                }
            })
            .catch(error=> {
                console.log(error);
            });
        },
        cancelDelete2Screen() {
            this.deleteChoosed2Modal = false
        },
        downloadTemplate() {
            window.open('/api/customer/download/template', '_blank');
        },
        customRequest(data) { // 上传提交
            this.newFormData = ''
            const formData = new FormData() 
            formData.append('file', data.file)
            this.newFormData = formData
        },
        onUpload() {
            this.$refs.pathClear.value = ''
        },
        cancelNormal() {
            this.normalModal = false
        },
        sureNormal() {
            this.normalModal = false
            this.axios({
                method: 'post',
                url: '/api/customer/import/excel',
                headers:{
                    'Content-Type':'application/x-www-form-urlencoded'
                },
                data: this.newFormData,
            
            }).then((response) => {
                if(response.data.code == 0){
                    this.newFormData = ''
                    this.$parent.queryBasicData()
                    this.exportResponseData = response.data.data
                    this.errorTipModal = true
                }else{
                    this.$message.error(response.data.userTip);
                    if(response.data.code == 'A0200'){
                        this.$router.push("/login")
                    }
                }
            }).catch(function (error) {
                console.log(error)
            })
        },
        cancelErrorTip() {
            this.errorTipModal = false
        },
        sureErrorTip() {
            this.errorTipModal = false
        },
        copyAppid(arr) {
            let str = ''
            for(let i = 0; i < arr.length; i++){
                str += arr[i] + ''
            }
            let text = str
            let input = document.getElementById("copyHtml1");
            input.value = text; 
            input.select(); 
            document.execCommand("copy"); 
            this.$message.success('复制成功');
        },
    },
    mounted: function(){
    }
}
</script>
<style scoped lang="less">
    .more-operation{
        display: inline-block;
    }
    .modal-public{
        .divider1{
            margin: 0px 0 24px 0;
        }
        .divider2{
            margin: 24px 0 12px;
        }
        .divider3{
            margin: 0px 0 12px;
        }
        .btn-group{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                margin-right: 16px;
            }
        }
        .btn-group2{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                background: #fff;
                color: rgba(121, 136, 155, 0.7);
                border: 1px solid rgba(121, 136, 155, 0.5);
                margin-right: 16px;
            }
            button:nth-child(1):hover{
                background: rgba(121, 136, 155, 0.08);
            }
        }
    }
    .delete-choosed-modal{
        padding-bottom: 46px;
        .des{
            color: rgba(153, 153, 153, 1);
            font-size: 14px;
        }
        .data-num{
            color: rgba(24, 144, 255, 1);
            font-size: 14px;
            margin-top: 8px;
        }
    }
    .change-choosed-modal{
        padding-bottom: 46px;
        .des{
            color: rgba(153, 153, 153, 1);
            font-size: 14px;
        }
        .data-num{
            color: rgba(24, 144, 255, 1);
            font-size: 14px;
            margin-top: 8px;
        }
        .perfect-change{
            color:rgba(153, 153, 153, 1);
            font-size: 14px;
            margin: 16px 0 8px;
        }
        .reset-changed{
            text-align: right;
            color: rgba(24, 144, 255, 1);
            font-size: 14px;
            span{
                cursor: pointer;
            }
        }
    }
    .senior-batch-modal{
        padding-bottom: 46px;
        .head{
            color: rgba(153, 153, 153, 1);
            font-size: 14px;
        }
        .select-box{
            margin-top: 8px;
            .batch-operate{
                display: inline-block;
                width: 328px;
                height: 82px;
                border: 1px solid rgba(121, 136, 155, 0.3);
                border-radius: 4px;
                cursor: pointer;
                padding: 11px 16px 0;
                .title{
                    color: rgba(80, 80, 80, 1);
                    font-size: 14px;
                }
                .des{
                    color: rgba(188, 188, 188, 1);
                    font-size: 12px;
                    margin-top: 5px;
                }
            }
            div.active{
                border: 1px solid rgba(24, 144, 255, 1);
            }
            .batch-box{
                margin-right: 16px;
            }
        }
    }
    .set-screen-modal{
        padding-bottom: 46px;
        .reset-changed{
            text-align: right;
            color: rgba(24, 144, 255, 1);
            font-size: 14px;
            span{
                cursor: pointer;
            }
        }
    }
    .change-selected-modal{
        padding-bottom: 46px;
        .des{
            color: rgba(153, 153, 153, 1);
            font-size: 14px;
        }
        .data-num{
            color: rgba(24, 144, 255, 1);
            font-size: 14px;
            margin-top: 8px;
        }
        .reset-changed{
            text-align: right;
            color: rgba(24, 144, 255, 1);
            font-size: 14px;
            span{
                cursor: pointer;
            }
        }
    }
    .normal-modal{
        padding-bottom: 46px;
        .tab-normal{
            height: 400px;
        }
    }
    .error-tip-modal{
        padding-bottom: 46px;
        .tab-normal{
            // height: 400px;
            .sure-off-bind{
                i{
                    color: rgba(24, 144, 255, 1);
                    font-size: 24px;
                }
                span{
                    color: rgba(18, 18, 18, 1);
                    font-size: 20px;
                    margin-left: 24px;
                }
            }
            .tip{
                margin: 16px 0 0 0;
                span{
                    color: rgba(18, 18, 18, 1);
                    font-size: 14px;
                }
            }
            .import-failed{
                margin: 16px 0;
                border-top: 1px solid rgba(229, 229, 229, 1);
                padding-top: 23px;
                span:nth-child(1){
                    color: rgba(80, 80, 80, 1);
                    color: rgba(18, 18, 18, 1);
                    font-size: 14px;
                }
                span:nth-child(2){
                    color: rgba(80, 80, 80, 1);
                    float: right;
                    color: rgba(24, 144, 255, 1);
                    font-size: 14px;
                    cursor: pointer;
                }
            }
            .num-box{
                border: 1px solid rgba(121, 136, 155, 0.3);
                padding: 12px 16px;
                max-height: 240px;
                overflow-y: auto;
                background: rgba(121, 136, 155, 0.08);
                div{
                    color: rgba(80, 80, 80, 1);
                    font-size: 12px;
                    margin-bottom: 2px;
                }
            }
        }
    }
    #copyHtml1{
        position: fixed;
        z-index: -10000;
        left: 1000px;
        top: 1000000px;
    }
</style>