<template>
    <div class="cropper-image">
        <div class="cropper-content">
            <div class="cropper-box">
                <div class="pic-cut">图片裁切：</div>
                <div class="cropper">
                    <vue-cropper
                        ref="cropper"
                        :img="option.img"
                        :outputSize="option.outputSize"
                        :outputType="option.outputType"
                        :info="option.info"
                        :canScale="option.canScale"
                        :autoCrop="option.autoCrop"
                        :autoCropWidth="option.autoCropWidth"
                        :autoCropHeight="option.autoCropHeight"
                        :fixed="option.fixed"
                        :fixedNumber="option.fixedNumber"
                        :full="option.full"
                        :fixedBox="option.fixedBox"
                        :canMove="option.canMove"
                        :canMoveBox="option.canMoveBox"
                        :original="option.original"
                        :centerBox="option.centerBox"
                        :height="option.height"
                        :infoTrue="option.infoTrue"
                        :maxImgSize="option.maxImgSize"
                        :enlarge="option.enlarge"
                        :mode="option.mode"
                        @realTime="realTime"
                        @imgLoad="imgLoad">
                    </vue-cropper>
                </div>
                <!--底部操作工具按钮-->
                <div class="footer-btn">
                    <label class="btn" for="uploads">
                        <span>+</span>
                        <span>选择封面</span>
                    </label>
                    <input type="file" id="uploads" style="position:absolute; clip:rect(0 0 0 0);" accept="image/png, image/jpeg, image/gif, image/jpg" @change="selectImg($event)">
                    <div class="btn-group">
                        <a-button @click="changeScale(1)">放大</a-button>
                        <a-button @click="changeScale(-1)">缩小</a-button>
                        <a-button @click="rotateLeft">旋转</a-button>
                    </div>
                </div>
            </div>
            <!--预览效果图-->
            <div class="show-preview">
                <div class="pic-view">图片预览：</div>
                <div class="preview">
                    <img :src="previews.url" :style="previews.img">
                </div>
                <div class="needs">
                    <div class="title">图片要求：</div>
                    <div class="item">logo固定尺寸为200*200px；</div>
                    <div class="item">只支持JPG/PNG/GIF，GIF文件不能动画化；</div>
                    <div class="item">大小不超过5M</div>
                </div>

            </div>

        </div>

    </div>
    
</template>

<script>
import { VueCropper } from 'vue-cropper'
export default {
    name: "CropperImage",
    components: {
        VueCropper
    },
    props:['Name'],
    data() {
        return {
            name:this.Name,
            previews: {},
            option:{
                img: '',             //裁剪图片的地址
                outputSize: 1,       //裁剪生成图片的质量(可选0.1 - 1)
                outputType: 'jpeg',  //裁剪生成图片的格式（jpeg || png || webp）
                info: true,          //图片大小信息
                canScale: true,      //图片是否允许滚轮缩放
                autoCrop: true,      //是否默认生成截图框
                autoCropWidth: 200,  //默认生成截图框宽度
                autoCropHeight: 200, //默认生成截图框高度
                fixed: true,         //是否开启截图框宽高固定比例
                fixedNumber: [1, 1], //截图框的宽高比例
                full: false,         //false按原比例裁切图片，不失真
                fixedBox: true,      //固定截图框大小，不允许改变
                canMove: false,      //上传图片是否可以移动
                canMoveBox: true,    //截图框能否拖动
                original: false,     //上传图片按照原始比例渲染
                centerBox: false,    //截图框是否被限制在图片里面
                height: true,        //是否按照设备的dpr 输出等比例图片
                infoTrue: false,     //true为展示真实输出图片宽高，false展示看到的截图框宽高
                maxImgSize: 3000,    //限制图片最大宽度和高度
                enlarge: 1,          //图片根据截图框输出比例倍数
                mode: '230px 150px'  //图片默认渲染方式
            },
            imgBase64: '',
        };
    },
    methods: {
        //初始化函数
        imgLoad (msg) {
            console.log("工具初始化函数====="+msg)
        },
        //图片缩放
        changeScale (num) {
            num = num || 1
            this.$refs.cropper.changeScale(num)
        },
        //向左旋转
        rotateLeft () {
            this.$refs.cropper.rotateLeft()
        },
        //向右旋转
        rotateRight () {
            this.$refs.cropper.rotateRight()
        },
        //实时预览函数
        realTime (data) {
            this.previews = data
        },
        //选择图片
        selectImg (e) {
            let file = e.target.files[0]
            if (!/\.(jpg|jpeg|png|JPG|PNG)$/.test(e.target.value)) {
                this.$message({
                    message: '图片类型要求：jpeg、jpg、png',
                    type: "error"
                });
                return false
            }
            //转化为blob
            let reader = new FileReader()
            reader.onload = (e) => {
                let data
                if (typeof e.target.result === 'object') {
                    data = window.URL.createObjectURL(new Blob([e.target.result]))
                } else {
                    data = e.target.result
                }
                this.option.img = data
            }
            //转化为base64
            reader.readAsDataURL(file)
            setTimeout(()=>{
                this.imgBase64 = reader.result
            },500)
        },
    },
}
</script>

<style scoped lang="less">
    .cropper-image{
        .cropper-content{
            display: flex;
            display: -webkit-flex;
            justify-content: flex-end;
            .cropper-box{
                flex: 1;
                width: 320px;
                .pic-cut{
                    color: rgba(153, 153, 153, 1);
                    font-size: 14px;
                    margin-bottom: 8px;
                }
                .cropper{
                    width: auto;
                    height: 260px;
                }
            }
            .show-preview{
                margin-left: 24px;
                width: 200px;
                display: inline-block;
                .pic-view{
                    color: rgba(153, 153, 153, 1);
                    font-size: 14px;
                    margin-bottom: 8px;
                }
                .preview{
                    overflow: hidden;
                    border:1px solid rgba(229, 229, 229, 1);
                    background: #fff;
                    width: 200px;
                    height: 200px;
                    display: inline-block;
                }
                .needs{
                    margin-top: 24px;
                    .title{
                        color: rgba(153, 153, 153, 1);
                        font-size: 14px;
                        margin-bottom: 8px;
                    }
                    .item{
                        color: rgba(80, 80, 80, 1);
                        font-size: 12px;
                    }
                }
            }
        }
        .footer-btn{
            margin-top: 24px;
            .btn {
                outline: none;
                display: inline-block;
                line-height: 30px;
                white-space: nowrap;
                cursor: pointer;
                -webkit-appearance: none;
                text-align: center;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                outline: 0;
                -webkit-transition: .1s;
                transition: .1s;
                font-weight: 500;
                width: 136px;
                height: 32px;
                font-size: 12px;
                border-radius: 4px;
                color: rgba(24, 144, 255, 1);
                background-color: #fff;
                border: 1px solid rgba(24, 144, 255, 1);
                margin-right: 10px;
                span:nth-child(1){
                    margin-right: 4px;
                    font-size: 16px;
                }
            }
            .btn-group{
                margin-top: 12px;
                button{
                    margin-right: 8px;
                }
            }
        }
        .upload-btn{
            button{
                float: right;
            }
        }
    }
   
</style>

