<template>
    <div class="drop-down-tree">
        <div class="tree-data">
            <a-tree
                show-line
                :tree-data="treeSource"
                :expandedKeys="expandedData"
                @expand="handleExpand"
                @select="onSelect"
            >
                <a-icon slot="switcherIcon" type="caret-down" />
                <template slot="custom" slot-scope="item">
                    <span v-if="item.isEdit">
                        <a-input ref="new" type="text" class="editInput" v-model.trim="newItem" @pressEnter="addConfirm(item)" />
                        <a-icon class="add-icon" type="close-circle" @click="addCancel(item)"/>
                        <a-icon class="check-icon" type="check-circle" @click="addConfirm(item)"/>
                    </span>
                    <div v-else>
                        <div class="tree-item">
                            <span class="node-title">{{ item.title }}</span>
                            <div class="operate-icon">
                                <span class="icon-wrap">
                                    <a-icon type="plus" title="添加子集" @click="addItem(item)" />
                                </span>
                                <span class="icon-wrap">
                                    <a-icon type="edit" title="编辑"  @click="editItem(item)" />
                                </span>
                                <span class="icon-wrap">
                                    <a-icon type="delete" title="删除"  @click="deleteItem(item)" />
                                </span>
                            </div>
                        </div>
                    </div>
                </template>
            </a-tree>
        </div>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="deleteModal" footer='' width='480px'>
            <div class="delete-modal modal-public">
                <div class="sure-off-bind">
                    <a-icon type="warning" />
                    <span>您确定要删除这个部门吗？</span>
                </div>
                <div class="tip">删除后，数据无法恢复</div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group2">
                    <a-button ghost @click="cancelDelete">取消</a-button>
                    <a-button type="primary" @click="sureDelete">确认</a-button>
                </div>
            </div>
        </a-modal>
    </div>
</template>
<script>
    export default {
        name: 'TreeData',
        data () {
            return {
                treeSource: [],
                expandedData: [],
                newItem: '',
                editFlag: false, // 记录是否有已经处于编辑状态的标识
                newFlag: false, // 记录是否是新建节点，用于在cancel的时候判断是该删除还是恢复原样
                deleteModal: false,
                currentKey: '',
                currentOperation: ''
            }
        },
        methods: {
            handleExpand (expandedKeys) {
                this.expandedData = expandedKeys
            },
            addItem (item) {
                if (this.editFlag) {
                    this.$message.warning('已有编辑项，请先编辑完成！')
                    return
                }
                item.children.push({
                    children: [],
                    key: '',
                    title: '',
                    level: item.level + 1,
                    scopedSlots: { title: "custom" },
                    isEdit: true
                })
                this.newItem = ''
                this.currentOperation = 'new'
                this.editFlag = true
                this.newFlag = true
                // this.expandedData.push(item.key)
                this.$nextTick(() => {
                    this.$refs.new.focus()
                })
                this.currentKey = item.eventKey
            },
            editItem (item) {
                if (this.editFlag) {
                    this.$message.warning('已有编辑项，请先编辑完成！')
                    return
                }
                this.currentOperation = 'edit'
                item.isEdit = true
                this.newFlag = false
                this.editFlag = true
                this.newItem = item.title
                this.treeListItemActions(this.treeSource, item.key, 'edit', item)
                this.currentKey = item.eventKey
            },
            deleteItem (item) {
                this.deleteModal = true
                this.currentKey = item.key
            },
            addCancel (item) {
                this.newItem = ''
                item.isEdit = false
                this.editFlag = false
                this.treeListItemActions(this.treeSource, item.key, this.newFlag ? 'delete' : 'edit', item)
            },
            addConfirm (item) {
                if (this.newItem !== null && this.newItem.trim() !== '') {
                    item.title = this.newItem
                    item.isEdit = false
                    this.editFlag = false
                    // this.treeListItemActions(this.treeSource, item.key, 'edit', item)
                    this.newEditDepartment(item.title)
                } else {
                    this.$message.warning('不允许为空')
                }
            },
            // 根据id属性从数组（树结构）中匹配元素，执行action操作
            treeListItemActions(treeList, key, action, item) {
                if (!treeList || !treeList.length) {
                    return
                }
                for (let i = 0; i < treeList.length; i++) {
                    if (treeList[i].key === key) {
                        if (action === 'delete') {
                            treeList.splice(i, 1)
                            this.$forceUpdate()
                        } else if (action === 'edit') {
                            treeList[i] = item
                            this.$forceUpdate()
                        }
                        break
                    }
                    this.treeListItemActions(treeList[i].children, key, action, item)
                }
            },
            cancelDelete() {
                this.deleteModal = false
            },  
            sureDelete() {
                this.deleteModal = false
                this.axios.delete('api/department/'+this.currentKey)
                .then(response=> {
                    if(response.data.code == 0){
                        this.$message.success('删除成功')
                        this.$parent.queryColumnList()
                        this.currentKey = ''
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            childMethod(data, allDept) {  
                this.treeSource = data
                this.expandedData = allDept
            },
            onSelect(selectedKeys) {
                if(selectedKeys[0]!=''&&selectedKeys[0]!=undefined){
                    this.currentKey = selectedKeys[0]
                    if(!this.editFlag){
                        this.$parent.getDeptId(selectedKeys[0])
                    }
                }
            },
            newEditDepartment(name) {
                this.axios.post('/api/department', {
                    id: this.currentOperation=='new'?undefined:this.currentKey,
                    parentId: this.currentOperation=='edit'?undefined:this.currentKey,
                    name: name,
                })
                .then(response=> {
                    if(response.data.code == 0){
                        let tip
                        if(this.currentOperation == 'new'){
                            tip = '新建成功'
                        }else{
                            tip = '编辑成功'
                        }
                        this.newItem = ''
                        this.$parent.queryColumnList()
                        this.$message.success(tip)
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            }
        },
        mounted: function() {
        }
    }
</script>
<style lang="less" scoped>
    .drop-down-tree{
        input{
            width:120px !important;
        }
        .tree-data {
            margin-left: 20px;
            .icon-wrap {
                padding: 0 8px;
            }
            .editInput {
                padding: 0;
                margin-right: 5px;
                height: 24px;
            }
            .add-icon{
                margin: 0 8px;
                font-size: 16px;
            }
            .check-icon{
                font-size: 16px;
            }
            .operate-icon{
                display: none;
            }
            .tree-item:hover{
                .operate-icon{
                    display: inline-block;
                }
            }
        }   
    }
    .modal-public{
        .divider1{
            margin: 0px 0 24px 0;
        }
        .divider2{
            margin: 24px 0 12px;
        }
        .divider3{
            margin: 0px 0 12px;
        }
        .btn-group{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                margin-right: 16px;
            }
        }
        .btn-group2{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                background: #fff;
                color: rgba(121, 136, 155, 0.7);
                border: 1px solid rgba(121, 136, 155, 0.5);
                margin-right: 16px;
            }
            button:nth-child(1):hover{
                background: rgba(121, 136, 155, 0.08);
            }
        }
    }
    .delete-modal{
        padding-top: 24px;
        padding-bottom: 48px;
        .sure-off-bind{
            i{
                color: rgba(255, 170, 0, 1);
                font-size: 24px;
            }
            span{
                color: rgba(18, 18, 18, 1);
                font-size: 20px;
                margin-left: 24px;
            }
        }
        .tip{
            color: rgba(80, 80, 80, 1);
            padding-left: 50px;
            margin: 24px 0;
        }
    }
</style>