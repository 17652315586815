<template>
    <div class="data-range">
        <span class="title">数据范围</span>
        <a-tree-select
            v-model="selectedDataRangeValue"
            style="width: 265px;display:inline-block;"
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            :tree-data="dataRange"
            placeholder="我的数据"
            tree-default-expand-all
        >
        </a-tree-select>
    </div>
</template>

<script>
export default {
    props:[],
    data(){
        return{
            dataRange: [],
            selectedDataRangeValue: "-2,0"
        }
    },
    methods: {
        getDataRangeData(data) {
            this.dataRange = data
        }
    },
    watch: {
        selectedDataRangeValue(value) {
            this.selectedDataRangeValue = value
            this.$parent.queryBasicData()
        },
    },
}
</script>
<style scoped lang="less">
    .data-range{
        display: inline-block;
        .title{
            color: rgba(18, 18, 18, 1);
            font-size: 14px;
            margin-right: 24px;
        }
        .selection-range{
            color: rgba(121, 136, 155, 1);
            font-size: 14px;
            width: 265px;
        }
    }
    .modal-public{
        .divider1{
            margin: 0px 0 24px 0;
        }
        .divider2{
            margin: 24px 0 12px;
        }
        .btn-group{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                margin-right: 16px;
            }
        }
        .btn-group2{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                background: #fff;
                color: rgba(121, 136, 155, 0.7);
                border: 1px solid rgba(121, 136, 155, 0.5);
                margin-right: 16px;
            }
            button:nth-child(1):hover{
                background: rgba(121, 136, 155, 0.08);
            }
        }
    }
    .new-modal{
        padding-bottom: 46px;
        .new-modal-list{
            margin-bottom: 20px;
            div{
                color: rgba(153, 153, 153, 1);
                font-size: 14px;
                margin-bottom: 8px;
            }
            .input-name{
                width: 100%;
            }
            .input-order{
                width: 100%;
            }
        }
    }
    .edit-view-modal{
        padding-bottom: 46px;
        .reset-condition{
            text-align: right;
            color: rgba(24, 144, 255, 1);
            font-size: 14px;
            span{
                cursor: pointer;
            }
        }
    }
    .copy-modal{
        padding-bottom: 46px;
        .new-modal-list{
            margin-bottom: 20px;
            div{
                color: rgba(153, 153, 153, 1);
                font-size: 14px;
                margin-bottom: 8px;
            }
            .input-name{
                width: 100%;
            }
            .input-order{
                width: 100%;
            }
        }
    }
    .change-modal{
        padding-bottom: 46px;
        .new-modal-list{
            margin-bottom: 20px;
            div{
                color: rgba(153, 153, 153, 1);
                font-size: 14px;
                margin-bottom: 8px;
            }
            .input-name{
                width: 100%;
            }
            .input-order{
                width: 100%;
            }
        }
    }
    .delete-modal{
        padding-top: 24px;
        padding-bottom: 46px;
        .sure-off-bind{
            i{
                color: rgba(255, 170, 0, 1);
                font-size: 24px;
            }
            span{
                color: rgba(18, 18, 18, 1);
                font-size: 20px;
                margin-left: 24px;
            }
        }
        .tip{
            color: rgba(80, 80, 80, 1);
            padding-left: 50px;
            margin: 24px 0;
        }
    }
</style>