<template>
    <div class="digital-detail">
        <div class="head">
            <span class="title">{{digitalDetail.moduleName+"-"+digitalDetail.dataTitle}}</span>
            <OutLink v-if="operationAuthority.EXTERNAL_LINKS" :moduleId='moduleId' :dataId='currentId' :original='"DigitalShare"'></OutLink>
        </div>
        <div class="content">
            <div class="content-in">
                <div class="modular-content">
                    <div class="left-content">
                        <div class="related-module">
                            <span></span>
                            相关模块
                        </div>
                        <ul class="module-list">
                            <li :class="{'active':currentSelectModule==index}" @click="viewSenceDetail(index,item)" v-for="(item,index) in moduleNumList" :key='index'>
                                <a-icon type="profile" />
                                <div>{{item.name}}</div>
                                <span>{{item.num}}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="center-content">
                        <div class="basic-info">
                            <div class="title">基本信息</div>
                            <a-row class="row-list">
                                <a-col :span="12" class="col-list" :class="{'col-left':index%2==0,'col-right':index%2!=0}" v-for="(item,index) in digitalDetail.detailList" :key='index'>
                                    <div class="first-content">{{item.name}}:</div>
                                    <div v-if="item.type!='IMAGE'&&item.type!='SELECT_SINGLE'&&item.code!='experienceUrl'" class="second-content">{{item.value}}</div>
                                    <div class="second-content" v-if="item.code=='experienceUrl'" style="cursor:pointer;color:#1890FF" :title='item.value'>
                                        <span @click="jumpToUrl(item.value)">{{item.value}}</span>
                                    </div>
                                    <div v-if="item.type=='SELECT_SINGLE'&&ifEditDrawer!=true" class="second-content">{{item.value}}</div>
                                    <div v-if="item.type=='IMAGE'" class="second-content">
                                        <img :src="item.value" alt="">
                                        <a-icon style="color:rgba(121, 136, 155, 1);font-size:16px;margin-left:30px" @click="showBigImg(item.value)" v-if="!ifEditDrawer" type="eye" />
                                        <span @click="changeHead" class="change-head" v-if="ifEditDrawer">更换</span>
                                    </div>
                                    <div v-if="item.type=='SELECT_SINGLE'&&ifEditDrawer==true&&item.isEditable==1">
                                        <a-select class="selection-range" :defaultValue='item.selectedValue' @change="changeState">
                                            <a-select-option :value="item1.value" v-for="(item1,index1) in item.selectList" :key='index1'>
                                                {{item1.name}}
                                            </a-select-option>
                                        </a-select>
                                    </div>
                                </a-col>
                            </a-row>
                        </div>
                        <div class="comments">
                            <Comments ref="commentChild"></Comments>
                        </div>
                    </div>
                    <div class="right-content">
                        <div class="data-info">
                            <div class="related-info">
                                <span></span>
                                相关信息
                            </div>
                            <ul>
                                <li>
                                    <div>创建时间：</div>
                                    <div>{{digitalDetail.createTime}}</div>
                                </li>
                                <li>
                                    <div>修改时间：</div>
                                    <div>{{digitalDetail.updateTime}}</div>
                                </li>
                                <li>
                                    <div>创建人：</div>
                                    <div>{{digitalDetail.createBy}}</div>
                                </li>
                            </ul>
                        </div>
                        <div class="fast-operate">
                            <div class="fast-do">
                                <span></span>
                                快速操作
                            </div>
                            <div v-for="(item,index) in moduleNumList" :key='index' @click="addNewTask" class="add-task" :class="{'need-show':!item.hasAddAuthority}">
                                <div>
                                    <div>
                                        <a-icon type="carry-out" />
                                    </div>
                                    <div>新增{{item.name}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-edit" v-if="operationAuthority.EDIT">
            <a-button v-if="!ifEditDrawer" type="primary"  @click="editSence">编辑</a-button>
            <a-button v-if="ifEditDrawer" type="primary" ghost @click="cancelEdit">取消</a-button>
            <a-button v-if="ifEditDrawer" type="primary" @click="saveEdit">保存</a-button>
        </div>
        <a-modal :centered='true' :maskClosable='false' v-model.trim="uoloadModal" title="logo图片" footer='' width='600px'>
            <div class="upload-modal">
                <a-divider class="divider1" type="horizontal" />
                <CropperImage ref="child"></CropperImage>
                <a-divider class="divider2" type="horizontal" />
                <div class="upload-btn">
                    <a-button type="primary" style="float:right" @click="uploadImg()">确定 </a-button>
                </div>
            </div>
        </a-modal>
        <a-modal :centered='true' :visible="previewVisible" :footer="null" @cancel="handleCancel">
            <img alt="example" style="width: 100%" :src="currentUrl" />
        </a-modal>
        <a-drawer
            title=''
            placement="right"
            :closable="true"
            :visible="drawerVisibleSence"
            :destroyOnClose='true'
            :maskClosable='true'
            @close="onCloseDrawerSence"
            :width='800'
            :mask='true'
            :headerStyle="{ border: 'none', height: '56px', position: 'absolute', width: '100%', left: '0', top: '0', zIndex: '0'}"
            :bodyStyle="{ background: 'rgba(240, 242, 243, 1)', height: '100%', position: 'absolute', width: '100%', left: '0', top: '0', zIndex: '10', padding: '0 0 0' }"
        >
            <div class="drawer-sence drawer-public">
                <div class="drawer-head">
                    <div class="drawer-title">
                        <span class="left">{{digitalDetail.moduleName+"-"+(digitalDetail.dataTitle.length>28?digitalDetail.dataTitle.substring(0,28)+'...':digitalDetail.dataTitle)}}</span>
                        <div class="right">
                            <a-icon @click="viewDetail" type="zoom-in" />
                            <a-icon @click="onCloseDrawerSence" type="close" />
                        </div>
                    </div>
                </div>
                <div class="drawer-content">
                    <div class="drawer-body-content">
                        <a-table
                            :columns="columns"
                            :data-source="data"
                            :pagination='false'
                            :scroll="{ x: 'max-content' }"
                        />
                        <a-pagination
                            class="pagination-table"
                            show-size-changer
                            :total="total"
                            :show-total="total => `共 ${total} 条     `"
                            v-model="currentPage"
                            @change="pageChange"
                            @showSizeChange="onShowSizeChange"
                        />
                    </div>
                </div>
            </div>
        </a-drawer>
        <a-drawer
            :title='digitalDetail.moduleName+"-"+(digitalDetail.dataTitle.length>23?digitalDetail.dataTitle.substring(0,23)+"...":digitalDetail.dataTitle)+"-新增任务"'
            placement="right"
            :closable="true"
            :visible="drawerAddVisiable"
            :destroyOnClose='true'
            :maskClosable='true'
            @close="onClose2"
            :width='800'
            :mask='true'
            :headerStyle="{ border: 'none', height: '56px', position: 'absolute', width: '100%', left: '0', top: '0', zIndex: '100'}"
            :bodyStyle="{ background: 'rgba(240, 242, 243, 1)', height: '100%', position: 'absolute', width: '100%', left: '0', top: '0', zIndex: '10', padding: '56px 0 0' }"
        >
            <div class="drawer-newtask drawer-public">
                <div class="newtask-body">
                    <div class="newtask-content">
                        <a-row class="row-list">
                            <a-col :span="12" class="col-list col-left">
                                <div class="first-content">任务名称：</div>
                                <div class="second-content">
                                    <a-input v-model="newTaskName" placeholder="请输入任务名称" />
                                </div>
                            </a-col>
                            <a-col :span="12" class="col-list col-right">
                                <div class="first-content">数据员工：</div>
                                <div class="second-content">
                                    <a-select default-value="" class="digital-staff"  v-model="selectedEmployee">
                                        <a-select-option v-for="(item,index) in DiditalEmployeeList" :key='index' :value='item.value'>
                                            {{item.name}}
                                        </a-select-option>
                                    </a-select>
                                </div>
                            </a-col>
                        </a-row>
                        <a-row class="row-list">
                            <a-col :span="12" class="col-list col-left">
                                <div class="first-content">任务描述：</div>
                                <div class="second-content">
                                    <a-textarea v-model="newTaskDes" class="textarea-des" placeholder="请输入任务描述" :rows="3"/>
                                </div>
                            </a-col>
                        </a-row>
                    </div>
                </div>
                <div class="drawer-foot">
                    <div class="btn-group">
                        <a-button type="primary" ghost @click="offEditDrawer">取消</a-button>
                        <a-button type="primary" @click="submitAndNext">提交并新增下一条</a-button>
                        <a-button type="primary" @click="submitDrawer">提交</a-button>
                    </div>
                </div>
            </div>
        </a-drawer>
    </div>
</template>

<script>
    import CropperImage from "../../components/CropperImage";
    import Comments from "../../components/Comments";
    import OutLink from "../../components/OutLink";
    export default {
        name: 'DigitalDetail',
        components: {CropperImage, Comments, OutLink},
        data() {
            return {
                ifEditDrawer: false,
                uoloadModal: false,
                cropperName:'',
                previewVisible: false,
                drawerVisibleSence: false,
                data: null,
                columns: null,
                drawerAddVisiable: false,
                currentId: '',
                digitalDetail: {
                    dataTitle: ''
                },
                imgId: '',
                currentSelect: '',
                selectedEmployee: '',
                newTaskName: '',
                newTaskDes: '',
                DiditalEmployeeList: [],
                moduleId: '',
                moduleList: [],
                moduleNumList: [],
                currentSelectModule: null,
                currentPage: 1,
                currentSize: 10,
                total: 0,
                currentUrl: '',
                operationAuthority: ''
            }
        },
        methods: {
            jumpToUrl(url) {
                window.open(url)
            },
            querySelectData() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/employee/'+this.currentId, 
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.digitalDetail = response.data.data
                        this.$refs.commentChild.getParentCommentTree(this.moduleId, this.currentId, true)
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            queryModuleList(arr) {
                this.axios(
                    {
                        method:'get',
                        url:'/api/module/associatedModuleList', 
                        params: {
                            moduleId: this.moduleId,
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        let data = response.data.data
                        for(let i = 0; i < data.length; i++){
                            for(let j = 0; j < arr.length; j++){
                                if(data[i]['id'] == arr[j]['moduleId']){
                                    data[i]['num'] = arr[j]['count']
                                }
                            }
                        }
                        this.moduleNumList = data
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            queryDataNum() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/module/associatedModule/data/countList', 
                        params: {
                            moduleId: this.moduleId,
                            dataId: this.currentId
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        let data = response.data.data
                        
                        let arr = []
                        for(let i = 0; i < data.length; i++){
                            let obj = {}
                            obj.count = data[i]['count']
                            obj.moduleId = data[i]['id']
                            arr.push(obj)
                        }
                        this.queryModuleList(arr)
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            queryDiditalEmployee() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/employee/select/list', 
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.DiditalEmployeeList = response.data.data
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            editSence() {
                this.ifEditDrawer = true
            },
            saveEdit() {
                this.ifEditDrawer = false
                this.axios.post('/api/employee', {
                    id: this.currentId,
                    avatar: this.imgId!=''?this.imgId:undefined,
                    status: this.currentSelect!=''?this.currentSelect:undefined,
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.$message.success('修改成功')
                        this.imgId = ''
                        this.currentSelect = ''
                        this.querySelectData()
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            cancelEdit() {
                this.ifEditDrawer = false
                this.imgId = ''
                this.currentSelect = ''
            },
            changeHead() {
                this.uoloadModal = true
            },
            cancelUpload() {
                this.uoloadModal = false
            },
            sureUpload() {
                this.uoloadModal = false
            },
            handleUploadSuccess (data){
                switch(data.name){
                    case 'flagImg':
                    this.formValidate.mainImage = 'http://ydfblog.cn/dfs/'+data.url;
                    break;
                }
                this.cropperModel = false;
            },
            showBigImg(url) {
                this.currentUrl = url
                if(this.currentUrl == ''){
                    this.$message.error('请先上传头像')
                }else{
                    this.previewVisible = true
                }
            },
            handleCancel() {
                this.previewVisible = false
            },
            onCloseDrawerSence() {
                this.drawerVisibleSence = false
                this.currentSelectModule = null
            },
            viewDetail() {

            },
            viewSenceDetail(index,item) {
                this.drawerVisibleSence = true
                this.currentSelectModule = index
                this.associatedModuleId = item.id
                this.queryTableData()
            },
            queryTableData() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/module/associatedModule/data/List', 
                        params: {
                            moduleId: this.moduleId,
                            dataId: this.currentId,
                            associatedModuleId: this.associatedModuleId,
                            current: this.currentPage,
                            size: this.currentSize,
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.columns = response.data.data.tableHeader
                        this.data = response.data.data.tableData.records
                        for(let i = 0; i < this.columns.length; i++){
                            if(this.columns[i]['title'] == 'id'){
                                this.columns[i] = {
                                    title: this.columns[i]['title'],
                                    dataIndex: this.columns[i]['dataIndex'],
                                    colSpan:0,  
                                    customRender: () => {
                                        return {
                                            attrs: {
                                                colSpan: 0,
                                            },
                                        };
                                    },
                                }
                            }
                        }
                        this.total = response.data.data.tableData.total
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            pageChange(current, pageSize) {
                this.currentPage = current
                this.pageSize = pageSize
                this.queryTableData()
            },
            onShowSizeChange(current, pageSize) {
                this.currentPage = current
                this.currentSize = pageSize
                this.queryTableData()
            },
            addNewTask() {
                this.drawerAddVisiable = true
            },
            onClose2() {
                this.drawerAddVisiable = false
            },
            offEditDrawer() {
                this.drawerAddVisiable = false
                this.selectedEmployee = ''
                this.newTaskName = ''
                this.newTaskDes = ''
            },
            submitAndNext() {
                this.addTask()
            },
            addTask(){
                this.axios.post('/api/callTask', {
                    digitalEmployeeId: this.selectedEmployee,
                    name: this.newTaskName,
                    description: this.newTaskDes
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.$message.success('任务创建成功')
                        this.selectedEmployee = ''
                        this.newTaskName = ''
                        this.newTaskDes = ''
                        this.queryDataNum()
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error);
                });
            },
            submitDrawer() {
                this.addTask()
                this.drawerAddVisiable = false
            },
            changeState(val) {
                this.currentSelect = val
            },
            uploadImg() {
                if(this.$refs.child.imgBase64==''){
                    this.$message.error('请先上传图片')
                }else{
                    this.uoloadModal = false
                    this.axios.post('/api/file/uploadImg', {
                        base64Img: this.$refs.child.imgBase64,
                    })
                    .then(response=> {
                        if(response.data.code == 0){
                            this.$message.success('上传成功')
                            this.imgId = response.data.data.fileId
                        }else{
                            this.$message.error(response.data.userTip);
                            if(response.data.code == 'A0200'){
                                this.$router.push("/login")
                            }
                        }
                    })
                    .catch(error=> {
                        console.log(error);
                    });
                }
            },
            queryAuthority() {
                this.axios(
                    {
                        method:'get',
                        url:'api/module/'+this.moduleId+'/operationAuthority', 
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.operationAuthority = response.data.data
                    }else{
                        this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            }
        },
        mounted: function() {
            this.currentId = this.$route.query.id
            this.moduleId = this.$route.query.moduleId
            this.queryAuthority()
            this.querySelectData()
            this.queryDiditalEmployee()
            this.queryDataNum()
        }
    }
</script>

<style scoped lang="less">
    .digital-detail{
        height: 100%;
        position: relative;
        background: rgba(240, 242, 243, 1);
        .head{
            height: 48px;
            line-height: 48px;
            padding: 0 24px;
            background: #fff;
            z-index: 100;
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            button{
                float: right;
                width: 64px;
                height: 32px;
                margin: 8px 0 0 8px;
            }
            .title{
                color: rgba(18, 18, 18, 1);
                font-size: 16px;
            }
            i{
                float: right;
                margin-top: 16px;
                font-size: 16px;
                color: rgba(204, 204, 204, 1);
                cursor: pointer;
            }
        }
        .content{
            position: absolute;
            padding: 64px 16px 68px 16px;
            left: 0px;
            top: 0px;
            height: 100%;
            padding-top: 64px;
            width: 100%;
            .content-in{
                width: 100%;
                height: 100%;
                position: relative;
                .modular-content{
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    height: 100%;
                    width: 100%;
                    overflow-y: auto;
                    display: flex;
                    .left-content{
                        width: 288px;
                        height: 100%;
                        background: #fff;
                        border-radius: 4px;
                        padding: 21px 24px;
                        .related-module{
                            color: rgba(18, 18, 18, 1);
                            font-size: 14px;
                            span{
                                width: 3px;
                                height: 14px;
                                float: left;
                                background: rgba(24, 144, 255, 1);
                                margin: 4px 7px 0 0;
                            }
                        }
                        .module-list{
                            padding-top: 16px;
                            li{
                                cursor: pointer;
                                padding: 8px 0;
                                i{
                                    font-size: 16px;
                                    color: rgba(51, 51, 51, 1);
                                    margin-left: 24px;
                                }
                                div{
                                    display: inline-block;
                                    color: rgba(18, 18, 18, 1);
                                    font-size: 14px;
                                    margin-left: 6px;
                                }
                                span{
                                    display: inline-block;
                                    width: 18px;
                                    height: 18px;
                                    text-align: center;
                                    line-height: 18px;
                                    background: rgba(245, 87, 78, 1);
                                    color: #fff;
                                    border-radius: 9px;
                                    float: right;
                                    margin-right: 10px;
                                }
                            }
                            li.active{
                                background: rgba(24, 144, 255, 0.1);
                                border-radius: 19px;
                                i{
                                    color: rgba(24, 144, 255, 1);
                                }
                                div{
                                    color: rgba(24, 144, 255, 1);
                                }
                            }
                        }
                    }
                    .center-content{
                        flex: 1;
                        height: 100%;
                        margin: 0 24px;
                        overflow-y: auto;
                        .basic-info{
                            width: 100%;
                            // height: 704px;
                            background: #fff;
                            border-radius: 4px;
                            padding: 24px;
                            .title{
                                color: rgba(18, 18, 18, 1);
                                font-size: 14px;
                            }
                            .col-left{
                                padding-right: 24px;
                            }
                            .col-right{
                                padding-left: 24px;
                            }
                            .row-list{
                                .col-list{
                                    margin-top: 19px;
                                    margin-bottom: 5px;
                                    height: 69px;
                                    .first-content{
                                        color: rgba(188, 188, 188, 1);
                                        font-size: 12px;
                                    }
                                    .second-content{
                                        color: rgba(51, 51, 51, 1);
                                        font-size: 14px;
                                        margin-top: 9px;
                                        img{
                                            width: 21px;
                                        }
                                        .change-head{
                                            color: rgba(24, 144, 255, 1);
                                            font-size: 12px;
                                            cursor: pointer;
                                            margin-left: 24px;
                                        }
                                    }
                                    .selection-range{
                                        margin-top: 9px;
                                        width: 100%;
                                    }
                                }
                            }
                        }  
                        .comments{
                            padding: 18px 24px;
                            background: #fff;
                            margin-top: 16px;
                            border-radius: 4px;
                            .title{
                                color: rgba(18, 18, 18, 1);;
                                font-size: 14px;
                            }
                            .textarea-comment{
                                margin-top: 20px;
                                background: rgba(247, 247, 247, 1);
                                border: none;
                                resize: none;
                            }
                            .release{
                                text-align: right;
                                color: rgba(24, 144, 255, 1);
                                font-size: 14px;
                                margin-top: 4px;
                                span{
                                    cursor: pointer;
                                }
                            }
                        }  
                    }
                    .right-content{
                        width: 288px;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        .data-info{
                            width: 100%;
                            height: 280px;
                            background: #fff;
                            border-radius: 4px;
                            margin-bottom: 16px;
                            padding: 18px 24px;
                            .related-info{
                                color: rgba(18, 18, 18, 1);
                                font-size: 14px;
                                span{
                                    width: 3px;
                                    height: 14px;
                                    float: left;
                                    background: rgba(24, 144, 255, 1);
                                    margin: 4px 7px 0 0;
                                }
                            }
                            ul{
                                li{
                                    padding-top: 24px;
                                    div:nth-child(1){
                                        color: rgba(188, 188, 188, 1);
                                        font-size: 12px;
                                        margin-bottom: 4px;
                                    }
                                    div:nth-child(2){
                                        color: rgba(80, 80, 80, 1);
                                        font-size: 14px;
                                    }
                                }
                            }
                        }
                        .fast-operate{
                            width: 100%;
                            flex: 1;
                            background: #fff;
                            padding: 18px 24px;
                            .fast-do{
                                color: rgba(18, 18, 18, 1);
                                font-size: 14px;
                                span{
                                    width: 3px;
                                    height: 14px;
                                    float: left;
                                    background: rgba(24, 144, 255, 1);
                                    margin: 4px 7px 0 0;
                                }
                            }
                            .add-task{
                                width: 100%;
                                height: 70px;
                                padding: 10px 0 0 0;
                                box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
                                text-align: center;
                                margin-top: 24px;
                                cursor: pointer;
                                i{
                                    color: rgba(24, 144, 255, 1);
                                    margin-bottom: 4px;
                                }
                                div{
                                    color: rgba(80, 80, 80, 1);
                                    font-size: 14px;
                                }
                            }
                            .need-show{
                                display: none;
                            }
                        }
                    }
                }
            }
        }
        .footer-edit{
            height: 52px;
            line-height: 52px;
            text-align: center;
            position: absolute;
            bottom: 0px;
            left: 0px;
            z-index: 100;
            width: 100%;
            background: #fff;
            box-shadow: 0px 0px 6px 0px rgba(0 0 0 0.06);
            button{
                margin-right: 8px;
            }
        }
    }
    .modal-public{
        .divider1{
            margin: 0px 0 24px 0;
        }
        .divider2{
            margin: 24px 0 12px;
        }
        .divider3{
            margin: 0px 0 12px;
        }
        .btn-group{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                margin-right: 16px;
            }
        }
        .btn-group2{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                background: #fff;
                color: rgba(121, 136, 155, 0.7);
                border: 1px solid rgba(121, 136, 155, 0.5);
                margin-right: 16px;
            }
            button:nth-child(1):hover{
                background: rgba(121, 136, 155, 0.08);
            }
        }
    }
    .upload-modal{
        .divider1{
            margin: 0px 0 24px 0;
        }
        .divider2{
            margin: 24px 0 12px;
        }
        padding-bottom: 50px;
        .btn-group{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                margin-right: 16px;
            }
        }
    }
    .drawer-public{
        height: 100%;
        position: relative;
        padding-bottom: 60px;
        .drawer-title{
            height: 55px;
            width: 100%;
            span{
                color: rgba(18, 18, 18, 1);
                font-size: 16px;
                line-height: 55px;
            }
            i{
                color: rgba(153, 153, 153, 1);
                font-size: 16px;
                margin-left: 16px;
                cursor: pointer;
                line-height: 50px;
            }
        }
        .drawer-head{
            height: 56px;
            background: #fff;
            padding: 0 24px 0;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            z-index: 100;

        }
        .drawer-content{
            padding: 71px 16px 16px 16px;
            width: 100%;
            height: 100%;
            position: absolute;
        }
        .drawer-foot{
            position: absolute;
            left: 0px;
            bottom: 0px;
            width: 100%;
            height: 52px;
            background: #fff;
            box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
            .btn-group{
                text-align: center;
                padding-top: 10px;
                width: 100%;
                button{
                    height: 32px;
                    font-size: 14px;
                }
                button:nth-child(2){
                    margin: 0 16px;
                }
            }
        }
    }
    .drawer-sence{
        .row-head{
            padding-top: 20px;
            border-top: 1px solid rgba(229, 229, 229, 1);
            .col-left{
                div:nth-child(1){
                    color: rgba(188, 188, 188, 1);
                    font-size: 12px;
                }
                div:nth-child(2){
                    color: rgba(80, 80, 80, 1);
                    font-size: 14px;
                    margin-top: 6px;
                }
            }
        }
        .drawer-body-content{
            width: 100%;
            height: 100%;
            border-radius: 4px;
            overflow-y: auto;
            padding: 24px;
            background: #fff;
            .pagination-table{
                float: right;
                padding-top: 24px;
            }
        }
    }
    .drawer-newtask{
        .newtask-body{
            height: 496px;
            padding: 16px;
            .newtask-content{
                width: 100%;
                height: 100%;
                overflow: hidden;
                background: #fff;
                padding: 24px;
                border-radius: 4px;
                .col-left{
                    padding-right: 24px;
                }
                .col-right{
                    padding-left: 24px;
                }
                .row-list{
                    .col-list{
                        margin-top: 19px;
                        margin-bottom: 5px;
                        .select-admin{
                            width: 336px;
                            height: 32px;
                            margin-top: 9px;
                        }
                        .textarea-remarks{
                            margin-top: 9px;
                        }
                        .first-content{
                            color: rgba(188, 188, 188, 1);
                            font-size: 12px;
                        }
                        .second-content{
                            color: rgba(51, 51, 51, 1);
                            font-size: 14px;
                            margin-top: 9px;
                            .digital-staff{
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
</style>