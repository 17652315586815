<template>
    <div class="login-auto">
        <div>正在跳转...</div>
    </div>
</template>

<script>
export default {
    name: 'Login',
    props: {
        msg: String
    },
    components: {},
    data() {
        return {
            token: '',
        }
    },
    methods: {
        intoSystem() {
            this.axios.post('/api/login/superLogin',{
                token: this.token,
            })
            .then(response=> {
                if(response.data.code == 0){
                    this.sessionData('set','token',response.data.data.token);
                    this.sessionData('set','name',response.data.data.name);
                    this.sessionData('set','avatar',response.data.data.avatar);
                    // this.$router.push("/PersonalInfo")
                    this.$router.push({
                        path: "/customerLead",
                        query: {
                            moduleId: 8
                        }
                    })
                }else{
                    this.$message.error(response.data.userTip);
                }
            })
            .catch(error=> {
                console.log(error);
            });
        },
    },
    mounted: function() {
        this.token = this.$route.query.token
        this.intoSystem()
    }
}
</script>

<style scoped lang="less"> 
    .login-auto{
        width: 100%;
        height: 100%;
        display:flex;
        justify-content:center;
        align-items:center;
        div{
            text-align: center;
            font-size: 24px;
            color: #1A73E8;
        }
    }
</style>
